import { useGet } from 'components/api'
import queryString from 'query-string'

export default ({
  bookableId,
  amount,
  guestTypeIds,
  pickedPriceType,
  dateTime,
  from,
  to
}) => {
  const { data, ...rest } = useGet({
    path: `/bookables/${bookableId}/price?${queryString.stringify(
      { amount, dateTime, from, to, guestTypeIds, pickedPriceType },
      { arrayFormat: 'comma' }
    )}`,
    resolve: (data) => {
      return data
    }
  })
  return {
    priceResult: data || {},
    ...rest
  }
}
