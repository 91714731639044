import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import americanExpress from './icons/americanExpress.svg'
import carteBleue from './icons/carteBleue.svg.png'
import carteBancaires from './icons/carteBancaires.jpg'
import chinaUnionPay from './icons/chinaUnionPay.svg'
import dinersClub from './icons/dinersClub.svg'
import discover from './icons/discover.svg.png'
import euro6000 from './icons/euro6000.gif'
import jcb from './icons/jcb.svg.png'
import maestro from './icons/maestro.svg.png'
import mastercard from './icons/mastercard.svg.png'
import multibanco from './icons/multibanco.svg.png'
import visa from './icons/visa.svg.png'
import vPay from './icons/vPay.svg.png'

const paycometIcons = [
  visa,
  mastercard,
  maestro,
  americanExpress,
  dinersClub,
  jcb,
  carteBleue,
  carteBancaires,
  chinaUnionPay,
  multibanco,
  vPay
]
const nmiIcons = [visa, mastercard, discover, americanExpress, dinersClub, jcb]
const cecabankIcons = [visa, mastercard, euro6000]
const powertranzIcons = [visa, mastercard, americanExpress]

const PaymentIconsFooter = ({ gateway }) => {
  const { msg } = useIntl({
    scope: 'paymentIconsFooter'
  })
  const { classNames } = useClassNames({ alias: 'PaymentIconsFooter' })
  const paycomet = gateway === 'paycomet'
  const nmi = gateway === 'nmi'
  const cecabank = gateway === 'cecabank'
  const powertranz = gateway === 'powertranz'
  return (
    <div className={classNames()}>
      <div>
        <span className={classNames('title')}>{msg({ id: 'title' })}</span>
        <div className={classNames('icons')}>
          {paycomet && (
            <>
              {paycometIcons.map((img, index) => {
                return <img src={img} key={index} alt="" />
              })}
            </>
          )}
          {nmi && (
            <>
              {nmiIcons.map((img, index) => {
                return <img src={img} key={index} alt="" />
              })}
            </>
          )}
          {cecabank && (
            <>
              {cecabankIcons.map((img, index) => {
                return <img src={img} key={index} alt="" />
              })}
            </>
          )}
          {powertranz && (
            <>
              {powertranzIcons.map((img, index) => {
                return <img src={img} key={index} alt="" />
              })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

PaymentIconsFooter.propTypes = {
  gateway: PropTypes.string
}

PaymentIconsFooter.defaultProps = {}

PaymentIconsFooter.displayName = 'PaymentIconsFooter'

export default PaymentIconsFooter
