import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '@trileuco/triskel-react-ui/components/router'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  FieldSet,
  Form,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { SubmitButton } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useGetBookablesSearchFilters } from 'components/api'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'

import BookableSearchField from './BookableSearchField'

const enabledInNavSearchFilter = ['search', 'type', 'location', 'date']
const defaultEnabledSearchFilters = [
  'search',
  'type',
  'location',
  'duration',
  'tag',
  'price',
  'date'
]

export const fromJSONToQueryParams = ({
  date = {},
  price = {},
  duration,
  ...rest
}) => ({
  fromDate: date.from && date.from.toISOString(),
  toDate: date.to && date.to.toISOString(),
  minPrice: price.min,
  maxPrice: price.max,
  maxDuration: duration,
  ...rest
})

export const fromQueryParamsToJSON = ({
  fromDate,
  toDate,
  minPrice,
  maxPrice,
  maxDuration,
  ...rest
}) => ({
  date: {
    from: fromDate,
    to: toDate
  },
  price: {
    min: minPrice,
    max: maxPrice
  },
  duration: maxDuration,
  ...rest
})

const BookableSearchForm = (props) => {
  const {
    formDirection,
    defaultValues,
    showInline,
    onSubmit,
    autoSubmit,
    enabledSearchFilters
  } = props
  const { msg } = useIntl({
    scope: 'balaena.bookableSearchForm',
    ignoreGlobalScope: true
  })

  const isDesktop = useMediaQuery(DesktopBreakpoint)

  const { classNames } = useClassNames({ alias: 'BookableSearchForm' })

  const handleSubmit = useCallback(
    (searchParams) => {
      onSubmit(searchParams)
    },
    [onSubmit]
  )
  const handleClear = useCallback(() => {
    onSubmit({})
  }, [onSubmit])

  const isAValidSubmition = useCallback((fieldValue) => {
    let isValid = true
    if (_.has(fieldValue, 'from')) {
      isValid = fieldValue.from !== null && fieldValue.to !== null
    }
    return isValid
  }, [])

  const handleValidation = useCallback(
    (fieldValue, instance) => {
      if (autoSubmit && isAValidSubmition(fieldValue)) {
        instance.debounce(instance.handleSubmit, 1200)
      }
    },
    [autoSubmit, isAValidSubmition]
  )

  const { bookableSearchFilters, loading } = useGetBookablesSearchFilters()

  const enabledSearchFilter = useCallback(
    ({ id, options }) => {
      const filters = _.isEmpty(enabledSearchFilters)
        ? defaultEnabledSearchFilters
        : enabledSearchFilters
      return (
        (enabledInNavSearchFilter.includes(id) || showInline) &&
        filters.includes(id) &&
        (!options || options.length > 1)
      )
    },
    [enabledSearchFilters, showInline]
  )

  const filteredSearchFilters = useMemo(
    () => bookableSearchFilters.filter(enabledSearchFilter),
    [bookableSearchFilters, enabledSearchFilter]
  )
  const FilterField = useMemo(() => {
    const Field = (props) => (
      <BookableSearchField
        onChange={handleValidation}
        key={props.id}
        showInline={showInline}
        {...props}
      />
    )
    Field.propTypes = {
      id: PropTypes.string
    }
    Field.displayName = 'FilterField'
    return Field
  }, [showInline, handleValidation])

  if (loading) {
    return null
  }
  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={classNames([{ formDirection: formDirection }])}
    >
      <FieldSet className={classNames('fieldSet')} direction={formDirection}>
        {filteredSearchFilters.map(FilterField)}
      </FieldSet>
      <div className={classNames('actionButtonsWrapper')}>
        {!autoSubmit && (
          <SubmitButton
            iconBefore="fas fa-search"
            text={msg({
              id:
                _.isEmpty(enabledSearchFilters) || !isDesktop
                  ? 'search'
                  : 'checkAvailability'
            })}
            variant="fill"
            size="l"
            className={classNames('searchBtn')}
          />
        )}
        {showInline && (
          <Button
            onClick={handleClear}
            text={msg({ id: 'clearFilters' })}
            variant="clear"
            size="m"
            className={classNames('clearFiltersBtn')}
          />
        )}
      </div>
    </Form>
  )
}

BookableSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  showInline: PropTypes.bool,
  defaultValues: PropTypes.object,
  formDirection: PropTypes.oneOf(['row', 'column']),
  enabledSearchFilters: PropTypes.array,
  autoSubmit: PropTypes.bool
}

BookableSearchForm.defaultProps = {
  formDirection: 'row',
  defaultValues: {}
}

BookableSearchForm.displayName = 'BookableSearchForm'

export default withRouter(BookableSearchForm)
