import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookableWysiwygBlock from './BookableWysiwygBlock'

const BookableIncludedBlock = ({ bookable }) => {
  const { msg } = useIntl({ scope: '' })
  return (
    <BookableWysiwygBlock
      className="BookableIncluded"
      title={msg({ id: 'included' })}
      content={bookable.info.included}
    />
  )
}

BookableIncludedBlock.propTypes = {
  bookable: PropTypes.shape()
}

BookableIncludedBlock.displayName = 'BookableIncludedBlock'

export default BookableIncludedBlock
