import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Price from 'components/misc/Price'

import BookablePriceAmount from './BookablePriceAmount'

const BookableTotalPrice = ({
  totalPrice,
  currency,
  hasDiscount,
  discountedPrice,
  label
}) => {
  const { classNames } = useClassNames({
    alias: 'BookableTotalPrice'
  })
  return (
    <div className={classNames()}>
      <span className={classNames('label')}>{label}</span>
      {hasDiscount && discountedPrice ? (
        <BookablePriceAmount
          withDiscount
          discountedPriceAmount={
            <Price
              amount={discountedPrice === totalPrice ? 0 : discountedPrice}
              currency={currency}
            />
          }
          basePriceAmount={
            totalPrice && <Price amount={totalPrice} currency={currency} />
          }
        />
      ) : (
        <BookablePriceAmount
          priceAmount={
            totalPrice && <Price amount={totalPrice} currency={currency} />
          }
        />
      )}
    </div>
  )
}

BookableTotalPrice.propTypes = {
  totalPrice: PropTypes.number,
  label: PropTypes.string,
  currency: PropTypes.string,
  hasDiscount: PropTypes.bool,
  discountedPrice: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

BookableTotalPrice.displayName = 'BookableTotalPrice'

export default BookableTotalPrice
