import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import Block from 'components/layout/Block'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import RadioFieldSet from '@trileuco/triskel-react-ui/components/ui/RadioFieldSet'
import { Button, FieldSet } from '@trileuco/triskel-react-ui/components/ui'
import _ from 'lodash'

const ToUpdateBookingStateModal = ({
  handleUpdateState,
  stateOptions,
  isOpen,
  close
}) => {
  const { msg } = useIntl({
    scope: 'balaena.toUpdateBookingStateModal',
    ignoreGlobalScope: true
  })

  const { Form, values = {}, ...instance } = useForm({
    state: ''
  })

  return (
    <Modal
      name="ToUpdateBookingStateModal"
      className="ToUpdateBookingStateModal"
      isOpen={isOpen}
      onClose={close}
      header={<h1>{msg({ id: 'modalTitle' })}</h1>}
      body={
        <Block className="ToUpdateBookingState" variant="clear">
          <Form>
            <FieldSet direction="column">
              <RadioFieldSet
                field="state"
                variant="outline"
                options={stateOptions}
              />
              <Button
                disabled={_.isEmpty(values.state)}
                onClick={() =>
                  handleUpdateState({ state: values.state }).then(() => {
                    close()
                    instance.reset()
                  })
                }
              >
                {msg({ id: 'modalButton' })}
              </Button>
            </FieldSet>
          </Form>
        </Block>
      }
    />
  )
}

ToUpdateBookingStateModal.propTypes = {
  stateOptions: PropTypes.array,
  booking: PropTypes.shape(),
  handleUpdateState: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

ToUpdateBookingStateModal.displayName = 'ToUpdateBookingStateModal'

export default ToUpdateBookingStateModal
