import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { useErrorNotification } from 'modules/bookables/notifications'

const useBookingRemoveAction = ({ bookingId }) => {
  const { msg } = useIntl({
    scope: 'balaena.api.useBookingRemoveAction',
    ignoreGlobalScope: true
  })
  const {
    notify,
    statusTypes: { LOADING, SUCCESS, DANGER }
  } = useNotifications()
  const { notifyError } = useErrorNotification()
  const getNotificationMessage = useCallback(
    (status) => {
      switch (status) {
        case LOADING:
          return msg({ id: 'loading' })
        case DANGER:
          return msg({ id: 'error' })
        case SUCCESS:
          return msg({ id: 'success' })
        default:
          return 'Unknown status: ' + status
      }
    },
    [msg, DANGER, SUCCESS, LOADING]
  )
  const getRemoveActionNotification = useCallback(
    (status) => ({
      id: `BOOKING_${bookingId}_REMOVE_ACTION`,
      message: getNotificationMessage(status),
      status
    }),
    [bookingId, getNotificationMessage]
  )
  const { mutate, ...rest } = useMutate({
    verb: 'PUT',
    path: `/bookings/${bookingId}/delete`
  })
  const mutateWithNotifications = useCallback(() => {
    notify(getRemoveActionNotification(LOADING))
    return mutate()
      .then((response) => {
        notify(getRemoveActionNotification(SUCCESS))
        return response
      })
      .catch((error) => {
        notifyError({
          ...getRemoveActionNotification(DANGER),
          error
        })
        throw error
      })
  }, [
    notify,
    LOADING,
    SUCCESS,
    DANGER,
    mutate,
    notifyError,
    getRemoveActionNotification
  ])
  return { mutate: mutateWithNotifications, ...rest }
}

export default useBookingRemoveAction
