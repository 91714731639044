import { useState, useCallback } from 'react'
import { useGet } from 'components/api'
import _ from 'lodash'
const initialState = {
  articles: [],
  pagination: { page: 1, pageSize: 0 },
  tag: ''
}

const useGetArticles = ({ queryParams, ...options }) => {
  const { tag, pageSize = 10, page = 1 } = queryParams
  const [articlesData, setArticlesData] = useState(initialState)
  const [loadingMore, setLoadingMore] = useState(false)

  const { articles, refetch, ...rest } = useGet({
    path: `/articles`,
    resolve: (data) => {
      const articles =
        articlesData.tag === tag
          ? [...articlesData.articles, ..._.get(data, 'articles')]
          : [..._.get(data, 'articles')]
      setArticlesData({
        articles,
        pagination: {
          ..._.get(data, 'meta.pagination'),
          pageSize:
            articlesData.pagination.pageSize +
            _.get(data, 'meta.pagination.pageSize')
        },
        tag
      })
    },
    queryParams: {
      tag,
      pageSize,
      page
    },
    ...options
  })

  const fetchMore = useCallback(() => {
    setLoadingMore(true)
    refetch({
      queryParams: { tag, pageSize, page: articlesData.pagination.page + 1 }
    }).then(() => {
      setLoadingMore(false)
    })
  }, [refetch, pageSize, tag, articlesData])

  return {
    ...rest,
    fetchMore,
    loadingMore,
    articles: _.get(articlesData, 'articles', []),
    pagination: _.get(articlesData, 'pagination', {})
  }
}

export default useGetArticles
