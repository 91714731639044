import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useGetBundleItemsAvailabilities, LoadingResults } from 'components/api'

const getBookingCurrentStep = (booking, initialStepId) => {
  const isReserved = _.get(booking, 'status') === 'reserved'
  const isPaid = _.get(booking, 'payment.status') === 'paid'
  if (isReserved) {
    return 'payment'
  } else if (isPaid) {
    return 'registry'
  } else if (booking) {
    return 'payment'
  }
  return initialStepId
}

const buildStepsForBooking = (booking, withScheduleStep) => {
  const steps = [
    // in case of bundles - new step is added to select bundle item occurrences
    ...(withScheduleStep ? [{ id: 'schedule' }] : []),
    {
      id: 'details'
    },
    {
      id: 'payment'
    },
    {
      id: 'registry'
    }
  ]
  if (booking) {
    const isReserved = _.get(booking, 'status') === 'reserved'
    const isPaid = _.get(booking, 'payment.status') === 'paid'
    if (isReserved) {
      steps.splice(1, 0, {
        id: 'reserve',
        completed: true
      })
      return steps
    } else if (isPaid) {
      return steps.map((step) => {
        if (step.id === 'registry') {
          return {
            ...step,
            completed: true
          }
        }
        return step
      })
    }
  }
  return steps
}

const BaseStep = ({ indicator, name, state }) => (
  <li className={`BookingBreadcrumb_step ${state || ''}`.trim()}>
    <span className="BookingBreadcrumb_stepIndicator">{indicator}</span>
    <span className="BookingBreadcrumb_stepName">{name}</span>
  </li>
)

BaseStep.propTypes = {
  indicator: PropTypes.object,
  name: PropTypes.string,
  state: PropTypes.oneOf(['current', 'complete'])
}

const Step = ({ step, index, title, currentIndex }) => {
  const completeClass = 'complete'
  const currentClass = 'current'
  const isCurrentStep = currentIndex === index
  const isComplete = Boolean(currentIndex > index || step.completed)
  return (
    <BaseStep
      indicator={
        isComplete ? <i className="fas fa-check" /> : <span>{index + 1}</span>
      }
      name={title}
      state={isComplete ? completeClass : isCurrentStep ? currentClass : null}
    />
  )
}

const stepPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  completed: PropTypes.bool
})

Step.propTypes = {
  steps: PropTypes.arrayOf(stepPropType),
  step: stepPropType,
  title: PropTypes.string,
  currentIndex: PropTypes.number,
  index: PropTypes.number
}

export const BookingBreadcrumb = ({
  formStepId,
  initialBundleItemsAvailabilities,
  booking
}) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingBreadcrumb',
    ignoreGlobalScope: true
  })

  const [bundleItemsAvailabilities, setBundleItemsAvailabilities] = useState(
    initialBundleItemsAvailabilities || null
  )

  const { fetchBundleItemsAvailabilities } = useGetBundleItemsAvailabilities()

  useEffect(() => {
    // for BookingsPaymentPage in order to render schedule step if required
    if (booking && booking.bookable.bundle) {
      fetchBundleItemsAvailabilities({
        id: booking.bookable.id,
        dateTime: booking.dateTime,
        bundleItems: booking.children.map(({ bookable: { id } }) => ({ id })),
        guestAmount: booking.amount
      }).then(({ availabilities }) =>
        setBundleItemsAvailabilities(availabilities)
      )
    }
  }, [booking, fetchBundleItemsAvailabilities])

  const bookingSteps = useMemo(() => {
    return buildStepsForBooking(booking, !_.isEmpty(bundleItemsAvailabilities))
  }, [booking, bundleItemsAvailabilities])

  const bookingCurrentStepId = useMemo(() => {
    return getBookingCurrentStep(booking, formStepId)
  }, [booking, formStepId])

  if (booking && booking.bookable.bundle && !bundleItemsAvailabilities) {
    return <LoadingResults />
  }

  return (
    <ul className="BookingBreadcrumb">
      {bookingSteps.map((step, index) => (
        <Step
          key={step.id}
          step={step}
          steps={bookingSteps}
          index={index}
          currentIndex={bookingSteps.findIndex(
            ({ id }) => bookingCurrentStepId === id
          )}
          title={msg({ id: step.id })}
          currentStepId={bookingCurrentStepId}
        />
      ))}
    </ul>
  )
}

BookingBreadcrumb.propTypes = {
  formStepId: PropTypes.string,
  initialBundleItemsAvailabilities: PropTypes.arrayOf(PropTypes.shape()),
  booking: PropTypes.shape()
}

BookingBreadcrumb.displayName = 'BookingBreadcrumb'

export default BookingBreadcrumb
