import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { useErrorNotification } from 'modules/bookables/notifications'

const useBookingPaymentGatewayPurchase = ({
  token,
  bookingId,
  reservationPayment,
  ...options
}) => {
  const { mutate, ...state } = useMutate({
    verb: 'POST',
    path: `/bookings/${bookingId}/payment/gateway/purchase`,
    queryParams: {
      reservationPayment
    },
    ...options
  })

  const {
    notify,
    statusTypes: { LOADING, SUCCESS, DANGER }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useBookingPaymentGatewayPurchase',
    ignoreGlobalScope: true
  })
  const { notifyError } = useErrorNotification()

  const getNotificationMessage = useCallback(
    (status) => {
      switch (status) {
        case LOADING:
          return msg({ id: 'loading' })
        case DANGER:
          return msg({ id: 'error' })
        case SUCCESS:
          return msg({ id: 'success' })
        default:
          return 'Unknown status: ' + status
      }
    },
    [msg, DANGER, SUCCESS, LOADING]
  )
  const getPaymentGatewayNotification = useCallback(
    (status) => ({
      id: `BOOKING_${bookingId}_PAYMENT_GATEWAY_PURCHASE`,
      message: getNotificationMessage(status),
      status
    }),
    [bookingId, getNotificationMessage]
  )

  const mutateWithNotifications = useCallback(
    (params) => {
      notify(getPaymentGatewayNotification(LOADING))
      return mutate(params)
        .then((response) => {
          notify(getPaymentGatewayNotification(SUCCESS))
          return response.purchase
        })
        .catch((error) => {
          notifyError({
            ...getPaymentGatewayNotification(DANGER),
            error
          })
          throw error
        })
    },
    [
      notify,
      notifyError,
      LOADING,
      SUCCESS,
      DANGER,
      mutate,
      getPaymentGatewayNotification
    ]
  )
  return {
    mutate: mutateWithNotifications,
    ...state
  }
}

export default useBookingPaymentGatewayPurchase
