import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import { TextField } from '@trileuco/triskel-react-ui/components/ui'

const WalletCardNumberInput = formalizer(
  ({ value, onChange, maxDigits, ...props }) => {
    const handleChange = useCallback(
      (value) => {
        onChange(
          value.replace(new RegExp('[^0-9]', 'ig'), '').substring(0, maxDigits)
        )
      },
      [onChange, maxDigits]
    )
    const localValue = useMemo(() => {
      if (value) {
        return value
          .replace(new RegExp('[^0-9]', 'ig'), '')
          .substring(0, maxDigits)
          .split('')
          .reduce((l, v, i) => (i % 4 === 0 ? `${l} ${v}` : `${l}${v}`), '')
          .trim()
      } else {
        return ''
      }
    }, [value, maxDigits])
    return <TextField value={localValue} onChange={handleChange} {...props} />
  }
)

WalletCardNumberInput.propTypes = {
  maxDigits: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string
}

WalletCardNumberInput.defaultProps = {
  maxDigits: 16
}

WalletCardNumberInput.displayName = 'WalletCardNumberInput'

export default WalletCardNumberInput
