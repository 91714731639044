import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const BookableGuestsCell = ({ guests }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable',
    ignoreGlobalScope: true
  })
  return (
    <div className="BookableGuestsCell">
      {guests.length > 0 ? (
        <Fragment>
          <span className="BookableGuestsCell_guestsLength">
            {guests.length === 1
              ? guests[0].firstName + ' ' + guests[0].lastName
              : guests.length + ' ' + msg({ id: 'guests' })}
          </span>
        </Fragment>
      ) : (
        '-'
      )}
    </div>
  )
}

BookableGuestsCell.propTypes = {
  guests: PropTypes.arrayOf(PropTypes.object)
}

BookableGuestsCell.displayName = 'BookableGuestsCell'

export default BookableGuestsCell
