import React, { useState, useCallback, useEffect } from 'react'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import {
  IconButton,
  Modal,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import {
  useLocation,
  useHistory
} from '@trileuco/triskel-react-ui/components/router'
import BookableSearchForm, {
  fromJSONToQueryParams,
  fromQueryParamsToJSON
} from 'components/bookable/BookableSearchForm'
import { generateBookablesSearchPath } from 'modules/bookables/routes'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const SearchModal = ({
  providerId = null,
  enabledSearchFilters = [],
  toggleProviderSearchBar = null
}) => {
  const { msg } = useIntl({
    scope: 'balaena.searchModal',
    ignoreGlobalScope: true
  })
  const [showModal, toggleModal] = useState(false)

  const location = useLocation()
  const history = useHistory()

  const filter = useQueryParams({
    type: QueryParamTypes.Array,
    location: QueryParamTypes.Array,
    fromDate: QueryParamTypes.Date,
    toDate: QueryParamTypes.Date,
    maxDuration: QueryParamTypes.String,
    tag: QueryParamTypes.Array,
    minPrice: QueryParamTypes.Int,
    maxPrice: QueryParamTypes.Int,
    keyword: QueryParamTypes.String
  })

  useEffect(() => {
    toggleModal(false)
  }, [location])

  const handleSubmit = useCallback(
    (searchFormValue) => {
      const params = {
        ...(providerId && { providerIds: providerId }),
        ...searchFormValue
      }
      history.push(
        generateBookablesSearchPath({
          searchParams: fromJSONToQueryParams(params)
        })
      )
    },
    [history, providerId]
  )

  return (
    <>
      {providerId === null ? (
        <IconButton
          icon={showModal ? 'fas fa-times' : 'fas fa-search'}
          size="l"
          variant="clear"
          onClick={() => toggleModal(!showModal)}
        />
      ) : (
        <Button
          size="l"
          variant="fill"
          onClick={() => toggleModal(!showModal)}
          text={msg({ id: 'search' })}
          iconBefore="fas fa-search"
        />
      )}

      <Modal
        isOpen={showModal}
        onClose={() => {
          toggleModal(false)
          if (toggleProviderSearchBar !== null) {
            toggleProviderSearchBar(false)
          }
        }}
        className="BookableSearchFormModal"
        body={
          <BookableSearchForm
            showInline
            formDirection="column"
            onSubmit={handleSubmit}
            defaultValues={fromQueryParamsToJSON(filter)}
            enabledSearchFilters={enabledSearchFilters}
          />
        }
      />
    </>
  )
}

SearchModal.displayName = 'SearchModal'

export default SearchModal
