import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutate } from 'restful-react'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useGenerateBookablePath } from 'modules/bookables/routes'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { useErrorNotification } from 'modules/bookables/notifications'

const BookableLink = ({ bookable }) => {
  const generateBookablePath = useGenerateBookablePath()
  return <Link to={generateBookablePath(bookable)}>{bookable.name}</Link>
}

BookableLink.propTypes = {
  bookable: PropTypes.shape()
}

const useBookBookable = ({ bookable }) => {
  const { msg } = useIntl({
    scope: 'balaena.api.useBookBookable',
    ignoreGlobalScope: true
  })
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()
  const { notifyError } = useErrorNotification()
  const { mutate, ...rest } = useMutate({
    verb: 'POST',
    path: `/bookables/${bookable.slug}/book`
  })
  const mutateWithNotifications = useCallback(
    (params) => {
      notify({
        id: 'BOOK_BOOKABLE_NOTIFICATION',
        message: msg({ id: 'loading' }),
        title: <BookableLink bookable={bookable} />,
        status: LOADING
      })
      return mutate(params)
        .then((response) => {
          notify({
            id: 'BOOK_BOOKABLE_NOTIFICATION',
            message: msg({ id: 'success' }),
            title: <BookableLink bookable={bookable} />,
            status: SUCCESS
          })
          return response.booking
        })
        .catch((error) => {
          notifyError({
            id: 'BOOK_BOOKABLE_NOTIFICATION',
            title: <BookableLink bookable={bookable} />,
            error
          })
          throw error
        })
    },
    [notify, msg, bookable, LOADING, mutate, SUCCESS, notifyError]
  )
  return { mutate: mutateWithNotifications, ...rest }
}

export default useBookBookable
