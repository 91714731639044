import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export const toVariantUrl = (photo, variant) => {
  if (photo instanceof Object) {
    return _.get(photo, 'versions.' + variant) || photo.url
  }
  return photo
}

const PhotoImg = ({ photo, className, variant, alt }) => {
  return (
    <img
      className={className}
      src={toVariantUrl(photo, variant)}
      alt={alt || _.get(photo, 'alt')}
    />
  )
}

PhotoImg.propTypes = {
  photo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  alt: PropTypes.string,
  variant: PropTypes.string
}

PhotoImg.defaultProps = {
  variant: 'gallery',
  alt: ''
}

PhotoImg.displayName = 'PhotoImg'

export default PhotoImg
