import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  Form,
  Button,
  TextField
} from '@trileuco/triskel-react-ui/components/ui'

const PowertranzPaymentGatewayFieldSet = ({ onSubmit }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.PowertranzPayment',
    ignoreGlobalScope: true
  })

  const ERROR_MESSAGES = {
    emptyCardNumber: msg({ id: 'emptyCardNumber' }),
    invalidCardNumber: msg({ id: 'invalidCardNumber' }),
    emptyExpiryDate: msg({ id: 'emptyExpiryDate' }),
    monthOutOfRange: msg({ id: 'monthOutOfRange' }),
    yearOutOfRange: msg({ id: 'yearOutOfRange' }),
    dateOutOfRange: msg({ id: 'dateOutOfRange' }),
    invalidExpiryDate: msg({ id: 'invalidExpiryDate' }),
    emptyCVC: msg({ id: 'emptyCVC' }),
    invalidCVC: msg({ id: 'invalidCVC' })
  }

  const normalizeCardHolder = (value) => {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '')
  }

  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES
  })

  const [cardData, setCardData] = useState({
    cvc: '',
    expiryDate: '',
    cardNumber: '',
    cardHolder: ''
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCardData({
      ...cardData,
      [name]: value
    })
  }

  const { erroredInputs, touchedInputs } = meta

  const validCardData = useMemo(() => {
    return (
      (meta.error && meta.error.length > 0) || cardData.cardHolder.length === 0
    )
  }, [meta, cardData])

  return (
    <div className="PowertranzForm">
      <Form>
        <TextField
          field="text"
          label={msg({ id: 'cardHolder' })}
          name="cardHolder"
          variant="outline"
          onChange={(value) => {
            setCardData({
              ...cardData,
              cardHolder: normalizeCardHolder(value)
            })
          }}
          rules={['required']}
        />
        <PaymentInputsWrapper
          {...wrapperProps}
          styles={{
            inputWrapper: {
              errored: css`
                border-color: #ff4444;
              `
            },
            input: {
              errored: css`
                color: #ff4444;
              `
            },
            errorText: {
              base: css`
                color: #ff4444;
                padding: 0 12px;
                font-size: 11px;
                font-style: italic;
              `
            }
          }}
        >
          <svg {...getCardImageProps({ images })} />
          <input
            {...getCardNumberProps({ onChange: handleInputChange })}
            placeholder="0000 0000 0000 0000"
            maxlength="19"
            state={
              erroredInputs.cardNumber && touchedInputs.cardNumber
                ? 'danger'
                : undefined
            }
            validationText={
              touchedInputs.cardNumber && erroredInputs.cardNumber
            }
          />
          <input
            {...getExpiryDateProps({ onChange: handleInputChange })}
            placeholder={`12 / ${(new Date().getFullYear() % 100) + 1}`}
            maxlength="7"
            state={
              erroredInputs.expiryDate && touchedInputs.expiryDate
                ? 'danger'
                : undefined
            }
            validationText={
              touchedInputs.expiryDate && erroredInputs.expiryDate
            }
          />
          <input
            {...getCVCProps({ onChange: handleInputChange })}
            placeholder="123"
            maxlength="3"
            state={
              erroredInputs.cvc && touchedInputs.cvc ? 'danger' : undefined
            }
            validationText={touchedInputs.cvc && erroredInputs.cvc}
          />
        </PaymentInputsWrapper>
        <Button
          type="submit"
          text={msg({ id: 'continue' })}
          disabled={validCardData}
          onClick={() => {
            onSubmit(cardData)
          }}
        />
      </Form>
    </div>
  )
}

PowertranzPaymentGatewayFieldSet.propTypes = {
  onSubmit: PropTypes.func
}

PowertranzPaymentGatewayFieldSet.displayName = 'PowertranzPaymentTokenFieldSet'

export default PowertranzPaymentGatewayFieldSet
