import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Page from 'components/layout/Page'
import { MessageProvider } from '@trileuco/triskel-react-ui/components/i18n'
import { Redirect } from '@trileuco/triskel-react-ui/components/router'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import useBookableAnalytics from 'modules/bookables/hooks/useBookableAnalytics'
import {
  decodeQueryParamsFromLocationSearch,
  generateBookingPath,
  useGenerateBookablePath,
  homePath
} from 'modules/bookables/routes'
import { useGetBookable, LoadingResults } from 'components/api'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BookingForm from 'components/booker/BookingForm/BookingForm'
import AuthModal from 'components/auth/AuthModal'
import NotFoundError from 'components/error/NotFoundError'

const BookingPage = ({ match, location, history }) => {
  const { authenticated, hasSomeRole } = useAuth()
  const { bookable, loading } = useGetBookable({
    bookableId: match.params.id
  })
  const generateBookablePath = useGenerateBookablePath()

  const bookableAnalytics = useBookableAnalytics()

  const handleBooking = useCallback(
    (bookResponse) => {
      if (bookable) {
        bookableAnalytics.impressionAction({
          bookable
        })
      }
      history.push(generateBookingPath({ id: bookResponse.id }))
    },
    [bookable, bookableAnalytics, history]
  )

  if (loading) {
    return <LoadingResults />
  }
  if (!bookable) {
    throw new NotFoundError('Bookable not found for id ' + match.params.id)
  }

  const queryParams = _.get(location, 'search')

  if (!queryParams) {
    return <Redirect to={generateBookablePath(bookable)} />
  }

  const {
    guests,
    dateTime,
    dateRange,
    pickedPriceType
  } = decodeQueryParamsFromLocationSearch(queryParams)

  return (
    <Page id="BookingPage" analytics={{ pagetype: 'Booking' }}>
      {!authenticated && (
        <AuthModal
          isOpen={!authenticated}
          onClose={() => {
            if (history.length > 2) {
              history.goBack()
            } else {
              history.push(homePath)
            }
          }}
        />
      )}
      <MessageProvider scope="bookingPage">
        <PublicPageSkeleton
          renderBalaenaToolkitAd={false}
          renderCategoriesFooter={false}
          renderTopFooter={false}
          mainContent={
            <BookingForm
              isAdmin={hasSomeRole([
                'balaena-admin',
                'balaena-bookings-manager'
              ])}
              bookable={bookable}
              dateTime={dateTime}
              dateRange={dateRange}
              guests={guests}
              onBooking={handleBooking}
              pickedPriceType={
                pickedPriceType || (bookable && bookable.priceTypes[0])
              }
            />
          }
        />
      </MessageProvider>
    </Page>
  )
}

BookingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.object
}

BookingPage.displayName = 'BookingPage'

export default BookingPage
