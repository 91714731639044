import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Block from 'components/layout/Block'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import BookingTicketsSummary from 'modules/bookables/pages/bookingTickets/BookingTicketsSummary'
import BookingDate from 'components/booker/BookingForm/BookingDate'
import _ from 'lodash'
import BookingTicketsActions from 'modules/bookables/pages/bookingTickets/BookingTicketsActions'

const BookingTicket = ({ booking, refetch }) => {
  const { classNames } = useClassNames({
    alias: 'BookingTicket'
  })
  const { msg } = useIntl({
    scope: 'balaena.BookingTicket',
    ignoreGlobalScope: true
  })

  const { msg: msgPayment } = useIntl({
    scope: 'balaena.bookingPaymentStatus',
    ignoreGlobalScope: true
  })
  const isPaid =
    _.get(booking, 'payment.status') === 'paid' ||
    _.get(booking, 'payment.status') === 'invited'
  return (
    <Block
      className={classNames()}
      variant="clear"
      title={
        <>
          <spa className={classNames('bookingId')}>
            {msg({ id: 'block_title' }, { id: booking.hashId })}
            {!isPaid && (
              <span className={classNames('pendingPayment')}>
                {_.capitalize(msgPayment({ id: 'pending' }))}
              </span>
            )}
          </spa>
          <span className={classNames('bookableName')}>
            {booking.bookable.name}
          </span>
          <span className={classNames('bookingDate')}>
            <BookingDate
              dateTime={booking.dateTime}
              dateRange={booking.dateRange}
              timeZone={booking.bookable.timeZone}
            />
          </span>
        </>
      }
    >
      <BookingTicketsSummary booking={booking} />
      {isPaid && <BookingTicketsActions booking={booking} refetch={refetch} />}
    </Block>
  )
}

BookingTicket.propTypes = {
  refetch: PropTypes.func,
  booking: PropTypes.shape()
}

BookingTicket.displayName = 'BookingTicket'

export default BookingTicket
