import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'

const QuestionsSummary = ({ questions }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  return (
    <Block
      className="QuestionSummary"
      title={msg({ id: 'questionsBlockTitle' })}
    >
      {questions.map(({ id, question: { title }, answer }, index) => {
        return (
          <div className="Question" key={id}>
            <p className="Question_title">{title}</p>
            <p className="Question_answer">{answer}</p>
          </div>
        )
      })}
    </Block>
  )
}

QuestionsSummary.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.shape({
        title: PropTypes.string
      }),
      id: PropTypes.number,
      answer: PropTypes.any
    })
  )
}

QuestionsSummary.displayName = 'QuestionsSummary'

export default QuestionsSummary
