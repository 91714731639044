import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useAnalytics } from '@trileuco/triskel-react-ui/modules/analytics'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import {
  Page as TriskelPage,
  Link
} from '@trileuco/triskel-react-ui/components/ui'
import { useLocation } from '@trileuco/triskel-react-ui/components/router'
import { useTenantConfig } from 'modules/bookables/Provider'
import CookiesConsentBar from 'components/layout/CookiesConsentBar'
import { cookiesPath } from 'modules/bookables/routes'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'

function useScrollToTopEffect() {
  const { pathname, search } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, search])
}

const useSetDimesionsEffect = (options) => {
  const { analytics } = useTenantConfig()

  const Analytics = useAnalytics()

  const location = useLocation()

  const { authenticated, userInfo } = useAuth()

  useEffect(() => {
    if (analytics) {
      const { dimensions = [] } = analytics
      const customDimensions = {
        [dimensions.pagetype]: _.get(options, 'pagetype'),
        [dimensions.islogged]: authenticated,
        [dimensions.userId]: authenticated && userInfo.sub,
        [dimensions.referrer]: document.referrer
      }
      Analytics.set(customDimensions)
      Analytics.pageview(location.pathname + location.search)
    }
  }, [userInfo, authenticated, options, location, analytics, Analytics])
}

const Page = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.page',
    ignoreGlobalScope: true
  })

  const { analytics } = props
  useSetDimesionsEffect(analytics)

  const { legalPages } = useTenantConfig()

  const hasCookiesPage =
    legalPages &&
    legalPages.find((legalPage) => legalPage.termOfUseSlug === 'cookies')

  useScrollToTopEffect()

  return (
    <>
      <TriskelPage {...props} />
      {hasCookiesPage && (
        <CookiesConsentBar
          text={
            <>
              {msg({ id: 'cookiesConsent' })}{' '}
              <Link to={cookiesPath}>{msg({ id: 'cookiesConsent.here' })}</Link>
            </>
          }
        />
      )}
    </>
  )
}

Page.propTypes = {
  analytics: PropTypes.object
}

Page.displayName = 'Page'

export default Page
