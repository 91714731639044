import Webpage from './pages/web/Webpage'
import LegalPage from './pages/legal/LegalPage'

export const tenantWebpages = (
  tenantConfig,
  filter = ['home', 'blog', 'contact', 'toolkit', 'providers']
) => {
  if (!tenantConfig.webpages) {
    return []
  }
  return tenantConfig.webpages.filter(({ slug }) => !filter.includes(slug))
}

export const buildWebpagesModule = (tenantConfig) => {
  const webpagesModule = {
    key: 'webpages',
    routes: [],
    messages: {},
    reducer: (state = {}, action) => {
      return state
    }
  }
  if (tenantConfig.legalPages) {
    webpagesModule.routes = webpagesModule.routes.concat(
      tenantConfig.legalPages.map(({ termOfUseSlug }) => ({
        path: '/' + termOfUseSlug,
        component: LegalPage,
        exact: true
      }))
    )
  }
  if (tenantConfig.webpages) {
    webpagesModule.routes = webpagesModule.routes.concat(
      tenantWebpages(tenantConfig).map(({ slug }) => ({
        path: '/' + slug,
        component: Webpage,
        exact: true
      }))
    )
  }
  return webpagesModule
}
