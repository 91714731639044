import React from 'react'
import PropTypes from 'prop-types'
import ImageAndTextSection from 'components/cms/ImageAndTextSection'
import ProvidersLocationsSection from 'components/cms/ProvidersLocationsSection'
import HighlightedBookablesSection from 'components/cms/HighlightedBookablesSection'
import RootCategoriesSection from 'components/cms/RootCategoriesSection'
import SafeHtml from 'components/cms/SafeHtml'
import ProvidersCarouselSection from 'components/cms/ProvidersCarouselSection'
import LastArticlesSection from 'components/cms/LastArticlesSection'
import ProviderPage from 'modules/bookables/pages/provider/ProviderPage'

const WebpageSections = ({ sections }) => {
  return sections.map(({ type, ...section }, index) => {
    switch (type) {
      case 'RootCategories':
        return <RootCategoriesSection key={index} {...section} />
      case 'HighlightedBookables':
        return <HighlightedBookablesSection key={index} {...section} />
      case 'ProvidersLocations':
        return <ProvidersLocationsSection key={index} {...section} />
      case 'ProvidersCarousel':
        return <ProvidersCarouselSection key={index} {...section} />
      case 'LastArticleEntries':
        return <LastArticlesSection key={index} {...section} />
      case 'ImageAndText':
        return <ImageAndTextSection key={index} {...section} />
      case 'ProviderPage':
        return (
          <ProviderPage
            key={index}
            match={{ params: { slug: section.slug } }}
            renderFooter={false}
            renderNavBar={false}
            renderBreadcrumb={false}
            {...section}
          />
        )
      case 'Text':
        return (
          <div key={index} className="PageTextContent">
            <SafeHtml html={section.content} />
          </div>
        )
      case 'Html':
        return (
          <div
            key={index}
            className="PageHtmlContent"
            dangerouslySetInnerHTML={{ __html: section.content }}
          />
        )
      default:
        return null
    }
  })
}

WebpageSections.propTypes = {
  sections: PropTypes.array
}

WebpageSections.displayname = 'WebpageSections'

export default WebpageSections
