import React from 'react'
import PropTypes from 'prop-types'
import NoPhoto from 'assets/img/fallback/missing_provider_photo.svg'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import PhotoImg from 'components/misc/PhotoImg'
import _ from 'lodash'

const ProviderLogo = ({ provider, className }) => {
  const { logo, logoSvg } = provider
  return (
    <Visible
      condition={!_.isEmpty(logoSvg)}
      ifTrue={
        <img
          className={className}
          src={logoSvg?.url}
          alt={logoSvg ? logoSvg.alt : ''}
        />
      }
      otherwise={
        <Visible
          condition={!_.isEmpty(logo)}
          ifTrue={
            <PhotoImg photo={logo} variant="medium" className={className} />
          }
          otherwise={<img className={className} src={NoPhoto} alt="" />}
        />
      }
    />
  )
}

ProviderLogo.propTypes = {
  provider: PropTypes.shape(),
  className: PropTypes.string
}

ProviderLogo.displayName = 'ProviderLogo'

export default ProviderLogo
