import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'

const useGetBackendError = () => {
  const { msg } = useIntl({
    scope: 'backend.errors',
    ignoreGlobalScope: true
  })

  const getBackendError = (error) => {
    return error.errorCode
      ? msg(
          {
            id: error.errorCode,
            defaultMessage: error.message
          },
          error.args || {}
        )
      : error.message
  }
  return {
    getBackendError
  }
}

export default useGetBackendError
