import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext
} from 'react'
import useFetch from 'components/api/useFetch'

const initialApiCache = {}

export const ApiCacheContext = createContext(initialApiCache)

export const useApiCache = () => {
  return useContext(ApiCacheContext)
}

export const useFetchCache = ({
  path,
  resolve,
  entity,
  skip,
  pollInterval,
  onError,
  ...fetchOptions
}) => {
  const fetch = useFetch()

  const entityCacheId = `${entity}${
    fetchOptions.queryParams
      ? `-${btoa(JSON.stringify(fetchOptions.queryParams))}`
      : ''
  }`

  const { apiCache, updateCache } = useApiCache()

  const cacheEntity = useMemo(() => {
    return (
      apiCache[entityCacheId] || {
        loading: true,
        error: false,
        [entity]: undefined
      }
    )
  }, [entityCacheId, entity, apiCache])

  const refetch = useCallback(
    (refetchOptions = {}) => {
      updateCache((cache) => ({
        [entityCacheId]: {
          ...cache[entityCacheId],
          error: false,
          reloading: true
        }
      }))
      return fetch(path, {
        ...fetchOptions,
        ...refetchOptions
      })
        .then((response) => {
          const data = {
            loading: false,
            reloading: false,
            error: false,
            [entity]: !resolve ? response[entity] : resolve(response)
          }
          updateCache({
            [entityCacheId]: data
          })
          return data
        })
        .catch((error) => {
          const data = {
            loading: false,
            reloading: false,
            error,
            [entity]: undefined
          }
          updateCache({
            [entityCacheId]: data
          })
          if (onError) {
            onError(error)
          } else {
            return data
          }
        })
    },
    [
      entity,
      fetch,
      path,
      fetchOptions,
      updateCache,
      resolve,
      entityCacheId,
      onError
    ]
  )

  useEffect(() => {
    if (!apiCache[entityCacheId]) {
      updateCache({
        [entityCacheId]: {
          loading: !skip,
          error: false,
          [entity]: undefined
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, skip, entityCacheId])

  useEffect(() => {
    if (pollInterval) {
      const interval = setInterval(() => {
        refetch()
      }, pollInterval)
      return () => {
        clearInterval(interval)
      }
    }
  }, [refetch, pollInterval])

  useEffect(() => {
    if (
      (!apiCache[entityCacheId] || fetchOptions.ignoreCache === true) &&
      !fetchOptions.skip
    ) {
      // XXX: State changed by reference in order avoid paralell invocations of the same hook
      apiCache[entityCacheId] = {
        loading: true,
        error: false,
        [entity]: undefined
      }
      refetch()
    }
  }, [
    apiCache,
    fetchOptions.ignoreCache,
    refetch,
    entity,
    fetchOptions.skip,
    entityCacheId
  ])

  return {
    ...cacheEntity,
    refetch
  }
}

const useForceUpdate = () => {
  const [, setTick] = useState(0)
  const update = useCallback(() => {
    setTick((tick) => tick + 1)
  }, [])
  return update
}

export const ApiCacheProvider = (props) => {
  const apiCacheRef = React.useRef({ current: initialApiCache })
  const forceUpdate = useForceUpdate()
  const updateCache = useCallback(
    (updatedApiCache) => {
      if (updatedApiCache instanceof Function) {
        apiCacheRef.current = {
          ...apiCacheRef.current,
          ...updatedApiCache(apiCacheRef.current)
        }
      } else {
        apiCacheRef.current = {
          ...apiCacheRef.current,
          ...updatedApiCache
        }
      }
      forceUpdate()
    },
    [forceUpdate]
  )

  return (
    <ApiCacheContext.Provider
      value={{ apiCache: apiCacheRef.current, updateCache }}
      {...props}
    />
  )
}

ApiCacheProvider.displayName = 'ApiCacheProvider'

export default ApiCacheContext
