import React, { forwardRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const StatelessSidebar = (
  {
    children,
    visible,
    placement,
    onClick,
    offsetX,
    offsetY,
    className,
    ...rest
  },
  ref
) => {
  const { classNames } = useClassNames({ alias: 'Sidebar' })

  const handleClick = useCallback(
    (event) => {
      onClick(event)
    },
    [onClick]
  )

  const sidebarStyles = useMemo(() => {
    const styles = {
      ...(placement === 'right' &&
        offsetX && {
          right: offsetX
        }),
      ...(placement === 'left' &&
        offsetX && {
          left: offsetX
        }),
      ...(offsetY && {
        top: offsetY,
        height: `calc(100vh - ${offsetY}px)`
      })
    }
    return styles
  }, [placement, offsetX, offsetY])

  return (
    <>
      <CSSTransition
        {...rest}
        in={visible}
        mountOnEnter
        unmountOnExit
        appear
        timeout={{ enter: 0, exit: 3000 }}
        classNames={{
          enter: classNames(),
          enterVisible: classNames({ visible: true }),
          enterDone: classNames({ visible: true }),
          exit: classNames()
        }}
      >
        <div
          ref={ref}
          style={sidebarStyles}
          className={`${className} ${classNames([{ placement }])}`}
          onClick={handleClick}
        >
          {children}
        </div>
      </CSSTransition>
    </>
  )
}

StatelessSidebar.displayName = 'StatelessSidebar'

StatelessSidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  visible: PropTypes.bool,
  placement: PropTypes.oneOf(['left', 'right']),
  offsetY: PropTypes.number,
  offsetX: PropTypes.number,
  onOverlayClick: PropTypes.func,
  onClick: PropTypes.func,
  onEnter: PropTypes.func,
  onEntering: PropTypes.func,
  onEntered: PropTypes.func,
  onExit: PropTypes.func,
  onExiting: PropTypes.func,
  onExited: PropTypes.func
}

StatelessSidebar.defaultProps = {
  placement: 'right',
  className: '',
  noOverlay: false,
  visible: false,
  onClick: () => null,
  onOverlayClick: () => null
}

export default forwardRef(StatelessSidebar)
