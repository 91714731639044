import { useState } from 'react'
import * as Cookies from 'js-cookie'
import _ from 'lodash'

const useCookie = (key, initialValue = '') => {
  const initialState = () => {
    return Cookies.get(key) || initialValue
  }

  const [item, setItemValue] = useState(() => initialState())

  const setValue = (value, options) => {
    if (!_.isNil(value)) {
      setItemValue(value)
      Cookies.set(key, value, options)
    }
  }

  const removeItem = (options) => {
    Cookies.remove(key)
  }

  return { item, setValue, removeItem }
}

export default useCookie
