import { useMemo, useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import queryString from 'query-string'

import useFetch from '../useFetch'

const useFetchBookable = () => {
  const fetch = useFetch()
  const fetchBookable = useCallback(({ slug }) => fetch(`/bookables/${slug}`), [
    fetch
  ])
  return fetchBookable
}

const useFetchBookableAvailability = () => {
  const fetch = useFetch()
  const fetchBookableAvailability = useCallback(
    ({ slug, from, to }) =>
      fetch(
        `/bookables/${slug}/availability?${queryString.stringify({
          from: moment(from).format(),
          to: moment(to).format()
        })}`
      ),
    [fetch]
  )
  return fetchBookableAvailability
}

const useGetSchedulesByBookable = ({ queryParams }) => {
  const {
    bookableIds: slugs = [],
    from = moment().startOf('week').toDate(),
    to = moment().endOf('week').toDate()
  } = queryParams
  const hash = useMemo(() => {
    return btoa(JSON.stringify(queryParams))
  }, [queryParams])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState([])
  const fetchBookable = useFetchBookable()
  const fetchBookableAvailability = useFetchBookableAvailability()
  useEffect(() => {
    async function fetch() {
      setError(null)
      setLoading(true)
      setResponse([])
      return Promise.all(
        slugs.map((slug) => {
          return Promise.all([
            fetchBookable({
              slug
            }).then(({ bookable }) => bookable),
            fetchBookableAvailability({
              slug,
              from,
              to
            }).then(({ availability }) => availability)
          ]).then(([bookable, availability]) => ({
            schedules: availability.schedules,
            bookable
          }))
        })
      )
        .then((response) => {
          setLoading(false)
          setResponse(response)
        })
        .catch((error) => {
          setLoading(false)
          setError(error)
          throw error
        })
    }
    fetch()
    // eslint-disable-next-line
  }, [hash])

  return {
    loading,
    error,
    schedulesByBookable: response
  }
}

export default useGetSchedulesByBookable
