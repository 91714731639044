import React, { createContext, useContext } from 'react'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import PropTypes from 'prop-types'
import { RestfulProvider } from 'restful-react'
import { MessageProvider } from '@trileuco/triskel-react-ui/components/i18n'
import { ApiCacheProvider } from 'components/api/ApiCacheContext'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { homePath } from './routes'

const TenantContext = createContext({})

export const useTenantConfig = () => {
  return useContext(TenantContext)
}

export const useAbsoluteUrl = (urlPath) => {
  const { basename } = useTenantConfig()
  const basenamePath = basename ? '/' + basename : ''
  if (!urlPath) {
    return window.location.origin + basenamePath
  }
  return (
    window.location.origin +
    basenamePath +
    (urlPath.startsWith('/') ? urlPath : '/' + urlPath)
  )
}

export const useAbsoluteHomePath = () => {
  return useAbsoluteUrl(homePath)
}

const BalaenaProvider = ({ children, tenantConfig }) => {
  const {
    api: { baseUrl: apiBaseUrl },
    intl: { locale, defaultLocale },
    recaptchaSiteKey
  } = tenantConfig
  const {
    keycloak: { token: accessToken }
  } = useAuth()

  return (
    <TenantContext.Provider value={tenantConfig}>
      <RestfulProvider
        base={apiBaseUrl}
        requestOptions={() => ({
          headers: {
            ...(locale ? { 'accept-language': locale } : {}),
            ...(accessToken ? { 'access-token': accessToken } : {})
          }
        })}
      >
        <GoogleReCaptchaProvider
          reCaptchaKey={recaptchaSiteKey}
          language={locale || defaultLocale}
        >
          <ApiCacheProvider>
            <MessageProvider scope="balaena">
              <>{children}</>
            </MessageProvider>
          </ApiCacheProvider>
        </GoogleReCaptchaProvider>
      </RestfulProvider>
    </TenantContext.Provider>
  )
}

BalaenaProvider.propTypes = {
  children: PropTypes.node,
  tenantConfig: PropTypes.object
}

BalaenaProvider.displayName = 'BalaenaProvider'

export default BalaenaProvider
