import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const TagFilter = ({ name, onClick, amount, showAmount, active }) => {
  const { classNames } = useClassNames({ alias: 'TagFilter' })
  return (
    <div onClick={onClick} className={classNames({ active })}>
      <span className={classNames('name')}>{name}</span>
      {showAmount && <span className={classNames('amount')}>{amount}</span>}
    </div>
  )
}

TagFilter.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  amount: PropTypes.number,
  showAmount: PropTypes.bool,
  active: PropTypes.bool
}

TagFilter.displayName = 'TagFilter'

export default TagFilter
