import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import DateTime from 'components/misc/DateTime'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import BookingStates from 'modules/bookables/pages/bookingDetails/summary/BookingStates'

const BookingStatus = ({ booking }) => {
  const bookingCancelled = booking.status === 'canceled'
  const bookingConfirmed = booking.status === 'active'
  const { classNames } = useClassNames({
    alias: 'BookingStatus'
  })
  const { msg } = useIntl({
    scope: 'bookingStatusAndExtraStates'
  })
  return (
    <>
      {bookingConfirmed && (
        <span className={classNames({ isConfirmed: true })}>
          <i className="far fa-check-circle"></i>
          {msg({ id: 'confirmedBooking' })}
        </span>
      )}
      {bookingCancelled && (
        <span
          className={classNames({
            isCancelled: true
          })}
        >
          <i className="fas fa-ban"></i>
          {msg({ id: 'cancelledBooking' })}
        </span>
      )}
    </>
  )
}

BookingStatus.propTypes = {
  booking: PropTypes.shape()
}

const BookingStatusAndExtraStates = ({ booking, onlyStates = false }) => {
  const { msg } = useIntl({
    scope: 'bookingStatusAndExtraStates'
  })
  const authContext = useAuth()
  return (
    <div className="BookingStatusAndExtraStates">
      {!onlyStates && (
        <>
          <BookingStatus booking={booking} />
          <span>
            {msg(
              {
                id: 'createdAt'
              },
              {
                createdAt: (
                  <DateTime
                    data={booking.createdAt}
                    timeZone={booking.bookable.timeZone}
                  />
                )
              }
            )}
          </span>
        </>
      )}
      <Visible
        condition={authContext.hasSomeRole([
          'balaena-admin',
          'balaena-bookings-manager'
        ])}
        ifTrue={<BookingStates booking={booking} />}
      />
    </div>
  )
}

BookingStatusAndExtraStates.propTypes = {
  booking: PropTypes.shape(),
  onlyStates: PropTypes.bool
}

BookingStatusAndExtraStates.displayName = 'BookingStatusAndExtraStates'

export default BookingStatusAndExtraStates
