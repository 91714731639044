import { useCallback } from 'react'
import { useMutate } from 'restful-react'

const useBackendErrorNotification = (options) => {
  const { mutate, ...rest } = useMutate({
    path: `/web-client-exception`,
    verb: 'POST',
    ...options
  })

  const backendErrorNotification = useCallback(
    (params) => {
      return mutate(params)
        .then((response) => console.log(response.data))
        .catch((err) => console.error(err))
    },
    [mutate]
  )

  return {
    backendErrorNotification,
    ...rest
  }
}

export default useBackendErrorNotification
