import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useGenerateBookablePath } from 'modules/bookables/routes'
import Block from 'components/layout/Block'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import PhotoImg from 'components/misc/PhotoImg'

const BookingBookableBlock = ({ booking, bookable }) => {
  const { msg } = useIntl({
    scope: 'bookingBookableBlock'
  })
  const generateBookablePath = useGenerateBookablePath()
  return (
    <Block className="BookingBookableBlock" variant="fill">
      <Visible
        condition={!_.isEmpty(bookable.photos)}
        ifTrue={<PhotoImg photo={bookable.photos[0]} variant="card" />}
        otherwise={<img src={NoPhoto} alt="" />}
      />
      <div>
        <p className="BookableName">{booking.bookable.name}</p>
        <p>
          <Link to={generateBookablePath(booking.bookable)}>
            {msg({ id: 'moreInfo' })}
          </Link>
        </p>
      </div>
    </Block>
  )
}

BookingBookableBlock.propTypes = {
  booking: PropTypes.shape(),
  bookable: PropTypes.shape()
}

BookingBookableBlock.displayName = 'BookingBookableBlock'

export default BookingBookableBlock
