import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

// TODO: Review boolean answers
const getAnswerString = (answer, msg) => {
  if (answer === 't') {
    return msg({ id: 'yes' })
  } else if (answer === 'f') {
    return msg({ id: 'no' })
  } else {
    return answer
  }
}

const QuestionsSummary = ({ questions }) => {
  return (
    <>
      {questions.map(({ id, question: { title }, answer }, index) => {
        return (
          <div className="Question" key={id}>
            <p className="Question_title">{title}</p>
            <p className="Question_answer">{answer}</p>
          </div>
        )
      })}
    </>
  )
}

QuestionsSummary.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape())
}

const BookingAditionalInfoBlock = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.globals',
    ignoreGlobalScope: true
  })
  const { msg: message } = useIntl({
    scope: 'bookingAditionalInfo'
  })
  const { questions } = booking
  return (
    <BookingDetailsSummaryBlock
      className="BookingAditionalInfoBlock"
      title={message({ id: 'title' })}
    >
      <div>
        <QuestionsSummary
          questions={questions.map(({ answer, ...question }) => {
            return {
              ...question,
              answer: getAnswerString(answer, msg)
            }
          })}
        />
      </div>
    </BookingDetailsSummaryBlock>
  )
}

BookingAditionalInfoBlock.propTypes = {
  booking: PropTypes.shape()
}

BookingAditionalInfoBlock.displayName = 'BookingAditionalInfoBlock'

export default BookingAditionalInfoBlock
