import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { useIntl as useReactIntl } from 'react-intl'
import FullCalendar from '@fullcalendar/react'
import esLocale from '@fullcalendar/core/locales/es'
import enLocale from '@fullcalendar/core/locales/en-gb'
import glLocale from '@fullcalendar/core/locales/gl'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezone from '@fullcalendar/moment-timezone'
import listPlugin from '@fullcalendar/list'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import Block from 'components/layout/Block'

import BookingEvent from './BookingEvent'
import BookablesSelector from './BookablesSelector'

const getFullCalendarLocale = (locale) => {
  switch (locale) {
    case 'en':
      return enLocale
    case 'gl':
      return glLocale
    case 'es':
    default:
      return esLocale
  }
}

const BookingsCalendar = ({ events, onFilterChange, timeZone, filter }) => {
  const [currentView, setView] = useState({
    startDate: moment(filter.from).toDate(),
    endDate: moment(filter.to).toDate()
  })
  const handleDatesRender = useCallback(
    ({ view }) => {
      const { activeStart, activeEnd } = view
      if (
        !moment(activeStart).isSame(moment(currentView.startDate)) ||
        !moment(activeEnd).isSame(moment(currentView.endDate))
      ) {
        setView({
          activeStart,
          activeEnd
        })
        onFilterChange({
          ...filter,
          from: activeStart,
          to: activeEnd
        })
      }
    },
    [currentView.endDate, currentView.startDate, filter, onFilterChange]
  )

  const { locale } = useReactIntl()

  const isDesktop = useMediaQuery(DesktopBreakpoint)
  const { bookableIds = [] } = filter
  return (
    <Block
      title={
        <BookablesSelector
          multiple
          value={bookableIds}
          onChange={(bookableIds) => {
            onFilterChange({
              ...filter,
              bookableIds
            })
          }}
        />
      }
      variant="clear"
      className="Bookings"
    >
      <div className="BookingsCalendar">
        <FullCalendar
          height="auto"
          eventColor="transparent"
          defaultView="timeGridWeek"
          locale={getFullCalendarLocale(locale)}
          defaultDate={filter.from}
          timeZone={timeZone}
          aspectRatio="3"
          datesRender={handleDatesRender}
          allDaySlot={false}
          header={
            isDesktop
              ? {
                  left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                  right: 'prev,next today',
                  center: 'title'
                }
              : {
                  left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                  center: 'title prev,next today',
                  right: ''
                }
          }
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
            momentTimezone
          ]}
          events={events}
          eventRender={({ el, event: { extendedProps } }) => {
            const BookingEventElement = React.createElement(
              BookingEvent,
              extendedProps,
              []
            )
            ReactDOM.render(BookingEventElement, el)
          }}
          eventDestroy={({ el }) => {
            ReactDOM.unmountComponentAtNode(el)
          }}
        />
      </div>
    </Block>
  )
}

BookingsCalendar.propTypes = {
  events: PropTypes.array,
  timeZone: PropTypes.string,
  onFilterChange: PropTypes.func,
  filter: PropTypes.object
}

BookingsCalendar.defaultProps = {}

BookingsCalendar.displayName = 'BookingsCalendar'

export default BookingsCalendar
