import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import ArticleExcerpt from 'modules/blog/components/ArticleExcerpt'
import { LoadingResults, useGetArticles } from 'components/api'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'

const ArticleList = ({
  title,
  view,
  pageSize = 10,
  showLoadMoreButton,
  tag
}) => {
  const { classNames } = useClassNames({ alias: 'ArticleList' })

  const {
    articles,
    pagination,
    loading,
    loadingMore,
    fetchMore
  } = useGetArticles({
    queryParams: { tag, pageSize }
  })

  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'blog.articles.ArticleList'
  })

  let content = null
  const hasArticles = pagination.totalCount > 0
  const hasNextPage = hasArticles && articles.length < pagination.totalCount

  if (loading && !loadingMore) {
    content = <LoadingResults />
  } else if (!hasArticles) {
    content = <p>{msg({ id: 'noResults' })}</p>
  } else {
    content = (
      <div>
        {articles.map((article, index) => (
          <ArticleExcerpt view={view} article={article} key={index} />
        ))}
        {showLoadMoreButton && (
          <Visible
            condition={hasNextPage}
            ifTrue={
              <Button
                onClick={() => fetchMore()}
                disabled={loadingMore}
                className={classNames('loadMore')}
                variant="clear"
                text={msg({ id: 'loadMore' })}
              />
            }
          />
        )}
      </div>
    )
  }
  return (
    <>
      {title && <h2 className={classNames('title')}>{title}</h2>}
      {content}
    </>
  )
}

ArticleList.propTypes = {
  tag: PropTypes.string,
  title: PropTypes.string,
  pageSize: PropTypes.number,
  view: PropTypes.string,
  showLoadMoreButton: PropTypes.bool
}

ArticleList.displayName = 'ArticleList'

export default ArticleList
