import React from 'react'
import PropTypes from 'prop-types'

const BookablePriceAmount = ({
  children,
  className,
  withDiscount,
  discountedPriceAmount,
  basePriceAmount,
  priceAmount,
  ...other
}) => {
  return (
    <div
      className={`BookablePrice_amount ${withDiscount ? 'withDiscount' : ''} ${
        className || ''
      }`.trim()}
      {...other}
    >
      {children}
      {withDiscount ? (
        <>
          <span className="DiscountedPriceAmount">{discountedPriceAmount}</span>
          <span className="BasePriceAmount">{basePriceAmount}</span>
        </>
      ) : (
        <span>{priceAmount}</span>
      )}
    </div>
  )
}

BookablePriceAmount.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  withDiscount: PropTypes.bool,
  discountedPriceAmount: PropTypes.node,
  basePriceAmount: PropTypes.node,
  priceAmount: PropTypes.node
}

BookablePriceAmount.defaultProps = {
  className: ''
}

BookablePriceAmount.displayName = 'BookablePriceAmount'

export default BookablePriceAmount
