import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { useErrorNotification } from 'modules/bookables/notifications'

const useBookingPaymentAction = ({ paymentAction, bookingId }) => {
  const { msg } = useIntl({
    scope: 'balaena.api.useBookingPaymentAction',
    ignoreGlobalScope: true
  })
  const {
    notify,
    statusTypes: { LOADING, SUCCESS, DANGER }
  } = useNotifications()

  const { notifyError } = useErrorNotification()
  const getNotificationMessage = useCallback(
    (action, status) => {
      switch (action) {
        case 'pay':
          switch (status) {
            case LOADING:
              return msg({ id: 'pay.loading' })
            case DANGER:
              return msg({ id: 'pay.error' })
            case SUCCESS:
              return msg({ id: 'pay.success' })
            default:
              return 'Unknown status: ' + status
          }
        default:
        case 'cancel':
          switch (status) {
            case LOADING:
              return msg({ id: 'cancel.loading' })
            case DANGER:
              return msg({ id: 'cancel.error' })
            case SUCCESS:
              return msg({ id: 'cancel.success' })
            default:
              return 'Unknown status: ' + status
          }
        case 'invite':
          switch (status) {
            case LOADING:
              return msg({ id: 'invite.loading' })
            case DANGER:
              return msg({ id: 'invite.error' })
            case SUCCESS:
              return msg({ id: 'invite.success' })
            default:
              return 'Unknown status: ' + status
          }
      }
    },
    [msg, DANGER, SUCCESS, LOADING]
  )
  const getPaymentActionNotification = useCallback(
    (status) => ({
      id: `BOOKING_${bookingId}_PAYMENT_ACTION_${paymentAction}`,
      message: getNotificationMessage(paymentAction, status),
      status
    }),
    [paymentAction, bookingId, getNotificationMessage]
  )
  const { mutate, ...rest } = useMutate({
    verb: 'POST',
    path: `/bookings/${bookingId}/payment/${paymentAction}`
  })
  const mutateWithNotifications = useCallback(
    (queryParams = null) => {
      notify(getPaymentActionNotification(LOADING))
      return mutate(queryParams)
        .then((response) => {
          notify(getPaymentActionNotification(SUCCESS))
          return response
        })
        .catch((error) => {
          notifyError({
            ...getPaymentActionNotification(DANGER),
            error
          })
          throw error
        })
    },
    [
      notify,
      notifyError,
      LOADING,
      SUCCESS,
      DANGER,
      mutate,
      getPaymentActionNotification
    ]
  )
  return { mutate: mutateWithNotifications, ...rest }
}

export default useBookingPaymentAction
