import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  IconButton,
  PopOver,
  ShareButtons
} from '@trileuco/triskel-react-ui/components/ui'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useGenerateBookablePath } from 'modules/bookables/routes'
import { useAbsoluteUrl, useTenantConfig } from 'modules/bookables/Provider'
import { DesktopView, MobileView } from 'components/layout/Mediaqueries'
import { toVariantUrl } from 'components/misc/PhotoImg'

import BookableReport from './BookableReport'

const BookableShare = ({ bookable }) => {
  const {
    name: tenantName,
    components: { disablePdfExport } = {}
  } = useTenantConfig()
  const { msg } = useIntl({
    scope: 'balaena.bookableShare',
    ignoreGlobalScope: true
  })
  const bookableNameHashtag = _.upperFirst(_.camelCase(bookable.name))
  const generateBookablePath = useGenerateBookablePath()
  const bookableRoute = useAbsoluteUrl(generateBookablePath(bookable))
  const sharedTitle = msg(
    {
      id: 'sharedTitle'
    },
    {
      bookableName: bookable.name,
      client: tenantName
    }
  )
  let sharedImg
  if (!_.isEmpty(bookable.photos) && bookable.photos[0]) {
    const photo = bookable.photos[0]
    sharedImg = toVariantUrl(photo, 'gallery')
  }

  return (
    <PopOver
      triggerEvent="click"
      className="BookableSharePopOver"
      placement="bottom-end"
      content={
        <div className="BookableShareButtons">
          <ShareButtons
            size="s"
            sharedRoute={bookableRoute}
            sharedTitle={sharedTitle}
            sharedDescription={bookable.descriptions.short}
            whatsapp={{
              separator: ' → '
            }}
            facebook={{
              hashtag: bookableNameHashtag
            }}
            twitter={{
              hashtags: [bookableNameHashtag, tenantName]
            }}
            email={{
              separator: ' → '
            }}
            pinterest={
              sharedImg && {
                media: sharedImg
              }
            }
            line
            reddit
          />
          {!disablePdfExport && <BookableReport bookableSlug={bookable.slug} />}
        </div>
      }
    >
      <DesktopView>
        <Button
          variant="clear"
          size="m"
          iconBefore="fas fa-share-square"
          text={msg({ id: 'shareButton' })}
        />
      </DesktopView>
      <MobileView>
        <IconButton variant="clear" size="m" icon="fas fa-share-square" />
      </MobileView>
    </PopOver>
  )
}

BookableShare.propTypes = {
  bookable: PropTypes.object
}

BookableShare.displayName = 'BookableShare'

export default BookableShare
