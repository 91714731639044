import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import WebpagesNavMenu from 'components/layout/nav/WebpagesNavMenu'
import _ from 'lodash'

import CategoriesNavMenu from './CategoriesNavMenu'

const TouchCategoriesNavMenu = ({ sidebarCategoryText }) => {
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'components.nav.TouchCategoriesNavMenu'
  })

  const categoryText = useMemo(() => {
    return !_.isUndefined(sidebarCategoryText)
      ? sidebarCategoryText
      : msg({ id: 'title' })
  }, [msg, sidebarCategoryText])

  return (
    <>
      <p className="NavMenuGroup_title">{categoryText}</p>
      <CategoriesNavMenu />
    </>
  )
}

TouchCategoriesNavMenu.propTypes = {
  sidebarCategoryText: PropTypes.string
}

const MobileNavMenu = ({ renderCategories, sidebarCategoryText }) => {
  return (
    <div className="MobileNavMenu">
      {renderCategories && (
        <TouchCategoriesNavMenu sidebarCategoryText={sidebarCategoryText} />
      )}
      <WebpagesNavMenu />
    </div>
  )
}

MobileNavMenu.propTypes = {
  renderCategories: PropTypes.bool,
  sidebarCategoryText: PropTypes.string
}

MobileNavMenu.displayName = 'MobileNavMenu'

export default MobileNavMenu
