import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import BookingPaymentGatewayIframe from 'components/booking/BookingPaymentGatewayIframe'

import PowertranzPaymentGatewayFieldSet from './PowertranzPaymentGatewayFieldSet'
import NMIPaymentGatewayFieldSet from './NMIPaymentGatewayFieldSet'
import RedSysPaymentGatewayFieldSet from './RedSysPaymentGatewayFieldSet'
import PaycometPaymentGatewayFieldSet from './PaycometPaymentGatewayFieldSet'

const PaymentGatewayField = ({ gatewayConfig, booking, ...restProps }) => {
  const { value, onChange } = restProps

  const { gateway, options } = gatewayConfig

  const handleClose = useCallback(() => {
    onChange(null)
  }, [onChange])

  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.paymentGatewayField',
    ignoreGlobalScope: true
  })

  const showCard = !value

  const { classNames } = useClassNames({
    alias: 'AddCardModal'
  })

  const [cardData, setCardData] = useState({})

  switch (gateway) {
    case 'nmi':
      return (
        <Modal
          header={<h1>{msg({ id: 'modalTitle' })}</h1>}
          isOpen={showCard}
          onClose={handleClose}
          className={classNames()}
          body={
            <NMIPaymentGatewayFieldSet
              {...restProps}
              tokenizationKey={options.tokenizationKey}
            />
          }
        />
      )
    case 'paycomet':
      return (
        <Modal
          header={<h1>{msg({ id: 'modalTitle' })}</h1>}
          isOpen={showCard}
          onClose={handleClose}
          className={classNames()}
          body={
            <PaycometPaymentGatewayFieldSet
              {...restProps}
              jetID={options.jetID}
            />
          }
        />
      )
    case 'redsys':
      return <RedSysPaymentGatewayFieldSet {...restProps} />
    case 'powertranz':
      return (
        <Modal
          header={<h1>{msg({ id: 'modalTitle' })}</h1>}
          isOpen={showCard}
          onClose={handleClose}
          className={classNames()}
          body={
            _.isEmpty(cardData) ? (
              <PowertranzPaymentGatewayFieldSet onSubmit={setCardData} />
            ) : (
              <BookingPaymentGatewayIframe
                cardData={cardData}
                booking={booking}
              />
            )
          }
        />
      )
    default:
      return null
  }
}

PaymentGatewayField.propTypes = {
  gatewayConfig: PropTypes.shape({
    gateway: PropTypes.oneOf(['nmi', 'redsys', 'paycomet', 'powertranz']),
    options: PropTypes.shape()
  }),
  booking: PropTypes.object
}
PaymentGatewayField.displayName = 'PaymentGatewayField'

export default formalizer(PaymentGatewayField)
