import { useCallback, useEffect, useMemo } from 'react'
import { ga, useAnalytics } from '@trileuco/triskel-react-ui/modules/analytics'

let pluginEC = false

const useBookableAnalytics = () => {
  const Analytics = useAnalytics()
  useEffect(() => {
    if (!pluginEC) {
      pluginEC = true
      ga('require', 'ec')
    }
  }, [])

  const action = useCallback(
    ({ bookable, action, command, position = null, step, option }) => {
      if (bookable && Analytics.enabled) {
        ga(`ec:${command}`, {
          id: bookable.id,
          name: bookable.name,
          price: bookable.price.amount / 100,
          category: bookable.category.name,
          variant: bookable.location.description,
          position
        })
        if (action) {
          ga('ec:setAction', action, {
            ...(step ? { step } : {}),
            ...(option ? { option } : {})
          })
        }
        ga('send', 'pageview')
      }
    },
    [Analytics]
  )
  const addAction = useCallback(
    ({ bookable, position = null }) => {
      action({
        command: 'addProduct',
        action: 'add',
        bookable,
        position
      })
    },
    [action]
  )
  const checkoutAction = useCallback(
    ({ bookable, position = null, step, option }) => {
      action({
        command: 'addProduct',
        action: 'checkout',
        bookable,
        position,
        step,
        option
      })
    },
    [action]
  )
  const viewAction = useCallback(
    ({ bookable, position = null }) => {
      action({
        command: 'addProduct',
        action: 'detail',
        bookable,
        position
      })
    },
    [action]
  )
  const impressionAction = useCallback(
    ({ bookable, position = null }) => {
      action({
        command: 'addProduct',
        action: 'detail',
        bookable,
        position
      })
    },
    [action]
  )
  return useMemo(
    () => ({
      addAction,
      viewAction,
      impressionAction,
      checkoutAction,
      action
    }),
    [addAction, viewAction, impressionAction, checkoutAction, action]
  )
}

export default useBookableAnalytics
