import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const BoundedPageTemplate = (props) => {
  const { header, mainContent, sidebar, footer, ...other } = props
  const { classNames } = useClassNames({
    alias: 'BoundedPageTemplate'
  })
  return (
    <div className={classNames()} {...other}>
      {header && <div className="BoundedPageTemplate_header">{header}</div>}
      <div className={classNames('body')}>
        <div className={classNames('main', { sidebarOn: Boolean(sidebar) })}>
          {mainContent}
        </div>
        {sidebar && (
          <div className="BoundedPageTemplate_sidebar">{sidebar}</div>
        )}
      </div>
      {footer && <div className="BoundedPageTemplate_footer">{footer}</div>}
    </div>
  )
}

BoundedPageTemplate.propTypes = {
  header: PropTypes.node,
  mainContent: PropTypes.node,
  sidebar: PropTypes.node,
  footer: PropTypes.node
}

BoundedPageTemplate.displayName = 'BoundedPageTemplate'

export default BoundedPageTemplate
