import { useCallback } from 'react'
import queryString from 'query-string'
import useFetch from 'components/api/useFetch'
import { filterAvailabilitiesWithUseOnlyOneOffset } from 'modules/bookables/domain/availabilities'

export default () => {
  const fetch = useFetch()
  const fetchBundleItemsAvailabilities = useCallback(
    ({ id, dateTime, bundleItems, guestAmount }) =>
      fetch(
        `/bookables/${id}/bundle_items/availabilities?${queryString.stringify({
          dateTime: dateTime
        })}`
      ).then(({ availabilities }) => {
        return {
          availabilities: filterAvailabilitiesWithUseOnlyOneOffset({
            availabilities,
            bundleItems,
            guestAmount
          })
        }
      }),
    [fetch]
  )

  return {
    fetchBundleItemsAvailabilities
  }
}
