import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ShareButtons } from '@trileuco/triskel-react-ui/components/ui'
import { generateArticlePath } from 'modules/blog/routes'
import { useAbsoluteUrl, useTenantConfig } from 'modules/bookables/Provider'

export const ArticleShareButtons = ({ article }) => {
  const articleRoute = useAbsoluteUrl(generateArticlePath(article))
  const sharedImg = article.metaTags.ogImage
  const { name: tenantName } = useTenantConfig()
  const tenantHashtag = _.upperFirst(_.camelCase(tenantName))
  const articleHashtags = article.metaTags.ogKeywords

  return (
    <ShareButtons
      size="s"
      sharedRoute={articleRoute}
      sharedTitle={article.metaTags.ogTitle}
      sharedDescription={article.metaTags.ogDescription}
      whatsapp={{
        separator: ' → '
      }}
      facebook={{
        hashtag: tenantHashtag
      }}
      twitter={{
        hashtags: [articleHashtags, tenantName]
      }}
      email={{
        separator: ' → '
      }}
      pinterest={
        sharedImg && {
          media: sharedImg
        }
      }
      line
      reddit
    />
  )
}

ArticleShareButtons.propTypes = {
  article: PropTypes.object
}

ArticleShareButtons.displayName = 'ArticleShareButtons'

export default ArticleShareButtons
