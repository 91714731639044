import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import { SelectField, FieldSet } from '@trileuco/triskel-react-ui/components/ui'
import {
  useForm,
  SubmitButton
} from '@trileuco/triskel-react-ui/components/ui/Form'
import _ from 'lodash'

const BookersModal = ({ bookers, handleMakeAdmin, isOpen, close }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookersModal',
    ignoreGlobalScope: true
  })

  const bookersOptions = useMemo(() => {
    return bookers
      .filter(({ assignedRoles }) => !assignedRoles.includes('balaena-admin'))
      .map(({ email: value, name: label }) => ({ value, label }))
  }, [bookers])

  const handleSubmit = useCallback(
    (values, instance) => {
      handleMakeAdmin(values).then(() => {
        close()
        instance.reset()
      })
    },
    [handleMakeAdmin, close]
  )

  const { Form, values, ...instance } = useForm({
    onSubmit: handleSubmit
  })

  return (
    <Modal
      name="PickerToBook"
      className="BookersModal"
      isOpen={isOpen}
      onClose={() => {
        instance.reset()
        close()
      }}
      header={<h1>{msg({ id: 'modalTitle' })}</h1>}
      body={
        <Form>
          <FieldSet direction={'row'}>
            <SelectField
              variant="outline"
              field="identifier"
              options={bookersOptions}
              label={msg({ id: 'select.label' })}
              placeholder={msg({ id: 'select.placeholder' })}
            />
          </FieldSet>
          <SubmitButton
            size="l"
            text={msg({ id: 'submit' })}
            color="primary"
            disabled={_.isEmpty(values)}
          />
        </Form>
      }
    />
  )
}

BookersModal.propTypes = {
  bookers: PropTypes.array,
  handleMakeAdmin: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

BookersModal.displayName = 'BookersModal'

export default BookersModal
