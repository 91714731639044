import { useGet } from 'components/api'

export default ({
  id,
  bookerId,
  bookableId,
  status,
  from,
  to,
  guestFilter,
  state,
  page,
  pageSize,
  orderBy,
  onlyRoot,
  createdFrom,
  createdTo,
  ...moreParams
}) => {
  const queryParams = {
    id,
    bookerId,
    bookableId,
    status,
    from,
    to,
    guestFilter,
    state,
    page,
    pageSize,
    orderBy,
    onlyRoot,
    createdFrom,
    createdTo
  }
  const { data, ...rest } = useGet({
    path: `/bookings`,
    resolve: (data) => {
      return {
        bookings: data.bookings,
        pagination: data.meta.pagination
      }
    },
    queryParams,
    ...moreParams
  })
  if (!data) {
    return {
      bookings: [],
      pagination: {},
      ...rest
    }
  }
  return {
    ...data,
    ...rest
  }
}
