import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth/useAuth'
import { useTenantConfig } from 'modules/bookables/Provider'
import * as queryString from 'query-string'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'

const BookableReport = (props) => {
  const { bookableSlug } = props

  const {
    api: { baseUrl }
  } = useTenantConfig()

  const {
    keycloak: { token: accessToken }
  } = useAuth()

  const reportParams = queryString.stringify({
    'access-token': accessToken
  })

  const pdfUrl = `${baseUrl}/bookables/${bookableSlug}.pdf?${reportParams}`

  return (
    <IconButton
      externalLinkTo={pdfUrl}
      icon="fas fa-file-pdf"
      variant="outline"
      size="s"
    />
  )
}
BookableReport.propTypes = {
  bookableSlug: PropTypes.string
}

BookableReport.displayName = 'BookableReport'

export default BookableReport
