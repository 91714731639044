import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  IconButton,
  SelectField,
  Modal
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'

const BookingGuestsSelectModal = ({ onChange, value, ...props }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingGuestsSelectModal',
    ignoreGlobalScope: true
  })
  const { open, close, isOpen } = useModal(false)
  const handleChange = useCallback(
    (value) => {
      onChange(value)
      close()
    },
    [onChange, close]
  )
  const handleToogleGuest = useCallback(() => {
    if (value) {
      onChange(null)
    } else {
      open()
    }
  }, [value, onChange, open])
  return (
    <>
      <div className="BookingGuestsImport">
        <p>
          {msg({
            id: 'fieldLabel'
          })}
        </p>
        {value ? (
          <IconButton
            icon="fas fa-eraser"
            size="m"
            variant="clear"
            color="danger"
            onClick={handleToogleGuest}
          />
        ) : (
          <Button
            text={msg({
              id: 'modalTrigger'
            })}
            size="s"
            variant="clear"
            onClick={handleToogleGuest}
          />
        )}
      </div>
      <Modal
        className="BookingGuestsSelectModal"
        name="PickerToBook"
        isOpen={isOpen}
        onClose={close}
        header={
          <h1>
            {msg({
              id: 'modalTitle'
            })}
          </h1>
        }
        body={
          <SelectField
            value={value}
            autoFocus={true}
            variant="outline"
            onChange={handleChange}
            label={msg({
              id: 'selectLabel'
            })}
            helperText={msg({
              id: 'selectHelperText'
            })}
            menuPosition={'fixed'}
            {...props}
          />
        }
      />
    </>
  )
}

BookingGuestsSelectModal.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
}

BookingGuestsSelectModal.displayName = 'BookingGuestsSelectModal'

export default BookingGuestsSelectModal
