import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { SelectField, FieldSet } from '@trileuco/triskel-react-ui/components/ui'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import PhotoImg from 'components/misc/PhotoImg'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import _ from 'lodash'
import Block from 'components/layout/Block'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const BookingBundleItem = ({
  id,
  item,
  schedules,
  format = 'L LT',
  ...other
}) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  const { classNames } = useClassNames({ alias: 'BookingBundleItem' })
  return (
    <div className={classNames()} {...other}>
      <div className={classNames('info')}>
        <Visible
          condition={!_.isEmpty(item.photos)}
          ifTrue={
            <PhotoImg photo={item.photos[0].versions.card} variant="thumb" />
          }
          otherwise={<img src={NoPhoto} alt="" />}
        />
        {item.name}
      </div>
      <SelectField
        key={id}
        variant="outline"
        field={`pickedOccurrence_${id.toString()}`}
        options={schedules.map(({ dateTime: value }) => ({
          label: moment(value).format(format),
          value
        }))}
        label={msg({
          id: 'pickedOccurrenceLabel'
        })}
        placeholder={msg({
          id: 'pickedOccurrencePlaceHolder'
        })}
      />
    </div>
  )
}

BookingBundleItem.propTypes = {
  id: PropTypes.number,
  item: PropTypes.shape(),
  schedules: PropTypes.array,
  format: PropTypes.string
}

const BookingBundleItemsFieldsets = ({ bundleItemsAvailabilities }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  return (
    <Block title={msg({ id: 'pickedOccurrence.blockTitle' })}>
      <FieldSet
        className="BookingBundleItems"
        direction="column"
        fieldset="pickedOccurrences"
      >
        {bundleItemsAvailabilities.map(({ id, schedules, item }) => {
          return (
            <BookingBundleItem
              key={id}
              id={id}
              item={item}
              schedules={schedules}
            />
          )
        })}
      </FieldSet>
    </Block>
  )
}

BookingBundleItemsFieldsets.propTypes = {
  bundleItemsAvailabilities: PropTypes.arrayOf(PropTypes.shape()),
  format: PropTypes.string
}

BookingBundleItemsFieldsets.displayName = 'BookingBundleItemsFieldsets'

export default BookingBundleItemsFieldsets
