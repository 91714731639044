import React, { useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  useTable,
  usePagination,
  useExpanded,
  useFlexLayout
} from 'react-table'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

export const TableLayout = {
  CARD: 'card',
  DEFAULT: 'default'
}

const mapCardColumns = ({ CardCell, Cell, columns, ...column }) => ({
  ...column,
  columns: columns && columns.map(mapCardColumns),
  Cell: CardCell || Cell
})

const ReactTable = (props) => {
  const {
    className,
    classNames,
    layout,
    columns,
    data,
    getSubRows,
    loading,
    pagination,
    fetchData,
    pageSizeOptions,
    showPageSizeOptions,
    onChangePreviousWeek,
    onChangeNextWeek,
    ...other
  } = useClassNames(props)

  const tableColumns = useMemo(() => {
    if (layout === TableLayout.CARD) {
      return columns.map(mapCardColumns)
    }
    return columns
  }, [columns, layout])

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data,
      manualPagination: true,
      pageCount: pagination.totalPages,
      getSubRows,
      initialState: {
        pageIndex: pagination.page - 1,
        pageSize: pagination.pageSize,
        expanded: [
          {
            rowId: '',
            expanded: false
          }
        ]
      },
      ...other
    },
    useFlexLayout,
    useExpanded,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize }
  } = tableInstance

  const fetchDataRef = useRef(fetchData)

  useEffect(() => {
    fetchDataRef.current = fetchData
  }, [fetchData])

  useEffect(() => {
    fetchDataRef.current({ pageIndex, pageSize })
  }, [pageIndex, pageSize])

  return (
    <div className={`${classNames([{ layout }])} ${className}`.trim()}>
      <div {...getTableProps()} className="rt-table">
        <div className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps()}
                  className="rt-th rt-resizable-header"
                >
                  <span>{column.render('Header')}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="rt-tbody">
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <div
                {...row.getRowProps()}
                className={`rt-tr-group${row.depth > 0 ? ' subRow' : ''}`}
              >
                <div className={`rt-tr ${i % 2 === 0 ? '-even' : '-odd'}`}>
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className="rt-td">
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

ReactTable.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(TableLayout)),
    PropTypes.string
  ])
}

ReactTable.defaultProps = {
  alias: 'ReactTable',
  className: '',
  layout: TableLayout.DEFAULT,
  pagination: {
    pageSize: 10,
    page: 1,
    totalPages: 0
  }
}

ReactTable.displayName = 'ReactTable'

export default ReactTable
