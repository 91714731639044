import React from 'react'
import PropTypes from 'prop-types'
import Block from 'components/layout/Block'

const BookableVideoBlock = ({ video, title }) => (
  <Block className="BookableVideo">
    <iframe
      title={title}
      aria-hidden="true"
      src={video}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Block>
)

BookableVideoBlock.propTypes = {
  video: PropTypes.string,
  title: PropTypes.string
}

BookableVideoBlock.displayName = 'BookableVideoBlock'

export default BookableVideoBlock
