import React from 'react'
import PropTypes from 'prop-types'
import { useTenantConfig } from 'modules/bookables/Provider'
import DesktopNavBar from 'components/layout/nav/DesktopNavBar'
import TouchNavBar from 'components/layout/nav/TouchNavBar'
import { DesktopView, MobileView } from 'components/layout/Mediaqueries'
import WhatsappBadge from 'components/misc/WhatsappBadge'
import TenantFooter from 'components/layout/TenantFooter'

const PublicPageSkeleton = (props) => {
  const {
    headerAssets,
    mainContent,
    footerAssets,
    renderBalaenaToolkitAd,
    renderCategoriesFooter,
    renderTopFooter,
    renderFooter,
    renderNavBar,
    ...other
  } = props
  const {
    components: {
      renderSearch,
      renderCategories,
      navigation: {
        sidebarCategoryText,
        sidebarSubcategoryText,
        sidebarTriggerText
      } = {}
    } = {},
    contact
  } = useTenantConfig()

  return (
    <div className="PublicPageSkeleton">
      {renderNavBar && (
        <>
          <DesktopView>
            <DesktopNavBar
              renderSearch={renderSearch}
              renderCategories={renderCategories}
              sidebarCategoryText={sidebarCategoryText}
              sidebarSubcategoryText={sidebarSubcategoryText}
              sidebarTriggerText={sidebarTriggerText}
              {...other}
            />
          </DesktopView>
          <MobileView>
            <TouchNavBar
              renderSearch={renderSearch}
              renderCategories={renderCategories}
              sidebarCategoryText={sidebarCategoryText}
              {...other}
            />
          </MobileView>
        </>
      )}
      {headerAssets}
      <div className="PublicPageSkeleton_body">
        <>
          {mainContent}
          {contact && contact.whatsapp && parseInt(contact.whatsapp.phone) && (
            <WhatsappBadge
              openChatMessage={contact.whatsapp.message}
              phone={parseInt(contact.whatsapp.phone)}
            />
          )}
        </>
      </div>
      {footerAssets}
      {renderFooter && (
        <TenantFooter
          renderBalaenaToolkitAd={renderBalaenaToolkitAd}
          renderCategoriesFooter={renderCategoriesFooter}
          renderTopFooter={renderTopFooter}
        />
      )}
    </div>
  )
}

PublicPageSkeleton.propTypes = {
  ...DesktopNavBar.propTypes,
  headerAssets: PropTypes.node,
  mainContent: PropTypes.node,
  footerAssets: PropTypes.node,
  renderBalaenaToolkitAd: PropTypes.bool,
  renderCategoriesFooter: PropTypes.bool,
  renderTopFooter: PropTypes.bool,
  renderFooter: PropTypes.bool,
  renderNavBar: PropTypes.bool
}

PublicPageSkeleton.defaultProps = {
  renderFooter: true,
  renderNavBar: true
}

PublicPageSkeleton.displayName = 'PublicPageSkeleton'

export default PublicPageSkeleton
