import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import Block from 'components/layout/Block'
import TermsOfUseModal from 'components/bookable/TermsOfUseModal'

const BookableTermsOfUseBlock = ({ termsOfUse }) => {
  const { msg } = useIntl({
    scope: 'termsOfUse',
    ignoreGlobalScope: false
  })

  const { open, close, isOpen } = useModal(false)
  const [termOfUse, setTermOfUse] = useState(null)

  const showTermOfUse = useCallback(
    (termOfUse) => {
      setTermOfUse(termOfUse)
      open()
    },
    [setTermOfUse, open]
  )
  const closeTermOfUse = useCallback(() => {
    setTermOfUse()
    close()
  }, [close, setTermOfUse])

  if (termsOfUse.length === 0) {
    return null
  }

  return (
    <Block
      className="BookableTermsOfUseBlock"
      variant="outline"
      title={msg({ id: 'title' })}
      TitleTag="p"
    >
      <ul>
        {termsOfUse.map((termOfUse, index) => (
          <li key={index}>
            <button
              className="Link"
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                showTermOfUse(termOfUse)
              }}
            >
              {termOfUse.name}
            </button>
          </li>
        ))}

        {termOfUse && (
          <TermsOfUseModal
            termOfUseId={termOfUse.id}
            isOpen={isOpen}
            onClose={closeTermOfUse}
          />
        )}
      </ul>
    </Block>
  )
}

BookableTermsOfUseBlock.propTypes = {
  termsOfUse: PropTypes.arrayOf(PropTypes.object)
}

BookableTermsOfUseBlock.displayName = 'BookableTermsOfUseBlock'

export default BookableTermsOfUseBlock
