import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useFormContext } from 'react-form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import ExtrasSummary from 'components/booker/BookingForm//ExtrasSummary'
import BookingExtrasFieldSet from 'components/booker/BookingForm/BookingExtrasFieldSet'

const BookingExtrasByBooking = ({ extras, currency }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  const {
    values,
    meta: { isSubmitted }
  } = useFormContext()

  if (isSubmitted) {
    const submittedExtras = values.extrasByBooking
      .filter((extra) => extra.answer === true)
      .map((extra) => ({
        id: extra.extraId,
        name: extra.name
      }))
    return (
      !_.isEmpty(submittedExtras) && (
        <Block className="ExtrasByBookingSummary" title={msg({ id: 'extras' })}>
          <ExtrasSummary extras={submittedExtras} />
        </Block>
      )
    )
  } else {
    return (
      <Block title={msg({ id: 'extras' })} className="ExtrasByBookingBlock">
        <BookingExtrasFieldSet
          fieldset="extrasByBooking"
          extras={extras}
          currency={currency}
        />
      </Block>
    )
  }
}

BookingExtrasByBooking.propTypes = {
  extras: PropTypes.arrayOf(PropTypes.shape()),
  currency: PropTypes.string
}

BookingExtrasByBooking.displayName = 'BookingExtrasByBooking'

export default BookingExtrasByBooking
