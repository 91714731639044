import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Map, TileLayer } from 'react-leaflet'
import Block from 'components/layout/Block'
import ContainerDimensions from 'react-container-dimensions'
import MapMarker from 'components/misc/MapMarker'

export const ProviderLocation = ({ location }) => {
  const { classNames } = useClassNames({
    alias: 'ProviderLocation'
  })
  const { msg } = useIntl({
    scope: 'balaena.providersPage',
    ignoreGlobalScope: true
  })
  const mapStyle = {
    height: '180px'
  }
  const checkLatLng = () => {
    if (!location.latlng) {
      return false
    }
    if (location.latlng.every((coordinate) => coordinate === 0)) {
      return false
    }
    return true
  }
  return (
    <Block
      className={classNames()}
      title={msg({ id: 'location' })}
      variant="clear"
    >
      {checkLatLng() && (
        <div className={classNames('mapWrapper')}>
          <ContainerDimensions>
            <Map
              center={location.latlng}
              zoom={12}
              style={mapStyle}
              animate={true}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution={
                  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                }
              />
              <MapMarker position={location.latlng} />
            </Map>
          </ContainerDimensions>
        </div>
      )}
      <div className={classNames('details')}>
        <p>{location.description}</p>
      </div>
    </Block>
  )
}

ProviderLocation.propTypes = {
  classNames: PropTypes.func,
  location: PropTypes.shape()
}
