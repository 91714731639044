import React from 'react'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import sslIcon from 'assets/img/misc/ssl.svg'

const SSLBadge = () => {
  const { classNames } = useClassNames({ alias: 'SSLBadge' })
  const { msg } = useIntl({
    scope: 'balaena.sslBadge',
    ignoreGlobalScope: true
  })
  return (
    <div className={classNames()}>
      <div>
        <img src={sslIcon} className={classNames('icon')} alt="" />
        <span>
          <span className={classNames('title')}>{msg({ id: 'title' })}</span>
          <span className={classNames('subtitle')}>
            {msg({ id: 'subtitle' })}
          </span>
        </span>
      </div>
    </div>
  )
}

SSLBadge.displayName = 'SSLBadge'

export default SSLBadge
