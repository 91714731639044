import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  CheckboxField,
  FieldSet
} from '@trileuco/triskel-react-ui/components/ui'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import TermsOfUseModal from 'components/bookable/TermsOfUseModal'

const BookingTermsAcceptance = ({ bookable }) => {
  const { termsOfUse } = bookable

  const { msg } = useIntl({
    scope: 'balaena.bookingFields.bookingSummary.termsOfUse',
    ignoreGlobalScope: true
  })

  const { open, close, isOpen } = useModal(false)
  const [termOfUse, setTermOfUse] = useState(null)

  const hasTermsOfUse = termsOfUse.length > 0

  const showTermOfUse = useCallback(
    (termOfUse) => {
      setTermOfUse(termOfUse)
      open()
    },
    [setTermOfUse, open]
  )
  const closeTermOfUse = useCallback(() => {
    setTermOfUse()
    close()
  }, [close, setTermOfUse])

  if (hasTermsOfUse) {
    return (
      <>
        <FieldSet
          className="TermsOfUse"
          direction="column"
          fieldset="termsOfUse"
        >
          {bookable.termsOfUse.map((termOfUse) => (
            <CheckboxField
              key={termOfUse.id}
              variant="outline"
              rules={['required']}
              defaultValue={false}
              field={`term_${termOfUse.id.toString()}`}
              helperText={
                <button
                  className="Link"
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    showTermOfUse(termOfUse)
                  }}
                >
                  {msg({
                    id: 'readMore'
                  })}
                </button>
              }
              label={termOfUse.acceptanceTitle}
            />
          ))}
        </FieldSet>
        {termOfUse && (
          <TermsOfUseModal
            termOfUseId={termOfUse.id}
            isOpen={isOpen}
            onClose={closeTermOfUse}
          />
        )}
      </>
    )
  }
  return null
}

BookingTermsAcceptance.propTypes = {
  bookable: PropTypes.object
}

BookingTermsAcceptance.displayName = 'BookingTermsAcceptance'

export default BookingTermsAcceptance
