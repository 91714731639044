import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import ToBookPicker from 'components/bookable/ToBookPicker'
import Modal, { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import _ from 'lodash'

import BookablePriceTypeSelector from './BookablePriceTypeSelector'

const ToBookPickerModal = ({
  bookable,
  onSubmit,
  externalOpen = null,
  externalClose = null,
  externalClassName = null,
  providerBook = false,
  priceTypes = [],
  pickedPriceType = null,
  setPickedPriceType = null
}) => {
  const { msg } = useIntl({
    scope: 'balaena.toBookPickerModal',
    ignoreGlobalScope: true
  })
  const { open, close, isOpen } = useModal(false)

  const initialPriceSelectorState = useMemo(() => {
    return !_.isEmpty(priceTypes) && priceTypes.length > 1
  }, [priceTypes])

  const [showPriceTypeSelector, setShowPriceTypeSelector] = useState(
    initialPriceSelectorState
  )

  const handleCloseModal = useCallback(() => {
    close()
    setShowPriceTypeSelector(initialPriceSelectorState)
  }, [initialPriceSelectorState, close])

  /* Cleanup function
  Required for opening this modal correcly from ProviderBookingPopOverActions */
  useEffect(() => {
    if (pickedPriceType == null && priceTypes.length === 1) {
      setPickedPriceType(priceTypes[0])
    }
    return () => {
      if (externalOpen === true) {
        setPickedPriceType(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="ToBookPickerModal_open">
      {!providerBook && <Button text={msg({ id: 'bookNow' })} onClick={open} />}
      <Modal
        className={externalClassName}
        name="PickerToBook"
        isOpen={externalOpen === null ? isOpen : externalOpen}
        onClose={externalClose === null ? handleCloseModal : externalClose}
        header={
          <h1>{msg({ id: 'modalTitle' }, { bookableName: bookable.name })}</h1>
        }
        body={
          <Block className="ToBookPicker" variant="clear">
            {showPriceTypeSelector ? (
              <BookablePriceTypeSelector
                priceTypes={priceTypes}
                pickedPriceType={pickedPriceType}
                onPriceSelected={setPickedPriceType}
                handleSubmit={() => setShowPriceTypeSelector(false)}
              />
            ) : (
              pickedPriceType && (
                <ToBookPicker
                  pickedPriceType={pickedPriceType}
                  providerBook={providerBook}
                  bookable={bookable}
                  onSubmit={onSubmit}
                />
              )
            )}
          </Block>
        }
      ></Modal>
    </div>
  )
}

ToBookPickerModal.propTypes = {
  bookable: PropTypes.shape(),
  onSubmit: PropTypes.func,
  externalOpen: PropTypes.bool,
  externalClose: PropTypes.func,
  externalClassName: PropTypes.string,
  providerBook: PropTypes.bool,
  pickedPriceType: PropTypes.object,
  priceTypes: PropTypes.array,
  setPickedPriceType: PropTypes.func
}

ToBookPickerModal.displayName = 'ToBookPickerModal'

export default ToBookPickerModal
