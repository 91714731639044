import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { generateBookingCommentsPath } from 'modules/bookables/routes'

const ContactProviderBanner = ({ loading, bookable, booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsPage.contactProviderBanner',
    ignoreGlobalScope: true
  })
  return (
    <>
      {!loading && (
        <div className="ContactProviderBanner">
          <h2>
            {msg(
              {
                id: 'title'
              },
              {
                bookableProvider: bookable.provider.name
              }
            )}
          </h2>
          <p>{msg({ id: 'description' })}</p>
          <Link to={generateBookingCommentsPath({ id: booking.id })}>
            <i className="fas fa-paper-plane"></i>
            {msg({ id: 'link' })}
          </Link>
        </div>
      )}
    </>
  )
}

ContactProviderBanner.propTypes = {
  loading: PropTypes.bool,
  bookable: PropTypes.shape(),
  booking: PropTypes.shape()
}

ContactProviderBanner.displayName = 'ContactProviderBanner'

export default ContactProviderBanner
