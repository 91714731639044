import React, { useMemo, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTable, usePagination, useSortBy } from 'react-table'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'

export const TableLayout = {
  CARD: 'card',
  DEFAULT: 'default'
}

const NextButton = (props) => {
  const { ...other } = props
  return <IconButton {...other} icon="fas fa-arrow-right" variant="outline" />
}

const PrevButton = (props) => {
  const { ...other } = props
  return <IconButton {...other} icon="fas fa-arrow-left" variant="outline" />
}

const SortColumnButton = ({ column, ...other }) => {
  const { isSortedDesc, isSorted, canSort, toggleSortBy } = column

  const handleOnClick = useCallback(() => {
    toggleSortBy(!isSortedDesc, false)
  }, [isSortedDesc, toggleSortBy])

  return (
    canSort && (
      <IconButton
        {...other}
        onClick={handleOnClick}
        color={isSorted ? 'primary' : 'black'}
        icon={`fas fa-arrow-${isSortedDesc ? 'down' : 'up'}`}
      />
    )
  )
}

SortColumnButton.propTypes = {
  className: PropTypes.string,
  column: PropTypes.shape({
    isSortedDesc: PropTypes.bool,
    isSorted: PropTypes.bool,
    canSort: PropTypes.bool,
    toggleSortBy: PropTypes.func
  })
}

const mapCardColumns = ({ CardCell, Cell, columns, ...column }) => ({
  ...column,
  columns: columns && columns.map(mapCardColumns),
  Cell: CardCell || Cell
})

const ReactTable = (props) => {
  const {
    className,
    classNames,
    layout,
    columns,
    data,
    loading,
    pagination,
    sortable,
    fetchData,
    pageSizeOptions,
    showPageSizeOptions,
    ...other
  } = useClassNames(props)

  const tableColumns = useMemo(() => {
    if (layout === TableLayout.CARD) {
      return columns.map(mapCardColumns)
    }
    return columns
  }, [columns, layout])

  const hooks = useMemo(() => {
    return [
      ...(sortable ? [useSortBy] : []),
      ...(pagination ? [usePagination] : [])
    ]
  }, [sortable, pagination])

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data,
      manualPagination: true,
      pageCount: pagination.totalPages,
      initialState: {
        pageIndex: pagination.page - 1,
        pageSize: pagination.pageSize,
        ...(sortable
          ? {
              sortBy: pagination.orderBy
                ? [
                    {
                      id: pagination.orderBy.split('-')[0],
                      desc: pagination.orderBy.split('-')[1] === 'desc'
                    }
                  ]
                : []
            }
          : {})
      },
      ...(sortable ? { manualSortBy: true } : {}),
      ...other
    },
    ...hooks
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize, sortBy }
  } = tableInstance

  const fetchDataRef = useRef(fetchData)

  useEffect(() => {
    fetchDataRef.current({
      pageIndex,
      pageSize,
      ...(sortable
        ? {
            orderBy: sortBy[0]
              ? `${sortBy[0].id}${sortBy[0].desc ? '-desc' : '-asc'}`
              : ''
          }
        : {})
    })
  }, [pageIndex, pageSize, sortable, sortBy])

  useEffect(() => {
    fetchDataRef.current = fetchData
  }, [fetchData])

  return (
    <div className={`${classNames([{ layout }])} ${className}`.trim()}>
      <div {...getTableProps()} className="rt-table">
        <div className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps()}
                  className="rt-th rt-resizable-header"
                >
                  <span>{column.render('Header')}</span>
                  {sortable && <SortColumnButton column={column} />}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="rt-tbody">
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="rt-tr-group">
                <div className={`rt-tr ${i % 2 === 0 ? '-even' : '-odd'}`}>
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className="rt-td">
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="pagination-bottom">
        <div className="-pagination">
          <div className="-previous">
            <PrevButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
          </div>
          <div className="-center">
            <span className="-pageInfo">
              Página{' '}
              <div className="-pageJump">
                <input
                  type="number"
                  min={1}
                  max={pagination.totalPages}
                  value={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </div>
              de
              <span className="-totalPages">{pagination.totalPages}</span>
            </span>
            {showPageSizeOptions && (
              <span className="select-wrap -pageSizeOptions">
                <select
                  aria-label="rows per page"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {pageSizeOptions.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </span>
            )}
          </div>
          <div className="-next">
            <NextButton onClick={() => nextPage()} disabled={!canNextPage} />
          </div>
        </div>
      </div>
    </div>
  )
}

ReactTable.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(TableLayout)),
    PropTypes.string
  ])
}

ReactTable.defaultProps = {
  alias: 'ReactTable',
  className: '',
  layout: TableLayout.DEFAULT,
  pagination: {
    pageSize: 10,
    page: 1,
    totalPages: 0
  }
}

ReactTable.displayName = 'ReactTable'

export default ReactTable
