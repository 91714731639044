import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const WalletCardSummary = ({ value, onChange, disabled }) => {
  const removeWallet = useCallback(() => {
    onChange(null)
  }, [onChange])
  const { classNames } = useClassNames({
    alias: 'WalletCardSummary'
  })
  return (
    <div className={classNames()}>
      {value && (
        <>
          <div className={classNames('info')}>
            <p>{value.number.replace(/.(?=.{4})/g, '*')}</p>
          </div>
          {onChange && (
            <IconButton
              icon="fas fa-trash"
              color="danger"
              size="m"
              onClick={removeWallet}
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  )
}

WalletCardSummary.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

WalletCardSummary.displayName = 'WalletCardSummary'

export default WalletCardSummary
