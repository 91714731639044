import messages from 'modules/bookables/i18n'
import routes from 'modules/bookables/router'
import reducer from 'modules/bookables/reducer'

export { default as BalaenaProvider } from 'modules/bookables/Provider'

export default {
  key: 'bookables',
  messages: messages,
  routes: routes,
  reducer: reducer
}
