import { useFetchCache } from 'components/api/ApiCacheContext'

const useGetArticle = ({ id, ...options }) => {
  const { [`articles#${id}`]: article, ...rest } = useFetchCache({
    path: `/articles/${id}`,
    entity: `articles#${id}`,
    resolve: ({ article }) => article,
    ...options
  })
  return {
    ...rest,
    article
  }
}

export default useGetArticle
