import _ from 'lodash'
import moment from 'moment'

const DATE_TIME_FORMAT = 'YYYY-MM-DD'

const formatDateTime = (date, timeZone, format = DATE_TIME_FORMAT) =>
  moment(date).tz(timeZone).format(format)

const groupSchedulesByDateTime = (schedules, timeZone) =>
  _.groupBy(schedules, (schedule) =>
    moment
      .tz(schedule.dateTime, timeZone)
      .startOf('day')
      .format(DATE_TIME_FORMAT)
  )

const groupSchedulesByDayAndDateTime = (schedules, timeZone) =>
  _.each(
    groupSchedulesByDateTime(schedules, timeZone),
    (value, key, result) =>
      (result[key] = _.keyBy(value, (schedule) =>
        moment.tz(schedule.dateTime, timeZone).format('HH:mm')
      ))
  )

const getSchedulesByDateTime = (
  dateTime,
  schedulesByDayAndDateTime,
  nullable = false
) => {
  return nullable
    ? schedulesByDayAndDateTime[dateTime]
    : _.get(schedulesByDayAndDateTime, dateTime, {})
}

const scheduleIsNotValidForSelection = (
  dateTime,
  reservationCutoff,
  adminBook = false
) => {
  if (adminBook) {
    return false
  }
  const cutOff =
    reservationCutoff.value <= 0
      ? 0
      : moment.duration(reservationCutoff.value, 'minutes').asMilliseconds()

  let currentDateTime = new Date(
    moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
  )
  let ocurrenceDateTime = new Date(
    moment(dateTime).format('YYYY-MM-DDTHH:mm:ss')
  )

  if (reservationCutoff.future) {
    ocurrenceDateTime = new Date(ocurrenceDateTime.getTime() + cutOff)
  } else {
    currentDateTime = new Date(currentDateTime.getTime() + cutOff)
  }
  return currentDateTime > ocurrenceDateTime
}

const filterAvailabilitiesWithUseOnlyOneOffset = ({
  availabilities = [],
  bundleItems,
  guestAmount
}) => {
  return availabilities.reduce(
    (bundleItemAvailabilities, { id, slug, useOnlyOneOffset, schedules }) => {
      // Only published bundle items
      const bundleItem = _.find(bundleItems, { id })
      if (useOnlyOneOffset && !_.isUndefined(bundleItem)) {
        const schedulesWithCapacity = _.filter(
          schedules,
          ({ capacity: { remaining } }) => remaining >= guestAmount
        )

        // Not only one occurrence
        if (schedulesWithCapacity.length > 1) {
          bundleItemAvailabilities.push({
            id,
            slug,
            useOnlyOneOffset,
            schedules: schedulesWithCapacity,
            item: bundleItem
          })
        }
      }
      return bundleItemAvailabilities
    },
    []
  )
}

export {
  formatDateTime,
  groupSchedulesByDateTime,
  groupSchedulesByDayAndDateTime,
  getSchedulesByDateTime,
  scheduleIsNotValidForSelection,
  filterAvailabilitiesWithUseOnlyOneOffset
}
