import React from 'react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const BookableNotPublishedBadge = (props) => {
  const { ...other } = props
  const { msg } = useIntl({
    scope: 'balaena.bookable',
    ignoreGlobalScope: true
  })
  return (
    <div className="BookableNotPublishedBadge" {...other}>
      {msg({ id: 'notPublishedBadge' })}
    </div>
  )
}

BookableNotPublishedBadge.propTypes = {}

BookableNotPublishedBadge.defaultProps = {}

BookableNotPublishedBadge.displayName = 'BookableNotPublishedBadge'

export default BookableNotPublishedBadge
