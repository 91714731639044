import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'

const FooterLogosSection = ({ title, logos }) => {
  const { classNames } = useClassNames({
    alias: 'FooterLogosSection'
  })
  return (
    <div className={classNames()}>
      <div>
        {title && <p className={classNames('title')}>{title}</p>}
        {logos && (
          <ul className={classNames('logos')}>
            {logos.map((logo, index) => {
              return (
                <li key={index}>
                  <Visible
                    condition={!_.isEmpty(logo.href)}
                    ifTrue={
                      <a href={logo.href}>
                        <img src={logo.url} alt={logo.alt} />
                      </a>
                    }
                    otherwise={<img src={logo.url} alt={logo.alt} />}
                  />
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

FooterLogosSection.propTypes = {
  title: PropTypes.node,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      href: PropTypes.string
    })
  )
}

FooterLogosSection.displayName = 'FooterLogosSection'

export default FooterLogosSection
