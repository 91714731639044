import React from 'react'
import _ from 'lodash'
import { Redirect } from '@trileuco/triskel-react-ui/components/router'
import { notify } from '@trileuco/triskel-react-ui/components/ui/notifications/NotifySetter'
import { useQueryParams } from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import { useBookingDetails } from 'components/booker/BookingDetails/BookingDetailsContext'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  generateBookingPaymentPath,
  generateBookingCommentsPath,
  generateBookingSummaryPath
} from 'modules/bookables/routes'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

const getBookingStatus = (booking) => {
  return booking.status === 'reserved'
    ? 'reserved'
    : _.get(booking.payment, 'status')
}

const BookingRedirectByStatus = () => {
  const authContext = useAuth()
  const { booking } = useBookingDetails()
  const { result } = useQueryParams()
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsPage',
    ignoreGlobalScope: true
  })
  if (result) {
    notify({
      id: 'PAYMENT_RESULTS',
      title: msg({ id: result }),
      status: result === 'paymentNok' ? 'danger' : 'success'
    })
    if (result === 'paymentNok') {
      return <Redirect to={generateBookingPaymentPath({ id: booking.id })} />
    }
  }
  const status = getBookingStatus(booking)
  switch (status) {
    case 'pending':
    case 'reserved':
      return (
        <Redirect
          to={
            authContext.hasSomeRole([
              'balaena-admin',
              'balaena-bookings-manager'
            ])
              ? generateBookingSummaryPath({ id: booking.id })
              : generateBookingPaymentPath({ id: booking.id })
          }
        />
      )
    case 'rejected':
    case 'canceled':
      return <Redirect to={generateBookingCommentsPath({ id: booking.id })} />
    case 'invited':
    case 'paid':
    default:
      return <Redirect to={generateBookingSummaryPath({ id: booking.id })} />
  }
}

BookingRedirectByStatus.propTypes = {}

BookingRedirectByStatus.displayName = 'BookingRedirectByStatus'

export default BookingRedirectByStatus
