import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import {
  useHistory,
  useLocation
} from '@trileuco/triskel-react-ui/components/router'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import { Button, Modal } from '@trileuco/triskel-react-ui/components/ui'
import {
  MessageProvider,
  useIntl
} from '@trileuco/triskel-react-ui/components/i18n'
import { generateBookablesSearchPath } from 'modules/bookables/routes'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import FullPageTemplate from 'components/layout/pageTemplates/FullPageTemplate'
import BookablesSearch from 'components/bookable/BookablesSearch/BookablesSearch'
import BookableSearchForm, {
  fromQueryParamsToJSON,
  fromJSONToQueryParams
} from 'components/bookable/BookableSearchForm'
import { MobileBreakpoint } from 'components/layout/Mediaqueries'

const PageHeader = ({ msg, areFilters, filter }) => (
  <div className="BookablesPage_header">
    <p className="BookablesPage_title">
      {msg({
        id: 'pageTitle'
      })}
    </p>
    {areFilters && (
      <p className="AppliedFilters">
        {msg(
          {
            id: 'appliedFilters'
          },
          {
            filteredCategory: filter.category && (
              <span> {filter.category}</span>
            ),
            filteredSearch: filter.search && <span> {filter.search}</span>
          }
        )}
      </p>
    )}
  </div>
)

PageHeader.propTypes = {
  msg: PropTypes.func,
  areFilters: PropTypes.bool,
  filter: PropTypes.object
}

const BookablesPage = () => {
  const [showModal, toggleModal] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const filter = useQueryParams({
    type: QueryParamTypes.Array,
    location: QueryParamTypes.Array,
    fromDate: QueryParamTypes.Date,
    toDate: QueryParamTypes.Date,
    maxDuration: QueryParamTypes.String,
    tag: QueryParamTypes.Array,
    minPrice: QueryParamTypes.Int,
    maxPrice: QueryParamTypes.Int,
    keyword: QueryParamTypes.String
  })

  const isMobile = useMediaQuery(MobileBreakpoint)

  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'balaena.bookablesPage'
  })

  useEffect(() => {
    toggleModal(false)
  }, [location])

  const handleSubmit = useCallback(
    (searchFormValue) => {
      history.push(
        generateBookablesSearchPath({
          searchParams: fromJSONToQueryParams(searchFormValue)
        })
      )
    },
    [history]
  )

  return (
    <Page id="BookablesPage">
      <MessageProvider scope="bookablesPage">
        <PublicPageSkeleton
          renderSearch={isMobile}
          mainContent={
            <FullPageTemplate
              sidebar={
                !isMobile && (
                  <BookableSearchForm
                    showInline
                    formDirection="column"
                    autoSubmit={!isMobile}
                    defaultValues={fromQueryParamsToJSON(filter)}
                    onSubmit={handleSubmit}
                  />
                )
              }
              mainContent={
                <>
                  <BookablesSearch filter={filter} />
                  {isMobile && (
                    <div className="BookableSearch_actions">
                      <Button
                        iconBefore="fas fa-sliders-h"
                        size="l"
                        variant="fill"
                        text={msg({ id: 'actions.filter' })}
                        onClick={() => toggleModal(!showModal)}
                      />
                      <Modal
                        isOpen={showModal}
                        onClose={() => toggleModal(false)}
                        className="BookableSearchFormModal"
                        body={
                          <BookableSearchForm
                            showInline
                            formDirection="column"
                            onSubmit={handleSubmit}
                            defaultValues={fromQueryParamsToJSON(filter)}
                          />
                        }
                      />
                    </div>
                  )}
                </>
              }
            />
          }
        />
      </MessageProvider>
    </Page>
  )
}

BookablesPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
}

export default BookablesPage
