import React, { useState, useCallback, useMemo } from 'react'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import photosPropTypes from 'components/api/photos/photosPropTypes'

import { toVariantUrl } from '../PhotoImg'

const PhotoGallery = ({ photos }) => {
  const { classNames } = useClassNames({
    alias: 'PhotoGallery'
  })

  const { msg } = useIntl({
    scope: 'balaena.photoGallery',
    ignoreGlobalScope: true
  })

  const isDesktop = useMediaQuery(DesktopBreakpoint)

  const images = useMemo(() => {
    return photos
      .filter(({ alt }) => !alt.includes('only_card'))
      .map((photo) => {
        return {
          width: 4,
          height: 4,
          src: toVariantUrl(photo, 'gallery'),
          alt: photo.alt,
          caption: photo.alt
        }
      })
  }, [photos])

  const maxHightlightImagesNumber = isDesktop ? 5 : 1
  const highlightImages = images.slice(0, maxHightlightImagesNumber)
  const highlightImagesLength = highlightImages.length

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const openLightBoxFromBtn = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <div
      className={classNames({ highlightImagesLength: highlightImagesLength })}
    >
      <Gallery photos={highlightImages} onClick={openLightbox} />
      <Button
        text={msg({ id: 'showAllPhotos' }, { photosLength: images.length })}
        onClick={openLightBoxFromBtn}
        className={classNames('showAllPhotos')}
        size="m"
        color="white"
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              hideControlsWhenIdle={false}
              currentIndex={currentImage}
              views={images.map((img) => ({
                ...img
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

PhotoGallery.propTypes = {
  photos: photosPropTypes
}

PhotoGallery.defaultProps = {}

PhotoGallery.displayName = 'PhotoGallery'

export default PhotoGallery
