import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useParseReferrer from 'components/api/useParseReferrer'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useAbsoluteHomePath } from 'modules/bookables/Provider'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import _ from 'lodash'
import { notify } from '@trileuco/triskel-react-ui/components/ui/notifications/NotifySetter'

const Body = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.globals.auth',
    ignoreGlobalScope: true
  })

  const { referrer, setReferrer } = useParseReferrer()

  const [showNotification, setShowNotification] = useState(false)

  const {
    keycloak: { authenticated, userInfo, logout }
  } = useAuth()

  const absoluteHomePath = useAbsoluteHomePath()

  useEffect(() => {
    setReferrer()
    if (!authenticated && !_.isEmpty(userInfo)) {
      logout({ redirectUri: absoluteHomePath })
      setShowNotification(true)
    }
    if (showNotification) {
      notify({
        id: 'SessionExpired',
        title: msg({ id: 'sessionExpired' }),
        status: 'success'
      })
    }
    return () => {
      setShowNotification(false)
    }
  }, [
    setReferrer,
    referrer,
    msg,
    authenticated,
    userInfo,
    showNotification,
    logout,
    absoluteHomePath
  ])
  return <>{props.children}</>
}

Body.propTypes = {
  children: PropTypes.node
}

Body.displayName = 'Body'

export default Body
