import React, { useState, useMemo, useCallback } from 'react'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  formatDateTime,
  groupSchedulesByDayAndDateTime,
  getSchedulesByDateTime,
  scheduleIsNotValidForSelection
} from 'modules/bookables/domain/availabilities'
import { DateSelectorCalendar } from 'components/bookable/ToBookPicker/DateSelectorCalendar'
import { DateSelectorSchedule } from 'components/bookable/ToBookPicker/DateSelectorSchedule'
import { AvailableTimeLabel } from 'components/bookable/ToBookPicker/AvailableTimeLabel'
import {
  MessageProvider,
  useIntl
} from '@trileuco/triskel-react-ui/components/i18n'
import { FieldSet, Button } from '@trileuco/triskel-react-ui/components/ui'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useGetBookableAvailability } from 'components/api'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

const ToUpdateBookingTimePicker = ({
  bookable,
  booking,
  handleUpdateDateTime
}) => {
  const { msg } = useIntl({
    scope: 'balaena.toBookPicker',
    ignoreGlobalScope: true
  })

  const authContext = useAuth()

  const defaultValues = useMemo(
    () => ({
      guests: {
        amount: booking.amount
      },
      day: null,
      time: null
    }),
    [booking]
  )
  const { Form, values = {}, ...instance } = useForm({
    defaultValues
  })
  const { timeZone } = bookable
  const {
    day,
    time,
    guests: { amount }
  } = values

  const [dateRange, setDateRange] = useState(null)

  const {
    schedules,
    dateTimeFilters: schedulesDateTimeFilters,
    prices,
    loading: loadingBookableAvailabilities,
    refetch
  } = useGetBookableAvailability({
    id: bookable.slug
  })

  const handleChangeMonth = (month, instance) => {
    // FIXME: always set noon to avoid time zone issues
    month.setHours(12, 0, 0, 0)
    refetch({
      queryParams: {
        from: moment(month).startOf('month').format(),
        to: moment(month).endOf('month').format()
      }
    }).then(() => {
      instance.debounce(() => {
        instance.setFieldValue('time', null)
      })
    })
  }

  const handleChangeDay = useCallback(
    (date) => {
      if (
        bookable.continuous &&
        date.value &&
        date.value.from &&
        date.value.to &&
        date.schedules
      ) {
        setDateRange({
          from: moment(date.value.from).format(),
          to: moment(date.value.to).format()
        })
      }
    },
    [bookable.continuous]
  )

  const scheduleGroupedByDayAndDateTime = useMemo(
    () => groupSchedulesByDayAndDateTime(schedules, timeZone),
    [schedules, timeZone]
  )

  const getSchedulesByDate = useCallback(
    (date) =>
      getSchedulesByDateTime(
        formatDateTime(date, timeZone),
        scheduleGroupedByDayAndDateTime
      ),
    [timeZone, scheduleGroupedByDayAndDateTime]
  )

  const availableTimes = useMemo(() => {
    const mapScheduleToTimeOption = (schedule) => {
      const { capacity, dateTime } = schedule

      const full = capacity.remaining === 0
      const insufficient = !full && capacity.remaining < amount

      return {
        label: formatDateTime(dateTime, timeZone, 'HH:mm'),
        helperText: (
          <AvailableTimeLabel bookable={bookable} capacity={capacity} />
        ),
        value: dateTime,
        disabled:
          full ||
          insufficient ||
          scheduleIsNotValidForSelection(
            dateTime,
            bookable.reservationCutoff,
            authContext.hasSomeRole([
              'balaena-admin',
              'balaena-bookings-manager'
            ])
          )
      }
    }
    return _.values(getSchedulesByDate(day)).map(mapScheduleToTimeOption)
  }, [timeZone, getSchedulesByDate, day, amount, bookable, authContext])

  return (
    <MessageProvider scope="ToUpdateBookingTimePicker">
      <Form>
        <FieldSet direction="row">
          <DateSelectorCalendar
            completedGuestsAmount={true}
            prices={prices}
            loadingBookableAvailabilities={loadingBookableAvailabilities}
            scheduleGroupedByDayAndDateTime={scheduleGroupedByDayAndDateTime}
            handleChangeMonth={handleChangeMonth}
            handleChangeDay={handleChangeDay}
            bookable={bookable}
            amount={amount}
            initialMonth={
              schedulesDateTimeFilters &&
              moment(schedulesDateTimeFilters.from).toDate()
            }
            bookingDateRange={booking.dateRange}
          />
          {!bookable.continuous && (
            <DateSelectorSchedule
              msg={msg}
              time={time}
              day={day}
              completedGuestsAmount={true}
              loadingBookableAvailabilities={loadingBookableAvailabilities}
              availableTimes={availableTimes}
            />
          )}

          <Button
            disabled={_.isEmpty(time) && _.isEmpty(dateRange)}
            text={msg({ id: 'change' })}
            onClick={() => {
              handleUpdateDateTime({
                dateTime: time,
                ...dateRange
              })
              instance.reset()
            }}
          />
        </FieldSet>
      </Form>
    </MessageProvider>
  )
}

ToUpdateBookingTimePicker.displayName = 'ToUpdateBookingTimePicker'

ToUpdateBookingTimePicker.propTypes = {
  bookable: PropTypes.shape(),
  booking: PropTypes.shape(),
  handleUpdateDateTime: PropTypes.func
}

export default ToUpdateBookingTimePicker
