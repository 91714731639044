import { useGet } from 'components/api'

export default ({
  bookingId,
  formId,
  cardData,
  reservationPayment = false
}) => {
  const { data, ...rest } = useGet({
    path: `/bookings/${bookingId}/payment/new-iframe`,
    queryParams: {
      formId,
      reservationPayment,
      ...cardData
    }
  })
  if (data) {
    const { paymentIframeHtml, errors, ...dataRest } = data
    return {
      paymentIframeHtml,
      errors,
      ...dataRest,
      ...rest
    }
  }
  return {
    ...data,
    ...rest
  }
}
