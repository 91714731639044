import React from 'react'
import PropTypes from 'prop-types'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import BlogBreadcrumb from 'modules/blog/components/BlogBreadcrumb'
import TagsCloud from 'modules/blog/components/TagsCloud'
import ArticleList from 'modules/blog/components/ArticleList'
import Article from 'modules/blog/components/Article'
import _ from 'lodash'
import { LoadingResults, useGetWebpage } from 'components/api'
import { NotFoundError } from 'components/error'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'

const ArticlePage = ({
  match: {
    params: { id }
  }
}) => {
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'blog.articles'
  })
  const { webpage, loading } = useGetWebpage({ id: 'blog' })
  if (loading) {
    return <LoadingResults />
  }
  if (!webpage) {
    throw new NotFoundError('Webpage not found for slug blog')
  }
  const {
    content: { archive, lastEntries }
  } = webpage
  return (
    <Page id="ArticlePage">
      <PublicPageSkeleton
        mainContent={
          <BoundedPageTemplate
            header={<BlogBreadcrumb />}
            mainContent={<Article articleId={id} />}
            sidebar={
              <>
                <TagsCloud
                  title={_.get(archive, 'title.text') || msg({ id: 'archive' })}
                />
                <ArticleList
                  title={
                    _.get(lastEntries, 'title.text') ||
                    msg({ id: 'lastArticles' })
                  }
                  view="mini"
                  pageSize={_.get(lastEntries, 'limit') || 10}
                />
              </>
            }
          />
        }
      />
    </Page>
  )
}

ArticlePage.propTypes = {
  match: PropTypes.object
}

ArticlePage.displayName = 'ArticlePage'

export default ArticlePage
