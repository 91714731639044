import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {
  TableLayout,
  IconButton,
  SelectField
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useMediaQuery } from 'react-responsive'
import { MobileBreakpoint } from 'components/layout/Mediaqueries'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Block from 'components/layout/Block'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'

import ProviderSelector from './ProviderSelector'
import ReactTable from './ReactTable'
import providerBookingColumns from './providerBookingColumns'

const NextButton = (props) => {
  return <IconButton {...props} icon="fas fa-arrow-right" variant="outline" />
}

const PrevButton = (props) => {
  return <IconButton {...props} icon="fas fa-arrow-left" variant="outline" />
}

const WeekSelectorField = ({ weeks, initialWeek, ...otherProps }) => {
  const options = weeks.map((week) => {
    return {
      value: { from: week.start.format(), to: week.end.format() },
      label: week.start.format('ll') + ' - ' + week.end.format('ll')
    }
  })
  return (
    <SelectField
      variant="outline"
      size="s"
      options={options}
      {...otherProps}
      value={initialWeek}
    />
  )
}

WeekSelectorField.displayName = 'WeekSelectorField'

WeekSelectorField.propTypes = {
  weeks: PropTypes.array,
  initialWeek: PropTypes.string
}

const ProviderBookingsTable = (props) => {
  const {
    providerId,
    onChangeProvider,
    bookings,
    onPreviousWeek,
    onNextWeek,
    onSelectWeek,
    from,
    to,
    providers
  } = props
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable',
    ignoreGlobalScope: true
  })
  const { classNames } = useClassNames({
    alias: 'ProviderBookingsTable'
  })
  const handlePreviousWeek = useCallback(() => {
    onPreviousWeek()
    setInitialWeek(
      moment(from).add(-1, 'weeks').startOf('week').format('ll') +
        ' - ' +
        moment(to).add(-1, 'weeks').endOf('week').format('ll')
    )
  }, [onPreviousWeek, from, to])

  const handleNextWeek = useCallback(() => {
    onNextWeek()
    setInitialWeek(
      moment(from).add(1, 'weeks').startOf('week').format('ll') +
        ' - ' +
        moment(to).add(1, 'weeks').endOf('week').format('ll')
    )
  }, [onNextWeek, from, to])

  const handleSelectWeek = useCallback(
    (dateRange) => {
      setInitialWeek(
        moment(dateRange.from).format('ll') +
          ' - ' +
          moment(dateRange.to).format('ll')
      )
      onSelectWeek(dateRange)
    },
    [onSelectWeek]
  )

  const [initialWeek, setInitialWeek] = useState(
    moment(from).format('LL') + ' - ' + moment(to).format('LL')
  )

  const memoColumns = useMemo(() => {
    return providerBookingColumns({
      msg,
      providerId,
      onChangeProvider,
      from,
      to
    })
  }, [msg, providerId, onChangeProvider, from, to])

  const weeks = useMemo(() => {
    let startDate = moment(new Date()).startOf('week').format('YYYY-MM-DD')
    const endDate = moment(startDate)
      .add(1, 'year')
      .startOf('week')
      .format('YYYY-MM-DD')

    const weeks = []
    while (moment(startDate).isBefore(endDate)) {
      const startDateWeek = moment(startDate).startOf('week')
      const endDateWeek = moment(startDate).endOf('week')
      weeks.push({ start: startDateWeek, end: endDateWeek })

      startDate = moment(startDate).add(1, 'week').format('YYYY-MM-DD')
    }
    return weeks
  }, [])

  const isMobile = useMediaQuery(MobileBreakpoint)

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <Visible
          condition={!_.isEmpty(providers) && providers.length > 1}
          ifTrue={
            <ProviderSelector
              providers={providers}
              value={providerId}
              onChange={onChangeProvider}
            />
          }
          otherwise={
            <Block
              title={!_.isEmpty(providers) && providers[0].name}
              variant="clear"
              className={classNames('providerName')}
            />
          }
        />
        <WeekSelectorField
          label={msg({ id: 'selectWeek' })}
          onChange={(week) => handleSelectWeek(week)}
          weeks={weeks}
          initialWeek={initialWeek}
        />
        <div className={classNames('dateHandlers')}>
          <PrevButton onClick={handlePreviousWeek} />
          <NextButton onClick={handleNextWeek} />
        </div>
      </div>
      <ReactTable
        manual
        minRows={0}
        sortable={false}
        data={bookings}
        columns={memoColumns}
        fetchData={() => null}
        getSubRows={(row) => {
          if (_.get(row, 'availableTimes.length', 0) > 1) {
            return row.availableTimes.map(
              ({ availableTime, weeklyBookings }) => ({
                availableTime,
                bookable: row.bookable,
                daysOfWeek: weeklyBookings
              }),
              {}
            )
          }
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        showPageSizeOptions={!isMobile}
        className={classNames('body')}
        layout={TableLayout.DEFAULT}
        rowsText={msg({ id: 'rowsText' })}
        pageText={msg({ id: 'pageText' })}
        noDataText={msg({ id: 'noDataText' })}
      />
    </div>
  )
}

ProviderBookingsTable.propTypes = {
  bookings: PropTypes.array,
  providers: PropTypes.array,
  providerId: PropTypes.number,
  onChangeProvider: PropTypes.func,
  onPreviousWeek: PropTypes.func,
  onNextWeek: PropTypes.func,
  onSelectWeek: PropTypes.func,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date)
}

ProviderBookingsTable.defaultProps = {
  bookings: [],
  providers: [],
  providerId: null,
  onChangeProvider: () => null,
  onPreviousWeek: () => null,
  onNextWeek: () => null,
  onSelectWeek: () => null
}

ProviderBookingsTable.displayName = 'ProviderBookingsTable'

export default ProviderBookingsTable
