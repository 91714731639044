import { useCallback } from 'react'
import { generatePath } from 'react-router'
import queryString from 'query-string'
import moment from 'moment'
import { useGetCategories } from 'components/api'
import { generateCategoryPath } from 'modules/bookables/domain/categories'

export const homePath = '/'
export const contactPath = '/contact'
export const cookiesPath = '/cookies'
export const bookablesSearchPath = '/search'
export const bookablePath = `(/.*)*/p/:id`
export const bookableBookingPath = `${bookablePath}/booking`
export const bookingsPath = '/bookings'
export const bookingsCalendarPath = `${bookingsPath}/calendar`
export const bookingsReportPath = `${bookingsPath}/report`
export const bookingsTicketsPath = `${bookingsPath}/tickets`

export const bookingDetailsRelativePath = '/'
export const bookingSummaryRelativePath = '/summary'
export const bookingPaymentRelativePath = '/payment'
export const bookingCommentsRelativePath = '/comments'
export const bookingGuestsRelativePath = '/guests'
export const bookingObservationsRelativePath = '/observations'

export const bookingPath = `${bookingsPath}/:id`
export const bookingPaymentPath = `${bookingsPath}/:id${bookingPaymentRelativePath}`
export const bookingSummaryPath = `${bookingsPath}/:id${bookingSummaryRelativePath}`
export const bookingCommentsPath = `${bookingsPath}/:id${bookingCommentsRelativePath}`
export const bookingObservationsPath = `${bookingsPath}/:id${bookingObservationsRelativePath}`
export const bookingGuestsPath = `${bookingsPath}/:id${bookingGuestsRelativePath}`

export const bookersPath = '/bookers'
export const providerBookingsPath = '/providers/:providerId?/bookings'
export const providersPath = '/providers'
export const providerPath = '/providers/:slug'

export const categoryPath = '(/.*)*/:slug'

export const encodeQueryParams = (queryParams) =>
  btoa(JSON.stringify(queryParams))

export const decodeQueryParams = (encodedParams) =>
  JSON.parse(atob(encodedParams))

export const encodeUrl = (baseUrl, queryParams) =>
  `${baseUrl}?hash=${encodeQueryParams(queryParams)}`

export const decodeQueryParamsFromLocationSearch = (locationSearch) =>
  decodeQueryParams(queryString.parse(locationSearch).hash)

export const toQueryParams = (params) => {
  return Object.keys(params)
    .filter((key) => Boolean(params[key]) && params[key] !== 'all')
    .map((key) => {
      if (params[key] instanceof Date) {
        return key + '=' + moment(params[key]).format(moment.HTML5_FMT.DATE)
      } else if (params[key] instanceof Object) {
        return toQueryParams(params[key])
      } else {
        return key + '=' + params[key]
      }
    })
    .join('&')
}

export const generateBookablesSearchPath = ({ searchParams }) =>
  `${generatePath(bookablesSearchPath)}?${queryString.stringify(searchParams, {
    skipEmptyString: true,
    skipNull: true
  })}`

export const generateBookingsCalendarPath = ({ searchParams }) =>
  `${generatePath(bookingsCalendarPath)}?${queryString.stringify(searchParams)}`

export const generateBookablePath = ({ id, categoryPath }) =>
  generatePath((categoryPath ? categoryPath + '/' : '') + bookablePath, {
    id
  })

export const useGenerateBookablePath = () => {
  const { bookableCategories } = useGetCategories()
  return useCallback(
    (bookable) => {
      return generateBookablePath({
        id: bookable.slug,
        categoryPath: bookable.category
          ? generateCategoryPath(bookableCategories, bookable.category.id)
          : ''
      })
    },
    [bookableCategories]
  )
}

export const generateBookableBookingPath = ({
  id,
  dateRange,
  dateTime,
  guests,
  pickedPriceType
}) => {
  const filteredGuests = {
    ...guests,
    amount: parseInt(guests.amount),
    guestTypes: guests.guestTypes.filter(({ amount }) => amount)
  }
  return encodeUrl(generatePath(bookableBookingPath, { id }), {
    dateTime,
    dateRange,
    guests: filteredGuests,
    pickedPriceType
  })
}

export const generateBookingsSearchPath = ({ searchParams }) =>
  `${generatePath(bookingsPath)}?${queryString.stringify(searchParams)}`

export const generateBookingPath = ({ id }) => generatePath(bookingPath, { id })

export const generateBookingPaymentPath = ({ id }) =>
  generatePath(bookingPaymentPath, { id })

export const generateBookingCommentsPath = ({ id }) =>
  generatePath(bookingCommentsPath, { id })

export const generateBookingSummaryPath = ({ id }) =>
  generatePath(bookingSummaryPath, { id })

export const generateBookingGuestsPath = ({ id }) =>
  generatePath(bookingGuestsPath, { id })

export const generateBookingObservationsPath = ({ id }) =>
  generatePath(bookingObservationsPath, { id })

export const generateProviderBookings = ({ providerId, searchParams }) =>
  `${generatePath(providerBookingsPath, {
    providerId
  })}?${queryString.stringify(searchParams)}`

export const generateProviderPath = ({ slug }) =>
  generatePath(providerPath, {
    slug
  })
