import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useTenantConfig } from 'modules/bookables/Provider'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Sidebar from 'components/layout/Sidebar'

import SearchModal from './SearchModal'
import MobileNavMenu from './MobileNavMenu'
import UserSidebar from './UserSidebar'

const TouchNavBar = ({
  renderSearch,
  renderCategories,
  sidebarCategoryText
}) => {
  const { classNames } = useClassNames({
    alias: 'TouchNavBar'
  })
  const { logo, name } = useTenantConfig()
  return (
    <div className={classNames()}>
      <Link to="/" className={classNames('appLogo')}>
        <img src={logo} alt={name} />
      </Link>
      <Sidebar className="TouchMenuSidebar" placement="left" offsetY={50}>
        <MobileNavMenu
          renderCategories={renderCategories}
          sidebarCategoryText={sidebarCategoryText}
        />
      </Sidebar>
      {renderSearch && <SearchModal />}
      <UserSidebar />
    </div>
  )
}

TouchNavBar.displayName = 'TouchNavBar'

TouchNavBar.propTypes = {
  renderSearch: PropTypes.bool,
  renderCategories: PropTypes.bool,
  sidebarCategoryText: PropTypes.string
}

TouchNavBar.defaultProps = {
  renderSearch: true,
  renderCategories: true
}

export default TouchNavBar
