import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  useIntl,
  MessageProvider
} from '@trileuco/triskel-react-ui/components/i18n'
import Layers from '@trileuco/triskel-react-ui/components/ui/Layers'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import useBackendErrorNotification from 'components/api/errors/useBackendErrorNotification'
import MetaTags from 'components/layout/MetaTags'
import Error403Img from 'assets/img/error/403.svg'
import Error404Img from 'assets/img/error/404.svg'
import Error500Img from 'assets/img/error/500.svg'
import _ from 'lodash'

import { ErrorInfo } from './ErrorInfo'

const ErrorPage = ({ error }) => {
  const { code, status, message } = error
  const { msg } = useIntl({ scope: 'errorPage' })
  const metaTags = {
    ogTitle: 'Error ' + code + ' - Balaena',
    ogRobots: 'noindex'
  }
  const isError403 = code === 403 || status === 403
  const isError404 = code === 404 || status === 404
  const isError500 = !code || code === 500 || status === 500

  const { backendErrorNotification } = useBackendErrorNotification()

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && isError500) {
      console.log('Notifying error to backend: ', error)
      const {
        appCodeName,
        appName,
        appVersion,
        platform,
        userAgent,
        language,
        languages
      } = navigator
      const errorPayload = {
        message: error.message,
        href: document.location.href,
        referrer: document.referrer,
        navigator: {
          appCodeName,
          appName,
          appVersion,
          platform,
          userAgent,
          language,
          languages
        },
        stack: _.get(error, 'stack')
      }
      backendErrorNotification({
        message: message,
        clientInfo: {
          appCodeName,
          appName,
          appVersion,
          platform,
          userAgent,
          language,
          languages
        },
        errorPayload: errorPayload
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Page id="ErrorPage">
        <MetaTags metaTags={metaTags} />
        <MessageProvider scope="errorPage">
          {(isError403 || isError404) && (
            <PublicPageSkeleton
              renderSearch={true}
              mainContent={
                <BoundedPageTemplate
                  mainContent={
                    <>
                      {isError403 && (
                        <ErrorInfo
                          code={code}
                          title={msg({ id: 'error403.title' })}
                          description={msg({ id: 'error403.description' })}
                          buttonText={msg({ id: 'error403.buttonText' })}
                          buttonLink="/"
                          imgUrl={Error403Img}
                          hiddenMessage={message}
                        />
                      )}
                      {isError404 && (
                        <ErrorInfo
                          code={code}
                          title={msg({ id: 'error404.title' })}
                          description={msg({ id: 'error404.description' })}
                          buttonText={msg({ id: 'error404.buttonText' })}
                          buttonLink="/"
                          imgUrl={Error404Img}
                          hiddenMessage={message}
                        />
                      )}
                    </>
                  }
                />
              }
            />
          )}
          {isError500 && (
            <div className="Error500">
              <ErrorInfo
                code={code}
                title={msg({ id: 'error500.title' })}
                description={msg({ id: 'error500.description' })}
                imgUrl={Error500Img}
                hiddenMessage={message}
              />
            </div>
          )}
        </MessageProvider>
      </Page>
      <Layers />
    </>
  )
}

ErrorPage.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.number,
    status: PropTypes.number,
    message: PropTypes.string
  })
}

ErrorPage.displayName = 'ErrorPage'

export default ErrorPage
