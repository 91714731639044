import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  forwardRef
} from 'react'
import PropTypes from 'prop-types'
import WebpagesNavMenu from 'components/layout/nav/WebpagesNavMenu'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Sidebar from 'components/layout/Sidebar'
import { useGetCategories } from 'components/api'
import _ from 'lodash'

import CategoryDetailsSidebar from './CategoryDetailsSidebar'
import DesktopCategoriesNavMenu from './DesktopCategoriesNavMenu'

const DesktopNavSidebarTrigger = forwardRef(function DesktopNavSidebarTrigger(
  { onClick, className, sidebarTriggerText },
  ref
) {
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'components.nav.DesktopNavSidebarTrigger'
  })

  const triggerText = useMemo(() => {
    return !_.isUndefined(sidebarTriggerText)
      ? sidebarTriggerText
      : msg({ id: 'text' })
  }, [msg, sidebarTriggerText])

  return (
    <div ref={ref}>
      <Button
        className={className}
        iconBefore={'fas fa-bars'}
        size="m"
        text={triggerText}
        variant="clear"
        onClick={onClick}
      />
    </div>
  )
})

DesktopNavSidebarTrigger.displayName = 'DefaultTrigger'

DesktopNavSidebarTrigger.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  visible: PropTypes.bool,
  sidebarTriggerText: PropTypes.string
}

const DesktopNavSidebar = ({
  renderCategories,
  sidebarCategoryText,
  sidebarSubcategoryText,
  sidebarTriggerText
}) => {
  const [open, setOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(null)

  const handleSelectCategory = useCallback(
    (category) => {
      if (open) {
        setSelectedCategory(category)
      }
    },
    [open]
  )

  useEffect(() => {
    if (!selectedCategory) {
      const timeout = setTimeout(() => {
        setCurrentCategory(null)
      }, 3000)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      setCurrentCategory(selectedCategory)
    }
  }, [selectedCategory])

  const handleMenuOpening = useCallback(() => {
    setOpen(true)
  }, [])

  const handleMenuClosing = useCallback(() => {
    setOpen(false)
    handleSelectCategory(null)
  }, [handleSelectCategory])

  const { bookableCategories, loading: loadingCategories } = useGetCategories()
  if (loadingCategories) {
    return null
  }
  return (
    <>
      <CategoryDetailsSidebar
        placement="left"
        visible={selectedCategory}
        offsetX={open ? 350 : 0}
        onClick={(e) => e.preventDefault()}
        onMouseEnter={() => handleSelectCategory(selectedCategory)}
        onMouseLeave={() => handleSelectCategory(null)}
        category={currentCategory}
        categories={bookableCategories}
        sidebarSubcategoryText={sidebarSubcategoryText}
      />
      <Sidebar
        className="DesktopNavSidebar"
        placement="left"
        Trigger={DesktopNavSidebarTrigger}
        onOpen={handleMenuOpening}
        onClose={handleMenuClosing}
        sidebarTriggerText={sidebarTriggerText}
      >
        {renderCategories && (
          <DesktopCategoriesNavMenu
            categories={bookableCategories}
            selectedCategory={selectedCategory}
            onSelectCategory={handleSelectCategory}
            sidebarCategoryText={sidebarCategoryText}
          />
        )}
        <WebpagesNavMenu />
      </Sidebar>
    </>
  )
}

DesktopNavSidebar.propTypes = {
  renderCategories: PropTypes.bool,
  sidebarCategoryText: PropTypes.string,
  sidebarSubcategoryText: PropTypes.string,
  sidebarTriggerText: PropTypes.string
}

DesktopNavSidebar.displayName = 'DesktopNavSidebar'

export default DesktopNavSidebar
