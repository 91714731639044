import PropTypes from 'prop-types'
import CountryRegionData from 'country-region-data/data'

const countryNameByCode = CountryRegionData.reduce(
  (countryByCode, countryData) => ({
    ...countryByCode,
    [countryData.countryShortCode]: countryData.countryName
  }),
  {}
)

const CountryRegionLabel = ({ countryCode, region }) => {
  const countryName = countryNameByCode[countryCode]
  return region ? `${region} - ${countryName}` : countryName
}

CountryRegionLabel.propTypes = {
  countryCode: PropTypes.string.isRequired,
  region: PropTypes.string
}

CountryRegionLabel.displayName = 'CountryRegionLabel'

export default CountryRegionLabel
