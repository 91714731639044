import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  IconButton,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import SearchPanel from 'components/layout/nav/DesktopNavBar/SearchPanel'
import { useTenantConfig } from 'modules/bookables/Provider'

import DesktopNavSidebar from './DesktopNavSidebar'
import UserMenuTooltip from './UserMenuTooltip'

const DesktopNavBar = (props) => {
  const [isOpenSearchBar, openSearchBar] = useState(false)
  const { logo, name } = useTenantConfig()
  const { classNames } = useClassNames({
    alias: 'DesktopNavBar'
  })
  const {
    renderSearch,
    renderCategories,
    sidebarCategoryText,
    sidebarSubcategoryText,
    sidebarTriggerText
  } = props
  const { msg } = useIntl({
    scope: 'balaena.userMenu',
    ignoreGlobalScope: true
  })
  return (
    <>
      <div className={classNames()}>
        <Link to="/" className={classNames('appLogo')}>
          <img src={logo} alt={name} />
        </Link>
        {!isOpenSearchBar && (
          <>
            <DesktopNavSidebar
              renderCategories={renderCategories}
              sidebarCategoryText={sidebarCategoryText}
              sidebarSubcategoryText={sidebarSubcategoryText}
              sidebarTriggerText={sidebarTriggerText}
            />
            {renderSearch && (
              <Button
                size="m"
                variant="clear"
                onClick={() => openSearchBar(true)}
                text={msg({ id: 'search' })}
                iconBefore="fas fa-search"
                className="StartSearchBtn"
              />
            )}
            <UserMenuTooltip />
          </>
        )}
        {isOpenSearchBar && (
          <>
            <SearchPanel />
            <div className="CloseSearchPanelWrapper">
              <IconButton
                size="s"
                variant="outline"
                onClick={() => openSearchBar(false)}
                icon="fas fa-times"
                className="CloseSearchPanel"
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

DesktopNavBar.displayName = 'DesktopNavBar'

DesktopNavBar.propTypes = {
  renderSearch: PropTypes.bool,
  renderCategories: PropTypes.bool,
  sidebarCategoryText: PropTypes.string,
  sidebarSubcategoryText: PropTypes.string,
  sidebarTriggerText: PropTypes.string
}

DesktopNavBar.defaultProps = {
  renderSearch: true,
  renderCategories: true
}

export default DesktopNavBar
