import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { generateBookingsCalendarPath } from 'modules/bookables/routes'
import {
  Button,
  PopOver,
  IconButton
} from '@trileuco/triskel-react-ui/components/ui'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'

import BookableShare from './BookableShare'

const BookableHeaderActions = ({ bookable }) => {
  const authContext = useAuth()
  const { msg } = useIntl({})
  return (
    <div className="BookableHeaderActions">
      <BookableShare bookable={bookable} />
      <Visible
        condition={
          authContext.hasSomeRole &&
          authContext.hasSomeRole(['balaena-admin', 'balaena-provider'])
        }
      >
        <PopOver
          triggerEvent="click"
          placement="bottom-end"
          TriggerTag={'div'}
          alias="BookableAdminActionsTooltip"
          content={
            <ul>
              <li>
                <Button
                  text={msg({ id: 'seeBookings' })}
                  linkTo={generateBookingsCalendarPath({
                    searchParams: {
                      bookableIds: [bookable.slug]
                    }
                  })}
                  variant="clear"
                  size="l"
                />
              </li>
            </ul>
          }
        >
          <IconButton icon="fas fa-ellipsis-v" />
        </PopOver>
      </Visible>
    </div>
  )
}

BookableHeaderActions.propTypes = {
  bookable: PropTypes.shape()
}

BookableHeaderActions.displayName = 'BookableHeaderActions'

export default BookableHeaderActions
