import React from 'react'
import PropTypes from 'prop-types'
import { SubmitButton } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  Form,
  FieldSet,
  TextField
} from '@trileuco/triskel-react-ui/components/ui'
import ReCaptchaField from 'components/misc/ReCaptchaField'

const SignUpForm = ({ onSubmit, disabled }) => {
  const { msg } = useIntl({
    scope: 'balaena.signUpForm',
    ignoreGlobalScope: true
  })
  return (
    <Form onSubmit={onSubmit} disabled={disabled} validatePristine>
      <FieldSet direction="column">
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'firstName' })}
          field="firstName"
          rules={['required']}
        />
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'lastName' })}
          field="lastName"
          rules={['required']}
        />
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'email' })}
          field="email"
          rules={['required', 'email']}
        />
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'password' })}
          field="password"
          type="password"
          rules={['required']}
        />
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'confirmPassword' })}
          field="confirmPassword"
          type="password"
          rules={[
            'required',
            ({ value, form: { values } }) => {
              if (value !== values.password) {
                return msg({ id: 'passwordMatchError' })
              }
              return false
            }
          ]}
        />
        <ReCaptchaField
          field="g-recaptcha-response-data"
          action="social"
          rules={['required']}
        />
        <SubmitButton text={msg({ id: 'submit' })} disabled={disabled} />{' '}
      </FieldSet>
    </Form>
  )
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
}

SignUpForm.displayName = 'SignUpForm'

export default SignUpForm
