import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { TableLayout } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useMediaQuery } from 'react-responsive'
import { MobileBreakpoint } from 'components/layout/Mediaqueries'
import { LoadingResults } from 'components/api'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

import BookingsReactTable from './BookingsReactTable'
import BookingsTableFilter from './BookingsTableFilter'
import bookingColumns from './bookingColumns'

const CardCellTitle = ({ text }) => <span className="td-th">{text}</span>
CardCellTitle.propTypes = {
  text: PropTypes.string
}

const BookingsTable = (props) => {
  const authContext = useAuth()
  const {
    bookings,
    pagination,
    filter,
    loading,
    onFilterChange,
    onPaginationChange
  } = props
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable',
    ignoreGlobalScope: true
  })

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, orderBy }) => {
      onPaginationChange({ page: pageIndex + 1, pageSize, orderBy })
    },
    [onPaginationChange]
  )

  const memoColumns = useMemo(() => {
    return bookingColumns(msg, authContext)
  }, [msg, authContext])

  const isMobile = useMediaQuery(MobileBreakpoint)

  if (loading) {
    return (
      <>
        <BookingsTableFilter filter={filter} />
        <LoadingResults />
      </>
    )
  }
  return (
    <>
      {loading && <LoadingResults />}
      <BookingsTableFilter filter={filter} onFilterChange={onFilterChange} />
      <BookingsReactTable
        manual
        minRows={0}
        sortable={true}
        data={bookings}
        columns={memoColumns}
        loading={loading}
        pagination={pagination}
        fetchData={handleFetchData}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        showPageSizeOptions={!isMobile}
        className="BookingsTable"
        layout={isMobile ? TableLayout.CARD : TableLayout.DEFAULT}
        rowsText={msg({ id: 'rowsText' })}
        pageText={msg({ id: 'pageText' })}
        noDataText={msg({ id: 'noDataText' })}
      />
    </>
  )
}

BookingsTable.propTypes = {
  bookings: PropTypes.array,
  loading: PropTypes.bool,
  filter: PropTypes.shape({
    bookerId: PropTypes.string,
    bookableId: PropTypes.string,
    status: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string
  }),
  onFilterChange: PropTypes.func,
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    orderBy: PropTypes.string
  }),
  onPaginationChange: PropTypes.func
}

BookingsTable.defaultProps = {
  filter: {},
  pagination: {
    pageSize: 10,
    page: 1
  },
  onFilterChange: () => null,
  onPaginationChange: () => null
}

BookingsTable.displayName = 'BookingsTable'

export default BookingsTable
