import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  DayPickerField,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const DateIntervalFilterFieldSummary = ({ value, clearValue }) => {
  const { classNames } = useClassNames({
    alias: 'DateIntervalFilterFieldSummary'
  })
  const { msg } = useIntl({
    scope: 'balaena.bookableSearchForm.dateIntervalFilterFieldSummary',
    ignoreGlobalScope: true
  })
  return (
    <div className={classNames()}>
      <span className={classNames('label')}>
        {msg({
          id: 'label'
        })}
      </span>
      <span className={classNames('dates')}>
        {moment(value.from).format('ll')} - {moment(value.to).format('ll')}
      </span>
      <Button
        onClick={clearValue}
        iconBefore="fas fa-edit"
        text={msg({
          id: 'edit'
        })}
        variant="clear"
        size="s"
      />
    </div>
  )
}

DateIntervalFilterFieldSummary.propTypes = {
  value: PropTypes.shape(),
  clearValue: PropTypes.func
}

DateIntervalFilterFieldSummary.displayName = 'DateIntervalFilterFieldSummary'

const DateIntervalFilterField = (props) => {
  const { value, onChange, ...restProps } = props
  const clearValue = useCallback(() => {
    onChange({})
  }, [onChange])
  if (value && value.from && value.to) {
    return (
      <DateIntervalFilterFieldSummary value={value} clearValue={clearValue} />
    )
  }
  return (
    <DayPickerField
      variant="clear"
      interval
      autoComplete={'off'}
      value={value}
      onChange={onChange}
      {...restProps}
    />
  )
}

DateIntervalFilterField.propTypes = {
  value: PropTypes.shape(),
  onChange: PropTypes.func
}

DateIntervalFilterField.displayName = 'DateIntervalFilterField'

export default formalizer(DateIntervalFilterField)
