import React from 'react'
import PropTypes from 'prop-types'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import TagsCloud from 'modules/blog/components/TagsCloud'
import ArticleList from 'modules/blog/components/ArticleList'
import { LoadingResults, useGetWebpage } from 'components/api'
import HeaderSection from 'components/cms/HeaderSection'
import BlogBreadcrumb from 'modules/blog/components/BlogBreadcrumb'
import photosPropTypes from 'components/api/photos/photosPropTypes'
import _ from 'lodash'
import MetaTags from 'components/layout/MetaTags'
import { NotFoundError } from 'components/error'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'

const BlogHeader = ({ header, photos }) => {
  return (
    <HeaderSection
      {...header}
      breadcrumb={<BlogBreadcrumb />}
      photos={photos}
      size="s"
    />
  )
}

BlogHeader.propTypes = {
  header: PropTypes.object,
  photos: photosPropTypes
}

const ArchiveHomePage = () => {
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'blog.articles'
  })
  const { webpage: blogWebpage, loading } = useGetWebpage({ id: 'blog' })
  if (loading) {
    return <LoadingResults />
  }
  if (!blogWebpage) {
    throw new NotFoundError('Webpage not found for slug blog')
  }
  const {
    content: { header, archive, lastEntries },
    photos
  } = blogWebpage
  return (
    <Page id="ArchiveHomePage">
      {blogWebpage && <MetaTags metaTags={blogWebpage.metaTags} />}
      <PublicPageSkeleton
        headerAssets={<BlogHeader header={header} photos={photos} />}
        mainContent={
          <BoundedPageTemplate
            mainContent={
              <>
                <ArticleList
                  showLoadMoreButton
                  title={
                    _.get(lastEntries, 'title.text') ||
                    msg({ id: 'lastArticles' })
                  }
                />
              </>
            }
            sidebar={
              <TagsCloud
                title={_.get(archive, 'title.text') || msg({ id: 'archive' })}
              />
            }
          />
        }
      />
    </Page>
  )
}

ArchiveHomePage.propTypes = {}

ArchiveHomePage.displayName = 'ArchiveHomePage'

export default ArchiveHomePage
