import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import { useGetBookables, LoadingResults } from 'components/api'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import MetaTags from 'components/layout/MetaTags'
import { useTenantConfig } from 'modules/bookables/Provider'
import BookablesCarousel from 'components/bookable/BookableCarousel/BookablesCarousel'
import BookablesList from 'components/bookable/BookablesList/BookablesList'
import Card from '@trileuco/triskel-react-ui/components/ui/Card'

const NoBookablesFound = ({ msg }) => (
  <Card
    className="NoBookablesFound"
    variant="clear"
    header={
      <p>
        <span>
          <i className="fas fa-search" />
        </span>
        {msg({
          id: 'noBookablesFound.title'
        })}
      </p>
    }
    body={
      <p>
        {msg({
          id: 'noBookablesFound.description'
        })}
      </p>
    }
  />
)

NoBookablesFound.propTypes = {
  msg: PropTypes.func
}

const BookablesSearch = (props) => {
  const { filter, spaceFillers, view, title, shuffle } = props
  const { msg } = useIntl({
    scope: 'balaena.bookablesSearch',
    ignoreGlobalScope: true
  })
  const { metaTags } = useTenantConfig()
  const isDesktop = useMediaQuery(DesktopBreakpoint)
  const isCarouselView = view === 'carousel' && isDesktop
  const {
    bookables: bookablesArray,
    loading: loadingBookables
  } = useGetBookables({
    queryParams: filter
  })
  const bookables = useMemo(() => {
    return shuffle ? _.shuffle(bookablesArray) : bookablesArray
  }, [shuffle, bookablesArray])
  if (loadingBookables) {
    return <LoadingResults />
  }

  const noResults = bookables.length === 0

  if (noResults) {
    return <NoBookablesFound msg={msg} />
  }

  return (
    <>
      <MetaTags metaTags={metaTags} />
      <Block
        variant="clear"
        className="BookablesSearch BookablesCategory"
        TitleTag="p"
        title={title || msg({ id: 'results' }, { total: bookables.length })}
      >
        {isCarouselView ? (
          <>
            <BookablesCarousel bookables={bookables} />
          </>
        ) : (
          <BookablesList bookables={bookables} spaceFillers={spaceFillers} />
        )}
      </Block>
    </>
  )
}

BookablesSearch.propTypes = {
  title: PropTypes.node,
  filter: PropTypes.object,
  spaceFillers: PropTypes.number,
  view: PropTypes.oneOfType([
    PropTypes.oneOf(['carousel, default']),
    PropTypes.string
  ]),
  shuffle: PropTypes.bool
}

BookablesSearch.defaultProps = {
  spaceFillers: 12,
  view: 'default',
  shuffle: false
}

BookablesSearch.displayName = 'BookablesSearch'

export default BookablesSearch
