import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookableWysiwygBlock from './BookableWysiwygBlock'

const BookableDescriptionBlock = ({ bookable }) => {
  const { msg } = useIntl({ scope: '' })
  return (
    <BookableWysiwygBlock
      title={msg({ id: 'description' })}
      content={bookable.descriptions.long}
    />
  )
}

BookableDescriptionBlock.propTypes = {
  bookable: PropTypes.shape()
}

BookableDescriptionBlock.displayName = 'BookableDescriptionBlock'

export default BookableDescriptionBlock
