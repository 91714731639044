import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FieldWrapper } from '@trileuco/triskel-react-ui/components/ui'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const RangeSlider = (props) => {
  const { value, min, max, onChange, lineal, options } = props
  const railRef = useRef()

  const { classNames } = useClassNames({ alias: 'RangeSlider' })

  const [active, setActive] = useState(null)
  const [deltaOffset, setDeltaOffset] = useState(0)

  const stepOffset = useCallback(
    ({ value, index }) => {
      if (lineal) {
        return (index * 100) / (options.length - 1)
      }
      return (value * 100) / (max - min)
    },
    [lineal, max, min, options.length]
  )

  const stepLeft = useCallback(
    ({ value, index }) => {
      if (lineal) {
        return (index * 100) / options.length
      }
      return (value * 100) / (max - min)
    },
    [lineal, max, min, options.length]
  )

  const stepWidth = useCallback(() => {
    if (lineal) {
      return 100 / options.length
    }
  }, [options, lineal])

  const steps = useMemo(() => {
    const maxStep = [...options].sort((a, b) => b.total - a.total)[0].total
    const normalizeStepHeight = (total) => {
      return (total / (maxStep - 0)) * 24
    }
    return options.map((option, index) => ({
      ...option,
      index,
      offset: stepOffset({ ...option, index }),
      style: {
        left: `${stepLeft({ ...option, index })}%`,
        width: `${stepWidth()}%`,
        height: `${normalizeStepHeight(option.total)}px`
      }
    }))
  }, [options, stepOffset, stepWidth, stepLeft])

  const minLimitStep = useMemo(
    () => steps.find((step) => step.value === value.min) || steps[0],
    [steps, value.min]
  )

  const maxLimitStep = useMemo(
    () =>
      steps.find((step) => step.value === value.max) || steps[steps.length - 1],
    [steps, value.max]
  )

  const getClosestStepFromPosition = useCallback(
    (position) => {
      if (active === 'min') {
        return steps
          .slice(0, maxLimitStep.index)
          .reduce((nearestStep, step) => {
            if (
              Math.abs(position - step.offset) <
              Math.abs(position - nearestStep.offset)
            ) {
              return step
            }
            return nearestStep
          }, steps[0])
      } else {
        return steps
          .slice(minLimitStep.index + 1)
          .reduce((nearestStep, step) => {
            if (
              Math.abs(position - step.offset) <
              Math.abs(position - nearestStep.offset)
            ) {
              return step
            }
            return nearestStep
          }, steps[minLimitStep.index + 1])
      }
    },
    [steps, active, minLimitStep, maxLimitStep]
  )

  const getLimitLabel = useCallback(
    (limit) => {
      if (active === limit) {
        return getClosestStepFromPosition(deltaOffset).label
      } else if (limit === 'min') {
        return minLimitStep.label
      } else {
        return maxLimitStep.label
      }
    },
    [
      minLimitStep,
      maxLimitStep,
      active,
      getClosestStepFromPosition,
      deltaOffset
    ]
  )

  const handleMoveLimit = useCallback((event, limit) => {
    const { x, width } = railRef.current.getBoundingClientRect()
    let eventPositionX
    if (event.type === 'touchstart') {
      eventPositionX = event.changedTouches[0].pageX
    } else if (event.type === 'mousedown') {
      eventPositionX = event.pageX
    } else {
      return
    }
    const initialOffset = ((eventPositionX - x) * 100) / width
    setActive(limit)
    setDeltaOffset(initialOffset)
  }, [])

  const handleMouseUp = useCallback(
    (event) => {
      if (active) {
        const currentStepValue = getClosestStepFromPosition(deltaOffset).value
        setActive(null)
        setDeltaOffset(0)
        onChange({ ...value, [active]: currentStepValue })
      }
    },
    [deltaOffset, onChange, active, value, getClosestStepFromPosition]
  )

  const handleMouseMove = useCallback(
    (event) => {
      if (active) {
        const { x, width } = railRef.current.getBoundingClientRect()
        let eventPositionX
        if (event.type === 'touchmove') {
          eventPositionX = event.changedTouches[0].pageX
        } else if (event.type === 'mousemove') {
          eventPositionX = event.pageX
        } else {
          return
        }
        const deltaOffset = ((eventPositionX - x) * 100) / width
        setDeltaOffset(getClosestStepFromPosition(deltaOffset).offset)
      }
    },
    [active, getClosestStepFromPosition]
  )

  useEffect(() => {
    if (active) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('touchmove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('touchend', handleMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('touchmove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('touchend', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp, active])

  const minStyles = useMemo(() => {
    const minOffset = active === 'min' ? deltaOffset : stepOffset(minLimitStep)
    return {
      left: `${Math.max(0, Math.min(stepOffset(maxLimitStep), minOffset))}%`
    }
  }, [stepOffset, active, deltaOffset, minLimitStep, maxLimitStep])

  const maxStyles = useMemo(() => {
    const maxOffset = active === 'max' ? deltaOffset : stepOffset(maxLimitStep)
    return {
      left: `${Math.min(100, Math.max(stepOffset(minLimitStep), maxOffset))}%`
    }
  }, [stepOffset, active, deltaOffset, minLimitStep, maxLimitStep])

  const rangeStyles = useMemo(() => {
    return {
      left: minStyles.left,
      right: `calc(100% - ${maxStyles.left})`
    }
  }, [minStyles, maxStyles])

  return (
    <div className={classNames()}>
      <div className={classNames('rail')} ref={railRef}>
        {steps.map((step) => (
          <div
            key={step.value}
            className={classNames('step')}
            style={step.style}
          />
        ))}
        <div className={classNames('range')} style={rangeStyles} />
        <div
          className={classNames('limit', { min: true })}
          onMouseDown={(event) => handleMoveLimit(event, 'min')}
          onTouchStart={(event) => handleMoveLimit(event, 'min')}
          style={minStyles}
        >
          <div className={classNames('stepLabel')}>{getLimitLabel('min')}</div>
        </div>
        <div
          className={classNames('limit', { max: true })}
          onMouseDown={(event) => handleMoveLimit(event, 'max')}
          onTouchStart={(event) => handleMoveLimit(event, 'max')}
          style={maxStyles}
        >
          <div className={classNames('stepLabel')}>{getLimitLabel('max')}</div>
        </div>
      </div>
      <div className={classNames('value')} ref={railRef}>
        {getLimitLabel('min')} - {getLimitLabel('max')}
      </div>
    </div>
  )
}

RangeSlider.propTypes = {
  label: PropTypes.node,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape()),
  lineal: PropTypes.bool
}

RangeSlider.defaultProps = {
  lineal: true
}

const RangeSliderField = (props) => {
  const { classNames } = useClassNames({ alias: 'RangeSliderField' })
  return (
    <FieldWrapper {...props} className={classNames()} Field={RangeSlider} />
  )
}

RangeSliderField.propTypes = {}

RangeSliderField.displayName = 'RangeSliderField'

export default formalizer(RangeSliderField)
