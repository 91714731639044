import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useGetBackendError } from 'modules/bookables/i18n'

const useErrorNotification = () => {
  const {
    notify,
    statusTypes: { DANGER }
  } = useNotifications()
  const { getBackendError } = useGetBackendError()

  const notifyError = ({ id, title, error }) =>
    notify({
      id,
      message: getBackendError(error.data),
      title,
      status: DANGER
    })
  return {
    notifyError
  }
}

export default useErrorNotification
