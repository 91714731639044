import React from 'react'
import PropTypes from 'prop-types'

export const formatPrice = ({ amount, currency, locale = 'en-US' }) =>
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(amount / 100)

const Price = (props) => {
  return <>{formatPrice(props)}</>
}

Price.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string
}

Price.defaultProps = {
  currency: 'USD'
}

Price.displayName = 'Price'

export default Price
