import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { noop } from '@trileuco/triskel-react-ui/utils'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import MultiCheckboxField from 'components/misc/MultiCheckboxField'

const filterSelectAllSelected = (value, options) => !value || value.length === 0

const CheckboxFilterField = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookableSearchForm.checkboxFilterField',
    ignoreGlobalScope: true
  })
  const { options, value, hideUnselected, selectAllSelected } = props
  const [allOptionsAreShown, setAllOptionsAreShown] = useState(!hideUnselected)

  const isSelected = useCallback((option) => value.includes(option.value), [
    value
  ])

  const toggleShowOptions = useCallback(() => {
    setAllOptionsAreShown(!allOptionsAreShown)
  }, [allOptionsAreShown])

  const showableOptions = useMemo(() => {
    if (selectAllSelected(value, options) && !allOptionsAreShown) {
      return []
    }
    if (hideUnselected && !allOptionsAreShown) {
      return options.filter(isSelected)
    }
    return options
  }, [
    hideUnselected,
    options,
    isSelected,
    allOptionsAreShown,
    selectAllSelected,
    value
  ])

  const thereAreOptionsUnselected = useMemo(() => {
    return options.filter(isSelected).length !== options.length
  }, [options, isSelected])

  const selectAllAllowed = value.length === 0 && !allOptionsAreShown

  return (
    <div>
      <MultiCheckboxField
        {...props}
        options={showableOptions}
        selectAllAllowed={selectAllAllowed}
        selectAllSelected={selectAllSelected}
        selectAllCallback={() => setAllOptionsAreShown(true)}
      />
      {hideUnselected && thereAreOptionsUnselected && (
        <Button
          className="ToogleVisibility"
          variant="clear"
          size="s"
          onClick={toggleShowOptions}
          iconBefore={allOptionsAreShown ? 'fas fa-minus' : 'fas fa-plus'}
          text={
            allOptionsAreShown
              ? msg({ id: 'showLess' })
              : msg({ id: 'showMore' })
          }
        />
      )}
    </div>
  )
}

CheckboxFilterField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  direction: PropTypes.oneOf(['column', 'row']),
  onChange: PropTypes.func,
  hideUnselected: PropTypes.bool,
  selectAllSelected: PropTypes.func,
  selectAllAllowed: PropTypes.bool
}

CheckboxFilterField.defaultProps = {
  selectAllSelected: filterSelectAllSelected,
  onChange: noop
}

CheckboxFilterField.displayName = 'CheckboxFilterField'

export default formalizer(CheckboxFilterField)
