import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import _ from 'lodash'
import { useGetBookablePrice, LoadingResults } from 'components/api'
import BookableBasePrice from 'components/bookable/BookablePrice/BookableBasePrice'
import BookableTotalPrice from 'components/bookable/BookablePrice/BookableTotalPrice'
import BookableDiscountPrice from 'components/bookable/BookablePrice/BookableDiscountPrice'
import BookableGuestPrice from 'components/bookable/BookablePrice/BookableGuestPrice'
import BookableExtrasPrice from 'components/bookable/BookablePrice/BookableExtrasPrice'
import moment from 'moment'

const BookablePrice = ({
  bookable,
  guests,
  discount,
  dateTime,
  dateRange,
  showTaxes,
  extrasByBooking,
  extrasByGuests,
  pickedPriceType
}) => {
  const plainGuestTypes = useMemo(() => {
    return guests.guestTypes.reduce(
      (plainGuests, { guestType, amount }) => [
        ...plainGuests,
        ...Array(amount).fill(guestType)
      ],
      []
    )
  }, [guests])

  const bookingDuration = useMemo(() => {
    if (dateRange && bookable.continuous) {
      const to = moment(dateRange.to)
      const from = moment(dateRange.from)
      return to.diff(from, 'days')
    }
    return false
  }, [dateRange, bookable.continuous])

  const calculateExtrasTotalPrice = useCallback(
    (extras) => {
      if (!_.isEmpty(extras)) {
        return extras
          .map((addedExtra) => {
            let total = addedExtra.price * addedExtra.amount
            if (addedExtra.type.includes('day')) {
              total *= bookingDuration
            }
            return total
          })
          .reduce((a, b) => a + b, 0)
      }
      return 0
    },
    [bookingDuration]
  )

  const addedExtrasPriceByBooking = useMemo(
    () => calculateExtrasTotalPrice(extrasByBooking),
    [calculateExtrasTotalPrice, extrasByBooking]
  )

  const addedExtrasPriceByGuests = useMemo(
    () => calculateExtrasTotalPrice(extrasByGuests),
    [calculateExtrasTotalPrice, extrasByGuests]
  )

  const { priceResult, loading } = useGetBookablePrice({
    bookableId: bookable.id,
    amount: guests.amount,
    dateTime,
    from: dateRange && dateRange.from,
    to: dateRange && dateRange.to,
    guestTypeIds: plainGuestTypes.map((guest) => guest.guestTypeId),
    pickedPriceType: pickedPriceType && pickedPriceType.id
  })

  const {
    totalAmount: totalPrice,
    totalAmountWithoutTaxes: basePrice,
    taxes = []
  } = priceResult

  const discountAmount = useMemo(() => {
    if (discount) {
      switch (discount.discountType) {
        case 'fixed':
          return discount.discountValue || 0
        case 'percent':
          return loading
            ? null
            : ((discount.discountValue || 0) / 100) * totalPrice
        default:
          return null
      }
    }
    return null
  }, [discount, totalPrice, loading])

  const discountedPrice = useMemo(() => {
    if (discountAmount > 0 && !loading) {
      return Math.max(0, totalPrice - discountAmount) === 0
        ? totalPrice
        : totalPrice - discountAmount
    }
    return false
  }, [discountAmount, totalPrice, loading])

  const total = useMemo(() => {
    return totalPrice + addedExtrasPriceByBooking + addedExtrasPriceByGuests
  }, [totalPrice, addedExtrasPriceByBooking, addedExtrasPriceByGuests])

  const hasDiscount = !_.isEmpty(discount)

  const hasExtrasByBookings = !_.isEmpty(extrasByBooking)

  const hasExtrasByGuests = !_.isEmpty(extrasByGuests)

  const { msg } = useIntl({
    scope: 'balaena.bookable.bookablePrice',
    ignoreGlobalScope: true
  })

  return basePrice && totalPrice ? (
    <div className="BookablePrice">
      {showTaxes && (
        <BookableBasePrice
          label={msg({ id: 'base' })}
          basePrice={basePrice}
          currency={bookable.price.currency}
          taxes={taxes}
        />
      )}

      {hasExtrasByGuests && (
        <BookableExtrasPrice
          extras={extrasByGuests}
          currency={bookable.price.currency}
          bookingDuration={bookingDuration}
        />
      )}

      {hasExtrasByBookings && (
        <BookableExtrasPrice
          extras={extrasByBooking}
          currency={bookable.price.currency}
          bookingDuration={bookingDuration}
        />
      )}

      {hasDiscount && (
        <BookableDiscountPrice
          discountName={discount.name}
          discountAmount={discountAmount}
          currency={bookable.price.currency}
        />
      )}

      <BookableTotalPrice
        label={msg({ id: 'total' })}
        totalPrice={total}
        currency={bookable.price.currency}
        hasDiscount={hasDiscount}
        discountedPrice={discountedPrice}
      />

      {pickedPriceType && (
        <BookableGuestPrice
          pickedPriceType={pickedPriceType}
          currency={bookable.price.currency}
          guests={guests}
        />
      )}
    </div>
  ) : (
    <LoadingResults />
  )
}

BookablePrice.propTypes = {
  bookable: PropTypes.shape(),
  guests: PropTypes.object,
  dateTime: PropTypes.string,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  discount: PropTypes.object,
  showTaxes: PropTypes.bool,
  extrasByBooking: PropTypes.arrayOf(PropTypes.shape()),
  extrasByGuests: PropTypes.arrayOf(PropTypes.shape()),
  pickedPriceType: PropTypes.object
}

BookablePrice.displayName = 'BookablePrice'

export default BookablePrice
