import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/layout/Page'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useGetBookers, useMakeAdmin } from 'components/api'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import Block from 'components/layout/Block'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import BookersTable from 'components/booker/BookersTable/BookersTable'
import { toQueryParams } from 'modules/bookables/routes'
import {
  PopOver,
  IconButton,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'

import BookersModal from './BookersModal'

const BookersPage = ({ history, location }) => {
  const { msg } = useIntl({ scope: 'BookersPage' })

  const { isOpen, open, close } = useModal(false)

  const { page, pageSize = 10, bookerFilter = '' } = useQueryParams({
    page: QueryParamTypes.Int,
    pageSize: QueryParamTypes.Int
  })

  const { loading, bookers, pagination = {}, refetch } = useGetBookers({
    queryParams: {
      includeBookerExtraInfo: true,
      bookerFilter,
      page,
      pageSize
    }
  })

  const { makeAdmin } = useMakeAdmin()

  const filterValue = {
    bookerFilter
  }

  const handlePaginationChange = useCallback(
    (pagination) => {
      history.replace({
        pathname: location.pathname,
        search: toQueryParams({
          ...filterValue,
          pageSize: pagination.pageSize,
          page: pagination.page
        })
      })
    },
    [history, location.pathname, filterValue]
  )

  const handleBookingFilterChange = useCallback(
    (newFilter) => {
      history.replace({
        pathname: location.pathname,
        search: toQueryParams({
          ...newFilter,
          page: 1,
          pageSize: pagination.pageSize
        })
      })
    },
    [history, location.pathname, pagination.pageSize]
  )

  const BookersActionsMenu = () => (
    <PopOver
      alias="BookersActionsMenuTooltip"
      placement="bottom-end"
      content={
        <ul>
          <li>
            <Button
              text={msg({ id: 'menu.makeAdmin' })}
              variant="clear"
              onClick={open}
            />
          </li>
        </ul>
      }
      triggerEvent="click"
    >
      <IconButton icon="fas fa-ellipsis-v" variant="clear" size="l" />
    </PopOver>
  )

  const handleMakeAdmin = useCallback(
    (identifer) => {
      return makeAdmin(identifer).then(() => {
        refetch({
          queryParams: {
            includeBookerExtraInfo: true,
            bookerFilter,
            page,
            pageSize
          }
        })
      })
    },
    [makeAdmin, refetch, bookerFilter, page, pageSize]
  )
  return (
    <Page id="BookersPage">
      <PublicPageSkeleton
        mainContent={
          <Block
            title={
              <>
                {msg({ id: 'pageTitle' })}
                {bookers && <BookersActionsMenu />}
              </>
            }
            variant="clear"
            className="Bookers"
          >
            <BookersTable
              bookers={bookers}
              loading={loading}
              pagination={{
                totalPages: pagination.totalPages,
                page: pagination.page,
                pageSize: parseInt(pagination.pageSize)
              }}
              onPaginationChange={handlePaginationChange}
              onFilterChange={handleBookingFilterChange}
              filter={filterValue}
            />
            <BookersModal
              bookers={bookers}
              handleMakeAdmin={handleMakeAdmin}
              isOpen={isOpen}
              close={close}
            />
          </Block>
        }
      />
    </Page>
  )
}

BookersPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

BookersPage.displayName = 'BookersPage'

export default BookersPage
