import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { generateArticlePath } from 'modules/blog/routes'

export const ArticleNavigation = ({ navigation }) => {
  const { classNames } = useClassNames({ alias: 'ArticleNavigation' })
  const { previous, next } = navigation

  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'blog.articles.ArticleNavigation'
  })
  return (
    <div className={classNames()}>
      {previous && (
        <Button
          linkTo={generateArticlePath({ slug: previous.slug })}
          iconBefore="fas fa-angle-left"
          variant="clear"
          className={classNames('previousArticle')}
        >
          <span>
            <span>{msg({ id: 'previousArticle' })}</span>
            <span className={classNames('articleTitle')}>{previous.title}</span>
          </span>
        </Button>
      )}
      {next && (
        <Button
          linkTo={generateArticlePath({ slug: next.slug })}
          iconAfter="fas fa-angle-right"
          variant="clear"
          className={classNames('nextArticle')}
        >
          <span>
            <span>{msg({ id: 'nextArticle' })}</span>
            <span className={classNames('articleTitle')}>{next.title}</span>
          </span>
        </Button>
      )}
    </div>
  )
}

ArticleNavigation.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string
    }),
    next: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string
    })
  })
}

ArticleNavigation.displayName = 'ArticleNavigation'

export default ArticleNavigation
