import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { generateCategoryPath } from 'modules/bookables/domain/categories'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import SafeHtml from 'components/cms/SafeHtml'
import PhotoImg from 'components/misc/PhotoImg'

const CategoryCard = ({ category, categories, size }) => {
  const { classNames } = useClassNames({
    alias: 'CategoryCard'
  })
  const { msg } = useIntl({
    scope: 'balaena.CategoryCard',
    ignoreGlobalScope: true
  })
  return (
    <Link
      to={generateCategoryPath(categories, category.id)}
      className={classNames({ size })}
    >
      <Visible
        condition={!_.isEmpty(category.photos)}
        ifTrue={
          <PhotoImg
            photo={category.photos[0]}
            variant="card"
            className={classNames('img')}
          />
        }
        otherwise={<img className={classNames('img')} src={NoPhoto} alt="" />}
      />
      <div className={classNames('info')}>
        <span className={classNames('name')}>{category.name}</span>
        {category.descriptions && category.descriptions.short && (
          <SafeHtml
            WrapperTag="p"
            html={category.descriptions.short}
            className={classNames('description')}
          />
        )}
        <span className={classNames('seeAll')}>
          {msg({ id: 'categoryDetails.seeAll' })}
        </span>
      </div>
    </Link>
  )
}

CategoryCard.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
  category: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
}

CategoryCard.displayName = 'CategoryCard'

export default CategoryCard
