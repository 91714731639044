import React from 'react'
import PropTypes from 'prop-types'
import WhatsappBadgeVendor from 'react-whatsapp-badge'
import WhatsappLogo from 'assets/img/misc/whatsapp_logo.svg'

const WhatsappBadge = ({ openChatMessage, phone, image }) => {
  return (
    <div className="WhatsappBadge">
      <WhatsappBadgeVendor text={openChatMessage} phone={phone} image={image} />
    </div>
  )
}

WhatsappBadge.propTypes = {
  openChatMessage: PropTypes.string.isRequired,
  phone: PropTypes.number.isRequired,
  image: PropTypes.string
}

WhatsappBadge.defaultProps = {
  image: WhatsappLogo
}

WhatsappBadge.displayName = 'WhatsappBadge'

export default WhatsappBadge
