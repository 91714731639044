import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useFormContext } from 'react-form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { SubmitButton } from '@trileuco/triskel-react-ui/components/ui/Form'
import Button from '@trileuco/triskel-react-ui/components/ui/Button'
import {
  formatDateTime,
  groupSchedulesByDateTime,
  groupSchedulesByDayAndDateTime
} from 'modules/bookables/domain/availabilities'
import BookableProviderBadge from 'components/bookableProvider/BookableProviderBadge'
import Block from 'components/layout/Block'
import { useGetBookableAvailability } from 'components/api'
import BookablePrice from 'components/bookable/BookablePrice'
import { generateBookablePath } from 'modules/bookables/routes'
import { Redirect } from '@trileuco/triskel-react-ui/components/router'

import BookingDate from './BookingDate'
import BookingTermsAcceptance from './BookingTermsAcceptance'
import BookingDiscountField from './BookingDiscountField'

const BookingSummary = (props) => {
  const {
    bookable,
    dateTime,
    dateRange,
    guests,
    extrasByBooking,
    extrasByGuest,
    handleContinue,
    formStepId,
    pickedPriceType
  } = props

  const { msg } = useIntl({
    scope: 'balaena.bookingFields.bookingSummary',
    ignoreGlobalScope: true
  })
  const { msg: globalMsg } = useIntl({
    scope: 'balaena.globals',
    ignoreGlobalScope: true
  })

  const { timeZone } = bookable
  const { schedules, loading: loadingSchedules } = useGetBookableAvailability({
    id: bookable.slug,
    timeZone: timeZone,
    from: dateTime || dateRange.from,
    to: dateTime || dateRange.to
  })

  const schedulesGroupedByDay = useMemo(
    () => groupSchedulesByDateTime(schedules, timeZone),
    [schedules, timeZone]
  )

  const scheduleGroupedByDayAndDateTime = useMemo(
    () => groupSchedulesByDayAndDateTime(schedules, timeZone),
    [schedules, timeZone]
  )

  const bookablePriceAmount = useMemo(() => {
    if (loadingSchedules || _.isEmpty(schedules)) {
      return
    }
    if (bookable.continuous) {
      const bookingDate = formatDateTime(dateRange.from, timeZone)
      return schedulesGroupedByDay[bookingDate][0].priceAmount
    } else {
      const bookingDate = formatDateTime(dateTime, timeZone)
      const bookingTime = formatDateTime(dateTime, timeZone, 'HH:mm')
      return scheduleGroupedByDayAndDateTime[bookingDate][bookingTime]
        .priceAmount
    }
  }, [
    bookable,
    dateRange,
    dateTime,
    loadingSchedules,
    schedules,
    scheduleGroupedByDayAndDateTime,
    schedulesGroupedByDay,
    timeZone
  ])

  const {
    setMeta,
    values,
    meta: { isSubmitted }
  } = useFormContext()
  const edit = useCallback(() => {
    setMeta({
      isSubmitted: false
    })
  }, [setMeta])

  const addedExtrasByBooking = useMemo(() => {
    return extrasByBooking
      .filter((extra) =>
        _.find(values.extrasByBooking, {
          answer: true,
          extraId: extra.id
        })
      )
      .map((extra) => ({ ...extra, amount: 1 }))
  }, [extrasByBooking, values.extrasByBooking])

  const addedExtrasByGuests = useMemo(() => {
    const guestArr = !_.isEmpty(values.guests)
      ? values.guests.map((guest) =>
          guest.extras.filter((extra) => extra.answer === true)
        )
      : []

    const count = {}
    guestArr.map((guest) => {
      return guest.forEach((guestExtra) => {
        const findedExtra = extrasByGuest.find(
          (extra) => extra.id === guestExtra.extraId
        )
        if (count[findedExtra.id] === undefined) {
          count[findedExtra.id] = 1
        } else {
          count[findedExtra.id] += 1
        }
      })
    })

    const addedExtras = extrasByGuest.filter(
      (addedExtra) => count[addedExtra.id] !== undefined
    )

    addedExtras.forEach((addedExtra) => {
      addedExtra.amount = count[addedExtra.id]
    })

    return addedExtras
  }, [extrasByGuest, values.guests])

  if (!loadingSchedules && _.isEmpty(schedules)) {
    // This booking configuration is not valid anymore
    return <Redirect to={generateBookablePath(bookable)} />
  }
  return (
    <Block title={msg({ id: 'summaryBlockTitle' })} className="BookingSummary">
      <BookableProviderBadge size="s" provider={bookable.provider} />
      <p className="BookingSummary_bookableName">{bookable.name}</p>
      <p className="BookingSummary_bookableDate">
        <BookingDate
          dateTime={dateTime}
          dateRange={dateRange}
          timeZone={bookable.timeZone}
        />
      </p>
      <Visible
        condition={!(formStepId === 'schedule')}
        ifTrue={<BookingDiscountField bookable={bookable} />}
      />

      {!loadingSchedules && (
        <BookablePrice
          discount={values.discount}
          extrasByBooking={addedExtrasByBooking}
          extrasByGuests={addedExtrasByGuests}
          bookable={bookable}
          dateTime={dateTime}
          dateRange={dateRange}
          price={bookablePriceAmount}
          pickedPriceType={pickedPriceType}
          guests={guests}
          showTaxes={true}
        />
      )}
      {isSubmitted && <BookingTermsAcceptance bookable={bookable} />}
      <Visible
        condition={!isSubmitted}
        ifTrue={
          <Button
            text={msg({ id: 'continue' })}
            className="BookingSummary_confirmationBtn"
            onClick={handleContinue}
          />
        }
        otherwise={
          <>
            <SubmitButton
              text={msg({ id: 'confirm' })}
              className="BookingSummary_confirmationBtn"
            />
            <Button
              variant="outline"
              text={globalMsg({ id: 'edit' })}
              className="BookingSummary_confirmationBtn"
              onClick={() => {
                edit()
              }}
            />
          </>
        }
      />
    </Block>
  )
}

BookingSummary.propTypes = {
  bookable: PropTypes.shape(),
  dateTime: PropTypes.string,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  guests: PropTypes.shape({
    amount: PropTypes.number,
    guestTypes: PropTypes.arrayOf(
      PropTypes.shape({
        guestType: PropTypes.shape(),
        amount: PropTypes.number
      })
    )
  }),
  extrasByBooking: PropTypes.arrayOf(PropTypes.shape()),
  extrasByGuest: PropTypes.arrayOf(PropTypes.shape()),
  handleContinue: PropTypes.func,
  formStepId: PropTypes.string,
  pickedPriceType: PropTypes.object
}

BookingSummary.displayName = 'BookingSummary'

export default BookingSummary
