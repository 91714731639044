import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useGetCategories } from 'components/api'
import { useLocation } from 'react-router-dom'
import {
  findCategory,
  generateCategoryPath
} from 'modules/bookables/domain/categories'
import Breadcrumb from 'components/layout/Breadcrumb'

const BookableCategoryBreadcrumb = ({
  categorySlugs,
  lastIsLink,
  noHome,
  noLinks
}) => {
  const location = useLocation()

  const { loading, bookableCategories } = useGetCategories()

  const slugsToShow = useMemo(() => {
    if (categorySlugs) {
      return categorySlugs
    } else {
      return location.pathname
        .split('/p/')[0]
        .split('/')
        .filter((slug) => slug)
    }
  }, [categorySlugs, location])

  const links = useMemo(() => {
    return _.compact(
      slugsToShow.map((categorySlug, index) => {
        const category = findCategory(bookableCategories, categorySlug)
        if (!category) {
          return null
        }
        const categoryPath = generateCategoryPath(
          bookableCategories,
          categorySlug
        )
        const showAsLink =
          categoryPath &&
          categoryPath !== location.pathname &&
          (lastIsLink || index < slugsToShow.length - 1)
        return {
          isNotLink: !showAsLink,
          name: category.name,
          to: categoryPath
        }
      })
    )
  }, [slugsToShow, bookableCategories, lastIsLink, location.pathname])

  return (
    !loading && <Breadcrumb links={links} noHome={noHome} noLinks={noLinks} />
  )
}

BookableCategoryBreadcrumb.displayName = 'BookableCategoryBreadcrumb'

BookableCategoryBreadcrumb.propTypes = {
  lastIsLink: PropTypes.bool,
  categorySlugs: PropTypes.arrayOf(PropTypes.string),
  noHome: PropTypes.bool,
  noLinks: PropTypes.bool
}

BookableCategoryBreadcrumb.defaultProps = {
  lastIsLink: false,
  noHome: false,
  noLinks: false
}

export default BookableCategoryBreadcrumb
