import { groupBy } from 'lodash'

const webpageToGroupItem = (webpage) => ({
  to: `/${webpage.slug}`,
  title: webpage.name
})

const webpagesToGroups = (webpages, filter) => {
  const filteredWebpages = webpages.filter(filter)
  const ungroupedWebpages = filteredWebpages
    .filter(({ group }) => !group)
    .reduce((theGroup, ungroupedWebpage, index, { length }) => {
      const items = theGroup?.items || []
      if (length > 0) {
        return {
          items: [...items, webpageToGroupItem(ungroupedWebpage)]
        }
      }
      return false
    }, null)

  const filteredGroupedWebpages = filteredWebpages.filter(({ group }) =>
    Boolean(group)
  )

  const groupedWebpages = groupBy(filteredGroupedWebpages, 'group')

  const webpagesGroups = Object.keys(groupedWebpages).map((key) => ({
    title: key,
    items: groupedWebpages[key].map(webpageToGroupItem)
  }))
  return [...webpagesGroups, ...(ungroupedWebpages ? [ungroupedWebpages] : [])]
}

export default webpagesToGroups
