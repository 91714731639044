import _ from 'lodash'
import { useFetchCache } from 'components/api/ApiCacheContext'

export default (options) => {
  const { data, ...rest } = useFetchCache({
    path: '/bookables/codes',
    entity: 'bookableCodes',
    resolve: (data) => _.sortBy(data.bookables, ['name']),
    ...options
  })
  return {
    bookableCodes: data,
    ...rest
  }
}
