import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import Block from 'components/layout/Block'
import { useGenerateBookablePath } from 'modules/bookables/routes'
import PhotoImg from 'components/misc/PhotoImg'

const BookableItemsBlock = ({ items, title }) => {
  const generateBookablePath = useGenerateBookablePath()
  return (
    <Block title={title} variant="clear" className="BookableItems">
      <div>
        {items.map((bundleItem) => {
          return (
            <div key={bundleItem.id}>
              <Link
                to={generateBookablePath(bundleItem)}
                target="_blank"
                className="BookableItems_item"
              >
                {!_.isEmpty(bundleItem.photos) && (
                  <PhotoImg
                    photo={bundleItem.photos[0]}
                    variant="thumb"
                    alt={bundleItem.name}
                    className="BookableItems_item_mainPhoto"
                  />
                )}
                <p className="BookableItems_item_name">{bundleItem.name}</p>
              </Link>
            </div>
          )
        })}
      </div>
    </Block>
  )
}

BookableItemsBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string
}

BookableItemsBlock.displayName = 'BookableItemsBlock'

export default BookableItemsBlock
