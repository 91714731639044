import React from 'react'
import PropTypes from 'prop-types'
import DateTime from 'components/misc/DateTime'
import { generateBookingsSearchPath } from 'modules/bookables/routes'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import _ from 'lodash'

const BookerCardCellTitle = ({ text }) => <span className="td-th">{text}</span>
BookerCardCellTitle.propTypes = {
  text: PropTypes.string
}

const BookersColumns = (msg, timeZone) => {
  const BookerNameDefaultCell = (cellInfo) => <>{cellInfo.row.original.name}</>
  const BookerNameCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.name}
    </>
  )

  const BookerEmailDefaultCell = (cellInfo) => (
    <>{cellInfo.row.original.email}</>
  )
  const BookerEmailCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.email}
    </>
  )

  const BookerCompanyDefaultCell = (cellInfo) => (
    <>{cellInfo.row.original.company}</>
  )
  const BookerCompanyCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.company}
    </>
  )

  const BookerRegistrationDateDefaultCell = (cellInfo) => (
    <DateTime
      timeZone={timeZone}
      data={cellInfo.row.original.registrationDate}
    />
  )
  const BookerRegistrationDateCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      <DateTime
        timeZone={timeZone}
        data={cellInfo.row.original.registrationDate}
      />
    </>
  )

  const BookerReferrerCodeDefaultCell = (cellInfo) => (
    <>{cellInfo.row.original.referrerCode}</>
  )
  const BookerReferrerCodeCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.referrerCode}
    </>
  )
  const BookerReferrerFeeDefaultCell = (cellInfo) => (
    <>{cellInfo.row.original.referrerFee.toString() + '%'}</>
  )
  const BookerReferrerFeeCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.referrerFee.toString() + '%'}
    </>
  )

  const BookerObservationsDefaultCell = (cellInfo) => (
    <>{cellInfo.row.original.observations}</>
  )
  const BookerObservationsCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.observations}
    </>
  )

  const BookerAmountOfBookingsDefaultCell = (cellInfo) => (
    <Button
      linkTo={generateBookingsSearchPath({
        searchParams: {
          bookerId: cellInfo.row.original.id
        }
      })}
      variant="clear"
      size="s"
      text={cellInfo.row.original.amountOfBookings.toString()}
    />
  )

  const BookerAmountOfBookingsCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      <Button
        className="ViewBookerBookings"
        linkTo={generateBookingsSearchPath({
          searchParams: {
            bookerId: cellInfo.row.original.id
          }
        })}
        variant="clear"
        size="m"
        text={cellInfo.row.original.amountOfBookings.toString()}
      />
    </>
  )

  const getRoles = (assignedRoles) => {
    if (_.isEmpty(assignedRoles)) {
      return '-'
    } else {
      return assignedRoles
        .map((rol) => _.capitalize(rol.split('-')[1]))
        .join(', ')
    }
  }

  const BookerRolDefaultCell = (cellInfo) => (
    <>{getRoles(cellInfo.row.original.assignedRoles)}</>
  )
  const BookerRolCardCell = (cellInfo) => (
    <>
      <BookerCardCellTitle text={cellInfo.column.Header} />
      <>{getRoles(cellInfo.row.original.assignedRoles)}</>
    </>
  )
  return [
    {
      id: 'bookerName',
      Header: msg({ id: 'bookerName' }),
      accessor: 'name',
      Cell: BookerNameDefaultCell,
      CardCell: BookerNameCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerEmail',
      Header: msg({ id: 'bookerEmail' }),
      accessor: 'email',
      Cell: BookerEmailDefaultCell,
      CardCell: BookerEmailCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerRoles',
      Header: msg({ id: 'bookerRoles' }),
      accessor: 'assignedRoles',
      Cell: BookerRolDefaultCell,
      CardCell: BookerRolCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerCompany',
      Header: msg({ id: 'bookerCompany' }),
      accessor: 'company',
      Cell: BookerCompanyDefaultCell,
      CardCell: BookerCompanyCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerRegistrationDate',
      Header: msg({ id: 'bookerRegistrationDate' }),
      accessor: 'registrationDate',
      Cell: BookerRegistrationDateDefaultCell,
      CardCell: BookerRegistrationDateCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerReferrerCode',
      Header: msg({ id: 'bookerReferrerCode' }),
      accessor: 'referrerCode',
      Cell: BookerReferrerCodeDefaultCell,
      CardCell: BookerReferrerCodeCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerReferrerFee',
      Header: msg({ id: 'bookerReferrerFee' }),
      accessor: 'referrerFee',
      Cell: BookerReferrerFeeDefaultCell,
      CardCell: BookerReferrerFeeCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerObservations',
      Header: msg({ id: 'bookerObservations' }),
      accessor: 'observations',
      Cell: BookerObservationsDefaultCell,
      CardCell: BookerObservationsCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    {
      id: 'bookerAmountOfBookings',
      Header: msg({ id: 'bookerAmountOfBookings' }),
      accessor: 'amountOfBookings',
      Cell: BookerAmountOfBookingsDefaultCell,
      CardCell: BookerAmountOfBookingsCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    }
  ]
}

BookersColumns.propTypes = {
  msg: PropTypes.func,
  timeZone: PropTypes.string
}

BookersColumns.displayName = 'BookersColumns'

export default BookersColumns
