import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'
import BookingPaymentFeeInfoModal from './paymentFee/BookingPaymentFeeInfoModal'

const BookingAdminBlock = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'bookingAdminBlock',
    ignoreGlobalScope: false
  })
  return (
    <BookingDetailsSummaryBlock
      className="BookingAdminBlock"
      title={msg({ id: 'title' })}
    >
      <div>
        {_.get(booking.payment, 'paymentFee') && (
          <BookingPaymentFeeInfoModal
            paymentFee={booking.payment.paymentFee}
            currency={booking.payment.currency}
          />
        )}
      </div>
    </BookingDetailsSummaryBlock>
  )
}

BookingAdminBlock.propTypes = {
  booking: PropTypes.shape()
}

BookingAdminBlock.displayName = 'BookingAdminBlock'

export default BookingAdminBlock
