import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Block from 'components/layout/Block'
import { generateProviderPath } from 'modules/bookables/routes'

import ProvidersItem from './ProvidersItem'

const ProvidersList = ({ providers, parentSlugs }) => {
  const { classNames } = useClassNames({
    alias: 'ProvidersList'
  })

  const items = providers.map((provider, index) => {
    const path = generateProviderPath(provider)
    return (
      <Link className={classNames('item')} key={index} to={path}>
        <ProvidersItem provider={provider} />
      </Link>
    )
  })

  return (
    <Block className={classNames()} variant="clear">
      {items}
    </Block>
  )
}

ProvidersList.propTypes = {
  parentSlugs: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.shape())
}

ProvidersList.displayName = 'ProvidersList'

export default ProvidersList
