import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useNewEnquiry = (options) => {
  const { mutate, ...rest } = useMutate({
    path: `/enquiries/new`,
    verb: 'POST',
    ...options
  })

  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.enquiries',
    ignoreGlobalScope: true
  })
  const notifyNewEnquiry = useCallback(
    (notification) => {
      notify({
        id: 'ENQUIRY',
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [msg, notify]
  )
  const newEnquiry = useCallback(
    (params) => {
      notifyNewEnquiry({ status: LOADING })
      return mutate(params)
        .then((result) => {
          notifyNewEnquiry({ status: SUCCESS })
          return result
        })
        .catch((error) => {
          notifyError({
            id: 'ENQUIRY',
            title: msg({ id: 'error' }),
            error
          })
          throw error
        })
    },
    [notifyNewEnquiry, LOADING, mutate, SUCCESS, notifyError, msg]
  )
  return {
    newEnquiry,
    ...rest
  }
}

export default useNewEnquiry
