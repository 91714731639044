import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  ReactTable,
  TableLayout
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useMediaQuery } from 'react-responsive'
import { MobileBreakpoint } from 'components/layout/Mediaqueries'
import { useTenantConfig } from 'modules/bookables/Provider'
import { LoadingResults } from 'components/api'

import bookersColumns from './BookersColumns'
import BookersTableFilter from './BookersTableFilter'

const BookersTable = (props) => {
  const {
    loading,
    bookers,
    pagination,
    filter,
    onFilterChange,
    onPaginationChange
  } = props
  const { msg } = useIntl({
    scope: 'balaena.bookersTable',
    ignoreGlobalScope: true
  })

  const { intl } = useTenantConfig()

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      onPaginationChange({ page: pageIndex + 1, pageSize })
    },
    [onPaginationChange]
  )

  const memoColumns = useMemo(() => {
    return bookersColumns(msg, intl.timeZone)
  }, [msg, intl])

  const isMobile = useMediaQuery(MobileBreakpoint)
  if (loading) {
    return (
      <>
        <BookersTableFilter filter={filter} />
        <LoadingResults />
      </>
    )
  }
  return (
    <>
      <BookersTableFilter filter={filter} onFilterChange={onFilterChange} />
      <ReactTable
        manual
        minRows={0}
        sortable={false}
        data={bookers}
        pagination={pagination}
        columns={memoColumns}
        fetchData={handleFetchData}
        showPageSizeOptions={!isMobile}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        className="BookersTable"
        layout={isMobile ? TableLayout.CARD : TableLayout.DEFAULT}
        rowsText={msg({ id: 'rowsText' })}
        pageText={msg({ id: 'pageText' })}
        noDataText={msg({ id: 'noDataText' })}
        value={null}
      />
    </>
  )
}

BookersTable.propTypes = {
  bookers: PropTypes.array,
  loading: PropTypes.bool,
  filter: PropTypes.shape({
    bookerFilter: PropTypes.string
  }),
  onFilterChange: PropTypes.func,
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number
  }),
  onPaginationChange: PropTypes.func
}

BookersTable.defaultProps = {
  filter: {},
  pagination: {
    pageSize: 10,
    page: 1
  },
  onFilterChange: () => null,
  onPaginationChange: () => null
}

BookersTable.displayName = 'BookersTable'

export default BookersTable
