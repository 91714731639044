import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import { generateCategoryPath } from 'modules/bookables/domain/categories'
import PhotoImg from 'components/misc/PhotoImg'

const DesktopCategoriesNavMenu = ({
  selectedCategory,
  onSelectCategory,
  categories,
  sidebarCategoryText
}) => {
  const { classNames } = useClassNames({
    alias: 'DesktopCategoriesNavMenu'
  })
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'components.nav.DesktopCategoriesNavMenu'
  })

  const categoryText = useMemo(() => {
    return _.isUndefined(sidebarCategoryText)
      ? msg({ id: 'title' })
      : sidebarCategoryText
  }, [msg, sidebarCategoryText])

  return (
    <div className={classNames()}>
      <p className={classNames('title')}>{categoryText}</p>
      <ul onMouseLeave={() => onSelectCategory(null)}>
        {categories.map((category) => {
          return (
            <li
              key={category.id}
              className={classNames('item', {
                selected: selectedCategory?.id === category.id
              })}
              onMouseEnter={() => onSelectCategory(category)}
            >
              <Link to={generateCategoryPath(categories, category.id)}>
                <Visible
                  condition={!_.isEmpty(category.photos)}
                  ifTrue={
                    <PhotoImg photo={category.photos[0]} variant="thumb" />
                  }
                  otherwise={<img src={NoPhoto} alt="" />}
                />
                {category.name}
                <i className="fas fa-chevron-right" />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

DesktopCategoriesNavMenu.propTypes = {
  selectedCategory: PropTypes.object,
  onSelectCategory: PropTypes.func,
  categories: PropTypes.array,
  sidebarCategoryText: PropTypes.string
}

DesktopCategoriesNavMenu.displayName = 'DesktopCategoriesNavMenu'

export default DesktopCategoriesNavMenu
