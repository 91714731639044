import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import { useLocation } from '@trileuco/triskel-react-ui/components/router'
import createLayoutPortal from 'components/hooks/createLayoutPortal'
import Overlay from 'components/layout/Overlay'

import StatelessSidebar from './StatelessSidebar'

const DefaultTrigger = ({ onClick, className, visible }, ref) => {
  return (
    <div ref={ref}>
      <IconButton
        className={className}
        icon={visible ? 'fas fa-times' : 'fas fa-bars'}
        size="l"
        variant="clear"
        onClick={onClick}
      />
    </div>
  )
}

DefaultTrigger.displayName = 'DefaultTrigger'

DefaultTrigger.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  visible: PropTypes.bool
}

const ForwardedDefaultTrigger = forwardRef(DefaultTrigger)

const Sidebar = ({
  Trigger,
  initialVisible,
  onOpen,
  onClose,
  closeWhenLocationChanges,
  closeOnSidebarClick,
  closeOnOverlayClick,
  ...moreProps
}) => {
  const [visible, setVisible] = useState(initialVisible)

  const open = useCallback(
    (event) => {
      if (!visible) {
        setVisible(true)
        onOpen(event)
      }
    },
    [visible, onOpen]
  )

  const close = useCallback(
    (event) => {
      if (visible) {
        setVisible(false)
        onClose(event)
      }
    },
    [visible, onClose]
  )

  const handleSidebarClick = useCallback(
    (event) => {
      if (closeOnSidebarClick) {
        close(event)
      }
    },
    [closeOnSidebarClick, close]
  )

  const handleTriggerClick = useCallback(
    (event) => {
      if (visible) {
        close(event)
      } else {
        open(event)
      }
    },
    [visible, close, open]
  )

  const handleOverlayClick = useCallback(
    (event) => {
      if (closeOnOverlayClick) {
        close(event)
      }
    },
    [closeOnOverlayClick, close]
  )

  // useOnLocationChange

  const location = useLocation()

  const handleOnLocationChangeRef = useRef(() => null)

  const handleOnLocationChange = useCallback(
    (location) => {
      if (closeWhenLocationChanges) {
        close(location)
      }
    },
    [closeWhenLocationChanges, close]
  )

  useEffect(() => {
    handleOnLocationChangeRef.current(location)
  }, [location])

  useEffect(() => {
    handleOnLocationChangeRef.current = handleOnLocationChange
  }, [handleOnLocationChange])

  // useClickOutside([nodes], trigger)

  const triggerRef = useRef()
  const overlayRef = useRef()
  const sidebarRef = useRef()

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !event.defaultPrevented &&
        !triggerRef.current?.contains(event.target) &&
        !overlayRef.current?.contains(event.target) &&
        !sidebarRef.current?.contains(event.target)
      ) {
        close(event)
      }
    },
    [close]
  )

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [visible, handleClickOutside])

  // useScrollLock(visible)

  useEffect(() => {
    if (visible) {
      document.documentElement.style.overflow = 'hidden'
      return () => {
        document.documentElement.style.overflow = null
      }
    }
  }, [visible])

  return (
    <>
      <Trigger
        ref={triggerRef}
        visible={visible}
        onClick={handleTriggerClick}
        {...moreProps}
      />
      {createLayoutPortal(
        <>
          <Overlay
            ref={overlayRef}
            visible={visible}
            onClick={handleOverlayClick}
          />
          <StatelessSidebar
            ref={sidebarRef}
            visible={visible}
            onClick={handleSidebarClick}
            {...moreProps}
          />
        </>
      )}
    </>
  )
}

Sidebar.displayName = 'Sidebar'

Sidebar.propTypes = {
  initialVisible: PropTypes.bool,
  closeOnSidebarClick: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  closeWhenLocationChanges: PropTypes.bool,
  Trigger: PropTypes.elementType,
  ...StatelessSidebar.propTypes
}

Sidebar.defaultProps = {
  placement: 'right',
  initialVisible: false,
  onOpen: () => null,
  onClose: () => null,
  closeOnSidebarClick: false,
  closeOnOverlayClick: true,
  closeWhenLocationChanges: true,
  Trigger: ForwardedDefaultTrigger,
  ...StatelessSidebar.defaultProps
}

export default Sidebar
