import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/FullPageTemplate/FullPageTemplate'
import HeaderSection from 'components/cms/HeaderSection'
import { useTenantConfig } from 'modules/bookables/Provider'
import ContactFormSection from 'components/cms/ContactFormSection'
import ContactForm from 'components/misc/ContactForm'
import useClassNames from '@trileuco/triskel-react-ui/components/hooks/useClassNames'
import useGetWebpage from 'components/api/webpages/useGetWebpage'
import _ from 'lodash'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import Breadcrumb from 'components/layout/Breadcrumb'
import SafeHtml from 'components/cms/SafeHtml'
import MetaTags from 'components/layout/MetaTags'
import { LoadingResults } from 'components/api'
import { NotFoundError } from 'components/error'

const ContactPage = () => {
  const isDesktop = useMediaQuery(DesktopBreakpoint)
  const { contact } = useTenantConfig()
  const { classNames } = useClassNames({
    alias: 'ContactPage'
  })
  const { msg } = useIntl({
    scope: 'balaena.contactPage',
    ignoreGlobalScope: true
  })
  const { webpage, loading } = useGetWebpage({ id: 'contact' })
  if (loading) {
    return <LoadingResults />
  }
  if (!webpage) {
    throw new NotFoundError('Webpage not found for slug contact')
  }
  return (
    <Page id="ContactPage">
      {webpage && <MetaTags metaTags={webpage.metaTags} />}
      <PublicPageSkeleton
        renderSearch={!isDesktop}
        headerAssets={
          <HeaderSection
            {...webpage.content.header}
            breadcrumb={
              <Breadcrumb links={[{ name: 'Contacto', isNotLink: true }]} />
            }
            className={classNames('Header')}
            size="s"
            photos={webpage.photos}
          />
        }
        mainContent={
          <BoundedPageTemplate
            mainContent={
              <>
                <ContactFormSection
                  form={<ContactForm />}
                  textContent={
                    <>
                      {_.get(webpage, 'content.text') && (
                        <SafeHtml html={webpage.content.text} />
                      )}
                      {contact && contact.address && (
                        <dl>
                          <dt>{msg({ id: 'address' })}</dt>
                          <dd>{contact.address}</dd>
                        </dl>
                      )}
                      {contact && contact.phone && (
                        <dl>
                          <dt>{msg({ id: 'phone' })}</dt>
                          <dd>
                            <a className="Link" href={`tel:${contact.phone}`}>
                              <i className="fas fa-phone-square-alt" />{' '}
                              {contact.phone}
                            </a>
                          </dd>
                        </dl>
                      )}
                      {contact && contact.email && (
                        <dl>
                          <dt>{msg({ id: 'email' })}</dt>
                          <dd>
                            <a
                              className="Link"
                              href={`mailto:${contact.email}`}
                            >
                              <i className="fas fa-envelope-square" />{' '}
                              {contact.email}
                            </a>
                          </dd>
                        </dl>
                      )}
                    </>
                  }
                />
              </>
            }
          />
        }
      />
    </Page>
  )
}

ContactPage.propTypes = {}

ContactPage.displayName = 'ContactPage'

export default ContactPage
