import { findCategory } from 'modules/bookables/domain/categories'

import useGetCategories from './useGetCategories'

export default ({ categoryId, ...options }) => {
  const {
    loading,
    error,
    refetch,
    reloading,
    bookableCategories
  } = useGetCategories()

  return {
    loading,
    bookableCategory: findCategory(bookableCategories, categoryId),
    error,
    refetch,
    reloading
  }
}
