import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import _ from 'lodash'
import Price from 'components/misc/Price'
import DateTime from 'components/misc/DateTime'
import BookableBasePrice from 'components/bookable/BookablePrice/BookableBasePrice'
import BookableTotalPrice from 'components/bookable/BookablePrice/BookableTotalPrice'
import BookableDiscountPrice from 'components/bookable/BookablePrice/BookableDiscountPrice'

const BookingBookablePrice = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsSummary',
    ignoreGlobalScope: true
  })
  const { payment = {}, bookable } = booking

  const {
    discount = {},
    taxesDetails,
    currency,
    baseAmount,
    discountedAmount,
    reservation
  } = payment
  const hasDiscount = !_.isEmpty(discount)

  const discountedPrice = useMemo(() => {
    if (discountedAmount > 0) {
      return Math.max(0, baseAmount - discountedAmount) === 0
        ? baseAmount
        : baseAmount - discountedAmount
    }
    return false
  }, [baseAmount, discountedAmount])
  return (
    <div className="BookablePrice">
      {taxesDetails && (
        <BookableBasePrice
          label={msg({ id: 'bookablePrice.base' })}
          currency={currency}
          taxes={taxesDetails.taxes}
          basePrice={taxesDetails.totalAmount}
        />
      )}

      {hasDiscount && (
        <BookableDiscountPrice
          currency={currency}
          discountName={payment.discount.name}
          discountAmount={discountedAmount}
        />
      )}

      {baseAmount && (
        <BookableTotalPrice
          label={msg({ id: 'bookablePrice.total' })}
          currency={currency}
          totalPrice={baseAmount}
          hasDiscount={hasDiscount}
          discountedPrice={discountedPrice}
        />
      )}

      {reservation && (
        <div className="BookablePrice_reservation">
          <p>
            {msg(
              { id: 'existsReservation' },
              {
                amount: (
                  <Price amount={reservation.amount} currency={currency} />
                )
              }
            )}
          </p>
          <p>
            <span className="label">{msg({ id: 'reservationDate' })}</span>
            <span>
              {
                <DateTime
                  data={reservation.createdAt}
                  timeZone={bookable.timeZone}
                />
              }
            </span>
          </p>
          <p>
            <span className="label">{msg({ id: 'deadline' })}</span>
            <span>
              {
                <DateTime
                  data={reservation.deadline}
                  timeZone={bookable.timeZone}
                />
              }
            </span>
          </p>
        </div>
      )}
    </div>
  )
}

BookingBookablePrice.propTypes = {
  booking: PropTypes.shape()
}

BookingBookablePrice.displayName = 'BookingBookablePrice'

export default BookingBookablePrice
