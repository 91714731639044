import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Date from 'components/misc/Date'
import Block from 'components/layout/Block'
import CountryLabel from 'components/misc/CountryLabel'
import LanguageLabel from 'components/misc/LanguageLabel'
import ExtrasSummary from 'components/booker/BookingForm//ExtrasSummary'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'

const GuestDataListItem = ({ label, data }) => (
  <li className="GuestDataListItem">
    <span className="GuestDataListItem_label">{label}</span>
    <span className="GuestDataListItem_data">{data || '-'}</span>
  </li>
)

GuestDataListItem.propTypes = {
  label: PropTypes.string,
  data: PropTypes.node
}

GuestDataListItem.displayName = 'GuestDataListItem'

/** FIXME: Use related form values for country and region */
const getGuestCountry = (guest) =>
  _.get(guest, 'countryAndRegion.country') || _.get(guest, 'country', '')
const getGuestRegion = (guest) =>
  _.get(guest, 'countryAndRegion.region') || _.get(guest, 'region', '')

const BookingGuestSummaryBlock = ({
  guest,
  guestIndex,
  variant,
  showEditButton,
  handleChangeGuest
}) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  return (
    <Block
      className="GuestSummary"
      key={guestIndex}
      variant={variant}
      title={
        <div className="BookingGuestSummaryBlockHeader">
          {msg(
            {
              id: 'guestBlockTitle'
            },
            {
              guestLabel: guest.type.labels.singular.toLowerCase(),
              guestCounter: guestIndex + 1
            }
          )}
          {showEditButton && (
            <IconButton
              size="l"
              variant="clear"
              icon={'fas fa-edit'}
              text={'Completar Informacion'}
              onClick={() => {
                handleChangeGuest(0, guest)
              }}
            />
          )}
        </div>
      }
    >
      <ul className="GuestDataList">
        <GuestDataListItem
          label={msg({ id: 'firstName' })}
          data={guest.firstName}
        />
        <GuestDataListItem
          label={msg({ id: 'lastName' })}
          data={guest.lastName}
        />
        <GuestDataListItem label={msg({ id: 'email' })} data={guest.email} />
        <GuestDataListItem label={msg({ id: 'phone' })} data={guest.phone} />
        <GuestDataListItem
          label={msg({ id: 'personalId' })}
          data={guest.personalId}
        />
        <GuestDataListItem
          label={msg({ id: 'birthdate' })}
          data={<Date data={guest.birthdate} />}
        />
        <GuestDataListItem
          label={msg({ id: 'gender' })}
          data={guest.gender && msg({ id: 'gender.' + guest.gender })}
        />
        <GuestDataListItem
          label={msg({ id: 'country' })}
          data={<CountryLabel countryCode={getGuestCountry(guest)} />}
        />
        <GuestDataListItem
          label={msg({ id: 'region' })}
          data={getGuestRegion(guest)}
        />
        <GuestDataListItem
          label={msg({ id: 'address' })}
          data={guest.address}
        />
        <GuestDataListItem
          label={msg({ id: 'language' })}
          data={<LanguageLabel languageCode={guest.language} />}
        />
        {!_.isEmpty(guest.extraOptions) && (
          <GuestDataListItem
            label={msg({ id: 'extras' })}
            data={<ExtrasSummary extras={guest.extraOptions} />}
          />
        )}
      </ul>
    </Block>
  )
}

BookingGuestSummaryBlock.propTypes = {
  guest: PropTypes.object,
  guestIndex: PropTypes.number,
  variant: PropTypes.string,
  showEditButton: PropTypes.bool,
  handleChangeGuest: PropTypes.func
}

BookingGuestSummaryBlock.displayName = 'BookingGuestSummaryBlock'

export default BookingGuestSummaryBlock
