import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useTenantConfig } from 'modules/bookables/Provider'
import NavMenuGroup from 'components/layout/nav/NavMenuGroup'
import webpagesToGroups from 'components/misc/WebpageGroups'
import { withAuth } from '@trileuco/triskel-react-ui/modules/auth'

const WebpagesNavMenu = ({ authContext }) => {
  const { classNames } = useClassNames({
    alias: 'WebpagesNavMenu'
  })

  const { webpages } = useTenantConfig()

  const webpagesNavMenuGroups = useMemo(() => {
    const filter = ({ visibility }) =>
      visibility &&
      visibility.includes('nav') &&
      (!visibility.includes('only_providers') ||
        authContext.hasSomeRole(['balaena-admin', 'balaena-provider']))
    return webpagesToGroups(webpages, filter)
  }, [webpages, authContext])
  return (
    <div className={classNames()}>
      {webpagesNavMenuGroups.map(({ title, items, separator }, index) => {
        if (separator) {
          return <hr key={index} className={classNames('separator')} />
        }
        return (
          <NavMenuGroup
            key={index}
            className={classNames('groupColumn')}
            title={title}
          >
            {items.map(({ to, title }, index) => (
              <Link key={index} to={to}>
                {title}
              </Link>
            ))}
          </NavMenuGroup>
        )
      })}
    </div>
  )
}

WebpagesNavMenu.propTypes = {
  authContext: PropTypes.object
}

WebpagesNavMenu.displayName = 'WebpagesNavMenu'

export default withAuth(WebpagesNavMenu)
