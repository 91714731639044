import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import BookableCard from 'components/bookable/BookableCard'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const BookablesList = ({ bookables, spaceFillers, onBookableClick }) => {
  const moreThanOneCategory = useMemo(
    () => _.uniq(bookables.map((bookable) => bookable.category.id)).length > 1,
    [bookables]
  )

  const { classNames } = useClassNames({ alias: 'BookablesCategory' })

  return (
    <div className={classNames('list')}>
      {bookables &&
        bookables.map((bookable) => (
          <BookableCard
            key={bookable.id}
            onBookableClick={onBookableClick}
            bookable={bookable}
            withBreadcrumb={moreThanOneCategory}
          />
        ))}
      {/* Needed to align bookables correctly */}
      {Array.apply(null, {
        length: spaceFillers
      }).map((spaceFiller, spaceFillerID) => (
        <i
          aria-hidden="true"
          key={spaceFillerID}
          className="BookableCardSpaceFiller"
        />
      ))}
    </div>
  )
}

BookablesList.propTypes = {
  bookables: PropTypes.arrayOf(PropTypes.object),
  spaceFillers: PropTypes.number,
  onBookableClick: PropTypes.func
}

BookablesList.defaultProps = {
  spaceFillers: 12,
  onBookableClick: null
}

BookablesList.displayName = 'BookablesList'

export default BookablesList
