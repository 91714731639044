import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { contactPath } from 'modules/bookables/routes'

import FooterGroup from './FooterGroup'

const FooterContactGroup = ({ className, title, msg, contact }) => {
  return (
    <FooterGroup className={className} title={title}>
      <ul>
        <li>
          <Link to={contactPath}>{msg({ id: 'contactColumn.link' })}</Link>
        </li>
        {contact.email && (
          <li>
            <a className="Link" href={`mailto:${contact.email}`}>
              <i className="fas fa-envelope-square" /> {contact.email}
            </a>
          </li>
        )}
        {contact.phone && (
          <li>
            <a className="Link" href={`tel:${contact.phone}`}>
              <i className="fas fa-phone-square-alt" />
              {contact.phone}
            </a>
          </li>
        )}
      </ul>
    </FooterGroup>
  )
}

FooterContactGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  msg: PropTypes.func,
  contact: PropTypes.object
}

FooterContactGroup.displayName = 'FooterContactGroup'

export default FooterContactGroup
