import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import SafeHtml from 'components/cms/SafeHtml'

import { ProviderLocation } from './ProviderLocation'

const ProviderInfo = ({ provider }) => {
  const { descriptions } = provider
  const { classNames } = useClassNames({
    alias: 'ProviderInfo'
  })

  return (
    <BoundedPageTemplate
      mainContent={
        <>
          {descriptions && descriptions.long && (
            <div className={classNames('description')}>
              <SafeHtml html={descriptions.long} />
            </div>
          )}
        </>
      }
      sidebar={
        provider.location && <ProviderLocation location={provider.location} />
      }
    />
  )
}

ProviderInfo.propTypes = {
  provider: PropTypes.shape()
}

ProviderInfo.displayName = 'ProvidersChildren'

export default ProviderInfo
