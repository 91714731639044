import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import LanguageSelectField from 'components/misc/LanguageSelectField'

import FooterContactGroup from './FooterContactGroup'
import FooterSocialColumn from './FooterSocialColumn'
import FooterLogosSection from './FooterLogos'
import FooterGroup from './FooterGroup'

const Footer = ({
  advertisment,
  renderTopFooter,
  logosSection,
  locales,
  groups,
  social,
  contact,
  logo,
  copyright
}) => {
  const { classNames } = useClassNames({
    alias: 'Footer'
  })
  const { msg } = useIntl({
    scope: 'balaena.footer',
    ignoreGlobalScope: true
  })

  const hasGroups = groups && groups.length > 0
  const hasLocales = locales && locales.length > 1

  return (
    <footer className={classNames()}>
      {renderTopFooter && (
        <div className={classNames('main')}>
          <div>
            <div className={classNames('topColumns')}>
              {logo && (
                <FooterGroup className={classNames('logoColumn')}>
                  <Link to={logo.to}>
                    <img
                      src={logo.url}
                      alt={logo.alt}
                      className={classNames('logo')}
                    />
                  </Link>
                </FooterGroup>
              )}
              {hasGroups &&
                groups.map(({ title, items, separator }, index) => {
                  if (separator) {
                    return (
                      <hr key={index} className={classNames('separator')} />
                    )
                  }
                  return (
                    <FooterGroup
                      key={index}
                      className={classNames('groupColumn')}
                      title={title}
                    >
                      {items.map(({ to, title }, index) => (
                        <p key={index}>
                          <Link to={to}>{title}</Link>
                        </p>
                      ))}
                    </FooterGroup>
                  )
                })}
              {contact && (
                <FooterContactGroup
                  msg={msg}
                  className={classNames('contactColumn')}
                  title={msg({ id: 'contactColumn.title' })}
                  contact={contact}
                />
              )}
              {social && (
                <FooterSocialColumn
                  social={social}
                  className={classNames('socialColumn')}
                  title={msg({ id: 'socialColumn.title' })}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {advertisment}
      {logosSection && !Array.isArray(logosSection) && (
        <FooterLogosSection
          title={logosSection.title}
          logos={logosSection.logos}
        />
      )}
      {logosSection &&
        Array.isArray(logosSection) &&
        logosSection.map((section) => (
          <FooterLogosSection title={section.title} logos={section.logos} />
        ))}

      <div className={classNames('secondary')}>
        <div>
          <p className={classNames('copyright')}>{copyright}</p>
          {hasLocales && (
            <LanguageSelectField
              languageCodes={locales}
              menuPlacement={'top'}
            />
          )}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  advertisment: PropTypes.node,
  renderTopFooter: PropTypes.bool,
  locales: PropTypes.array,
  social: PropTypes.array,
  contact: PropTypes.object,
  logo: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
    to: PropTypes.string
  }),
  logosSection: PropTypes.oneOf(
    PropTypes.array,
    PropTypes.shape({
      title: PropTypes.node,
      logos: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
          href: PropTypes.string
        })
      )
    })
  ),
  groups: PropTypes.array,
  copyright: PropTypes.node
}

Footer.defaultProps = {
  renderTopFooter: true
}

Footer.displayName = 'Footer'

export default Footer
