import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { generateBookingGuestsPath } from 'modules/bookables/routes'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

const GuestsSummaryField = ({ booking, maxVisibleGuests, ...rest }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsSummary',
    ignoreGlobalScope: true
  })
  const { guests } = booking
  const visibleGuestNames = guests
    .filter((guest) => guest.firstName !== undefined)
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
  return (
    <div {...rest}>
      <ul className="BookingGuestsList">
        {visibleGuestNames.map((guest, index) => (
          <li key={index}>{guest}</li>
        ))}
      </ul>
      <Link to={generateBookingGuestsPath({ id: booking.id })}>
        {msg({ id: 'viewGuestDetails' })}
      </Link>
    </div>
  )
}

GuestsSummaryField.displayName = 'GuestsSummaryField'

GuestsSummaryField.propTypes = {
  maxVisibleGuests: PropTypes.number,
  booking: PropTypes.shape()
}

GuestsSummaryField.defaultProps = {
  maxVisibleGuests: 1
}

const BookingGuestsBlock = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'bookingGuestsBlock'
  })
  return (
    <BookingDetailsSummaryBlock
      className="BookingGuestsBlock"
      title={msg({ id: 'title' })}
    >
      <GuestsSummaryField maxVisibleGuests={8} booking={booking} />
    </BookingDetailsSummaryBlock>
  )
}

BookingGuestsBlock.propTypes = {
  booking: PropTypes.shape()
}

BookingGuestsBlock.displayName = 'BookingGuestsBlock'

export default BookingGuestsBlock
