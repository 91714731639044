import PropTypes from 'prop-types'

export const textOrNodePropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.object,
  PropTypes.shape({
    text: PropTypes.string,
    other: PropTypes.object
  })
])
