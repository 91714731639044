import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  Button,
  IconButton,
  PopOver
} from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { generateBookingsSearchPath } from 'modules/bookables/routes'

import ProviderBookingFormModal from './ProviderBookingFormModal'

const ProviderBookingPopOverActions = ({ bookable, availability }) => {
  const {
    dateTime,
    capacity: { total, remaining }
  } = availability
  const totalBookings = total - remaining
  const { classNames } = useClassNames({
    alias: 'ProviderBookingActions'
  })
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.providerBookingPopOverActions',
    ignoreGlobalScope: true
  })

  const [pickedPriceType, setPickedPriceType] = useState(null)

  const {
    open: openReservationModal,
    close: closeReservationModal,
    isOpen: isOpenReservationModal
  } = useModal(false)

  return (
    <>
      <PopOver
        alias="ProviderBookingActions"
        content={
          <ul className={classNames()}>
            {totalBookings > 0 && (
              <li className={classNames('action')}>
                <Button
                  linkTo={generateBookingsSearchPath({
                    searchParams: {
                      bookableId: bookable.id,
                      from: moment(dateTime).format(),
                      to: moment(dateTime).format()
                    }
                  })}
                  text={msg({ id: 'showBookings' })}
                  variant="clear"
                />
              </li>
            )}
            {remaining > 0 && (
              <li className={classNames('action')}>
                <Button
                  onClick={openReservationModal}
                  variant="clear"
                  text={msg({ id: 'addBookings' })}
                />
              </li>
            )}
          </ul>
        }
        triggerEvent="click"
        placement="left"
        flipVariations="boolean"
      >
        <IconButton icon="fas fa-ellipsis-v" variant="clear" size="s" />
      </PopOver>

      <ProviderBookingFormModal
        bookableId={bookable.id}
        pickedPriceType={pickedPriceType}
        setPickedPriceType={setPickedPriceType}
        isOpen={isOpenReservationModal}
        onClose={closeReservationModal}
      />
    </>
  )
}

ProviderBookingPopOverActions.propTypes = {
  bookable: PropTypes.object,
  availability: PropTypes.object,
  capacity: PropTypes.shape({
    total: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired
  }),
  bookableColor: PropTypes.string
}

ProviderBookingPopOverActions.displayName = 'ProviderBookingPopOverActions'

export default ProviderBookingPopOverActions
