import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Price from 'components/misc/Price'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import ProviderBookingPopOverActions from './ProviderBookingPopOverActions'

const DayHeader = ({ column: { id }, from, to }) => {
  return moment(from, 'DD-MM-YYYY')
    .add('days', parseInt(id, 10) - 1)
    .format('ddd DD/MM')
}
DayHeader.displayName = 'DayHeader'

const BookableCell = ({ row }) => {
  if (!row.original) {
    return null
  }
  return (
    <div {...row.getToggleRowExpandedProps({})} className="BookableCell">
      {!row.original.availableTime && <span>{row.original.bookable.name}</span>}
      <span>{row.original.availableTime}</span>
      {row.canExpand && (
        <i className={`fas fa-caret-${row.isExpanded ? 'up' : 'down'}`} />
      )}
    </div>
  )
}

BookableCell.propTypes = {
  row: PropTypes.object
}

BookableCell.displayName = 'BookableCell'

const AvailabilityCell = ({ row, column }) => {
  const { classNames } = useClassNames({ alias: 'Availability' })
  if (!row.original) {
    return null
  }
  const availableDateTimes = row.original.daysOfWeek[column.id]
  if (availableDateTimes && availableDateTimes.length) {
    const totalCapacity = availableDateTimes.reduce((sum, { capacity }) => {
      return sum + capacity.total
    }, 0)
    const totalRemaining = availableDateTimes.reduce((sum, { capacity }) => {
      return sum + capacity.remaining
    }, 0)

    let availability
    if (totalRemaining === 0) {
      availability = 'none'
    } else if (totalCapacity - totalRemaining / totalCapacity >= 0.5) {
      availability = 'high'
    } else {
      availability = 'low'
    }
    return (
      <div className={classNames({ availability })}>
        <div>
          <span className={classNames('capacity')}>
            {totalCapacity - totalRemaining} / {totalCapacity}
          </span>
          {availableDateTimes.length === 1 && (
            <span className={classNames('price')}>
              <Price
                amount={availableDateTimes[0].priceAmount}
                currency={row.original.bookable.price.currency}
              />{' '}
              {row.original.bookable.price.description}
            </span>
          )}
        </div>
        {availableDateTimes.length === 1 && (
          <ProviderBookingPopOverActions
            bookable={row.original.bookable}
            availability={availableDateTimes[0]}
          />
        )}
      </div>
    )
  }
  return null
}

AvailabilityCell.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object
}

AvailabilityCell.displayName = 'AvailabilityCell'

const providerBookingColumns = ({
  msg,
  providerId,
  onChangeProvider,
  from,
  to
}) => {
  const DayHeaderCell = (props) => <DayHeader {...props} from={from} to={to} />
  return [
    {
      id: 'bookable',
      Header: () => msg({ id: 'bookableCell' }),
      Cell: BookableCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    },
    ...Array.from(Array(7).keys()).map((day, index) => ({
      id: day + 1,
      Header: DayHeaderCell,
      Cell: AvailabilityCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' }
    }))
  ]
}

providerBookingColumns.displayName = 'providerBookingColumns'

export default providerBookingColumns
