import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Link } from '@trileuco/triskel-react-ui/components/ui'
import {
  useGenerateBookablePath,
  generateBookingsCalendarPath
} from 'modules/bookables/routes'
import { withAuth } from '@trileuco/triskel-react-ui/modules/auth'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'

const BookableCell = ({ bookable, authContext }) => {
  const generateBookablePath = useGenerateBookablePath()
  return (
    <>
      {bookable && (
        <div className="BookableCell">
          <Link to={generateBookablePath(bookable)}>{bookable.name}</Link>
          <Visible
            condition={
              authContext.hasSomeRole &&
              authContext.hasSomeRole(['balaena-admin', 'balaena-provider'])
            }
          >
            <IconButton
              icon="far fa-calendar-alt"
              linkTo={generateBookingsCalendarPath({
                searchParams: { bookableIds: [bookable.slug] }
              })}
              size="s"
              className="viewBookingsCalendar"
            />
          </Visible>
        </div>
      )}
    </>
  )
}

BookableCell.propTypes = {
  bookable: PropTypes.object,
  authContext: PropTypes.object
}

BookableCell.displayName = 'BookableCell'

export default withAuth(BookableCell)
