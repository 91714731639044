import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from '@trileuco/triskel-react-ui/components/router'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { generateBookableBookingPath } from 'modules/bookables/routes'
import { useGetBookable } from 'components/api'
import ToBookPickerModal from 'modules/bookables/pages/bookableDetails/ToBookPickerModal'

const ProviderBookingFormModal = ({
  isOpen,
  onClose,
  bookableId,
  pickedPriceType,
  setPickedPriceType
}) => {
  const history = useHistory()

  const { classNames } = useClassNames({
    alias: 'ProviderBookingFormModal'
  })

  const { bookable, loading } = useGetBookable({
    bookableId,
    lazy: !isOpen
  })

  const handleSubmitBook = ({ dateRange, dateTime, bookable, guests }) => {
    history.push(
      generateBookableBookingPath({
        id: bookable && bookable.slug,
        dateRange,
        dateTime,
        guests,
        pickedPriceType
      })
    )
  }

  if (loading || !bookable) {
    return null
  }

  return (
    <ToBookPickerModal
      externalClassName={classNames()}
      externalOpen={isOpen}
      externalClose={onClose}
      bookable={bookable}
      onSubmit={handleSubmitBook}
      providerBook={true}
      pickedPriceType={pickedPriceType}
      setPickedPriceType={setPickedPriceType}
      priceTypes={bookable.priceTypes}
    />
  )
}

ProviderBookingFormModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  bookableId: PropTypes.number,
  pickedPriceType: PropTypes.object,
  setPickedPriceType: PropTypes.func
}

ProviderBookingFormModal.displayName = 'ProviderBookingFormModal'

export default ProviderBookingFormModal
