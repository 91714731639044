import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'

const NavMenuGroupTitle = ({ title }) => {
  if (title.to) {
    return <Link to={title.to}>{title.title}</Link>
  }
  return title
}

NavMenuGroupTitle.displayName = 'NavMenuGroupTitle'

const NavMenuGroup = ({ title, children, className }) => {
  const { classNames } = useClassNames({
    alias: 'NavMenuGroup'
  })
  return (
    <div className={`${classNames()} ${className}`.trim()}>
      {title && (
        <p className={classNames('title')}>
          <NavMenuGroupTitle title={title} />
        </p>
      )}
      <div className={classNames('children')}>{children}</div>
    </div>
  )
}

NavMenuGroup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string
}

NavMenuGroup.defaultProps = {
  className: ' '
}

NavMenuGroup.displayName = 'NavMenuGroup'

export default NavMenuGroup
