import { useGet } from 'components/api'

export default ({ bookingId, ...options }) => {
  const { data, ...rest } = useGet({
    path: `/bookings/${bookingId}`,
    ...options
  })
  return {
    ...data,
    ...rest
  }
}
