import React from 'react'
import PropTypes from 'prop-types'
import {
  FieldSet,
  TextField,
  CheckboxField
} from '@trileuco/triskel-react-ui/components/ui'
import Price from 'components/misc/Price'

const BookingExtrasFieldSet = ({ extras, fieldset, label = '', currency }) => {
  return (
    <FieldSet label={label} fieldset={fieldset} direction="column">
      {extras.map(({ name, id, price }, index) => {
        return (
          <FieldSet
            fieldset={`${index}`}
            key={id}
            direction="column"
            variant="clear"
            className="extrasByBooking"
          >
            <TextField
              disabled
              value={id}
              field="extraId"
              className="Field___hidden"
            />
            <CheckboxField
              variant="outline"
              label={
                <>
                  {`${name} - `}
                  <Price amount={price} currency={currency} />
                </>
              }
              field="answer"
            />
          </FieldSet>
        )
      })}
    </FieldSet>
  )
}

BookingExtrasFieldSet.propTypes = {
  fieldset: PropTypes.string,
  extras: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.string,
  currency: PropTypes.string
}

BookingExtrasFieldSet.displayName = 'BookingExtrasFieldSet'

export default BookingExtrasFieldSet
