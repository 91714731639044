import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useGetBooking, LoadingResults } from 'components/api'
import NotFoundError from 'components/error/NotFoundError'

export const BookingDetailsContext = createContext({})

export const useBookingDetails = () => useContext(BookingDetailsContext)

export const BookingDetailsProvider = ({ bookingId, children }) => {
  const { booking, refetch, loading } = useGetBooking({ bookingId })
  if (loading) {
    return <LoadingResults />
  }
  if (!booking) {
    throw new NotFoundError('Booking not found for id ' + bookingId)
  }

  return (
    <BookingDetailsContext.Provider value={{ booking, refetch, loading }}>
      {children}
    </BookingDetailsContext.Provider>
  )
}

BookingDetailsProvider.propTypes = {
  bookingId: PropTypes.string,
  children: PropTypes.node
}

BookingDetailsProvider.displayName = 'BookingDetailsProvider'

export const BookingDetailsConsumer = BookingDetailsContext.Consumer
