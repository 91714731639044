import { useGet } from 'components/api'

export default ({
  bookingId,
  formId,
  buttonText,
  reservationPayment = false
}) => {
  const { data, ...rest } = useGet({
    path: `/bookings/${bookingId}/payment/new-form`,
    queryParams: {
      formId,
      buttonText,
      reservationPayment
    }
  })
  return {
    ...data,
    ...rest
  }
}
