import { useCallback } from 'react'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'

import useCookie from './useCookie'

const useParseReferrer = () => {
  // XXX: Fallback for legacy referer paramName
  const { referrer, referer } = useQueryParams({
    referrer: QueryParamTypes.String,
    referer: QueryParamTypes.String
  })

  const { item, setValue } = useCookie('referrer')

  const setReferrer = useCallback(() => {
    setValue(referrer || referer, { expires: 1 })
  }, [setValue, referrer, referer])

  return { referrer: item, setReferrer }
}

export default useParseReferrer
