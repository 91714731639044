import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useDeleteComment = ({ bookingId, ...options }) => {
  const { mutate, ...rest } = useMutate({
    path: `/bookings/${bookingId}/comments`,
    verb: 'DELETE',
    ...options
  })

  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useDeleteComment',
    ignoreGlobalScope: true
  })

  const notifyDeleteComment = useCallback(
    (notification) => {
      notify({
        id: 'COMMENT',
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [msg, notify]
  )

  const deleteComment = useCallback(
    (commentId) => {
      notifyDeleteComment({ status: LOADING })
      return mutate(commentId)
        .then((result) => {
          notifyDeleteComment({ status: SUCCESS })
          return result
        })
        .catch((error) => {
          notifyError({
            id: 'COMMENT',
            title: msg({ id: 'error' }),
            error
          })
          throw error
        })
    },
    [notifyDeleteComment, LOADING, mutate, SUCCESS, notifyError, msg]
  )
  return {
    deleteComment,
    ...rest
  }
}

export default useDeleteComment
