import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const Overlay = forwardRef(
  ({ children, onClick, className, visible, ...rest }, ref) => {
    const { classNames } = useClassNames({ alias: 'Overlay' })

    return (
      <CSSTransition
        in={visible}
        mountOnEnter
        unmountOnExit
        appear
        timeout={300}
        classNames={{
          enter: classNames(),
          enterActive: classNames({ visible: true }),
          enterDone: classNames({ visible: true }),
          exit: classNames()
        }}
        {...rest}
      >
        <div className={classNames()} ref={ref} onClick={onClick} />
      </CSSTransition>
    )
  }
)

Overlay.displayName = 'Overlay'

Overlay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  visible: PropTypes.bool,
  onClick: PropTypes.func
}

Overlay.defaultProps = {
  visible: false,
  onClick: () => null
}

export default Overlay
