import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import BookableCard from 'components/bookable/BookableCard'

const CustomDirectionArrow = ({ direction, onClick, ...rest }) => (
  <div className={`Carousel_arrow Carousel_arrow___${direction}`}>
    <IconButton
      icon={`fas fa-arrow-${direction}`}
      variant="fill"
      size="l"
      onClick={onClick}
    />
  </div>
)

CustomDirectionArrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func
}

CustomDirectionArrow.displayName = 'CustomDirectionArrow'

const BookablesCarousel = ({ bookables, onBookableClick }) => {
  return bookables.length > 4 ? (
    <div className="BookablesCategory_carousel">
      <Carousel
        responsive={{
          desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 4,
            partialVisibilityGutter: 12
          }
        }}
        customLeftArrow={<CustomDirectionArrow direction="left" />}
        customRightArrow={<CustomDirectionArrow direction="right" />}
        partialVisible={true}
        draggable={true}
        swipeable={false}
      >
        {bookables.map((bookable) => (
          <BookableCard
            key={bookable.id}
            bookable={bookable}
            onBookableClick={onBookableClick}
          />
        ))}
      </Carousel>
    </div>
  ) : (
    <div className="BookablesCategory_carouselDisabled">
      {bookables.map((bookable) => (
        <BookableCard
          key={bookable.id}
          bookable={bookable}
          onBookableClick={onBookableClick}
        />
      ))}
    </div>
  )
}

BookablesCarousel.propTypes = {
  bookables: PropTypes.arrayOf(PropTypes.object),
  onBookableClick: PropTypes.func
}

BookablesCarousel.defaultProps = {
  onBookableClick: null
}

BookablesCarousel.displayName = 'BookablesCarousel'

export default BookablesCarousel
