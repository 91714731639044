import React from 'react'
import PropTypes from 'prop-types'
import { withAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import { DesktopView } from 'components/layout/Mediaqueries'

const UserBadge = (props) => {
  const { className, authContext, children } = props
  const { userInfo } = authContext
  if (!userInfo) {
    return <div />
  }
  return (
    <div className={`UserBadge ${className}`.trim()}>
      <div className="UserBadge_name">
        <i className="fas fa-user-circle" />
        <span>{userInfo.name}</span>
        <DesktopView>
          <IconButton icon="fas fa-ellipsis-v" variant="clear" size="s" />
        </DesktopView>
      </div>
      {children}
    </div>
  )
}

UserBadge.propTypes = {
  className: PropTypes.string,
  authContext: PropTypes.object,
  children: PropTypes.node
}

UserBadge.defaultProps = {
  className: ''
}

UserBadge.displayName = 'UserBadge'

export default withAuth(UserBadge)
