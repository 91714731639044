import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { CheckboxField } from '@trileuco/triskel-react-ui/components/ui'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import { formatPrice } from 'components/misc/Price'

import BookingPaymentMethodFieldSet from './BookingPaymentMethodFieldSet'

const BookingPaymentForm = ({ booking, payment, onChange, paymentsConfig }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm',
    ignoreGlobalScope: true
  })

  const { Form, setFieldValue, values } = useForm({
    defaultValues: payment,
    validate: onChange
  })

  const handleUpdateMethod = useCallback(
    (value) => {
      if (value === 'gateway') {
        setFieldValue('wallet', null)
      } else if (value === 'wallet') {
        setFieldValue('paymentGateway', null)
        setFieldValue('gateway', null)
      }
    },
    [setFieldValue]
  )

  return (
    <Form defaultValues={values} onValidate={(instance) => onChange(instance)}>
      {booking.reservationPaymentEnabled && (
        <Block className="BookingPartialPayment">
          <CheckboxField
            label={msg({ id: 'bookingPartialPayment.label' })}
            helperText={msg(
              {
                id: 'bookingPartialPayment.helperText'
              },
              {
                bookingPrice: formatPrice({
                  amount: booking.payment.reservationAmount,
                  currency: booking.payment.currency
                })
              }
            )}
            field="reservation"
          />
        </Block>
      )}
      <Block title={msg({ id: 'paymentMethods.blockTitle' })}>
        <BookingPaymentMethodFieldSet
          paymentsConfig={paymentsConfig}
          booking={booking}
          field="method"
          rules={['required']}
          onChange={handleUpdateMethod}
        />
      </Block>
    </Form>
  )
}

BookingPaymentForm.propTypes = {
  booking: PropTypes.shape(),
  paymentsConfig: PropTypes.shape(),
  onChange: PropTypes.func,
  payment: PropTypes.shape({
    reservation: PropTypes.bool
  })
}

BookingPaymentForm.displayName = 'BookingPaymentForm'

export default BookingPaymentForm
