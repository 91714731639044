import BookablesPage from 'modules/bookables/pages/bookables/BookablesPage'
import BookableDetailsPage from 'modules/bookables/pages/bookableDetails/BookableDetailsPage'
import BookingsCalendarPage from 'modules/bookables/pages/bookingsCalendar/BookingsCalendarPage'
import BookingPage from 'modules/bookables/pages/booking/BookingPage'
import BookingsPage from 'modules/bookables/pages/bookings/BookingsPage'
import BookingDetailsPage from 'modules/bookables/pages/bookingDetails/BookingDetailsPage'
import BookingPaymentPage from 'modules/bookables/pages/booking/BookingPaymentPage'
import BookingGuestsPage from 'modules/bookables/pages/bookingDetails/guests/BookingGuestsPage'
import BookingCommentsPage from 'modules/bookables/pages/bookingDetails/comments/BookingCommentsPage'
import BookingSummaryPage from 'modules/bookables/pages/bookingDetails/summary/BookingSummaryPage'
import BookingObservationsPage from 'modules/bookables/pages/bookingDetails/observations/BookingObservationsPage'
import BookingRedirectByStatus from 'modules/bookables/pages/bookingDetails/BookingRedirectByStatus'
import BookersPage from 'modules/bookables/pages/bookers/BookersPage'
import BookingTicketsPage from 'modules/bookables/pages/bookingTickets/BookingTicketsPage'
import ProviderBookingsPage from 'modules/bookables/pages/providerBookings/ProviderBookingsPage'
import ProvidersPage from 'modules/bookables/pages/providers/ProvidersPage'
import ProviderPage from 'modules/bookables/pages/provider/ProviderPage'
import CategoryPage from 'modules/bookables/pages/categories/CategoryPage'
import Webpage from 'modules/webpages/pages/web/Webpage'
import ContactPage from 'components/cms/ContactPage/ContactPage'

import {
  bookingsCalendarPath,
  bookingPaymentPath,
  bookingPath,
  bookingsPath,
  bookableBookingPath,
  bookablesSearchPath,
  bookablePath,
  bookingDetailsRelativePath,
  bookingSummaryRelativePath,
  bookingCommentsRelativePath,
  bookingObservationsRelativePath,
  bookingGuestsRelativePath,
  bookersPath,
  bookingsTicketsPath,
  categoryPath,
  providerBookingsPath,
  providersPath,
  providerPath,
  contactPath,
  homePath
} from './routes'

export default [
  {
    path: bookingsTicketsPath,
    component: BookingTicketsPage,
    exact: true,
    auth: {
      forSomeRole: ['balaena-admin', 'balaena-bookings-manager']
    }
  },
  {
    path: bookingsCalendarPath,
    component: BookingsCalendarPage,
    auth: {
      forSomeRole: [
        'balaena-admin',
        'balaena-provider',
        'balaena-bookings-manager'
      ]
    }
  },
  {
    path: bookingPaymentPath,
    component: BookingPaymentPage,
    exact: true
  },
  {
    path: bookingPath,
    component: BookingDetailsPage,
    menu: {
      label: 'BookingDetailsPage',
      path: bookingPath
    },
    auth: true,
    subroutes: [
      {
        path: bookingDetailsRelativePath,
        component: BookingRedirectByStatus,
        exact: true
      },
      {
        path: bookingGuestsRelativePath,
        component: BookingGuestsPage,
        exact: true
      },
      {
        path: bookingCommentsRelativePath,
        component: BookingCommentsPage,
        exact: true
      },
      {
        path: bookingSummaryRelativePath,
        component: BookingSummaryPage,
        exact: true
      },
      {
        path: bookingObservationsRelativePath,
        component: BookingObservationsPage,
        exact: true
      }
    ]
  },
  {
    path: bookingsPath,
    component: BookingsPage,
    menu: {
      label: 'BookingsPage',
      path: bookingsPath
    },
    auth: true
  },
  {
    path: bookableBookingPath,
    component: BookingPage,
    menu: {
      label: 'BookingPage',
      path: bookableBookingPath
    }
  },
  {
    path: bookablesSearchPath,
    component: BookablesPage,
    menu: {
      label: 'BookablesPage',
      path: bookablesSearchPath
    }
  },
  {
    path: '/bookables/:id',
    redirect: '/:id'
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: bookersPath,
    component: BookersPage,
    exact: true,
    auth: {
      forSomeRole: ['balaena-admin']
    }
  },
  {
    auth: {
      forSomeRole: [
        'balaena-admin',
        'balaena-provider',
        'balaena-bookings-manager'
      ]
    },
    path: providerBookingsPath,
    component: ProviderBookingsPage
  },
  {
    path: providerPath,
    component: ProviderPage
  },
  {
    path: providersPath,
    component: ProvidersPage
  },
  {
    path: bookablePath,
    component: BookableDetailsPage,
    menu: {
      label: 'BookableDetailsPage',
      path: bookablePath
    }
  },
  {
    path: contactPath,
    component: ContactPage,
    exact: true,
    menu: {
      label: 'ContactPage',
      path: contactPath
    }
  },
  {
    path: categoryPath,
    component: CategoryPage,
    menu: {
      label: 'CategoryPage',
      path: categoryPath
    }
  },
  {
    path: homePath,
    component: Webpage,
    exact: true,
    menu: {
      label: 'Webpage',
      path: homePath
    }
  }
]
