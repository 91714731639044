import { useMediaQuery } from 'react-responsive'

const DesktopBreakpoint = { minWidth: 1024 }
const MobileBreakpoint = { maxWidth: 1023 }

const DesktopView = ({ children }) => {
  const isDesktop = useMediaQuery(DesktopBreakpoint)
  return isDesktop ? children : null
}

DesktopView.displayName = 'DesktopView'

const MobileView = ({ children }) => {
  const isMobile = useMediaQuery(MobileBreakpoint)
  return isMobile ? children : null
}

MobileView.displayName = 'MobileView'

export { DesktopBreakpoint, MobileBreakpoint, DesktopView, MobileView }
