import React from 'react'
import PropTypes from 'prop-types'
import { Map, TileLayer, Popup } from 'react-leaflet'
import L from 'leaflet'
import { useMediaQuery } from 'react-responsive'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import ContainerDimensions from 'react-container-dimensions'
import { generateProviderPath } from 'modules/bookables/routes'
import CountryRegionLabel from 'components/misc/CountryRegionLabel'
import Block from 'components/layout/Block'
import MapMarker from 'components/misc/MapMarker'

import ProviderLogo from './ProviderLogo'

const providerHasLocation = (provider) =>
  Boolean(
    provider.location?.latlng &&
      provider.location.latlng[0] !== 0 &&
      provider.location.latlng[1] !== 0
  )

const providerToLatLng = ({
  location: {
    latlng: [lat, lng]
  }
}) => ({
  lat,
  lng
})

const ProviderMarkerTooltip = ({ provider }) => {
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'balaena.providersLocation.marker'
  })
  const { classNames } = useClassNames({ alias: 'ProviderMarkerTooltip' })
  return (
    <Link className={classNames()} to={generateProviderPath(provider)}>
      <ProviderLogo provider={provider} className={classNames('photo')} />
      <div className={classNames('details')}>
        <p className={classNames('name')}>{provider.name}</p>
        <p className={classNames('location')}>
          <CountryRegionLabel
            countryCode={provider.country}
            region={provider.region}
          />
        </p>

        <span className={classNames('viewDetails')}>
          {msg({ id: 'viewDetails' })}
        </span>
      </div>
    </Link>
  )
}

ProviderMarkerTooltip.propTypes = {
  provider: PropTypes.shape()
}

ProviderMarkerTooltip.displayName = 'ProviderMarkerTooltip'

const ProvidersLocationsMap = ({ providers, title }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const mapStyle = {
    height: isMobile ? '250px' : '400px'
  }
  const providersLocations = providers.filter(providerHasLocation)

  var outerBounds = new L.LatLngBounds(providersLocations.map(providerToLatLng))

  const { classNames } = useClassNames({ alias: 'ProvidersLocationsMap' })
  return (
    <Block className={classNames()} variant="clear" title={title}>
      <ContainerDimensions>
        <Map
          className={classNames('map')}
          bounds={outerBounds}
          style={mapStyle}
          animate={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {providersLocations.map((provider, index) => {
            return (
              <MapMarker
                position={provider.location.latlng}
                zoom={12}
                key={index}
              >
                <Popup>
                  <ProviderMarkerTooltip provider={provider} />
                </Popup>
              </MapMarker>
            )
          })}
        </Map>
      </ContainerDimensions>
    </Block>
  )
}

ProvidersLocationsMap.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.node
}

ProvidersLocationsMap.displayName = 'ProvidersLocationsMap'

export default ProvidersLocationsMap
