import moment from 'moment-timezone'

export const toDateIgnoringTime = (datetimeString) =>
  moment(datetimeString.substring(0, '2020-01-01'.length)).toDate()

export const getDaysArray = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    // eslint-disable-next-line no-unmodified-loop-condition
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt))
  }
  return arr
}
