import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  FieldSet,
  TextField,
  CheckboxField
} from '@trileuco/triskel-react-ui/components/ui'
import Block from 'components/layout/Block'

import QuestionsSummary from './QuestionsSummary'

const BookingQuestionsFieldsets = ({ questions }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  const { msg: globalMsg } = useIntl({
    scope: 'balaena.globals',
    ignoreGlobalScope: true
  })
  const {
    values,
    meta: { isSubmitted }
  } = useFormContext()
  const getAnswerText = (answerField, answerType) => {
    if (answerField) {
      if (answerType === 'boolean') {
        return answerField.answer
          ? globalMsg({ id: 'yes' })
          : globalMsg({ id: 'no' })
      } else {
        return answerField.answer || '-'
      }
    }
    return '-'
  }

  if (isSubmitted) {
    return (
      <QuestionsSummary
        questions={questions.map((question, index) => ({
          id: index,
          question: question,
          answer: getAnswerText(
            values.questions.find(
              ({ questionId }) => questionId === question.id
            ),
            question.answerType
          )
        }))}
      />
    )
  } else {
    return (
      <Block
        title={msg({ id: 'questionsBlockTitle' })}
        className="QuestionsBlock"
      >
        <FieldSet fieldset="questions" direction="column">
          {questions.map(
            ({ title, description, answerType, required, id }, index) => {
              const questionRules = required ? ['required'] : []
              return (
                <FieldSet
                  fieldset={`${index}`}
                  key={id}
                  direction="column"
                  variant="clear"
                  className="question"
                  label={title}
                >
                  <TextField
                    disabled
                    value={id}
                    field="questionId"
                    className="Field___hidden"
                  />
                  {answerType === 'number' && (
                    <TextField
                      variant="outline"
                      label={description}
                      field="answer"
                      type="number"
                      rules={[...questionRules, 'integer']}
                    />
                  )}
                  {answerType === 'string' && (
                    <TextField
                      variant="outline"
                      label={description}
                      field="answer"
                      rules={[...questionRules]}
                    />
                  )}
                  {answerType === 'boolean' && (
                    <CheckboxField
                      variant="outline"
                      label={description}
                      field="answer"
                    />
                  )}
                </FieldSet>
              )
            }
          )}
        </FieldSet>
      </Block>
    )
  }
}

BookingQuestionsFieldsets.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape())
}

BookingQuestionsFieldsets.displayName = 'BookingQuestionsFieldsets'

export default BookingQuestionsFieldsets
