import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate/BoundedPageTemplate'
import FullPageTemplate from 'components/layout/pageTemplates/FullPageTemplate/FullPageTemplate'
import { useGetWebpage, LoadingResults } from 'components/api'
import Breadcrumb from 'components/layout/Breadcrumb'
import HeaderSection from 'components/cms/HeaderSection'
import MetaTags from 'components/layout/MetaTags'
import WebpageSections from 'modules/webpages/components/WebpageSections'
import NotFoundError from 'components/error/NotFoundError'
import { Helmet } from 'react-helmet'

import RemoteWebpage from './RemoteWebpage'

const Templates = {
  full: FullPageTemplate,
  bounded: BoundedPageTemplate
}

const Webpage = ({ location }) => {
  const isHomePage = location.pathname === '/'
  const webpageSlug = isHomePage ? 'home' : location.pathname.replace('/', '')
  const { webpage, loading } = useGetWebpage({
    id: webpageSlug
  })
  if (loading) {
    return <LoadingResults />
  }
  if (!webpage) {
    throw new NotFoundError('Webpage not found for slug ' + webpageSlug)
  }
  const Template = Templates[_.get(webpage, 'content.template', 'bounded')]
  const breadcrumbLinks = [
    {
      name: _.get(webpage, 'content.header.breadcrumb.text', webpage.name),
      isNotLink: true
    }
  ]
  return (
    <Page id="Webpage" analytics={{ pagetype: webpageSlug }}>
      <Helmet>
        {_.get(webpage, 'content.css') && <style>{webpage.content.css}</style>}
        {_.get(webpage, 'content.javascript') && (
          <script>{webpage.content.javascript}</script>
        )}
      </Helmet>
      <MetaTags metaTags={webpage.metaTags} />
      <PublicPageSkeleton
        headerAssets={
          _.get(webpage, 'content.header') && (
            <HeaderSection
              {...webpage.content.header}
              breadcrumb={!isHomePage && <Breadcrumb links={breadcrumbLinks} />}
              size="s"
              photos={webpage.content.header.photos || webpage.photos || []}
            />
          )
        }
        mainContent={
          <>
            <Template
              mainContent={
                <>
                  {_.get(webpage, 'content.remoteHtmlUrl') && (
                    <RemoteWebpage
                      remoteHtmlUrl={webpage.content.remoteHtmlUrl}
                    />
                  )}
                  {_.get(webpage, 'content.remoteHtml') && (
                    <RemoteWebpage remoteHtml={webpage.content.remoteHtml} />
                  )}
                  {_.get(webpage, 'content.sections') && (
                    <WebpageSections sections={webpage.content.sections} />
                  )}
                </>
              }
            />
          </>
        }
      />
    </Page>
  )
}

Webpage.propTypes = {
  location: PropTypes.object
}

Webpage.displayName = 'Webpage'

export default Webpage
