import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import useGetTermOfUse from 'components/api/termsOfUse/useGetTermOfUse'
import SafeHtml from 'components/cms/SafeHtml'

const TermsOfUseModal = ({ termOfUseId, isOpen, onClose }) => {
  const { termOfUse, loading } = useGetTermOfUse({
    id: termOfUseId
  })
  if (loading) {
    return null
  }
  return (
    <Modal
      name={termOfUse.name}
      isOpen={isOpen}
      onClose={onClose}
      header={<h2>{termOfUse.name}</h2>}
      body={<SafeHtml html={termOfUse.content} />}
    />
  )
}

TermsOfUseModal.propTypes = {
  termOfUseId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

TermsOfUseModal.displayName = 'TermsOfUseModal'

export default TermsOfUseModal
