import React from 'react'
import PropTypes from 'prop-types'
import { textOrNodePropType } from 'components/cms/cmsPropTypes'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks/useClassNames'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import 'react-multi-carousel/lib/styles.css'
import Block from 'components/layout/Block'
import ArticleList from 'modules/blog/components/ArticleList'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { archiveHomePath } from 'modules/blog/routes'

const LastArticlesSection = ({ title, amount }) => {
  const { classNames } = useClassNames({
    alias: 'LastArticlesSection'
  })
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'components.cms.lastArticlesSection'
  })
  return (
    <Block className={classNames()} variant="clear" title={title}>
      <ArticleList view="photoCard" pageSize={amount} />
      <Link to={archiveHomePath} className={classNames('seeAll')}>
        {msg({ id: 'seeAll' })}
      </Link>
    </Block>
  )
}

LastArticlesSection.propTypes = {
  title: textOrNodePropType,
  amount: PropTypes.number
}

LastArticlesSection.defaultProps = {
  amount: 6
}

LastArticlesSection.displayName = 'LastArticlesSection'

export default LastArticlesSection
