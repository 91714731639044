import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Form, TextField } from '@trileuco/triskel-react-ui/components/ui'
import { SubmitButton } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useNewEnquiry } from 'components/api'
import ReCaptchaField from 'components/misc/ReCaptchaField'
import DataProtectionPolicyField from 'components/misc/DataProtectionPolicyField'
import { useTenantConfig } from 'modules/bookables/Provider'
import _ from 'lodash'

const ContactForm = () => {
  const { msg } = useIntl({ scope: 'balaena.contact', ignoreGlobalScope: true })
  const { newEnquiry, loading: creatingNewEnquiry } = useNewEnquiry()
  const handleSubmit = (enquiry, instance) => {
    newEnquiry(enquiry).then(() => {
      instance.reset()
    })
  }
  const {
    components: {
      dataProtectionPolicyText = '',
      dataProtectionPolicySlug = ''
    } = {}
  } = useTenantConfig()
  const defaultValues = useMemo(
    () => ({
      name: '',
      email: '',
      phone: '',
      message: '',
      recaptcha: ''
    }),
    []
  )
  const showDataProtectionPolicy =
    !_.isEmpty(dataProtectionPolicyText) && !_.isEmpty(dataProtectionPolicySlug)
  return (
    <Form
      className="ContactForm"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      <TextField
        variant="outline"
        label={msg({
          id: 'form.name'
        })}
        field="name"
        rules={['required']}
      />
      <TextField
        variant="outline"
        label={msg({
          id: 'form.email'
        })}
        field="email"
        rules={['required', 'email']}
      />
      <TextField
        variant="outline"
        label={msg({
          id: 'form.phone'
        })}
        field="phone"
        rules={['required']}
      />
      <TextField
        variant="outline"
        label={msg({
          id: 'form.message'
        })}
        multiLine
        field="message"
        rules={['required']}
      />
      {showDataProtectionPolicy && (
        <DataProtectionPolicyField
          text={dataProtectionPolicyText}
          slug={dataProtectionPolicySlug}
          rules={['required']}
        />
      )}
      <ReCaptchaField
        field="g-recaptcha-response-data"
        action="social"
        rules={['required']}
      />
      <SubmitButton
        text={msg({
          id: 'form.submit'
        })}
        disabled={creatingNewEnquiry}
      />
    </Form>
  )
}

ContactForm.propTypes = {
  msg: PropTypes.func
}

ContactForm.displayName = 'ContactForm'

export default ContactForm
