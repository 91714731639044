import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Block from 'components/layout/Block'

import BookableItemInfo from './BookableItemInfo'

const BookableListInfoBlock = ({ listInfos, message, className }) => {
  const { itemInfos } = listInfos

  return (
    <Block title={message} variant="clear" className="BookableListInfo">
      <ul>
        {!_.isEmpty(itemInfos) &&
          itemInfos.map((itemInfo) => (
            <BookableItemInfo key={itemInfo.id} itemInfo={itemInfo} />
          ))}
      </ul>
    </Block>
  )
}

BookableListInfoBlock.propTypes = {
  listInfos: PropTypes.shape(),
  message: PropTypes.string,
  className: PropTypes.string
}

export default BookableListInfoBlock
