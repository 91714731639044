import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import {
  bookingsPath,
  bookingsCalendarPath,
  bookersPath,
  bookingsTicketsPath,
  generateProviderBookings
} from 'modules/bookables/routes'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import {
  useTenantConfig,
  useAbsoluteHomePath
} from 'modules/bookables/Provider'
import _ from 'lodash'

const UserMenu = () => {
  const { authenticated, hasSomeRole, logout, recoveryUrl } = useAuth()

  const {
    api: { baseUrl }
  } = useTenantConfig()

  const { msg } = useIntl({
    scope: 'balaena.userMenu',
    ignoreGlobalScope: true
  })

  const absoluteHomePath = useAbsoluteHomePath()

  const handleLogout = useCallback(() => {
    logout({ redirectUri: absoluteHomePath })
  }, [logout, absoluteHomePath])

  const hasAdminRole = hasSomeRole(['balaena-admin'])
  const hasProviderRole = hasSomeRole(['balaena-provider'])
  const hasBookingsManagerRole = hasSomeRole(['balaena-bookings-manager'])

  const baseBackendUrl = useMemo(() => {
    return baseUrl.split('backend')[0]
  }, [baseUrl])

  return (
    <>
      <ul className="UserMenu">
        {authenticated && (
          <>
            <li>
              <Button
                variant="clear"
                linkTo={bookingsPath}
                text={msg({ id: 'bookingsList' })}
              />
            </li>
            <Visible
              condition={
                hasAdminRole || hasProviderRole || hasBookingsManagerRole
              }
              ifTrue={
                <>
                  <li>
                    <Button
                      variant="clear"
                      linkTo={bookingsCalendarPath}
                      text={msg({ id: 'bookingsCalendar' })}
                    />
                  </li>
                  <li>
                    <Button
                      variant="clear"
                      linkTo={generateProviderBookings({})}
                      text={msg({ id: 'providersBookings' })}
                    />
                  </li>
                </>
              }
            />
            <Visible
              condition={hasAdminRole}
              ifTrue={
                <li>
                  <Button
                    variant="clear"
                    linkTo={bookersPath}
                    text={msg({ id: 'bookers' })}
                  />
                </li>
              }
            />
            <Visible
              condition={hasAdminRole || hasBookingsManagerRole}
              ifTrue={
                <li>
                  <Button
                    variant="clear"
                    linkTo={bookingsTicketsPath}
                    text={msg({ id: 'tickets' })}
                  />
                </li>
              }
            />
            <li>
              <Button
                variant="clear"
                externalLinkTo={`${recoveryUrl}&redirect_uri=${absoluteHomePath}`}
                text={msg({ id: 'changePassword' })}
              />
            </li>
            <Visible
              condition={
                (hasAdminRole || hasProviderRole) &&
                !_.isUndefined(baseBackendUrl)
              }
              ifTrue={
                <>
                  <li>
                    <Button
                      variant="clear"
                      externalLinkTo={`${baseBackendUrl}backend/admin`}
                      iconBefore="fa fa-cog"
                      text={msg({ id: 'adminPanel' })}
                    />
                  </li>
                </>
              }
            />
            <li>
              <Button
                onClick={handleLogout}
                variant="clear"
                color="danger"
                iconBefore="fas fa-sign-out-alt"
                text={msg({ id: 'logout' })}
              />
            </li>
          </>
        )}
      </ul>
    </>
  )
}

UserMenu.propTypes = {
  authContext: PropTypes.object
}

UserMenu.defaultProps = {}

UserMenu.displayName = 'UserMenu'

export default UserMenu
