function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const useWidget = () => ({
  isWidget: inIframe()
})

export default useWidget
