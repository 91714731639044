import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import PhotoImg from 'components/misc/PhotoImg'
import { useGetCategories } from 'components/api'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { findCategory } from 'modules/bookables/domain/categories'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'

const CategorySiblings = ({ categorySlug, parentSlug }) => {
  const { classNames } = useClassNames({
    alias: 'CategorySiblings'
  })

  const { msg } = useIntl({ scope: 'categoriesPage.categorySiblings' })

  const { loading, bookableCategories } = useGetCategories()

  const parentCategory = useMemo(() => {
    if (loading || !bookableCategories || !parentSlug) {
      return null
    }
    return findCategory(bookableCategories, parentSlug)
  }, [loading, bookableCategories, parentSlug])

  const categorySiblings = useMemo(() => {
    if (loading || !bookableCategories || !parentCategory) {
      return []
    }
    return parentCategory.children.filter(
      (siblingCategory) => siblingCategory.slug !== categorySlug
    )
  }, [loading, bookableCategories, parentCategory, categorySlug])

  return parentCategory ? (
    <Block
      title={msg({ id: 'title' }, { category: parentCategory.name })}
      TitleTag="p"
      variant="clear"
      className={classNames()}
    >
      <div>
        {categorySiblings.map((siblingCategory) => {
          const categoryPath = `/${siblingCategory.parents.join('/')}/${
            siblingCategory.slug
          }`
          return (
            <Link
              to={categoryPath}
              className={classNames('link')}
              key={siblingCategory.id}
            >
              <div>
                <Visible
                  condition={!_.isEmpty(siblingCategory.photos)}
                  ifTrue={
                    <PhotoImg
                      photo={siblingCategory.photos[0]}
                      variant="thumb"
                      alt={siblingCategory.name}
                      className={classNames('photo')}
                    />
                  }
                  otherwise={
                    <img className={classNames('photo')} src={NoPhoto} alt="" />
                  }
                />
                <p className={classNames('name')}>{siblingCategory.name}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </Block>
  ) : null
}

CategorySiblings.propTypes = {
  categorySlug: PropTypes.string,
  parentSlug: PropTypes.string
}

CategorySiblings.displayName = 'CategorySiblings'

export default CategorySiblings
