import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookableItemsBlock from './BookableItemsBlock'
import BookableVideoBlock from './BookableVideoBlock'
import BookableDescriptionBlock from './BookableDescriptionBlock'
import BookableItineraryBlock from './BookableItineraryBlock'
import BookableIncludedBlock from './BookableIncludedBlock'
import BookableRecommendationsBlock from './BookableRecommendationsBlock'
import BookableLocationBlock from './BookableLocationBlock'
import BookableTagsBlock from './BookableTagsBlock'
import BookableListInfoBlock from './BookableListInfoBlock'
import BookableTermsOfUseBlock from './BookableTermsOfUseBlock'

const BookableDetailsContent = ({ bookable }) => {
  const { msg } = useIntl({ scope: '' })
  const { listInfos } = bookable
  const bookableListInfo = _.find(listInfos, { slug: 'accommodation-features' })
  return (
    <>
      {!_.isEmpty(bookable.videos) && (
        <>
          {bookable.videos.map((video, index) => (
            <BookableVideoBlock
              key={index}
              video={video}
              title={`${bookable.name} video ${index}`}
            />
          ))}
        </>
      )}
      {_.get(bookable, 'descriptions.long') && (
        <BookableDescriptionBlock bookable={bookable} />
      )}
      {_.get(bookable, 'info.itinerary') && (
        <BookableItineraryBlock bookable={bookable} />
      )}
      {_.get(bookable, 'info.included') && (
        <BookableIncludedBlock bookable={bookable} />
      )}
      {!_.isEmpty(bookableListInfo) && (
        <BookableListInfoBlock
          listInfos={bookableListInfo}
          message={msg({ id: 'bookableListInfo' })}
        />
      )}
      {_.get(bookable, 'info.recommendations') && (
        <BookableRecommendationsBlock bookable={bookable} />
      )}
      {(_.get(bookable, 'location.description') ||
        _.get(bookable, 'location.latlng')) && (
        <BookableLocationBlock bookable={bookable} />
      )}
      {!_.isEmpty(bookable.bundleItems) && (
        <BookableItemsBlock
          items={bookable.bundleItems}
          title={msg({ id: 'includedInBundle' })}
        />
      )}
      {!_.isEmpty(bookable.relatedBookables) && (
        <BookableItemsBlock
          items={bookable.relatedBookables}
          title={msg({ id: 'relatedBookables' })}
        />
      )}
      <BookableTermsOfUseBlock termsOfUse={bookable.termsOfUse} />
      {!_.isEmpty(bookable.tags) && <BookableTagsBlock tags={bookable.tags} />}
    </>
  )
}

BookableDetailsContent.propTypes = {
  bookable: PropTypes.shape()
}

BookableDetailsContent.displayName = 'BookableDetailsContent'

export default BookableDetailsContent
