import React from 'react'
import PropTypes from 'prop-types'
import RichTextSection from 'components/cms/RichTextSection'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate/BoundedPageTemplate'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import { LoadingResults, useGetTermOfUse } from 'components/api'
import Breadcrumb from 'components/layout/Breadcrumb'
import SafeHtml from 'components/cms/SafeHtml'

const LegalPage = ({ location }) => {
  const isDesktop = useMediaQuery(DesktopBreakpoint)
  const termOfUseId = location.pathname.replace('/', '')
  const { termOfUse, loading } = useGetTermOfUse({
    id: termOfUseId
  })
  if (loading) {
    return <LoadingResults />
  }
  return (
    <Page id="LegalPage">
      <PublicPageSkeleton
        renderSearch={!isDesktop}
        mainContent={
          <BoundedPageTemplate
            header={
              <Breadcrumb links={[{ name: termOfUse.name, isNotLink: true }]} />
            }
            mainContent={
              <RichTextSection title={termOfUse.name}>
                <SafeHtml html={termOfUse.content} />
              </RichTextSection>
            }
          />
        }
      />
    </Page>
  )
}

LegalPage.propTypes = {
  location: PropTypes.object
}

LegalPage.displayName = 'LegalPage'

export default LegalPage
