import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useField } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  formatDateTime,
  getSchedulesByDateTime,
  scheduleIsNotValidForSelection
} from 'modules/bookables/domain/availabilities'
import { useGetBookableAvailability } from 'components/api'
import Price from 'components/misc/Price'
import { DayPickerField } from '@trileuco/triskel-react-ui/components/ui'
import { getDaysArray } from 'components/misc/utils/dateUtils'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

const BookingDayPicker = ({
  bookable,
  field,
  disabled,
  onChange,
  timeZone,
  interval,
  initialMonth,
  reservationCutoff,
  prices,
  onMonthChange,
  scheduleGroupedByDayAndDateTime,
  bookingDateRange = {}
}) => {
  const { msg } = useIntl({
    scope: 'balaena.toBookPicker',
    ignoreGlobalScope: true
  })

  const authContext = useAuth()

  const instance = useField(field)
  const { value, setValue } = instance

  const isDateValueSelected = useCallback(
    (value) => Boolean(interval ? value && value.from && value.to : value),
    [interval]
  )

  const momentBookable = useCallback((date) => moment(date).tz(timeZone), [
    timeZone
  ])

  const formatBookableDate = useCallback(
    (date) => formatDateTime(date, timeZone),
    [timeZone]
  )

  const { refetch: refetchBookableAvailability } = useGetBookableAvailability({
    id: bookable.slug,
    lazy: true
  })

  const guestsAmount =
    instance.form.values.guests && instance.form.values.guests.amount

  const getSchedulesByDate = useCallback(
    (date) =>
      getSchedulesByDateTime(
        formatBookableDate(date),
        scheduleGroupedByDayAndDateTime
      ),
    [formatBookableDate, scheduleGroupedByDayAndDateTime]
  )

  const isAvailableDay = useCallback(
    (day) =>
      Boolean(
        getSchedulesByDateTime(
          formatBookableDate(day),
          scheduleGroupedByDayAndDateTime,
          true
        )
      ),
    [formatBookableDate, scheduleGroupedByDayAndDateTime]
  )

  const dayHasEnoughCapacity = useCallback(
    (day) => {
      const formatedDay = formatBookableDate(day)
      return _.values(scheduleGroupedByDayAndDateTime[formatedDay]).some(
        ({ capacity }) => capacity.remaining >= guestsAmount
      )
    },
    [guestsAmount, formatBookableDate, scheduleGroupedByDayAndDateTime]
  )

  const noCapacityButCanBeEndOfContinuousBooking = useCallback(
    (day) => {
      const aDayInMs = 86400000
      const dayBeforeHasCapacity = dayHasEnoughCapacity(
        new Date(day.getTime() - aDayInMs)
      )
      return (
        bookable.continuous &&
        !dayHasEnoughCapacity(day) &&
        dayBeforeHasCapacity
      )
    },
    [dayHasEnoughCapacity, bookable]
  )

  const dayIsOverlappedWithBookingCurrentDate = useCallback(
    (day) => {
      if (!_.isEmpty(bookingDateRange)) {
        const { from, to } = bookingDateRange

        const compareDate = moment(day, 'YYYY-MM-DD')
        const fromDate = moment(from, 'YYYY-MM-DD')
        const toDate = moment(to, 'YYYY-MM-DD')

        return (
          bookable.continuous &&
          !dayHasEnoughCapacity(day) &&
          compareDate.isBetween(fromDate, toDate)
        )
      }
      return false
    },
    [bookingDateRange, bookable.continuous, dayHasEnoughCapacity]
  )

  const onChangeCallback = useCallback(
    (value, schedules) => {
      setValue(value)
      if (onChange) {
        value === null
          ? onChange(null)
          : onChange({
              value,
              interval,
              schedule: _.values(getSchedulesByDate(value)).find(
                ({ capacity }) => capacity.remaining >= guestsAmount
              ),
              schedules
            })
      }
    },
    [getSchedulesByDate, guestsAmount, interval, onChange, setValue]
  )

  const onChangeIfIntervalHasCapacity = useCallback(
    (value, guestsAmount) => {
      if (isDateValueSelected(value)) {
        refetchBookableAvailability({
          queryParams: {
            from: moment.tz(value.from, timeZone).startOf('day').format(),
            to: moment.tz(value.to, timeZone).endOf('day').format()
          },
          resolve: (data) => {
            const numDaysInRange = getDaysArray(value.from, value.to).length
            const { schedules } = data.availability
            if (numDaysInRange !== schedules.length) {
              onChangeCallback(null)
            } else {
              const minCapacity = schedules
                .slice(0, -1)
                .reduce((min, { capacity }) => {
                  return Math.min(min, capacity.remaining)
                }, Number.MAX_SAFE_INTEGER)
              const daysOverlappedWithCurrentBooking = schedules
                .map(({ dateTime }) => dateTime)
                .some((day) => dayIsOverlappedWithBookingCurrentDate(day))
              if (
                guestsAmount <= minCapacity ||
                daysOverlappedWithCurrentBooking
              ) {
                onChangeCallback(value, schedules)
              } else {
                onChangeCallback(null)
              }
            }
          }
        })
      }
    },
    [
      isDateValueSelected,
      refetchBookableAvailability,
      timeZone,
      onChangeCallback,
      dayIsOverlappedWithBookingCurrentDate
    ]
  )

  const handleOnChange = useCallback(
    (value) => {
      if (!guestsAmount) {
        return
      }
      if (!interval) {
        if (!dayHasEnoughCapacity(value)) {
          onChangeCallback(null)
        } else {
          onChangeCallback(value)
        }
      } else {
        if (
          isDateValueSelected(value) &&
          value.from.getTime() === value.to.getTime()
        ) {
          onChangeCallback(null)
        } else {
          onChangeCallback(value)
          onChangeIfIntervalHasCapacity(value, guestsAmount)
        }
      }
    },
    [
      guestsAmount,
      interval,
      dayHasEnoughCapacity,
      onChangeCallback,
      isDateValueSelected,
      onChangeIfIntervalHasCapacity
    ]
  )

  const maxPrice = prices.max || 0
  const minPrice = prices.min || 0
  const currency = prices.currency
  const rangePrice = (maxPrice - minPrice) / 3

  /** TODO: isolate in a function */
  const availableDays = _.keys(scheduleGroupedByDayAndDateTime)

  const disabledDays = useCallback(
    (day) => {
      return (
        disabled ||
        availableDays.indexOf(formatBookableDate(day)) === -1 ||
        Object.values(
          scheduleGroupedByDayAndDateTime[formatBookableDate(day)]
        ).some(
          () =>
            !dayHasEnoughCapacity(day) &&
            !noCapacityButCanBeEndOfContinuousBooking(day) &&
            !dayIsOverlappedWithBookingCurrentDate(day)
        ) ||
        Object.values(
          scheduleGroupedByDayAndDateTime[formatBookableDate(day)]
        ).every(({ dateTime }) =>
          scheduleIsNotValidForSelection(
            dateTime,
            reservationCutoff,
            authContext.hasSomeRole([
              'balaena-admin',
              'balaena-bookings-manager'
            ])
          )
        )
      )
    },
    [
      disabled,
      availableDays,
      formatBookableDate,
      scheduleGroupedByDayAndDateTime,
      dayHasEnoughCapacity,
      noCapacityButCanBeEndOfContinuousBooking,
      dayIsOverlappedWithBookingCurrentDate,
      reservationCutoff,
      authContext
    ]
  )

  const modifiers = {
    full: (day) => {
      if (isAvailableDay(day)) {
        const formatedDay = formatBookableDate(day)
        return Boolean(
          Object.values(scheduleGroupedByDayAndDateTime[formatedDay]).every(
            ({ capacity }) =>
              capacity.remaining === 0 &&
              !noCapacityButCanBeEndOfContinuousBooking(day)
          )
        )
      }
    },
    insufficient: (day) => {
      if (isAvailableDay(day)) {
        const formatedDay = formatBookableDate(day)
        return Boolean(
          Object.values(scheduleGroupedByDayAndDateTime[formatedDay]).every(
            ({ capacity }) =>
              guestsAmount &&
              capacity.remaining < guestsAmount &&
              !noCapacityButCanBeEndOfContinuousBooking(day)
          )
        )
      }
    },
    success: (day) => {
      if (isAvailableDay(day)) {
        const formatedDay = formatBookableDate(day)
        return Boolean(
          Object.values(scheduleGroupedByDayAndDateTime[formatedDay]).find(
            ({ priceAmount, capacity }) =>
              priceAmount &&
              priceAmount <= minPrice + rangePrice &&
              guestsAmount &&
              guestsAmount <= capacity.remaining &&
              !noCapacityButCanBeEndOfContinuousBooking(day)
          )
        )
      }
    },
    warning: (day) => {
      if (isAvailableDay(day)) {
        const formatedDay = formatBookableDate(day)
        return Boolean(
          Object.values(scheduleGroupedByDayAndDateTime[formatedDay]).find(
            ({ priceAmount, capacity }) =>
              priceAmount &&
              priceAmount > minPrice + rangePrice &&
              priceAmount <= maxPrice - rangePrice &&
              guestsAmount &&
              guestsAmount <= capacity.remaining &&
              !noCapacityButCanBeEndOfContinuousBooking(day)
          )
        )
      }
    },
    error: (day) => {
      if (isAvailableDay(day)) {
        const formatedDay = formatBookableDate(day)
        return Boolean(
          Object.values(scheduleGroupedByDayAndDateTime[formatedDay]).find(
            ({ priceAmount, capacity }) =>
              priceAmount &&
              priceAmount > maxPrice - rangePrice &&
              guestsAmount &&
              guestsAmount <= capacity.remaining &&
              !noCapacityButCanBeEndOfContinuousBooking(day)
          )
        )
      }
    }
  }
  return (
    <>
      <DayPickerField
        id="dateTime"
        interval={interval}
        variant="outline"
        value={value}
        onChange={handleOnChange}
        modifiers={(!disabled && modifiers) || undefined}
        todayButton={msg({ id: 'todayButton' })}
        initialMonth={initialMonth || momentBookable().toDate()}
        onMonthChange={(month) => onMonthChange(month, instance.form)}
        disabledDays={disabledDays}
        showDateSelectors={true}
      />
      {rangePrice > 0 && (
        <div className="Field_helperText PriceLegend">
          <span className="Price Price___success">
            <Price amount={minPrice} currency={currency} />
            {' - '}
            <Price amount={minPrice + rangePrice} currency={currency} />
          </span>
          <span className="Price Price___warning">
            <Price amount={minPrice + rangePrice} currency={currency} />
            {' - '}
            <Price amount={maxPrice - rangePrice} currency={currency} />
          </span>
          <span className="Price Price___danger">
            <Price amount={maxPrice - rangePrice} currency={currency} />
            {' - '}
            <Price amount={maxPrice} currency={currency} />
          </span>
        </div>
      )}
    </>
  )
}

BookingDayPicker.displayName = 'BookingDayPicker'

BookingDayPicker.propTypes = {
  bookable: PropTypes.shape(),
  field: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  timeZone: PropTypes.string,
  interval: PropTypes.bool,
  initialMonth: PropTypes.object,
  reservationCutoff: PropTypes.object,
  prices: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    currency: PropTypes.string
  }),
  onMonthChange: PropTypes.func,
  scheduleGroupedByDayAndDateTime: PropTypes.object,
  bookingDateRange: PropTypes.object
}

export default BookingDayPicker
