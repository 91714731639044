import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { LoadingResults, useGetProviders } from 'components/api'
import { textOrNodePropType } from 'components/cms/cmsPropTypes'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks/useClassNames'
import Carousel from 'react-multi-carousel'
import ProviderLogo from 'modules/bookables/pages/providers/ProviderLogo'
import 'react-multi-carousel/lib/styles.css'
import { generateProviderPath } from 'modules/bookables/routes'
import Link from '@trileuco/triskel-react-ui/components/ui/Link'
import Block from 'components/layout/Block'

const ProviderItem = ({ provider }) => {
  const { classNames } = useClassNames({
    alias: 'ProviderItem'
  })
  return (
    <Link className={classNames()} to={generateProviderPath(provider)}>
      <ProviderLogo provider={provider} className={classNames('photo')} />
    </Link>
  )
}

ProviderItem.propTypes = {
  provider: PropTypes.shape()
}

const ProvidersCarouselSection = ({ title, shuffle }) => {
  const { providers: providersArray, loading } = useGetProviders({
    queryParams: { allAttributes: true }
  })
  const providers = useMemo(() => {
    return shuffle ? _.shuffle(providersArray) : providersArray
  }, [shuffle, providersArray])
  const { classNames } = useClassNames({
    alias: 'ProvidersCarouselSection'
  })
  if (loading) {
    return <LoadingResults />
  }

  return (
    <Block className={classNames()} variant="clear" title={title}>
      {providers.length > 6 ? (
        <div className={classNames('carousel')}>
          <Carousel
            responsive={{
              mobile: {
                breakpoint: { max: 767, min: 0 },
                items: 2,
                partialVisibilityGutter: 24
              },
              tablet: {
                breakpoint: { max: 1023, min: 768 },
                items: 4,
                partialVisibilityGutter: 12
              },
              desktop: {
                breakpoint: { max: 5000, min: 1024 },
                items: 6,
                partialVisibilityGutter: 12
              }
            }}
            arrows={false}
            partialVisible={true}
            draggable={true}
            swipeable={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            showDots={true}
            renderDotsOutside={true}
          >
            {providers.map((provider, index) => (
              <ProviderItem key={index} provider={provider} />
            ))}
          </Carousel>
        </div>
      ) : (
        <div className={classNames('carouselDisabled')}>
          {providers.map((provider, index) => (
            <ProviderItem key={index} provider={provider} />
          ))}
        </div>
      )}
    </Block>
  )
}

ProvidersCarouselSection.propTypes = {
  title: textOrNodePropType,
  shuffle: PropTypes.bool
}

ProvidersCarouselSection.defaultProps = {
  shuffle: false
}

ProvidersCarouselSection.displayName = 'ProvidersCarouselSection'

export default ProvidersCarouselSection
