import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const Date = ({ data, timeZone, format }) => {
  if (data !== undefined) {
    return moment(data).tz(timeZone).format(format)
  }
  return '-'
}

Date.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  timeZone: PropTypes.string.isRequired,
  format: PropTypes.string
}

Date.displayName = 'Date'

Date.defaultProps = {
  format: 'DD-MM-YYYY',
  timeZone: 'UTC'
}

export default Date
