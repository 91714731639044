import React from 'react'
import PropTypes from 'prop-types'
import Block from 'components/layout/Block'
import { Map, TileLayer } from 'react-leaflet'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import ContainerDimensions from 'react-container-dimensions'
import MapMarker from 'components/misc/MapMarker'

const BookableLocationBlock = ({ bookable }) => {
  const { msg } = useIntl({ scope: '' })
  const mapStyle = {
    height: '300px'
  }
  return (
    <Block
      title={msg({ id: 'location' })}
      className="BookableLocation"
      variant="clear"
    >
      <div>
        {bookable.location.latlng && (
          <div className="BookableLocation_mapWrapper">
            <ContainerDimensions>
              <Map
                center={bookable.location.latlng}
                zoom={12}
                style={mapStyle}
                animate={true}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <MapMarker position={bookable.location.latlng} />
              </Map>
            </ContainerDimensions>
          </div>
        )}
        {bookable.location.description && (
          <p>{_.get(bookable, 'location.description')}</p>
        )}
      </div>
    </Block>
  )
}
BookableLocationBlock.propTypes = {
  bookable: PropTypes.shape()
}

BookableLocationBlock.displayName = 'BookableLocationBlock'

export default BookableLocationBlock
