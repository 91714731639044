import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import RadioFieldSet from '@trileuco/triskel-react-ui/components/ui/RadioFieldSet'
import { useTenantConfig } from 'modules/bookables/Provider'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks/useClassNames'
import { generateBookingPath } from 'modules/bookables/routes'
import { useBookingPaymentAction, useUpdateBookingState } from 'components/api'
import { useHistory } from '@trileuco/triskel-react-ui/components/router'
import _ from 'lodash'

export const PaymentMethodAndStateModal = ({
  bookingId,
  isOpen,
  close,
  stateOptions
}) => {
  const { classNames } = useClassNames({ alias: 'PaymentMethodAndStateModal' })
  const [showStates, setShowStates] = useState(false)
  const history = useHistory()
  const { msg } = useIntl({
    scope: 'balaena.bookingActions',
    ignoreGlobalScope: true
  })
  const { Form, values = {} } = useForm({
    paymentMethod: ''
  })

  const {
    payments: { adminPaymentMethods }
  } = useTenantConfig()

  const { updateState } = useUpdateBookingState(bookingId)

  const paymentOptions = (adminPaymentMethods || []).map((m) => ({
    label: m,
    value: m
  }))

  const { mutate: pay } = useBookingPaymentAction({
    paymentAction: 'pay',
    bookingId: bookingId
  })
  const { mutate: invite } = useBookingPaymentAction({
    paymentAction: 'invite',
    bookingId: bookingId
  })

  const performStateUpdateOrRedirect = useCallback(
    (state) => {
      if (!_.isEmpty(state)) {
        updateState({ state }).then(() => {
          history.push(generateBookingPath({ id: bookingId }))
        })
      } else {
        history.push(generateBookingPath({ id: bookingId }))
      }
    },
    [updateState, history, bookingId]
  )

  const confirmChanges = useCallback(
    (paymentMethod, state) => {
      if (paymentMethod === 'invite') {
        invite({}).then(() => performStateUpdateOrRedirect(state))
      } else {
        pay({ paymentMethod }).then(() => performStateUpdateOrRedirect(state))
      }
    },
    [invite, performStateUpdateOrRedirect, pay]
  )

  const handleFormSubmit = useCallback(() => {
    if (!_.isEmpty(stateOptions) && showStates === false) {
      setShowStates(true)
    } else {
      confirmChanges(values.paymentMethod, values.state)
    }
  }, [
    stateOptions,
    showStates,
    confirmChanges,
    values.paymentMethod,
    values.state
  ])

  const buttonText = useMemo(() => {
    return !_.isEmpty(stateOptions) && showStates === false
      ? 'nextText'
      : 'confirmText'
  }, [showStates, stateOptions])

  return (
    <Modal
      className={classNames()}
      name={'Admin payment modal'}
      isOpen={isOpen}
      onClose={close}
      header={
        <p>
          {msg({
            id: !showStates ? 'modalHeaderPayment' : 'modalHeaderStates'
          })}
        </p>
      }
      body={
        <Form>
          {showStates === false ? (
            <RadioFieldSet
              field="paymentMethod"
              variant="outline"
              options={[
                { value: 'invite', label: msg({ id: 'inviteText' }) },
                ...paymentOptions
              ]}
            />
          ) : (
            <RadioFieldSet
              field="state"
              variant="outline"
              options={stateOptions}
            />
          )}

          <Button
            disabled={_.isEmpty(values.paymentMethod)}
            onClick={handleFormSubmit}
          >
            {msg({ id: buttonText })}
          </Button>
        </Form>
      }
    />
  )
}
PaymentMethodAndStateModal.propTypes = {
  bookingId: PropTypes.number,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  stateOptions: PropTypes.array
}

PaymentMethodAndStateModal.displayName = 'PaymentMethodAndStateModal'
