import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const FullPageTemplate = (props) => {
  const { header, mainContent, sidebar, footer, ...other } = props
  const { classNames } = useClassNames({
    alias: 'FullPageTemplate'
  })
  return (
    <div className={classNames()} {...other}>
      {header && <div className={classNames('header')}>{header}</div>}
      <div className={classNames('body')}>
        {sidebar && <div className={classNames('sidebar')}>{sidebar}</div>}
        <div className={classNames('main', { sidebarOn: Boolean(sidebar) })}>
          {mainContent}
        </div>
      </div>
      {footer && <div className={classNames('footer')}>{footer}</div>}
    </div>
  )
}

FullPageTemplate.propTypes = {
  header: PropTypes.node,
  sidebar: PropTypes.node,
  mainContent: PropTypes.node,
  footer: PropTypes.node
}

FullPageTemplate.displayName = 'FullPageTemplate'

export default FullPageTemplate
