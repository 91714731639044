const PaycometScriptElementId = 'Paycomet'

const PaycometSrc =
  'https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=es'

let loading = true

const PaycometUtils = {
  destroy: () => {
    if (document.getElementById(PaycometScriptElementId)) {
      document.getElementById(PaycometScriptElementId).remove()
    }
  },
  load: () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(PaycometScriptElementId)) {
        if (loading) {
          document
            .getElementById(PaycometScriptElementId)
            .addEventListener('load', () => resolve())
        } else {
          resolve()
        }
      } else {
        loading = true
        var PaycometScriptElement = document.createElement('script')
        PaycometScriptElement.id = PaycometScriptElementId
        PaycometScriptElement.async = true
        PaycometScriptElement.type = 'text/javascript'
        PaycometScriptElement.src = PaycometSrc
        PaycometScriptElement.onload = () => {
          loading = false
          resolve()
        }
        PaycometScriptElement.onerror = () => {
          reject(new Error('Error loading redsys script'))
        }
        document
          .getElementsByTagName('head')[0]
          .appendChild(PaycometScriptElement)
      }
    })
  }
}

export default PaycometUtils
