import React from 'react'
import PropTypes from 'prop-types'
import DateTime from 'components/misc/DateTime'

const BookingDate = ({ dateTime, dateRange, timeZone }) => {
  const isInterval = dateRange && dateRange.from
  return (
    <>
      {!isInterval && <DateTime data={dateTime} timeZone={timeZone} />}
      {isInterval && (
        <>
          <DateTime
            data={dateRange.from}
            timeZone={timeZone}
            format="DD-MM-YYYY"
          />{' '}
          <i className="fas fa-long-arrow-alt-right" />{' '}
          <DateTime
            data={dateRange.to}
            timeZone={timeZone}
            format="DD-MM-YYYY"
          />
        </>
      )}
    </>
  )
}

BookingDate.propTypes = {
  dateTime: PropTypes.string,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  timeZone: PropTypes.string
}

BookingDate.displayName = 'BookingDate'

export default BookingDate
