import { useGet } from 'components/api'

export default ({ id, from, to, ...moreParams }) => {
  const { data, ...rest } = useGet({
    path: `/bookables/${id}/availability`,
    queryParams: {
      from: from,
      to: to
    },
    ...moreParams
  })
  if (!data) {
    return {
      schedules: [],
      maxCapacity: 0,
      prices: {},
      ...rest
    }
  }
  return {
    ...data.availability,
    ...rest
  }
}
