import { useMemo } from 'react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { formatPrice } from 'components/misc/Price'
import { useFetchCache } from 'components/api/ApiCacheContext'
import { useTenantConfig } from 'modules/bookables/Provider'

const useGetBookablesSearchFilters = () => {
  const {
    loading: loadingBookableSearchFilters,
    bookableSearchFilters,
    error: bookableSearchFiltersError
  } = useFetchCache({
    path: '/bookables/search-filters',
    entity: 'bookableSearchFilters',
    resolve: (data) => data,
    onError: () => null
  })
  const { msg } = useIntl({
    scope: 'balaena.api.bookableSearchFilters',
    ignoreGlobalScope: true
  })
  const {
    payments: { currency }
  } = useTenantConfig()

  const output = useMemo(
    () => ({
      loading: loadingBookableSearchFilters,
      error: bookableSearchFiltersError,
      bookableSearchFilters: bookableSearchFilters
        ? [
            {
              type: 'text',
              id: 'search',
              label: msg({ id: `search.label` }),
              defaultValue: '',
              variant: 'outline'
            },
            {
              type: 'checkbox',
              id: 'type',
              defaultValue: [],
              label: msg({ id: 'type.label' }),
              options: Object.keys(bookableSearchFilters.type).map((type) => ({
                value: type,
                label: msg({ id: `type.options.${type}.label` }),
                totalBookables: bookableSearchFilters.type[type]
              }))
            },
            {
              type: 'checkbox',
              id: 'location',
              label: msg({ id: 'location.label' }),
              hideUnselected: true,
              selectAllLabel: msg({ id: 'location.options.allLocations' }),
              defaultValue: [],
              options: Object.keys(bookableSearchFilters.location)
                .filter((location) => {
                  const loc = location
                    .substring(1, location.length - 1)
                    .split(',')
                    .map((a) => a.trim().replace(/"/g, ''))[1]
                  return loc && loc !== 'nil'
                })
                .map((location) => {
                  const value = location
                    .substring(1, location.length - 1)
                    .split(',')
                    .map((a) => a.trim().replace(/"/g, ''))
                  return {
                    value: value.join('-'),
                    label: value[1] || 'No region',
                    totalBookables: bookableSearchFilters.location[location]
                  }
                })
            },
            {
              type: 'date:interval',
              id: 'date',
              defaultValue: { from: null, to: null },
              label: msg({ id: 'date.label' }),
              placeholder: 'Cualquier fecha'
            },
            {
              type: 'radio',
              id: 'duration',
              label: msg({ id: 'duration.label' }),
              defaultValue: '',
              options: Object.keys(bookableSearchFilters.duration)
                .reduce(
                  (durations, duration) => {
                    return durations.map((option) => {
                      const { value, totalBookables, comparator } = option
                      if (
                        duration &&
                        comparator &&
                        value &&
                        /* eslint-disable-next-line no-eval */
                        eval(`${duration}${comparator}${value}`)
                      ) {
                        return {
                          ...option,
                          totalBookables:
                            totalBookables +
                            bookableSearchFilters.duration[duration]
                        }
                      }
                      return option
                    })
                  },
                  [
                    {
                      value: '',
                      totalBookables: 0,
                      label: msg({ id: 'duration.option.allDurations' })
                    },
                    {
                      value: '360',
                      comparator: '<=',
                      totalBookables: 0,
                      label: msg({ id: 'duration.option.upTo6Hour' })
                    },
                    {
                      value: '1440',
                      comparator: '<=',
                      totalBookables: 0,
                      label: msg({ id: 'duration.option.upTo1Day' })
                    },
                    {
                      value: '4320',
                      comparator: '<=',
                      totalBookables: 0,
                      label: msg({ id: 'duration.option.upTo3Day' })
                    },
                    {
                      value: '8640',
                      comparator: '<=',
                      totalBookables: 0,
                      label: msg({ id: 'duration.option.upTo6Day' })
                    }
                  ]
                )
                .filter(
                  ({ totalBookables, value }) => !value || totalBookables > 0
                )
            },
            {
              type: 'tag',
              id: 'tag',
              defaultValue: [],
              label: msg({ id: 'tag.label' }),
              options: Object.keys(bookableSearchFilters.tags).map((tag) => ({
                value: tag,
                label: tag,
                totalBookables: bookableSearchFilters.tags[tag]
              }))
            },
            {
              type: 'number:range',
              id: 'price',
              label: msg({ id: 'price.label' }),
              defaultValue: {
                min: parseInt(
                  Object.keys(bookableSearchFilters.price_amount)[0]
                ),
                max: parseInt(
                  Object.keys(bookableSearchFilters.price_amount)[
                    Object.keys(bookableSearchFilters.price_amount).length - 1
                  ]
                )
              },
              min: parseInt(Object.keys(bookableSearchFilters.price_amount)[0]),
              max: parseInt(
                Object.keys(bookableSearchFilters.price_amount)[
                  Object.keys(bookableSearchFilters.price_amount).length - 1
                ]
              ),
              options: Object.keys(bookableSearchFilters.price_amount).map(
                (priceAmount) => ({
                  value: parseInt(priceAmount),
                  label: formatPrice({
                    amount: priceAmount,
                    currency
                  }),
                  total: bookableSearchFilters.price_amount[priceAmount]
                })
              )
            }
          ]
        : []
    }),
    [
      loadingBookableSearchFilters,
      bookableSearchFiltersError,
      bookableSearchFilters,
      msg,
      currency
    ]
  )
  return output
}

export default useGetBookablesSearchFilters
