import React from 'react'
import PropTypes from 'prop-types'
import BookableCategoryBreadcrumb from 'components/bookable/BookableCategoryBreadcrumb'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import HeaderSection from 'components/cms/HeaderSection'
import photosPropTypes from 'components/api/photos/photosPropTypes'
import SafeHtml from 'components/cms/SafeHtml'

const CategoryHeader = ({ photos, name, descriptions }) => {
  const { classNames } = useClassNames({
    alias: 'CategoryHeader'
  })
  return (
    <HeaderSection
      className={classNames()}
      breadcrumb={<BookableCategoryBreadcrumb />}
      photos={photos}
      title={{ text: name }}
      size="s"
      content={
        <>
          {descriptions && descriptions.short && (
            <SafeHtml
              html={descriptions.short}
              className={classNames('shortDescription')}
            />
          )}
        </>
      }
    />
  )
}

CategoryHeader.propTypes = {
  photos: photosPropTypes,
  name: PropTypes.string,
  descriptions: PropTypes.object
}

CategoryHeader.displayName = 'CategoryHeader'

export default CategoryHeader
