import React from 'react'
import BookingGuestsSummary from 'components/booker/BookingForm/BookingGuestsSummary'
import { useBookingDetails } from 'components/booker/BookingDetails/BookingDetailsContext'
import { useGetBookable, LoadingResults } from 'components/api'

const BookingGuestsPage = () => {
  const { booking, refetch } = useBookingDetails()
  const { bookable, loading } = useGetBookable({
    bookableId: booking && booking.bookable.id
  })

  if (loading) {
    return <LoadingResults />
  }
  return (
    bookable && (
      <BookingGuestsSummary
        refetch={refetch}
        booking={booking}
        bookable={bookable}
        guests={booking.guests}
      />
    )
  )
}

BookingGuestsPage.displayName = 'BookingGuestsPage'

export default BookingGuestsPage
