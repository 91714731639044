import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  PopOver,
  TextField,
  Button,
  FieldSet
} from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import moment from 'moment'

const DefaultRenderValue = ({
  label,
  disabled,
  error,
  value,
  options,
  className,
  renderStringValue = false
}) => {
  const stringValue = useMemo(() => {
    if (!renderStringValue) {
      if (!value?.length) {
        return ''
      }
      return value
        .map((id) => {
          if (options.find(({ value }) => id === value)) {
            return options.find(({ value }) => id === value).label
          }
          return ''
        })
        .join(', ')
    } else {
      if (value.from && value.to) {
        return `${moment(value.from).format('L')} - ${moment(value.to).format(
          'L'
        )}`
      }
      return ''
    }
  }, [value, options, renderStringValue])
  return (
    <TextField
      className={className}
      variant="outline"
      label={<span onClick={(e) => e.stopPropagation()}>{label}</span>}
      value={stringValue}
      disabled={disabled}
      error={error}
      readOnly
    />
  )
}

DefaultRenderValue.displayName = 'DefaultRenderValue'

DefaultRenderValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  label: PropTypes.node,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  renderStringValue: PropTypes.bool,
  error: PropTypes.node
}

const PopOverField = (props) => {
  const {
    value,
    label,
    Field,
    className,
    onChange,
    disabled,
    options,
    error,
    changeWithClick,
    defaultValue,
    showInline,
    RenderValue,
    ...restProps
  } = props

  const popOverRef = useRef()

  const { classNames } = useClassNames({ alias: 'PopOverField' })

  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'components.PopOverField'
  })

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const executeChange = useCallback(
    (value) => {
      popOverRef.current.setVisible(false)
      onChange(value)
    },
    [onChange]
  )

  const handleChange = useCallback(
    (value) => {
      if (changeWithClick) {
        setLocalValue(value)
      } else {
        onChange(value)
      }
    },
    [changeWithClick, onChange, setLocalValue]
  )

  if (showInline) {
    return <Field {...props} direction="column" />
  }
  return (
    <PopOver
      ref={popOverRef}
      className={`${classNames()} ${className || ''}`.trim()}
      placement="top-start"
      triggerEvent="click"
      TriggerTag={'div'}
      onClose={() => setLocalValue(value)}
      content={
        <>
          <Field
            {...restProps}
            label={label}
            onChange={handleChange}
            value={localValue}
            direction="column"
            options={options}
            disabled={disabled}
            hideUnselected={false}
            error={error}
          />

          {changeWithClick && (
            <FieldSet
              className={classNames('actions')}
              direction="row"
              variant="clear"
            >
              <Button
                size="m"
                onClick={() => executeChange(localValue)}
                text={msg({ id: 'accept' })}
              />
              <Button
                size="m"
                variant="clear"
                onClick={() => executeChange()}
                text={msg({ id: 'clear' })}
              />
            </FieldSet>
          )}
        </>
      }
    >
      <RenderValue {...props} />
    </PopOver>
  )
}

PopOverField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.object
  ]),
  label: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  changeWithClick: PropTypes.bool,
  defaultValue: PropTypes.any,
  Field: PropTypes.elementType,
  error: PropTypes.node,
  showInline: PropTypes.bool,
  RenderValue: PropTypes.elementType
}

PopOverField.defaultProps = {
  changeWithClick: true,
  RenderValue: DefaultRenderValue
}

export default formalizer(PopOverField)
