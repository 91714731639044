import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useUpdateObservations = (bookingId, text) => {
  const { mutate, ...rest } = useMutate({
    path: `/bookings/${bookingId}/observations`,
    verb: 'PATCH',
    text
  })

  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useUpdateObservations',
    ignoreGlobalScope: true
  })

  const notifyUpdatedObservations = useCallback(
    (notification) => {
      notify({
        id: 'OBSERVATIONS',
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [msg, notify]
  )

  const updateObservations = useCallback(
    (params) => {
      notifyUpdatedObservations({ status: LOADING })
      return mutate(params)
        .then((result) => {
          notifyUpdatedObservations({ status: SUCCESS })
          return result
        })
        .catch((error) => {
          notifyError({
            id: 'OBSERVATIONS',
            title: msg({ id: 'error' }),
            error
          })
          throw error
        })
    },
    [notifyUpdatedObservations, LOADING, mutate, SUCCESS, notifyError, msg]
  )
  return {
    updateObservations,
    ...rest
  }
}

export default useUpdateObservations
