import React from 'react'
import PropTypes from 'prop-types'
import SafeHtml from 'components/cms/SafeHtml'
import Block from 'components/layout/Block'

const BookableWysiwygBlock = ({ className, title, content }) => {
  return (
    <Block
      className={`BookableWysiwygBlock ${className || ''}`.trim()}
      variant="clear"
      title={title}
    >
      <SafeHtml html={content} />
    </Block>
  )
}

BookableWysiwygBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string
}

BookableWysiwygBlock.displayName = 'BookableWysiwygBlock'

export default BookableWysiwygBlock
