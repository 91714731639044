import React from 'react'
import Page from 'components/layout/Page'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import { LoadingResults, useGetWebpage } from 'components/api'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import NotFoundError from 'components/error/NotFoundError'
import useGetProviders from 'components/api/providers/useGetProviders'
import Breadcrumb from 'components/layout/Breadcrumb'
import HeaderSection from 'components/cms/HeaderSection'

import ProvidersList from './ProvidersList'
import ProvidersLocationsMap from './ProvidersLocationsMap'

const ProvidersPage = () => {
  let header = null
  let mainContent = null

  const { msg } = useIntl({
    scope: 'balaena.providersPage',
    ignoreGlobalScope: true
  })

  const { classNames: headerClassNames } = useClassNames({
    alias: 'ProvidersHeader'
  })

  const { providers, loading } = useGetProviders({
    queryParams: { allAttributes: true }
  })

  const { webpage, loading: loadingProvidersPage } = useGetWebpage({
    id: 'providers'
  })
  if (loading || loadingProvidersPage) {
    mainContent = <LoadingResults />
  } else if (!providers) {
    throw new NotFoundError('Providers not found')
  } else if (!webpage) {
    throw new NotFoundError('Webpage not found for slug providers')
  } else {
    header = (
      <HeaderSection
        className={headerClassNames()}
        breadcrumb={
          <Breadcrumb
            links={[{ name: msg({ id: 'providers' }), isNotLink: true }]}
          />
        }
        {...webpage.content.header}
      />
    )
    mainContent = (
      <BoundedPageTemplate
        mainContent={
          <>
            <ProvidersLocationsMap providers={providers} />
            <ProvidersList providers={providers} />
          </>
        }
      />
    )
  }
  return (
    <Page id="ProvidersPage">
      <PublicPageSkeleton headerAssets={header} mainContent={mainContent} />
    </Page>
  )
}

ProvidersPage.displayName = 'ProvidersPage'

export default ProvidersPage
