import React from 'react'
import PropTypes from 'prop-types'
import SafeHtml from 'components/cms/SafeHtml'

const TextOrNode = ({ content, TextTag, className, ...other }) => {
  if (!content) {
    return null
  }
  if (content.text) {
    const Tag = content.tag || TextTag
    return (
      <Tag className={className} {...content.other} {...other}>
        {content.text}
      </Tag>
    )
  }
  if (content.html) {
    if (content.tag) {
      return (
        <SafeHtml
          WrapperTag={content.tag}
          {...content.other}
          {...other}
          html={content.html}
        />
      )
    }
    return <SafeHtml {...content.other} {...other} html={content.html} />
  }
  return content
}

TextOrNode.propTypes = {
  TextTag: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.shape({
      tag: PropTypes.string,
      text: PropTypes.string,
      other: PropTypes.object
    })
  ]),
  className: PropTypes.string
}

TextOrNode.defaultProps = {
  TextTag: 'p',
  className: ' '
}

TextOrNode.displayName = 'TextOrNode'

export default TextOrNode
