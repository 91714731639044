import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

var moment = require('moment')

const BookingComment = ({ comment, onDelete }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingComments',
    ignoreGlobalScope: true
  })

  const { id, text, createdBy, createdAt } = comment

  const authContext = useAuth()
  const { classNames } = useClassNames({
    alias: 'BookingComment'
  })
  return (
    <div key={id} className="BookingComment">
      <div className={classNames('header')}>
        <span className={classNames('createdBy')}>{createdBy}</span>
        <span className={classNames('createdAt')}>
          {moment(createdAt).format('LLL')}
        </span>
        <Visible
          condition={authContext.hasSomeRole([
            'balaena-admin',
            'balaena-bookings-manager'
          ])}
          ifTrue={
            <IconButton
              className={classNames('deleteComment')}
              variant="clear"
              icon="fas fa-trash"
              size="s"
              color="danger"
              onClick={() => {
                if (window.confirm(msg({ id: 'delete.confirm' }))) {
                  onDelete(id)
                }
              }}
            />
          }
        />
      </div>
      <p className={classNames('text')}>{text}</p>
    </div>
  )
}

BookingComment.propTypes = {
  comment: PropTypes.shape(),
  onDelete: PropTypes.func
}

BookingComment.displayName = 'BookingComment'

export default BookingComment
