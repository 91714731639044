import { useGet } from 'components/api'
import queryString from 'query-string'
import _ from 'lodash'

const useGetBookables = ({ queryParams = {}, ...options }) => {
  const { fromDate, toDate, ...restParams } = queryParams

  const dateParams = !_.isUndefined(fromDate) && !_.isUndefined(toDate)

  const { data: bookables, ...rest } = useGet({
    path: `/bookables?${queryString.stringify(
      {
        ...restParams,
        ...(dateParams && {
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString()
        })
      },
      {
        arrayFormat: 'comma'
      }
    )}`,
    resolve: ({ bookables }) => bookables,
    ...options
  })
  return {
    bookables,
    ...rest
  }
}

export default useGetBookables
