import es from './es'
import gl from './gl'
import en from './en'

export default {
  es,
  gl,
  en
}

export { default as useGetBackendError } from './useGetBackendError'
