import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Button,
  FieldSet,
  FieldWrapper
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import PaycometUtils from './paycomet'

const PaycometField = ({ id, children }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.PaycometPayment',
    ignoreGlobalScope: true
  })
  return (
    <FieldWrapper
      variant="outline"
      fieldType="Text"
      className="PaycometField"
      label={msg({ id: `${id}.label` })}
    >
      <div id={`paycomet-${id}`} className="PaycometFieldWrapper"></div>
      {children}
    </FieldWrapper>
  )
}

PaycometField.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
}

const PaycometPaymentGatewayFieldSet = ({ onChange, jetID }) => {
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.PaycometPayment',
    ignoreGlobalScope: true
  })

  const paycometPaymentForm = useRef()

  /** Add script when components unmount */
  useEffect(() => {
    if (!loaded && !loading) {
      setLoading(true)
      PaycometUtils.load().then(() => {
        setLoading(false)
        setLoaded(true)
      })
      window.formAction = () => {
        onChange({
          token: paycometPaymentForm.current.elements.paytpvToken.value,
          card: {}
        })
      }
    }
  }, [onChange, loaded, loading])

  /** Remove script when components unmount */
  useEffect(
    () => () => {
      PaycometUtils.destroy()
    },
    []
  )

  const { classNames } = useClassNames({
    alias: 'AddCardModal'
  })

  const yearOptions = useMemo(() => {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().add(6, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push({
        label: dateStart.format('YYYY'),
        value: dateStart.format('YY')
      })
      dateStart.add(1, 'year')
    }
    return years
  }, [])
  const monthOptions = useMemo(
    () =>
      Array.apply(0, Array(12)).map((_, i) => moment().month(i).format('MM')),
    []
  )
  return (
    <form
      ref={paycometPaymentForm}
      // eslint-disable-next-line no-script-url
      action={'javascript:window.formAction()'}
      method="POST"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <FieldSet direction="column" className={classNames('fields')}>
        <input type="hidden" data-paycomet="jetID" value={jetID} />
        <PaycometField id="cardHolderName">
          <input
            type="text"
            name="username"
            data-paycomet="cardHolderName"
            placeholder=""
            required=""
          />
        </PaycometField>
        <PaycometField id="pan">
          <input
            paycomet-style={`
              background: none;
              outline: none;
              height: 50px;
              width: 100%;
              border: 0;
              font-size: 14px;
              color: #555;
              padding-top: 24px;
              padding-left: 12px;
              padding-right: 12px;
              padding-bottom: 10px;
              box-sizing: border-box;
            `}
            paycomet-name="pan"
          />
          <input type="hidden" data-paycomet="no-mask" value="" />
        </PaycometField>
        <FieldSet direction="row">
          <PaycometField id="dateMonth">
            <select data-paycomet="dateMonth">
              <option disabled selected value>
                {''}
              </option>
              {monthOptions.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </PaycometField>
          <PaycometField id="dateYear">
            <select data-paycomet="dateYear">
              <option disabled selected value>
                {''}
              </option>
              {yearOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </PaycometField>
          <PaycometField id="cvc2">
            <input
              paycomet-style={`
                background: none;
                outline: none;
                height: 50px;
                width: 100%;
                border: 0;
                font-size: 14px;
                color: #555;
                padding-top: 24px;
                padding-left: 12px;
                padding-right: 12px;
                padding-bottom: 10px;
                box-sizing: border-box;
              `}
              paycomet-name="cvc2"
              required=""
              type="text"
            />
          </PaycometField>
        </FieldSet>
        <Button type="submit" text={msg({ id: 'addCard' })} />
        <div
          className={'Field_helperText errorText'}
          id="paymentErrorMsg"
        ></div>
      </FieldSet>
    </form>
  )
}

PaycometPaymentGatewayFieldSet.propTypes = {
  onChange: PropTypes.func,
  jetID: PropTypes.string
}

PaycometPaymentGatewayFieldSet.displayName = 'PaycometPaymentGatewayFieldSet'

export default PaycometPaymentGatewayFieldSet
