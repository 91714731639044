import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useSignIn = (options) => {
  const { mutate, ...rest } = useMutate({
    path: `/auth/signin/`,
    verb: 'POST',
    ...options
  })
  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useSignIn',
    ignoreGlobalScope: true
  })
  const notifySignIn = useCallback(
    (notification) => {
      notify({
        id: 'SIGNIN',
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [notify, msg]
  )
  const signIn = useCallback(
    (params) => {
      notifySignIn({ status: LOADING })
      return mutate(params)
        .then((credentials) => {
          notifySignIn({ status: SUCCESS })
          return credentials
        })
        .catch((error) => {
          notifyError({
            id: 'SIGNIN',
            title: msg({ id: 'error' }),
            error
          })
          throw error
        })
    },
    [notifySignIn, LOADING, mutate, SUCCESS, notifyError, msg]
  )
  return {
    signIn,
    ...rest
  }
}

export default useSignIn
