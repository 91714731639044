import React from 'react'
import RootCategories from 'components/bookable/RootCategories/RootCategories'
import { textOrNodePropType } from 'components/cms/cmsPropTypes'

const RootCategoriesSection = ({ title }) => {
  return <RootCategories title={title} />
}

RootCategoriesSection.displayName = 'RootCategoriesSection'

RootCategoriesSection.propTypes = {
  title: textOrNodePropType
}
export default RootCategoriesSection
