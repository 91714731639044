import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Duration from 'components/misc/Duration'
import CountryRegionLabel from 'components/misc/CountryRegionLabel'

const BookableMainDetails = ({ bookable }) => {
  const { msg } = useIntl({ scope: 'mainDetails' })
  const { classNames } = useClassNames({ alias: 'BookableMainDetails' })
  return (
    <div className={classNames()}>
      {_.get(bookable, 'location.description') && (
        <span className={classNames('location')}>
          <span>{msg({ id: 'location' })}</span>
          <div>
            <i className="fas fa-map-marker-alt"></i>
            {`${bookable.location.description} `}
            {bookable.country && (
              <CountryRegionLabel
                countryCode={bookable.country}
                region={bookable.region}
              />
            )}
          </div>
        </span>
      )}
      {Boolean(bookable.duration) && (
        <span className={classNames('duration')}>
          <span>{msg({ id: 'duration' })}</span>
          <div>
            <i className="far fa-clock"></i>
            <Duration amount={bookable.duration} />
          </div>
        </span>
      )}
    </div>
  )
}

BookableMainDetails.propTypes = {
  bookable: PropTypes.shape()
}

BookableMainDetails.displayName = 'BookableMainDetails'

export default BookableMainDetails
