import React from 'react'
import PropTypes from 'prop-types'
import { Link, Button } from '@trileuco/triskel-react-ui/components/ui'
import Price from 'components/misc/Price'
import { generateBookingPath } from 'modules/bookables/routes'
import BookingPaymentStatus from 'components/booker/BookingPaymentStatus'
import DateTime from 'components/misc/DateTime'
import BookingDate from 'components/booker/BookingForm/BookingDate'

import BookableCell from './BookableCell'
import BookableGuestsCell from './BookableGuestsCell'
import BookerCell from './BookerCell'

const CardCellTitle = ({ text }) => <span className="td-th">{text}</span>
CardCellTitle.propTypes = {
  text: PropTypes.string
}

CardCellTitle.displayName = 'CardCellTitle'

const bookingColumns = (msg, authContext) => {
  const getIdLabel = (cellInfo) => {
    const cell = authContext.hasAllRoles(['balaena-admin'])
      ? `${cellInfo.row.original.hashId} (${cellInfo.row.original.id})`
      : cellInfo.row.original.hashId || ''
    return cell
  }
  const BookingIdNameDefaultCell = (cellInfo) => {
    return getIdLabel(cellInfo)
  }
  const BookingIdNameCardCell = (cellInfo) => {
    return (
      <>
        <CardCellTitle text={cellInfo.column.Header} />
        {getIdLabel(cellInfo)}
      </>
    )
  }
  const BookerDefaultCell = (cellInfo) => (
    <BookerCell booker={cellInfo.row.original.booker} />
  )
  const BookerCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      <BookerCell booker={cellInfo.row.original.booker} />
    </>
  )
  const BookableDefaultCell = (cellInfo) => (
    <BookableCell
      bookable={cellInfo.row.original.bookable}
      parent={cellInfo.row.original.parentBookable}
    />
  )
  const BookableCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      <BookableCell
        bookable={cellInfo.row.original.bookable}
        parent={cellInfo.row.original.parentBookable}
      />
    </>
  )
  const BookableParentDefaultCell = (cellInfo) => (
    <BookableCell bookable={cellInfo.row.original.parentBookable} />
  )
  const BookableParentCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      <BookableCell bookable={cellInfo.row.original.parentBookable} />
    </>
  )
  const BookingTimeDefaultCell = (cellInfo) => (
    <BookingDate
      dateTime={cellInfo.row.original.dateTime}
      dateRange={cellInfo.row.original.dateRange}
      timeZone={cellInfo.row.original.bookable.timeZone}
    />
  )
  const BookingTimeCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      <BookingDate
        dateTime={cellInfo.row.original.dateTime}
        dateRange={cellInfo.row.original.dateRange}
        timeZone={cellInfo.row.original.bookable.timeZone}
      />
    </>
  )
  const PaymentAmountDefaultCell = (cellInfo) => (
    <>
      {cellInfo.row.original.payment && (
        <Price
          amount={cellInfo.row.original.payment.amount}
          currency={cellInfo.row.original.payment.currency}
        />
      )}
    </>
  )
  const PaymentAmountCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.payment && (
        <Price
          amount={cellInfo.row.original.payment.amount}
          currency={cellInfo.row.original.payment.currency}
        />
      )}
    </>
  )
  const PaymentStatusDefaultCell = (cellInfo) => (
    <>
      {cellInfo.row.original.payment && (
        <BookingPaymentStatus
          booking={cellInfo.row.original}
          showTitle={false}
        />
      )}
    </>
  )
  const PaymentStatusCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.payment && (
        <BookingPaymentStatus
          booking={cellInfo.row.original}
          showTitle={false}
        />
      )}
    </>
  )
  const GuestsAmountDefaultCell = (cellInfo) => (
    <BookableGuestsCell guests={cellInfo.row.original.guests} />
  )
  const GuestsAmountCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      <BookableGuestsCell guests={cellInfo.row.original.guests} />
    </>
  )
  const CreatedAtDefaultCell = (cellInfo) => (
    <DateTime
      data={cellInfo.row.original.createdAt}
      timeZone={cellInfo.row.original.bookable.timeZone}
    />
  )
  const CreatedAtCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      <DateTime
        data={cellInfo.row.original.createdAt}
        timeZone={cellInfo.row.original.bookable.timeZone}
      />
    </>
  )

  const getLinkToBooking = (cellInfo) => {
    const id =
      cellInfo.row.original.parentBookingId !== undefined
        ? cellInfo.row.original.parentBookingId
        : cellInfo.row.original.id

    return generateBookingPath({ id })
  }

  const getViewDetailsLabel = (cellInfo) => {
    return msg(
      { id: 'viewDetails' },
      {
        pending:
          cellInfo.row.original.status === 'pending' &&
          !authContext.hasSomeRole([
            'balaena-admin',
            'balaena-bookings-manager'
          ])
            ? 1
            : 0
      }
    )
  }

  const ViewDetailsDefaultCell = (cellInfo) => {
    return (
      <Link to={getLinkToBooking(cellInfo)}>
        {getViewDetailsLabel(cellInfo)}
      </Link>
    )
  }

  const ViewDetailsCardCell = (cellInfo) => {
    return (
      <Button
        className="ViewDetails"
        linkTo={getLinkToBooking(cellInfo)}
        variant="clear"
        size="m"
        text={getViewDetailsLabel(cellInfo)}
      />
    )
  }
  const LatestStateCardCell = (cellInfo) => (
    <>
      <CardCellTitle text={cellInfo.column.Header} />
      {cellInfo.row.original.states && (
        <span className={'latestState'}>
          {cellInfo.row.original.states.latest}
        </span>
      )}
    </>
  )
  const LatestStateDefaultCell = (cellInfo) => (
    <>
      {cellInfo.row.original.states && (
        <span className={'latestState'}>
          {cellInfo.row.original.states.latest}
        </span>
      )}
    </>
  )
  return [
    {
      id: 'bookingId',
      Header: msg({ id: 'bookingIdColumn' }),
      accessor: 'id',
      Cell: BookingIdNameDefaultCell,
      CardCell: BookingIdNameCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    },
    {
      id: 'bookerId',
      Header: msg({ id: 'booker' }),
      accessor: 'bookable.booker',
      Cell: BookerDefaultCell,
      CardCell: BookerCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    },
    {
      id: 'bookableId',
      Header: msg({ id: 'bookableName' }),
      accessor: 'bookable.name',
      Cell: BookableDefaultCell,
      CardCell: BookableCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    },
    {
      Header: msg({ id: 'bookableParent' }),
      accessor: 'bookable.parentBookable',
      Cell: BookableParentDefaultCell,
      CardCell: BookableParentCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    },
    {
      id: 'time',
      Header: msg({ id: 'bookingDateTime' }),
      accessor: 'dateTime',
      Cell: BookingTimeDefaultCell,
      CardCell: BookingTimeCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: false
    },
    {
      Header: msg({ id: 'paymentAmount' }),
      accessor: 'payment.amount',
      Cell: PaymentAmountDefaultCell,
      CardCell: PaymentAmountCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    },
    {
      id: 'status',
      Header: msg({ id: 'paymentStatus' }),
      accessor: 'payment.status',
      Cell: PaymentStatusDefaultCell,
      CardCell: PaymentStatusCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: false
    },
    {
      id: 'state',
      Header: msg({ id: 'bookingLastState' }),
      accessor: 'states.latest',
      Cell: LatestStateDefaultCell,
      CardCell: LatestStateCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: false
    },
    {
      Header: msg({ id: 'guests' }),
      accessor: 'guests',
      Cell: GuestsAmountDefaultCell,
      CardCell: GuestsAmountCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    },
    {
      Header: msg({ id: 'createdAt' }),
      accessor: 'createdAt',
      Cell: CreatedAtDefaultCell,
      CardCell: CreatedAtCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: false
    },
    {
      Header: '',
      accessor: 'detalle',
      Cell: ViewDetailsDefaultCell,
      CardCell: ViewDetailsCardCell,
      headerStyle: { whiteSpace: 'unset' },
      style: { whiteSpace: 'unset' },
      disableSortBy: true
    }
  ]
}

bookingColumns.displayName = 'bookingColumns'

export default bookingColumns
