import React from 'react'
import PropTypes from 'prop-types'

import Date from '../Date'

const DateTime = ({ data, timeZone, format }) => {
  return (
    <Date
      data={data}
      timeZone={timeZone}
      format={format || 'DD-MM-YYYY HH:mm'}
    />
  )
}

DateTime.propTypes = {
  data: Date.propTypes.data,
  timeZone: Date.propTypes.timeZone,
  format: PropTypes.string
}

DateTime.displayName = 'DateTime'

export default DateTime
