import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { TextField } from '@trileuco/triskel-react-ui/components/ui'
import {
  useForm,
  SubmitButton
} from '@trileuco/triskel-react-ui/components/ui/Form'
import { useNewComment } from 'components/api'
import _ from 'lodash'

const BookingCommentsForm = ({ refetch, bookingId, maxCommentLength }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingComments',
    ignoreGlobalScope: true
  })

  const { newComment } = useNewComment(bookingId)

  const defaultValues = useMemo(() => ({ text: '' }), [])

  const handleSubmit = useCallback(
    (text, instance) => {
      newComment(text).then(() => {
        instance.reset()
        refetch()
      })
    },
    [newComment, refetch]
  )

  const { Form, values = {} } = useForm({
    defaultValues,
    onSubmit: handleSubmit
  })

  const validateComment = useCallback(
    (instance) => {
      const { value } = instance
      if (!value || !value.trim()) {
        return msg({ id: 'validations.required' })
      } else if (value.length > maxCommentLength) {
        return msg({ id: 'validations.maxLength' }, { maxCommentLength })
      }
      return true
    },
    [msg, maxCommentLength]
  )

  return (
    <Form className="BookingCommentsForm">
      <TextField
        field="text"
        label={msg({ id: 'textareaLabel' })}
        multiLine
        variant="outline"
        rules={[validateComment]}
      />
      <SubmitButton
        text={msg({ id: 'add' })}
        disabled={_.isEmpty(values.text)}
      />
    </Form>
  )
}

BookingCommentsForm.propTypes = {
  bookingId: PropTypes.number,
  refetch: PropTypes.func,
  maxCommentLength: PropTypes.number
}

BookingCommentsForm.defaultProps = {
  maxCommentLength: 2000
}

BookingCommentsForm.displayName = 'BookingCommentsForm'

export default BookingCommentsForm
