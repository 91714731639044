import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Price from 'components/misc/Price'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import BookablePriceDescription from './BookablePriceDescription'

const BookableExtrasPrice = ({ extras, currency, bookingDuration }) => {
  const { classNames } = useClassNames({
    alias: 'BookablePriceExtras'
  })

  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  return (
    <div className={classNames()}>
      {extras.map((extra) => {
        let price = extra.amount > 0 ? extra.price * extra.amount : extra.price
        const extraPriceByDay = extra.type.includes('day')

        if (bookingDuration && extraPriceByDay) {
          price *= bookingDuration
        }

        return (
          <BookablePriceDescription
            key={extra.id}
            className={classNames('description')}
          >
            <span className={classNames('label')}>{extra.name}</span>
            <span className={classNames('amount')}>
              {msg(
                {
                  id: extraPriceByDay
                    ? 'extrasByPriceAmountByDay'
                    : 'extrasByPriceAmount'
                },
                {
                  amount: extra.amount,
                  price: <Price amount={price} currency={currency} />,
                  basePrice: <Price amount={extra.price} currency={currency} />,
                  byGuest: extra.type.includes('guest') ? 1 : 0
                }
              )}
            </span>
          </BookablePriceDescription>
        )
      })}
    </div>
  )
}

BookableExtrasPrice.propTypes = {
  extras: PropTypes.arrayOf(PropTypes.shape()),
  currency: PropTypes.string,
  bookingDuration: PropTypes.number
}

BookableExtrasPrice.displayName = 'BookableExtrasPrice'

export default BookableExtrasPrice
