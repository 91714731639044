import React from 'react'
import PropTypes from 'prop-types'
import Block from 'components/layout/Block'

const BookableTagsBlock = ({ tags }) => {
  return (
    <Block variant="clear" className="BookableTags">
      <ul>
        {tags.map((tag) => tag !== 'highlighted' && <li key={tag}>{tag}</li>)}
      </ul>
    </Block>
  )
}

BookableTagsBlock.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string)
}

BookableTagsBlock.displayName = 'BookableTagsBlock'

export default BookableTagsBlock
