import { useFetchCache } from 'components/api/ApiCacheContext'

const useGetProvider = ({ id, ...options }) => {
  const { [`providers#${id}`]: provider, ...rest } = useFetchCache({
    path: `/providers/${id}`,
    entity: `providers#${id}`,
    resolve: ({ provider }) => provider,
    ...options
  })
  return {
    ...rest,
    provider
  }
}

export default useGetProvider
