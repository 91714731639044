import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  SelectField,
  TextField,
  DateField,
  Form,
  FieldSet
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useGetBookableCodes, useGetBookers } from 'components/api'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import { withAuth } from '@trileuco/triskel-react-ui/modules/auth'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'
import { useTenantConfig } from 'modules/bookables/Provider'

const BookindDateTimeField = (props) => {
  return <DateField variant="outline" interval size="s" {...props} />
}

BookindDateTimeField.displayName = 'BookindDateTimeField'

const BookingStateField = (props) => {
  const {
    defaultStates: {
      bookables = [],
      accommodations = [],
      otherStates = []
    } = {}
  } = useTenantConfig()
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.bookingStatusColumnFilter',
    ignoreGlobalScope: true
  })
  const stateOptions = [
    ...new Set([...bookables, ...accommodations, ...otherStates])
  ].map((state) => ({
    value: state,
    label: state
  }))

  stateOptions.unshift({
    label: msg({ id: 'all' }),
    value: 'all'
  })
  return (
    <SelectField variant="outline" size="s" options={stateOptions} {...props} />
  )
}

BookingStateField.displayName = 'BookingStateField'

const BookingStatusField = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.bookingStatusColumnFilter',
    ignoreGlobalScope: true
  })

  const { payments: { adminPaymentMethods = [] } = {} } = useTenantConfig()

  const statusOptions = [
    {
      value: 'all',
      label: msg({ id: 'all' })
    },
    {
      value: 'pending',
      label: msg({ id: 'pending' })
    },
    {
      value: 'active',
      label: msg({ id: 'active' })
    },
    {
      value: 'canceled',
      label: msg({ id: 'canceled' })
    },
    {
      value: 'gateway',
      label: msg({ id: 'gateway' })
    },
    ...adminPaymentMethods.map((method) => {
      return { value: method, label: method }
    })
  ]
  return (
    <SelectField
      variant="outline"
      size="s"
      options={statusOptions}
      {...props}
    />
  )
}

BookingStatusField.displayName = 'BookingStatusField'

const BookersField = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.bookersColumnFilter',
    ignoreGlobalScope: true
  })
  const { loading, bookers } = useGetBookers({
    queryParams: {
      pageSize: 1000
    }
  })
  const bookersOptions = useMemo(() => {
    if (loading) {
      return []
    } else {
      return [
        {
          value: 'all',
          label: msg({ id: 'all' })
        },
        ...bookers.map(({ id, name }) => ({
          value: id,
          label: name
        }))
      ]
    }
  }, [bookers, loading, msg])
  return (
    <SelectField
      variant="outline"
      disabled={loading}
      loading={loading}
      options={bookersOptions}
      {...props}
    />
  )
}

BookersField.displayName = 'BookersField'

const BookablesField = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.bookablesColumnFilter',
    ignoreGlobalScope: true
  })
  const { loading, bookableCodes } = useGetBookableCodes()

  const bookingOptions = useMemo(() => {
    if (loading) {
      return []
    } else {
      return [
        {
          value: 'all',
          label: msg({ id: 'all' })
        },
        ...bookableCodes.map(({ id, name }) => ({
          value: id,
          label: name
        }))
      ]
    }
  }, [bookableCodes, loading, msg])
  return (
    <SelectField
      variant="outline"
      disabled={loading}
      loading={loading}
      options={bookingOptions}
      {...props}
    />
  )
}

BookablesField.displayName = 'BookablesField'

const BookingGuestField = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.guests',
    ignoreGlobalScope: true
  })
  return <TextField type="search" variant="outline" label={msg} {...props} />
}

BookingGuestField.displayName = 'BookingGuestField'

const BookingIdField = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable.id',
    ignoreGlobalScope: true
  })
  return <TextField type="search" variant="outline" label={msg} {...props} />
}

BookingGuestField.displayName = 'BookingIdField'

const BookingsTableFilter = ({ filter, onFilterChange, authContext }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable',
    ignoreGlobalScope: true
  })
  const debouncedSubmit = (value, instance) => {
    instance.debounce(instance.handleSubmit, 2000)
  }
  const isDesktop = useMediaQuery(DesktopBreakpoint)

  const defaultValues = useMemo(() => {
    const { createdAt, ...restValues } = filter
    return {
      ...(createdAt && {
        createdAt: { from: createdAt.createdFrom, to: createdAt.createdTo }
      }),
      ...restValues
    }
  }, [filter])

  return (
    <Form
      className="BookingsFilter"
      defaultValues={defaultValues}
      onSubmit={onFilterChange}
    >
      <FieldSet direction={isDesktop ? 'row' : 'column'}>
        <BookingIdField
          label={msg({ id: 'bookingIdLabel' })}
          onChange={debouncedSubmit}
          field={'id'}
        />
        <Visible
          condition={authContext.hasSomeRole([
            'balaena-admin',
            'balaena-provider'
          ])}
        >
          <BookersField
            label={msg({ id: 'booker' })}
            onChange={debouncedSubmit}
            field={'bookerId'}
          />
        </Visible>
        <BookablesField
          label={msg({ id: 'bookableName' })}
          onChange={debouncedSubmit}
          field={'bookableId'}
        />
        <BookindDateTimeField
          label={msg({ id: 'bookingDateTime' })}
          onChange={debouncedSubmit}
          autoComplete="off"
          field={'dateTime'}
        />
        <BookingStatusField
          label={msg({ id: 'paymentStatus' })}
          onChange={debouncedSubmit}
          field={'status'}
        />
        <BookingStateField
          label={msg({ id: 'bookingLastState' })}
          onChange={debouncedSubmit}
          field={'state'}
        />
        <BookingGuestField
          label={msg({ id: 'guests' })}
          onChange={debouncedSubmit}
          field={'guestFilter'}
        />
        <BookindDateTimeField
          label={msg({ id: 'bookingCreatedAt' })}
          onChange={debouncedSubmit}
          autoComplete="off"
          field={'createdAt'}
        />
      </FieldSet>
    </Form>
  )
}

BookingsTableFilter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string,
    bookerId: PropTypes.number,
    bookableId: PropTypes.string,
    status: PropTypes.string,
    to: PropTypes.string,
    from: PropTypes.string,
    guestFilter: PropTypes.string,
    createdAt: PropTypes.object
  }),
  onFilterChange: PropTypes.func,
  authContext: PropTypes.object
}

BookingsTableFilter.displayName = 'BookingsTableFilter'

export default withAuth(BookingsTableFilter)
