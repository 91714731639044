import { useGet } from 'components/api'

const useGetTermOfUse = ({ id, ...options }) => {
  const { data: termOfUse, ...rest } = useGet({
    path: `/terms-of-use/${id}`,
    resolve: ({ termOfUse }) => termOfUse,
    ...options
  })
  return {
    ...rest,
    termOfUse
  }
}

export default useGetTermOfUse
