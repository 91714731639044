import { useGet } from 'components/api'

export default ({ ids, from, to, ...moreParams }) => {
  const { data, ...rest } = useGet({
    path: `/bookables/availabilities`,
    queryParams: {
      from: from,
      to: to,
      ids: ids
    },
    ...moreParams
  })
  if (!data) {
    return []
  }
  return {
    ...data,
    ...rest
  }
}
