import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import _ from 'lodash'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import PhotoImg from 'components/misc/PhotoImg'
import { generateCategoryPath } from 'modules/bookables/domain/categories'
import SafeHtml from 'components/cms/SafeHtml'
import { useGetCategories } from 'components/api'

const CategoryNavMenuItem = ({
  category,
  depthLevel,
  maxDepth,
  className,
  showSubCategoriesPhoto,
  showMainCategoryDescription,
  onlyChildren
}) => {
  const { bookableCategories } = useGetCategories()
  const url = generateCategoryPath(bookableCategories, category.id)
  return (
    <li className={className}>
      {!onlyChildren && (
        <Link to={url}>
          {showSubCategoriesPhoto && (
            <Visible
              condition={!_.isEmpty(category.photos)}
              ifTrue={<PhotoImg photo={category.photos[0]} variant="card" />}
              otherwise={<img src={NoPhoto} alt="" />}
            />
          )}
          <div>
            <span>{category.name}</span>
            {showMainCategoryDescription && depthLevel === 1 && (
              <>
                {category.descriptions && category.descriptions.short && (
                  <SafeHtml html={category.descriptions.short} />
                )}
              </>
            )}
          </div>
        </Link>
      )}
      {depthLevel < maxDepth &&
        category.children &&
        category.children.length > 0 && (
          <ul>
            {category.children.map((category, index) => {
              return (
                <CategoryNavMenuItem
                  key={index}
                  category={category}
                  depthLevel={depthLevel + 1}
                  maxDepth={maxDepth}
                  showSubCategoriesPhoto
                />
              )
            })}
          </ul>
        )}
    </li>
  )
}

CategoryNavMenuItem.propTypes = {
  category: PropTypes.object,
  depthLevel: PropTypes.number,
  maxDepth: PropTypes.number,
  className: PropTypes.string,
  showSubCategoriesPhoto: PropTypes.bool,
  showMainCategoryDescription: PropTypes.bool,
  onlyChildren: PropTypes.bool
}

CategoryNavMenuItem.defaultProps = {
  showSubCategoriesPhoto: false,
  onlyChildren: false
}

CategoryNavMenuItem.displayName = 'CategoryNavMenuItem'

export default CategoryNavMenuItem
