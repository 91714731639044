import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const ExtrasSummary = ({ extras }) => {
  const { classNames } = useClassNames({
    alias: 'Extras'
  })
  return (
    <>
      {!_.isEmpty(extras)
        ? extras.map(({ id, name }) => {
            return (
              <div className={classNames()} key={id}>
                <p className={classNames('name')}>{name}</p>
              </div>
            )
          })
        : '-'}
    </>
  )
}

ExtrasSummary.propTypes = {
  extras: PropTypes.arrayOf(PropTypes.shape())
}

ExtrasSummary.displayName = 'ExtrasSummary'

export default ExtrasSummary
