import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth/useAuth'
import { useTenantConfig } from 'modules/bookables/Provider'
import * as queryString from 'query-string'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'

const BookingInvoice = (props) => {
  const { bookingId, variant } = props

  const { msg } = useIntl({
    scope: 'balaena.bookingActions',
    ignoreGlobalScope: true
  })

  const {
    api: { baseUrl }
  } = useTenantConfig()

  const {
    hasSomeRole,
    keycloak: { token: accessToken }
  } = useAuth()

  const reportParams = queryString.stringify({
    'access-token': accessToken
  })

  const pdfUrl = `${baseUrl}/bookings/${bookingId}.pdf?${reportParams}`

  return (
    <>
      <Button
        text={msg({ id: 'downloadInvoice' })}
        externalLinkTo={pdfUrl}
        variant={variant || 'clear'}
      />
      <Visible
        condition={hasSomeRole([
          'balaena-admin',
          'balaena-bookings-manager',
          'balaena-provider'
        ])}
      >
        <Button
          text={msg({ id: 'downloadInvoiceAsTicket' })}
          externalLinkTo={`${pdfUrl}&ticket=true`}
          variant="clear"
        />
      </Visible>
    </>
  )
}
BookingInvoice.propTypes = {
  bookingId: PropTypes.number,
  variant: PropTypes.string
}

BookingInvoice.displayName = 'BookingInvoice'

export default BookingInvoice
