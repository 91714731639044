import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { useErrorNotification } from 'modules/bookables/notifications'

const useBookingPaymentWithWalletCard = ({ bookingId, reservationPayment }) => {
  const { msg } = useIntl({
    scope: 'balaena.api.useBookingPaymentWithWalletCard',
    ignoreGlobalScope: true
  })
  const {
    notify,
    statusTypes: { LOADING, SUCCESS, DANGER }
  } = useNotifications()

  const { notifyError } = useErrorNotification()
  const getNotificationMessage = useCallback(
    (status) => {
      switch (status) {
        case LOADING:
          return msg({ id: 'loading' })
        case DANGER:
          return msg({ id: 'error' })
        case SUCCESS:
          return msg({ id: 'success' })
        default:
          return 'Unknown status: ' + status
      }
    },
    [msg, DANGER, SUCCESS, LOADING]
  )
  const getPaymentNotification = useCallback(
    (status) => ({
      id: `BOOKING_${bookingId}_PAYMENT_WALLET_CARD`,
      message: getNotificationMessage(status),
      status
    }),
    [bookingId, getNotificationMessage]
  )
  const { mutate, ...rest } = useMutate({
    verb: 'POST',
    path: `/bookings/${bookingId}/payment/wallet-card`,
    queryParams: {
      reservationPayment
    }
  })
  const mutateWithNotifications = useCallback(
    (params) => {
      notify(getPaymentNotification(LOADING))
      return mutate({ ...params })
        .then((response) => {
          notify(getPaymentNotification(SUCCESS))
          return response
        })
        .catch((error) => {
          notifyError({
            ...getPaymentNotification(DANGER),
            error
          })
          throw error
        })
    },
    [
      notify,
      notifyError,
      LOADING,
      SUCCESS,
      DANGER,
      mutate,
      getPaymentNotification
    ]
  )
  return { mutate: mutateWithNotifications, ...rest }
}

export default useBookingPaymentWithWalletCard
