import React from 'react'
import PropTypes from 'prop-types'

const BookerCell = ({ booker }) => <>{booker.name}</>

BookerCell.propTypes = {
  booker: PropTypes.object
}

BookerCell.displayName = 'BookerCell'

export default BookerCell
