import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Price from 'components/misc/Price'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import BookablePriceAmount from './BookablePriceAmount'
import BookablePriceDescription from './BookablePriceDescription'

const BookableBasePrice = ({ basePrice, currency, taxes, label }) => {
  const { classNames } = useClassNames({
    alias: 'BookablePriceTaxes'
  })
  return (
    !_.isEmpty(taxes) && (
      <div className={classNames()}>
        <BookablePriceDescription className={classNames('description')}>
          <span className={classNames('label')}>{label}</span>
          <BookablePriceAmount
            priceAmount={
              basePrice && <Price amount={basePrice} currency={currency} />
            }
            className={classNames('amount')}
          />
        </BookablePriceDescription>
        {taxes.map(({ id, amount, name }) => (
          <BookablePriceDescription
            key={id}
            className={classNames('description')}
          >
            <span className={classNames('label')}>{name}</span>
            <span className={classNames('amount')}>
              <Price amount={amount} currency={currency} />
            </span>
          </BookablePriceDescription>
        ))}
      </div>
    )
  )
}

BookableBasePrice.propTypes = {
  basePrice: PropTypes.number,
  label: PropTypes.string,
  currency: PropTypes.string,
  taxes: PropTypes.arrayOf(PropTypes.shape())
}

BookableBasePrice.displayName = 'BookableBasePrice'

export default BookableBasePrice
