import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Block from 'components/layout/Block'
import TagsList from 'modules/blog/components/TagsList'
import { useGetArticleTags } from 'components/api'

const TagsCloud = ({ title }) => {
  const { classNames } = useClassNames({ alias: 'TagsCloud' })
  const { tags } = useGetArticleTags()
  return (
    <Block variant="clear" title={title} className={classNames()}>
      <TagsList showLinkedArticlesNumber direction="column" tags={tags} />
    </Block>
  )
}

TagsCloud.propTypes = {
  title: PropTypes.string
}

TagsCloud.displayName = 'TagsCloud'

export default TagsCloud
