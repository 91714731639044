import React from 'react'
import BookablesSearch from 'components/bookable/BookablesSearch/BookablesSearch'
import { textOrNodePropType } from 'components/cms/cmsPropTypes'

const HighlightedBookablesSection = (props) => {
  return <BookablesSearch view="carousel" {...props} />
}

HighlightedBookablesSection.displayName = 'HighlightedBookablesSection'

HighlightedBookablesSection.propTypes = {
  title: textOrNodePropType
}
export default HighlightedBookablesSection
