import { generatePath } from 'react-router'
import ArchiveHomePage from 'modules/blog/pages/archive/ArchiveHomePage'
import ArchivePage from 'modules/blog/pages/archive/ArchivePage'
import ArticlePage from 'modules/blog/pages/article/ArticlePage'

export const archiveHomePath = '/blog'
const archivePath = '/blog/tagged/:tag'
const articlePath = '/blog/:id'

export const generateArticlePath = ({ slug }) =>
  generatePath(articlePath, {
    id: slug
  })

export const generateTaggedArticles = ({ tag }) =>
  generatePath(archivePath, {
    tag
  })

export default [
  {
    path: archiveHomePath,
    component: ArchiveHomePage,
    exact: true,
    menu: {
      label: 'ArchiveHomePage',
      path: archiveHomePath
    }
  },
  {
    path: archivePath,
    component: ArchivePage,
    exact: true,
    menu: {
      label: 'ArchivePage',
      path: archivePath
    }
  },
  {
    path: articlePath,
    component: ArticlePage,
    exact: true,
    menu: {
      label: 'ArticlePage',
      path: articlePath
    }
  }
]
