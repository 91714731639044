import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link, Button } from '@trileuco/triskel-react-ui/components/ui'
import { useAuth, withAuth } from '@trileuco/triskel-react-ui/modules/auth'
import {
  useIntl,
  MessageProvider
} from '@trileuco/triskel-react-ui/components/i18n'
import Page from 'components/layout/Page'
import {
  DesktopView,
  MobileView,
  DesktopBreakpoint
} from 'components/layout/Mediaqueries'
import { useMediaQuery } from 'react-responsive'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import Block from 'components/layout/Block'
import ToBookPicker from 'components/bookable/ToBookPicker'
import {
  generateBookableBookingPath,
  generateProviderPath,
  generateBookingsSearchPath
} from 'modules/bookables/routes'
import MetaTags from 'components/layout/MetaTags'
import {
  useGetBookable,
  useGetBookers,
  useGetBookings,
  LoadingResults
} from 'components/api'
import BookableProviderBadge from 'components/bookableProvider/BookableProviderBadge'
import BookableCategoryBreadcrumb from 'components/bookable/BookableCategoryBreadcrumb'
import NotFoundError from 'components/error/NotFoundError'
import PhotoGalleryBlock from 'components/misc/PhotoGalleryBlock/PhotoGalleryBlock'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'

import BookableMainDetails from './BookableMainDetails'
import BookableDetailsContent from './BookableDetailsContent'
import ToBookPickerModal from './ToBookPickerModal'
import BookableHeaderActions from './BookableHeaderActions'

const BookableDetailsPage = (props) => {
  const { history, match } = props
  const { msg } = useIntl({ scope: 'bookableDetailsPage' })

  const { userInfo = {} } = useAuth()
  const [bookingParams, setBookingParams] = useState({
    booker: null,
    bookable: null
  })
  const [notifyUser, setNotifyUser] = useState(true)
  const [pickedPriceType, setPickedPriceType] = useState(null)

  const { bookable, loading } = useGetBookable({
    bookableId: match.params.id
  })

  const {
    loading: loadingBooker,
    bookers: [booker]
  } = useGetBookers({
    queryParams: {
      includeBookerExtraInfo: false,
      bookerFilter: userInfo && userInfo.email
    }
  })

  const { loading: loadingBooking, bookings } = useGetBookings({
    lazy: !bookingParams.booker && !bookingParams.bookable,
    bookerId: bookingParams && bookingParams.booker,
    bookableId: bookingParams && bookingParams.bookable,
    status: 'pending'
  })

  const {
    notify,
    statusTypes: { HIDDEN, INFO }
  } = useNotifications()

  const pendingBookingsUrl = useMemo(() => {
    return generateBookingsSearchPath({
      searchParams: {
        bookerId: bookingParams && bookingParams.booker,
        bookableId: bookingParams && bookingParams.bookable,
        status: 'pending'
      }
    })
  }, [bookingParams])

  const bookingIsEnabled = useMemo(() => {
    if (bookable) {
      return !bookable.disableBooking || bookable.disableBooking !== true
    }
  }, [bookable])

  const closeNotification = useCallback(() => {
    notify({
      id: 'PENDING-BOOKINGS',
      status: HIDDEN
    })
  }, [notify, HIDDEN])

  const customNotificationMessage = useCallback(() => {
    return (
      <>
        <span className="PendingBookingsNotificationMessage">
          <span>{msg({ id: 'pendingBookings.message' })}</span>
          <Button
            linkTo={pendingBookingsUrl}
            text={msg({ id: 'pendingBookings.link' })}
            variant="clear"
            size="s"
          />
        </span>
      </>
    )
  }, [msg, pendingBookingsUrl])

  const showNotification = useCallback(() => {
    notify({
      id: 'PENDING-BOOKINGS',
      title: msg({ id: 'pendingBookings.title' }),
      message: customNotificationMessage(),
      status: INFO
    })
    setNotifyUser(false)
  }, [msg, notify, customNotificationMessage, INFO])

  const isDesktop = useMediaQuery(DesktopBreakpoint)

  useEffect(() => {
    if (bookable && isDesktop && pickedPriceType === null) {
      setPickedPriceType(bookable.priceTypes[0])
    }
    if (
      booker &&
      bookable &&
      bookingParams.booker !== booker.id &&
      bookingParams.bookable !== bookable.id
    ) {
      setBookingParams({ booker: booker.id, bookable: bookable.id })
    }
    if (!_.isEmpty(bookings) && notifyUser) {
      showNotification()
    }
  }, [
    booker,
    bookable,
    bookings,
    notify,
    notifyUser,
    msg,
    showNotification,
    pickedPriceType,
    bookingParams,
    isDesktop
  ])

  useEffect(() => {
    return () => {
      closeNotification()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitBook = ({ dateRange, dateTime, bookable, guests }) => {
    history.push(
      generateBookableBookingPath({
        id: bookable.slug,
        dateRange,
        dateTime,
        guests,
        pickedPriceType
      })
    )
  }

  if (loading || loadingBooker || loadingBooking) {
    return <LoadingResults />
  }
  if (!bookable) {
    throw new NotFoundError('Bookable not found for id ' + match.params.id)
  }
  return (
    <Page id="BookableDetailsPage" analytics={{ pagetype: 'BookableDetails' }}>
      <MessageProvider scope="bookableDetailsPage">
        <PublicPageSkeleton
          mainContent={
            <BoundedPageTemplate
              header={
                <>
                  {bookable.metaTags && (
                    <MetaTags metaTags={bookable.metaTags} />
                  )}
                  <BookableCategoryBreadcrumb lastIsLink />

                  <Link to={generateProviderPath(bookable.provider)}>
                    <BookableProviderBadge
                      size="s"
                      provider={bookable.provider}
                    />
                  </Link>

                  <div className="BookableName">
                    <h1>{bookable.name}</h1>
                    <BookableHeaderActions bookable={bookable} />
                  </div>
                  <BookableMainDetails bookable={bookable} />
                  {!_.isEmpty(bookable.photos) && (
                    <PhotoGalleryBlock photos={bookable.photos} />
                  )}
                </>
              }
              mainContent={<BookableDetailsContent bookable={bookable} />}
              sidebar={
                <Visible
                  condition={bookingIsEnabled}
                  ifTrue={
                    <>
                      <DesktopView>
                        <Block
                          title={msg({ id: 'bookNow' })}
                          className="ToBookPicker"
                        >
                          {pickedPriceType && (
                            <ToBookPicker
                              bookable={bookable}
                              onSubmit={handleSubmitBook}
                              pickedPriceType={pickedPriceType}
                              setPickedPriceType={setPickedPriceType}
                              priceTypes={bookable.priceTypes}
                            />
                          )}
                        </Block>
                      </DesktopView>
                      <MobileView>
                        <ToBookPickerModal
                          bookable={bookable}
                          onSubmit={handleSubmitBook}
                          pickedPriceType={pickedPriceType}
                          setPickedPriceType={setPickedPriceType}
                          priceTypes={bookable.priceTypes}
                        />
                      </MobileView>
                    </>
                  }
                  otherwise={null}
                />
              }
            />
          }
        />
      </MessageProvider>
    </Page>
  )
}

BookableDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object,
  authContext: PropTypes.object
}

BookableDetailsPage.displayName = 'BookableDetailsPage'

export default withAuth(BookableDetailsPage)
