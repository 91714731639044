import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, SelectField } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const BookablePriceTypeSelector = (props) => {
  const {
    priceTypes,
    pickedPriceType,
    onPriceSelected,
    handleSubmit,
    ...restProps
  } = props

  const { msg } = useIntl({
    scope: 'balaena.bookablePriceSelector',
    ignoreGlobalScope: true
  })

  const priceOptions = useMemo(() => {
    return [
      ...priceTypes.map((priceType) => ({
        value: priceType,
        label: priceType.price.description
      }))
    ]
  }, [priceTypes])

  // First price type selected by default
  useEffect(() => {
    if (pickedPriceType === null) {
      onPriceSelected(priceTypes[0])
    }
  }, [onPriceSelected, pickedPriceType, priceTypes])

  return (
    pickedPriceType && (
      <div className="BookablePriceTypeSelector">
        <SelectField
          label={msg({ id: 'label' })}
          variant="outline"
          options={priceOptions}
          onChange={onPriceSelected}
          {...restProps}
          value={pickedPriceType}
        />
        {handleSubmit && (
          <Button
            text={msg({ id: 'button' })}
            disabled={pickedPriceType === null}
            onClick={handleSubmit}
          />
        )}
      </div>
    )
  )
}

BookablePriceTypeSelector.propTypes = {
  priceTypes: PropTypes.array,
  pickedPriceType: PropTypes.object,
  handleSubmit: PropTypes.func,
  onPriceSelected: PropTypes.func
}

BookablePriceTypeSelector.displayName = 'BookablePriceTypeSelector'

export default BookablePriceTypeSelector
