import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Card, Link } from '@trileuco/triskel-react-ui/components/ui'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import useBookableAnalytics from 'modules/bookables/hooks/useBookableAnalytics'
import { useGenerateBookablePath } from 'modules/bookables/routes'
import BookableProviderBadge from 'components/bookableProvider/BookableProviderBadge'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import _ from 'lodash'
import Price from 'components/misc/Price'
import CountryRegionLabel from 'components/misc/CountryRegionLabel'
import Duration from 'components/misc/Duration'
import PhotoImg from 'components/misc/PhotoImg'
import { findCategory } from 'modules/bookables/domain/categories'
import { useGetCategories } from 'components/api'
import BookableCategoryBreadcrumb from 'components/bookable/BookableCategoryBreadcrumb'
import SafeHtml from 'components/cms/SafeHtml'
import { useTenantConfig } from 'modules/bookables/Provider'

import BookableNotPublishedBadge from '../BookableNotPublishedBadge'

const BookableCard = (props) => {
  const { bookable, onBookableClick, withBreadcrumb, ...other } = props

  const { classNames } = useClassNames({ alias: 'BookableCard' })

  const { msg } = useIntl({
    scope: 'balaena.bookableCard',
    ignoreGlobalScope: true
  })

  const {
    components: { renderBookablePriceFromLabel = true } = {}
  } = useTenantConfig()

  const bookableAnalytics = useBookableAnalytics()

  const trackBookableCard = useCallback(
    ({ label }) => {
      bookableAnalytics.impressionAction({
        bookable
      })
    },
    [bookable, bookableAnalytics]
  )

  const generateBookablePath = useGenerateBookablePath()
  const bookablePath = generateBookablePath(bookable)
  const maxGuests = _.get(bookable, 'price.guests.max')

  const { bookableCategories, loading: loadingCategories } = useGetCategories()
  const categorySlugs = useMemo(() => {
    if (loadingCategories) {
      return []
    } else {
      const category = findCategory(bookableCategories, bookable.category.id)
      return category.parents.concat(category.slug)
    }
  }, [loadingCategories, bookableCategories, bookable])

  const showBookablePriceFromLabel = useMemo(() => {
    return renderBookablePriceFromLabel || bookable.continuous
  }, [renderBookablePriceFromLabel, bookable.continuous])

  const bookableCardPrice = useMemo(() => {
    const cardPriceInfoPrice = _.get(bookable, 'cardPriceInfo.price')
    if (_.isUndefined(cardPriceInfoPrice)) {
      const bookablePriceAmount = _.get(bookable, 'price.amount')
      const maxGuests = _.get(bookable, 'price.guests.max')
      if (maxGuests > 1) {
        return bookablePriceAmount / maxGuests
      }
      return bookablePriceAmount
    }
    return cardPriceInfoPrice
  }, [bookable])

  const bookableCardDescription = useMemo(() => {
    const cardPriceInfoDescription = _.get(
      bookable,
      'cardPriceInfo.description'
    )
    if (_.isUndefined(cardPriceInfoDescription)) {
      const bookablePriceDescription = _.get(bookable, 'price.description')
      const maxGuests = _.get(bookable, 'price.guests.max')
      if (maxGuests > 1) {
        return msg({ id: 'byPerson' })
      }
      return bookablePriceDescription
    }
    return cardPriceInfoDescription
  }, [bookable, msg])

  return (
    <Card
      {...other}
      className={classNames()}
      header={
        <Link
          onClick={() => {
            trackBookableCard({
              label: 'Click on card header link'
            })
            if (onBookableClick) {
              onBookableClick(bookable)
            }
          }}
          to={bookablePath}
        >
          <Visible
            condition={!bookable.published}
            ifTrue={<BookableNotPublishedBadge />}
          />

          <Visible
            condition={!_.isEmpty(bookable.photos)}
            ifTrue={
              <PhotoImg
                photo={bookable.photos[0]}
                className={classNames('mainPhoto')}
                variant="card"
                alt={
                  _.get(bookable.photos[0], 'alt') === 'only_card'
                    ? bookable.name
                    : null
                }
              />
            }
            otherwise={
              <img className={classNames('mainPhoto')} src={NoPhoto} alt="" />
            }
          />

          <BookableProviderBadge
            size="s"
            theme="countertype"
            provider={bookable.provider}
            {...other}
          />
        </Link>
      }
      variant="fill"
      body={
        <>
          <Link
            onClick={() => {
              trackBookableCard({
                label: 'Click on Title Link'
              })
              if (onBookableClick) {
                onBookableClick(bookable)
              }
            }}
            to={bookablePath}
            className={classNames('bookableName')}
          >
            {bookable.name}
          </Link>
          <div className={classNames('locationAndDuration')}>
            {_.get(bookable, 'country') && (
              <span className={classNames('bookableLocation')}>
                <i className="fas fa-map-marker-alt" />
                <CountryRegionLabel
                  countryCode={bookable.country}
                  region={bookable.region}
                />
              </span>
            )}
            <Visible
              condition={_.isEmpty(bookable.duration) && bookable.duration > 0}
              ifTrue={
                <span className={classNames('bookableDuration')}>
                  <i className="far fa-clock"></i>
                  <Duration amount={bookable.duration} />
                </span>
              }
            />
          </div>
          <SafeHtml
            html={
              _.get(bookable, 'descriptions.textInfoItems') ||
              _.get(bookable, 'descriptions.short')
            }
            className={classNames('bookableShortDescription')}
          />
          {withBreadcrumb && (
            <BookableCategoryBreadcrumb
              categorySlugs={categorySlugs}
              noHome
              lastIsLink
            />
          )}
        </>
      }
      footer={
        <>
          {Boolean(bookableCardPrice) && (
            <Link
              to={bookablePath}
              className={classNames('bookablePrice')}
              onClick={() => {
                trackBookableCard({
                  label: `Click on "View Details" button`
                })
                if (onBookableClick) {
                  onBookableClick(bookable)
                }
              }}
            >
              <div>
                {showBookablePriceFromLabel && (
                  <span className={classNames('bookablePrice_from')}>
                    {msg({ id: 'from' })}
                  </span>
                )}
                <span className={classNames('bookablePrice_amount')}>
                  <Price
                    amount={bookableCardPrice}
                    currency={bookable.price.currency}
                  />
                </span>

                <span className={classNames('bookablePrice_description')}>
                  {bookableCardDescription}
                </span>

                {maxGuests > 1 && (
                  <span className={classNames('bookablePrice_total')}>
                    <Price
                      amount={bookableCardPrice}
                      currency={bookable.price.currency}
                    />{' '}
                    {bookableCardDescription}
                  </span>
                )}
              </div>
              <i className="fas fa-chevron-right" />
            </Link>
          )}
        </>
      }
    />
  )
}

BookableCard.propTypes = {
  bookable: PropTypes.shape(),
  onBookableClick: PropTypes.func,
  withBreadcrumb: PropTypes.bool
}

BookableCard.defaultProps = {
  onBookableClick: null,
  withBreadcrumb: true
}

BookableCard.displayName = 'BookableCard'

export default BookableCard
