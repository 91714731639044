import React from 'react'
import PropTypes from 'prop-types'

const BookablePriceDescription = ({
  children,
  className,
  isDiscount,
  ...other
}) => {
  return (
    <span
      className={`BookablePrice_description ${isDiscount ? 'isDiscount' : ''} ${
        className || ''
      }`.trim()}
      {...other}
    >
      {children}
    </span>
  )
}

BookablePriceDescription.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDiscount: PropTypes.bool
}

BookablePriceDescription.defaultProps = {
  className: ''
}

BookablePriceDescription.displayName = 'BookablePriceDescription'

export default BookablePriceDescription
