import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  FieldSet,
  FieldWrapper
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import NMIUtils from './nmi'

const NMIField = ({ id, error }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.NMIPayment',
    ignoreGlobalScope: true
  })

  const errorMessage = error && msg({ id: `${id}.invalid` })
  return (
    <FieldWrapper
      variant="outline"
      fieldType="Text"
      className="NMIField"
      label={msg({ id: `${id}.label` })}
      error={errorMessage}
    >
      <div className="NMIInput">
        <div id={id} />
      </div>
    </FieldWrapper>
  )
}

NMIField.propTypes = {
  id: PropTypes.string,
  error: PropTypes.node
}

const NMIPaymentGatewayFieldSet = ({ onChange, tokenizationKey }) => {
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [generatingToken, setGeneratingToken] = useState(false)
  const [ccnumberIsValid, setCcnumberIsValid] = useState(null)
  const [ccexpIsValid, setCcexpIsValid] = useState(null)
  const [cvvIsValid, setCvvIsValid] = useState(null)

  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.NMIPayment',
    ignoreGlobalScope: true
  })
  const setValidField = useCallback((field, valid) => {
    if (field === 'ccnumber') {
      setCcnumberIsValid(valid)
    } else if (field === 'ccexp') {
      setCcexpIsValid(valid)
    } else if (field === 'cvv') {
      setCvvIsValid(valid)
    }
  }, [])

  const allFieldsAreValid = useMemo(
    () => ccnumberIsValid && ccexpIsValid && cvvIsValid,
    [ccnumberIsValid, ccexpIsValid, cvvIsValid]
  )

  useEffect(() => {
    if (!loaded && !loading) {
      setLoading(true)
      NMIUtils.load({ tokenizationKey }).then(() => {
        setLoading(false)
        setLoaded(true)
        window.CollectJS.configure({
          googleFont: 'Roboto:400',
          variant: 'inline',
          fields: {
            ccnumber: {
              placeholder: msg({ id: 'ccnumber.placeholder' }),
              selector: '#ccnumber'
            },
            ccexp: {
              selector: '#ccexp',
              placeholder: msg({ id: 'ccexp.placeholder' })
            },
            cvv: {
              selector: '#cvv',
              placeholder: msg({ id: 'cvv.placeholder' })
            }
          },
          validationCallback: (field, status, message) => {
            setValidField(field, status)
          },
          callback: function (response) {
            setGeneratingToken(false)
            onChange(response)
          }
        })
      })
    }
  }, [onChange, setValidField, tokenizationKey, loaded, loading, msg])

  const startPaymentRequest = () => {
    if (allFieldsAreValid) {
      setGeneratingToken(true)
    }
    window.CollectJS.startPaymentRequest()
  }

  const { classNames } = useClassNames({
    alias: 'AddCardModal'
  })

  return (
    <>
      {loading && <>{msg({ id: 'connecting' })}</>}
      {loaded && (
        <FieldSet direction="column" className={classNames('fields')}>
          <NMIField id="ccnumber" error={ccnumberIsValid === false} />
          <FieldSet direction="row">
            <NMIField id="ccexp" error={ccexpIsValid === false} />
            <NMIField id="cvv" error={cvvIsValid === false} />
          </FieldSet>
          <Button
            disabled={generatingToken}
            text={msg({ id: 'addCard' })}
            onClick={startPaymentRequest}
          />
        </FieldSet>
      )}
    </>
  )
}

NMIPaymentGatewayFieldSet.propTypes = {
  onChange: PropTypes.func,
  tokenizationKey: PropTypes.string
}

NMIPaymentGatewayFieldSet.displayName = 'NMIPaymentGatewayFieldSet'

export default NMIPaymentGatewayFieldSet
