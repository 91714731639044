import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  SelectField,
  FieldSet,
  CounterFieldSet,
  PopOver,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import { useField } from '@trileuco/triskel-react-ui/components/ui/Form'

const guestTypeConsumesAvailability = (guestType) =>
  guestType.consumesAvailability

const guestTypeDoesntConsumesAvailability = (guestType) =>
  !guestType.consumesAvailability

const GuestCounter = ({
  counter,
  onGuestCounterChange,
  guestType,
  ...restProps
}) => {
  const handleGuestCounterChange = (amount) => {
    onGuestCounterChange({ guestType, amount })
  }
  return (
    <CounterFieldSet
      label={guestType.labels.plural}
      value={counter}
      onChange={handleGuestCounterChange}
      {...restProps}
    />
  )
}

GuestCounter.propTypes = {
  guestType: PropTypes.object,
  counter: PropTypes.number,
  onGuestCounterChange: PropTypes.func
}

const GuestCounterInfo = ({ pickedPriceType, msg }) => {
  const infos = []
  infos.push(
    msg(
      {
        id: 'minBooking'
      },
      {
        minGuestsPerBooking:
          pickedPriceType.price.guests.min !== pickedPriceType.price.guests.max
            ? pickedPriceType.price.guests.min
            : 1,
        bookableLabel:
          pickedPriceType.price.labels[
            pickedPriceType.price.guests.min > 1 ? 'plural' : 'singular'
          ]
      }
    )
  )

  if (pickedPriceType.price.guests.min > 0) {
    const guestTypesThatConsumesAvailability = pickedPriceType.guestTypes.filter(
      guestTypeConsumesAvailability
    )
    infos.push(
      msg(
        { id: 'selectionInfo' },
        {
          minGuestsPerBooking: pickedPriceType.price.guests.min,
          missingGuests: guestTypesThatConsumesAvailability
            .map(
              (guestType) =>
                guestType.labels[
                  pickedPriceType.price.guests.min > 1 ? 'plural' : 'singular'
                ]
            )
            .join(msg({ id: 'or' }))
        }
      )
    )
  }

  const guestTypesThatNotConsumesAvailability = pickedPriceType.guestTypes.filter(
    guestTypeDoesntConsumesAvailability
  )
  guestTypesThatNotConsumesAvailability.forEach((guestType) => {
    infos.push(
      msg(
        { id: 'consumesAvailabilityInfo' },
        {
          guestType: guestType.labels.plural,
          maxGuestsByType: guestType.maxGuests,
          bookableLabel: pickedPriceType.price.labels.singular
        }
      )
    )
  })

  return infos.length > 0 ? (
    <ul className="GuestCounterInfo">
      {infos.map((info, i) => (
        <li key={i}>{info}</li>
      ))}
    </ul>
  ) : null
}

GuestCounterInfo.propTypes = {
  bookable: PropTypes.shape(),
  pickedPriceType: PropTypes.object,
  msg: PropTypes.func
}

const GuestCounterErrors = ({ errors }) => {
  return errors.length > 0 ? (
    <ul className="GuestCounterErrors">
      {errors.map((error, i) => (
        <li key={i}>{error}</li>
      ))}
    </ul>
  ) : null
}

GuestCounterErrors.propTypes = {
  errors: PropTypes.array,
  msg: PropTypes.func
}

const GuestsSelector = ({
  field,
  bookable,
  disabled,
  required,
  maxRemainingCapacity,
  showInline,
  onChange,
  pickedPriceType
}) => {
  const popperRef = useRef()

  const { msg } = useIntl({
    scope: 'balaena.guestsSelector',
    ignoreGlobalScope: true
  })

  const validateGuests = useCallback(
    async (value) => {
      const { amount, guestTypes } = value
      if (amount === 0 && required) {
        return 'Guests required'
      }
      if (pickedPriceType.price.guests.min > 0) {
        const guestTypesThatConsumesAvailability = guestTypes.filter(
          ({ guestType }) => guestTypeConsumesAvailability(guestType)
        )

        const guestTypesThatConsumesAvailabilityAmount = guestTypesThatConsumesAvailability.reduce(
          (
            guestTypesThatConsumesAvailabilityAmount,
            { amount: guestTypeAmount }
          ) => guestTypesThatConsumesAvailabilityAmount + guestTypeAmount,
          0
        )
        if (
          guestTypesThatConsumesAvailabilityAmount <
          pickedPriceType.price.guests.min
        ) {
          return msg(
            { id: 'selectionInfo' },
            {
              minGuestsPerBooking: pickedPriceType.price.guests.min,
              missingGuests: guestTypesThatConsumesAvailability
                .map(
                  ({ guestType }) =>
                    guestType.labels[
                      pickedPriceType.price.guests.min > 1
                        ? 'plural'
                        : 'singular'
                    ]
                )
                .join(msg({ id: 'or' }))
            }
          )
        }
      }
      return (
        guestTypes
          .map(({ guestType, amount: guestTypeAmount }) => {
            if (guestTypeAmount > 0 && guestType.maxGuests) {
              if (guestTypeAmount > guestType.maxGuests * amount) {
                return msg(
                  { id: 'maxGuestsToIncludeError' },
                  {
                    maxGuestsByType: guestType.maxGuests,
                    guestTypeLabel:
                      guestType.labels[
                        guestType.maxGuests > 1 ? 'plural' : 'singular'
                      ],
                    bookableLabel: pickedPriceType.price.labels.singular
                  }
                )
              }
            }
            if (guestType.minGuests) {
              if (guestTypeAmount < guestType.minGuests * amount) {
                return msg(
                  { id: 'minGuestsToIncludeError' },
                  {
                    minGuestsByType: guestType.minGuests,
                    guestTypeLabel:
                      guestType.labels[
                        guestType.minGuests > 1 ? 'plural' : 'singular'
                      ],
                    bookableLabel: pickedPriceType.price.labels.singular
                  }
                )
              }
            }
            return false
          })
          .find((guestTypeError) => Boolean(guestTypeError)) || false
      )
    },
    [
      msg,
      pickedPriceType.price.guests.min,
      pickedPriceType.price.labels.singular,
      required
    ]
  )
  const {
    value,
    setValue,
    meta: { error }
  } = useField(field, {
    validate: validateGuests
  })

  const guestsState = useMemo(
    () => ({
      maxGuests: maxRemainingCapacity * pickedPriceType.price.guests.max,
      currentGuests: value.guestTypes.reduce((sum, { guestType, amount }) => {
        return guestType.consumesAvailability ? sum + amount : sum
      }, 0)
    }),
    [maxRemainingCapacity, pickedPriceType.price.guests.max, value.guestTypes]
  )

  const multipleGuestTypes =
    pickedPriceType.guestTypes && pickedPriceType.guestTypes.length >= 1

  const showMinMaxGuests =
    pickedPriceType.price.guests.min !== pickedPriceType.price.guests.max

  // Commented as it may be interesting to recover this min guests calculation
  // If users change their mind about how the counter should work
  // const calculateMinGuestsByType = useCallback(
  //   (guestType) => {
  //     if (!showMinMaxGuests || value.amount === 0) return 0
  //     const currentType = value.guestTypes.find(
  //       ({ guestType: type }) => type.guestTypeId === guestType.guestTypeId
  //     )
  //     const minGuestsReached =
  //       bookable.price.guests.min * value.amount === guestsState.currentGuests
  //     if (minGuestsReached) {
  //       return currentType.amount
  //     }
  //     return 0
  //   },
  //   [
  //     bookable.price.guests.min,
  //     guestsState.currentGuests,
  //     showMinMaxGuests,
  //     value.amount,
  //     value.guestTypes
  //   ]
  // )

  const calculateMaxGuestsByType = useCallback(
    (guestType) => {
      if (guestsState.maxGuests === 0) return 0
      const currentType = value.guestTypes.find(
        ({ guestType: type }) => type.guestTypeId === guestType.guestTypeId
      )

      const maxGuestsMultiplier =
        pickedPriceType.price.guests.min * Math.max(value.amount, 1)
      if (
        !currentType.guestType.consumesAvailability &&
        currentType.guestType.maxGuests
      ) {
        return currentType.guestType.maxGuests * maxGuestsMultiplier
      } else if (!currentType.guestType.consumesAvailability) {
        return 10 * maxGuestsMultiplier
      }

      const maxGuestsReached =
        guestsState.maxGuests === guestsState.currentGuests
      if (currentType.guestType.maxGuests) {
        return maxGuestsReached
          ? currentType.amount
          : currentType.guestType.maxGuests * maxGuestsMultiplier
      }
      return maxGuestsReached ? currentType.amount : guestsState.maxGuests
    },
    [
      guestsState.currentGuests,
      guestsState.maxGuests,
      pickedPriceType.price.guests.min,
      value.amount,
      value.guestTypes
    ]
  )

  const resetGuestCounter = useCallback(() => {
    const newGuestTypes = value.guestTypes.map(({ guestType, amount }) => ({
      guestType,
      amount: 0
    }))

    setValue({
      ...value,
      amount: 0,
      guestTypes: newGuestTypes
    })
  }, [value, setValue])

  const handleGuestCounterChange = useCallback(
    ({ guestType: updatedGuestType, amount: updatedGuestAmount }) => {
      let guestConsumesAvailabilityAmount = 0
      const newGuestTypes = value.guestTypes.map(({ guestType, amount }) => {
        const guestAmount =
          guestType.guestTypeId === updatedGuestType.guestTypeId
            ? updatedGuestAmount
            : amount

        if (guestType.consumesAvailability) {
          guestConsumesAvailabilityAmount += guestAmount
        }
        return {
          guestType,
          amount: guestAmount
        }
      })
      const bookingsAmount = Math.ceil(
        guestConsumesAvailabilityAmount / pickedPriceType.price.guests.max
      )
      setValue({
        ...value,
        amount: bookingsAmount,
        guestTypes: newGuestTypes
      })
    },
    [value, pickedPriceType.price.guests.max, setValue]
  )

  const guestsAmountToText = useCallback(() => {
    const guestsToText = value.guestTypes
      .filter(({ amount, guestType }) => amount > 0 && guestType)
      .map(
        (guestType) =>
          guestType.amount +
          ' ' +
          (guestType.amount > 1
            ? guestType.guestType.labels.plural
            : guestType.guestType.labels.singular)
      )
      .join(', ')

    const bookableLabel =
      value.amount > 1
        ? pickedPriceType.price.labels.plural
        : pickedPriceType.price.labels.singular
    return value.amount > 0
      ? `${value.amount} ${bookableLabel}: ${guestsToText}`
      : msg({ id: 'selectText' })
  }, [
    value.guestTypes,
    value.amount,
    pickedPriceType.price.labels.plural,
    pickedPriceType.price.labels.singular,
    msg
  ])

  const closePopOver = () => {
    if (!showInline) {
      popperRef.current.setVisible(false)
    }
  }

  const multipleGuestTypeMode = multipleGuestTypes || showMinMaxGuests

  const popperContent = (
    <div>
      {multipleGuestTypeMode &&
        value.guestTypes.map(({ guestType, amount }, index) => {
          return (
            <GuestCounter
              key={index}
              guestType={guestType}
              counter={amount}
              disabled={disabled}
              onGuestCounterChange={handleGuestCounterChange}
              min={0}
              max={calculateMaxGuestsByType(guestType)}
            />
          )
        })}
      <GuestCounterInfo
        pickedPriceType={pickedPriceType}
        bookable={bookable}
        msg={msg}
      />
      <GuestCounterErrors errors={[error]} />
      {!showInline && (
        <Button
          size="l"
          text={msg({ id: 'addButtonText' })}
          color="primary"
          onClick={closePopOver}
          disabled={Boolean(error)}
        />
      )}
    </div>
  )

  const handleClosePopOver = useCallback(() => {
    onChange()
    if (error) {
      resetGuestCounter()
    }
  }, [error, resetGuestCounter, onChange])

  if (showInline) {
    return (
      <FieldSet direction="column">
        <div className="GuestsSelector">{popperContent}</div>
      </FieldSet>
    )
  }
  return (
    <FieldSet direction="column">
      <PopOver
        content={popperContent}
        triggerEvent="click"
        placement="bottom"
        positionFixed
        className="GuestsSelector"
        onClose={handleClosePopOver}
        ref={popperRef}
        TriggerTag={'div'}
      >
        <SelectField
          variant="outline"
          label={msg(
            { id: 'numberOfBookables' },
            { bookableLabel: pickedPriceType.price.labels.plural }
          )}
          value={guestsAmountToText()}
          disabled={true}
        />
      </PopOver>
    </FieldSet>
  )
}

GuestsSelector.propTypes = {
  bookable: PropTypes.shape(),
  maxRemainingCapacity: PropTypes.number,
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showInline: PropTypes.bool,
  onChange: PropTypes.func,
  pickedPriceType: PropTypes.object
}

GuestsSelector.displayName = 'GuestsSelector'

export default GuestsSelector
