import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useFormContext } from 'react-form'
import moment from 'moment'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import {
  FieldSet,
  TextField,
  SelectField,
  DateField,
  LanguageSelect,
  CountryRegionFieldSet
} from '@trileuco/triskel-react-ui/components/ui'
import Block from 'components/layout/Block'
import BookingGuestsSummary from 'components/booker/BookingForm/BookingGuestsSummary'
import BookingGuestsSelectModal from 'components/booker/BookingForm/BookingGuestsSelectModal'
import BookingPhoneField from 'components/booker/BookingForm/BookingPhoneField'
import { useGetBookerGuests } from 'components/api'
import BookingExtrasFieldSet from 'components/booker/BookingForm/BookingExtrasFieldSet'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth/useAuth'
import { useHistory } from '@trileuco/triskel-react-ui/components/router'

const guestToGuestField = ({
  guestTypeId,
  extras,
  firstName = '',
  lastName = '',
  birthdate = undefined,
  country = null,
  gender = null,
  region = null,
  phone = '',
  personalId = '',
  address = '',
  email = '',
  language = null
}) => ({
  guestTypeId,
  extras,
  firstName,
  lastName,
  birthdate: birthdate && new Date(birthdate),
  phone,
  email,
  personalId,
  gender,
  countryAndRegion: {
    country,
    region
  },
  address,
  language
})

const mapBookerGuestToOption = ({ id, firstName, lastName, personalId }) => ({
  value: id,
  label: `${firstName} ${lastName}${
    _.isUndefined(personalId) ? '' : ' - ' + personalId
  }`
})

const BookingGuestsFieldsets = ({
  onlyMainGuestInfo,
  requiredFields,
  guests,
  bookingDate,
  extrasByGuest,
  phoneInputError,
  currency
}) => {
  const [importedGuests, setImportedGuests] = useState({})
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })

  const history = useHistory()
  const inGuestsPage = history.location.pathname.includes('guests')

  const genderOptions = [
    { label: msg({ id: 'gender.male' }), value: 'male' },
    { label: msg({ id: 'gender.female' }), value: 'female' }
  ]

  const isMandatoryField = useCallback(
    (field) => requiredFields.includes(field),
    [requiredFields]
  )

  const handleBirthdateValidation = useCallback(
    ({ value }, guestType) => {
      if (value) {
        const mbirthdate = moment(value)
        const ageOnBookingDate = moment(bookingDate).diff(mbirthdate, 'years')
        if (guestType.minAge && ageOnBookingDate < guestType.minAge) {
          return msg(
            { id: 'birthdate.errors.lowerThanMinAge' },
            {
              guest: guestType.labels.singular.toLowerCase(),
              age: ageOnBookingDate,
              minAge: guestType.minAge
            }
          )
        }
        if (guestType.maxAge && ageOnBookingDate > guestType.maxAge) {
          return msg(
            { id: 'birthdate.errors.greaterThanMaxAge' },
            {
              guest: guestType.labels.singular.toLowerCase(),
              age: ageOnBookingDate,
              maxAge: guestType.maxAge
            }
          )
        }
      }
      return true
    },
    [bookingDate, msg]
  )
  const {
    values,
    setFieldValue,
    meta: { isSubmitted }
  } = useFormContext()

  const { authenticated } = useAuth()
  const { bookerGuests, loading } = useGetBookerGuests({ lazy: !authenticated })

  const {
    notify,
    statusTypes: { SUCCESS }
  } = useNotifications()

  const handleGuestChange = useCallback(
    (index, guestId) => {
      setImportedGuests({ ...importedGuests, [index]: guestId })
      if (guestId) {
        const guest = bookerGuests.find(({ id }) => id === guestId)
        notify({
          id: 'GUEST_ADDED',
          status: SUCCESS,
          title: `${guest.firstName} ${guest.lastName} añadido`
        })
        return setFieldValue(`guests.${index}`, (prevValue) => {
          return guestToGuestField({ ...prevValue, ...guest })
        })
      } else {
        const extras = extrasByGuest.map(({ id, name }) => ({
          extraId: id,
          answer: undefined,
          name: name
        }))
        return setFieldValue(`guests.${index}`, ({ guestTypeId }) => {
          return guestToGuestField({
            guestTypeId,
            extras
          })
        })
      }
    },
    [
      setFieldValue,
      bookerGuests,
      importedGuests,
      extrasByGuest,
      notify,
      SUCCESS
    ]
  )

  const summaryGuests = useMemo(() => {
    const guestsToSummary = onlyMainGuestInfo
      ? [values.guests[0]]
      : values.guests
    return guestsToSummary.map((guest) => {
      return {
        ...guest,
        type: guests.find(
          (guestType) => guestType.guestTypeId === guest.guestTypeId
        ),
        extraOptions: _.get(guest, 'extras', [])
          .filter((extra) => extra.answer === true)
          .map((extra) => ({
            id: extra.extraId,
            name: extra.name
          }))
      }
    })
  }, [guests, onlyMainGuestInfo, values.guests])

  const guestsToFill = useMemo(() => {
    return onlyMainGuestInfo ? [guests[0]] : guests
  }, [guests, onlyMainGuestInfo])

  const priorityCountries = useMemo(() => {
    return ['PA', 'US', 'ES', 'MX', 'CR', 'CO']
  }, [])

  const filterUsedBookerGuest = useCallback(
    ({ id }) => Object.values(importedGuests).indexOf(id) === -1,
    [importedGuests]
  )

  const isAlwaysMandatoryField = useCallback(
    (field) => field === 'firstName' || field === 'lastName',
    []
  )

  const getRulesFromField = useCallback(
    (field, guestIndex) => {
      if (isMandatoryField(field)) {
        return ['required']
      }
      return isAlwaysMandatoryField(field) ? ['required'] : []
    },
    [isMandatoryField, isAlwaysMandatoryField]
  )

  const fieldClassNames = useCallback(
    (field, index) => {
      if (getRulesFromField(field, index).includes('required')) {
        return 'GuestField___mandatoryField'
      }
      return 'GuestField'
    },
    [getRulesFromField]
  )

  const getGuestFieldProps = useCallback(
    (fieldName, index, rules = []) => ({
      variant: 'outline',
      label: msg({ id: fieldName }),
      field: fieldName,
      className: fieldClassNames(fieldName, index),
      rules: getRulesFromField(fieldName, index).concat(rules)
    }),
    [msg, fieldClassNames, getRulesFromField]
  )

  const MainGuestFieldset = (
    guestType,
    index,
    maxOrMinAgeRestrictions,
    mainGuest
  ) => {
    return (
      <FieldSet key={index} fieldset={`${index}`} direction="column">
        {!inGuestsPage && (
          <TextField
            value={guestType.guestTypeId}
            field="guestTypeId"
            className="Field___hidden"
          />
        )}
        <TextField {...getGuestFieldProps('firstName', index)} />
        <TextField {...getGuestFieldProps('lastName', index)} />
        <TextField {...getGuestFieldProps('email', index, ['email'])} />
        <DateField
          showDateSelectors
          autoComplete={'off'}
          fromMonth={new Date(1900, 0, 0)}
          toMonth={new Date()}
          disabledDays={(day) => moment().diff(day) < 0}
          {...getGuestFieldProps(
            'birthdate',
            index,
            maxOrMinAgeRestrictions
              ? [
                  ...(getRulesFromField('birthdate', index).includes(
                    requiredFields
                  )
                    ? ['required']
                    : []),
                  (value) => handleBirthdateValidation(value, guestType, index)
                ]
              : []
          )}
        />
        <SelectField
          options={genderOptions}
          {...getGuestFieldProps('gender', index)}
        />
        <TextField {...getGuestFieldProps('personalId', index)} />

        <BookingPhoneField
          key={index}
          field="phone"
          value={values.guests[index].phone}
          onChange={(value) => {
            setFieldValue(`guests.${index}.phone`, `+${value}`)
          }}
          placeholder={msg({ id: 'phone' })}
          preferredCountries={priorityCountries.map((country) =>
            _.lowerCase(country)
          )}
          phoneInputError={phoneInputError}
        />

        <CountryRegionFieldSet
          countryDropdownOptions={{
            priorityOptions: priorityCountries,
            className: fieldClassNames('countryAndRegion', index)
          }}
          regionDropdownOptions={{
            className: fieldClassNames('countryAndRegion', index)
          }}
          required={mainGuest && isMandatoryField('countryAndRegion')}
          {...getGuestFieldProps('countryAndRegion', index)}
        />
        <TextField {...getGuestFieldProps('address', index)} />
        <LanguageSelect {...getGuestFieldProps('language', index)} />
        {!_.isEmpty(extrasByGuest) && !inGuestsPage && (
          <BookingExtrasFieldSet
            fieldset="extras"
            extras={extrasByGuest}
            label={msg({ id: 'extras' })}
            currency={currency}
          />
        )}
      </FieldSet>
    )
  }

  if (isSubmitted && !inGuestsPage) {
    return <BookingGuestsSummary guests={summaryGuests} />
  } else {
    return (
      <FieldSet fieldset="guests" direction="column">
        {guestsToFill.map((guestType, index) => {
          const maxOrMinAgeRestrictions = guestType.minAge || guestType.maxAge
          const mainGuest = index === 0
          const showMainGuestBlockHelperText =
            onlyMainGuestInfo && index === 0 && guests.length > 1
          return inGuestsPage ? (
            MainGuestFieldset(
              guestType,
              index,
              maxOrMinAgeRestrictions,
              mainGuest
            )
          ) : (
            <Block
              key={index}
              title={
                <>
                  {msg(
                    {
                      id: 'guestBlockTitle'
                    },
                    {
                      guestLabel: guestType.labels.singular.toLowerCase(),
                      guestCounter: index + 1
                    }
                  )}
                </>
              }
              className={
                index === 0
                  ? 'BookableGuestFormBlockMainGuest'
                  : 'BookableGuestFormBlock'
              }
            >
              {showMainGuestBlockHelperText && (
                <span className="Field_helperText">
                  {msg({ id: 'mainGuestBlockHelperText' })}
                </span>
              )}
              <BookingGuestsSelectModal
                value={importedGuests[index]}
                options={bookerGuests
                  .filter(
                    (bookerGuest) =>
                      filterUsedBookerGuest(bookerGuest) &&
                      guestType.guestTypeId === bookerGuest.type.id
                  )
                  .map(mapBookerGuestToOption)}
                disabled={loading}
                onChange={(guest) => handleGuestChange(index, guest)}
              />
              {MainGuestFieldset(
                guestType,
                index,
                maxOrMinAgeRestrictions,
                mainGuest
              )}
            </Block>
          )
        })}
      </FieldSet>
    )
  }
}

BookingGuestsFieldsets.propTypes = {
  bookingDate: PropTypes.string,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      guestType: PropTypes.shape(),
      amount: PropTypes.number
    })
  ),
  extrasByGuest: PropTypes.arrayOf(PropTypes.shape()),
  currency: PropTypes.string,
  onlyMainGuestInfo: PropTypes.bool
}

BookingGuestsFieldsets.displayName = 'BookingGuestsFieldsets'

export default BookingGuestsFieldsets
