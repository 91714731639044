import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@trileuco/triskel-react-ui/components/ui/Button'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import FieldWrapper from '@trileuco/triskel-react-ui/components/ui/FieldWrapper'

import redsysUtils from './redsys'

const RedSysField = ({ id }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.NMIPayment',
    ignoreGlobalScope: true
  })
  return (
    <FieldWrapper
      variant="outline"
      fieldType="Text"
      className="RedSysField"
      label={msg({ id })}
    >
      <div className="RedSysInput">
        <div id={id} />
      </div>
    </FieldWrapper>
  )
}

RedSysField.propTypes = {
  id: PropTypes.string
}

const RedSysPaymentGatewayFieldSet = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    if (!loading) {
      redsysUtils.load().then(() => {
        setLoading(false)
        window.getCardInput(
          'card-number',
          `
          border: none;
        `
        )
        window.getExpirationMonthInput('expiration-month', {})
        window.getExpirationYearInput('expiration-year', {})
        window.getCVVInput('cvv', {})
        window.getPayButton(
          'boton',
          {},
          'Texto botón pago',
          '999008881',
          '1',
          'sq7HjrUOBfKmC576ILgskD5srU870gJ7'
        )
      })
    }
  }, [loading])
  return (
    <div style={{ minWidth: '100%' }}>
      <RedSysField id="card-number" />
      <RedSysField id="expiration-month" />
      <RedSysField id="expiration-year" />
      <RedSysField id="cvv" />
      <Button id="boton">Pagar</Button>
      <input type="hidden" id="token"></input>
      <input type="hidden" id="errorCode"></input>
    </div>
  )
}

RedSysPaymentGatewayFieldSet.propTypes = {}

RedSysPaymentGatewayFieldSet.displayName = 'RedSysPaymentGatewayFieldSet'

export default RedSysPaymentGatewayFieldSet
