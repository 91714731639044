import { useCallback } from 'react'
import queryString from 'query-string'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useTenantConfig } from 'modules/bookables/Provider'

const useFetch = () => {
  const {
    api: { baseUrl: apiBaseUrl },
    intl: { locale }
  } = useTenantConfig()
  const {
    keycloak: { token: accessToken }
  } = useAuth()
  return useCallback(
    (url, options = {}) =>
      fetch(
        `${apiBaseUrl}${url}${
          options.queryParams
            ? `?${queryString.stringify(options.queryParams, {
                arrayFormat: 'comma'
              })}`
            : ''
        }`,
        {
          ...options,
          headers: {
            ...(options.header ? options.header : {}),
            ...(locale ? { 'accept-language': locale } : {}),
            ...(accessToken ? { 'access-token': accessToken } : {})
          }
        }
      ).then((response) => response.json()),
    [accessToken, apiBaseUrl, locale]
  )
}

export default useFetch
