import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { generateArticlePath } from 'modules/blog/routes'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import DateTime from 'components/misc/DateTime'
import PhotoImg from 'components/misc/PhotoImg'
import Duration from 'components/misc/Duration'
import moment from 'moment-timezone'
import { useTenantConfig } from 'modules/bookables/Provider'

const ArticleExcerpt = ({ view, article }) => {
  const { classNames } = useClassNames({ alias: 'ArticleExcerpt' })
  const isDefaultView = view === 'default'
  const isMiniView = view === 'mini'
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'blog.articles.ArticleExcerpt'
  })
  const {
    intl: { timeZone }
  } = useTenantConfig()

  const currentYear = moment().year()
  const articleYear = moment(article.publishedAt).year()

  return (
    <Link
      to={generateArticlePath({ slug: article.slug })}
      className={classNames([{ view }])}
    >
      <div className={classNames('inner')}>
        <PhotoImg
          photo={_.get(article, 'photos.0', NoPhoto)}
          variant={isMiniView ? 'thumb' : 'card'}
          className={classNames('photo')}
        />
        <div className={classNames('text')}>
          <h2 className={classNames('title')}>{article.title}</h2>
          <div className={classNames('data')}>
            <span className={classNames('date')}>
              <DateTime
                data={article.publishedAt}
                format={currentYear === articleYear ? 'D MMMM' : 'll'}
                timeZone={timeZone}
              />
            </span>
            <span className={classNames('readTime')}>
              {msg(
                { id: 'readingTime' },
                { time: <Duration time={article.readingTime} /> }
              )}
            </span>
          </div>
          {isDefaultView && (
            <>
              <p className={classNames('excerpt')}>{article.summary}</p>
              <div className={classNames('related')}>
                <span className={classNames('readMore')}>
                  {msg({ id: 'readMore' })}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  )
}

ArticleExcerpt.propTypes = {
  view: PropTypes.oneOf(['default', 'mini', 'photoCard']),
  article: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    publishedAt: PropTypes.string,
    readingTime: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
  })
}

ArticleExcerpt.defaultProps = {
  view: 'default'
}

ArticleExcerpt.displayName = 'ArticleExcerpt'

export default ArticleExcerpt
