import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import CookieConsent from 'react-cookie-consent'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const CookiesConsentBar = ({ text, ...other }) => {
  const { classNames } = useClassNames({
    alias: 'CookiesConsentBar'
  })
  const { msg } = useIntl({
    scope: 'components.layout.CookiesConsentBar',
    ignoreGlobalScope: true
  })
  return (
    <CookieConsent
      cookieName="CookiesConsent"
      expires={150}
      disableStyles={true}
      containerClasses={classNames()}
      contentClasses={classNames('contentWrapper')}
      buttonWrapperClasses={classNames('buttonsWrapper')}
      location="bottom"
      enableDeclineButton={true}
      disableButtonStyles={true}
      buttonClasses="Btn Btn___primary___fill Btn___m"
      declineButtonClasses="Btn Btn___danger___outline Btn___m"
      buttonText={msg({ id: 'accept' })}
      declineButtonText={msg({ id: 'decline' })}
      flipButtons={true}
      {...other}
    >
      {text}
    </CookieConsent>
  )
}

CookiesConsentBar.displayName = 'CookiesConsentBar'

CookiesConsentBar.propTypes = {
  text: PropTypes.node
}

export default CookiesConsentBar
