import React, { useCallback } from 'react'
import { useHistory } from '@trileuco/triskel-react-ui/components/router'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import BookableSearchForm, {
  fromJSONToQueryParams,
  fromQueryParamsToJSON
} from 'components/bookable/BookableSearchForm'
import { generateBookablesSearchPath } from 'modules/bookables/routes'

const SearchPanel = ({ providerId = null, enabledSearchFilters = [] }) => {
  const history = useHistory()
  const filter = useQueryParams({
    type: QueryParamTypes.Array,
    location: QueryParamTypes.Array,
    fromDate: QueryParamTypes.Date,
    toDate: QueryParamTypes.Date,
    maxDuration: QueryParamTypes.String,
    tag: QueryParamTypes.Array,
    minPrice: QueryParamTypes.Int,
    maxPrice: QueryParamTypes.Int,
    keyword: QueryParamTypes.String
  })
  const handleSubmit = useCallback(
    (searchFormValue) => {
      const params = {
        ...(providerId && { providerIds: providerId }),
        ...searchFormValue
      }
      history.push(
        generateBookablesSearchPath({
          searchParams: fromJSONToQueryParams(params)
        })
      )
    },
    [history, providerId]
  )
  return (
    <div className="SearchPanel">
      <BookableSearchForm
        enabledSearchFilters={enabledSearchFilters}
        onSubmit={handleSubmit}
        defaultValues={fromQueryParamsToJSON(filter)}
      />
    </div>
  )
}

SearchPanel.displayName = 'SearchPanel'

export default SearchPanel
