import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const MetaTags = ({ metaTags }) => {
  if (!metaTags) {
    return null
  }
  return (
    <Helmet>
      {metaTags.ogTitle && <title>{metaTags.ogTitle}</title>}
      {metaTags.ogTitle && (
        <meta property="og:title" content={metaTags.ogTitle} />
      )}
      {metaTags.ogDescription && (
        <meta name="description" content={metaTags.ogDescription} />
      )}
      {metaTags.ogDescription && (
        <meta property="og:description" content={metaTags.ogDescription} />
      )}
      {metaTags.ogKeywords && (
        <meta property="keywords" content={metaTags.ogKeywords} />
      )}
      {metaTags.ogImage && (
        <meta property="og:image" content={metaTags.ogImage} />
      )}
      {metaTags.geoPlacename && (
        <meta name="geo.placename" content={metaTags.geoPlacename} />
      )}
      {metaTags.geoPosition && (
        <meta property="geo.position”" content={metaTags.geoPosition} />
      )}
      {metaTags.geoIcmb && (
        <meta property="”ICBM”" content={metaTags.geoIcmb} />
      )}
    </Helmet>
  )
}

MetaTags.propTypes = {
  metaTags: PropTypes.object
}

MetaTags.displayName = 'MetaTags'

export default MetaTags
