import React from 'react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import { PopOver, Button } from '@trileuco/triskel-react-ui/components/ui'
import AuthModal from 'components/auth/AuthModal'
import UserMenu from 'components/user/UserMenu'
import UserBadge from 'components/user/UserBadge'

const UserMenuTooltip = () => {
  const { authenticated } = useAuth()
  const { open, close, isOpen } = useModal(false)
  const { msg } = useIntl({
    scope: 'balaena.userMenu',
    ignoreGlobalScope: true
  })
  return (
    <>
      {!authenticated && (
        <>
          <Button
            onClick={() => open()}
            variant="clear"
            iconBefore="fas fa-sign-in-alt"
            text={msg({ id: 'login' })}
            size="m"
            className="SignInBtn"
          />
          <AuthModal isOpen={isOpen} onClose={() => close()} />
        </>
      )}
      {authenticated && (
        <PopOver
          alias="UserMenuTooltip"
          content={<UserMenu />}
          triggerEvent="click"
        >
          <UserBadge />
        </PopOver>
      )}
    </>
  )
}

UserMenuTooltip.displayName = 'UserMenuTooltip'

export default UserMenuTooltip
