import React from 'react'
import PropTypes from 'prop-types'

const Block = (props) => {
  const { className, variant, title, TitleTag, ...other } = props
  return (
    <div className={`Block Block___${variant} ${className}`.trim()} {...other}>
      {title && <TitleTag className="Block_title">{title}</TitleTag>}
      {props.children}
    </div>
  )
}

Block.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.node,
  TitleTag: PropTypes.string,
  children: PropTypes.node
}

Block.defaultProps = {
  className: '',
  variant: 'fill',
  TitleTag: 'h2'
}

Block.displayName = 'Block'

export default Block
