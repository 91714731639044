import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { SelectField } from '@trileuco/triskel-react-ui/components/ui'
import { useGetBookableCodes } from 'components/api'

import bookingColors from './_bookingColors.scss'

const LimitedMenu = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsCalendar.bookablesFilter',
    ignoreGlobalScope: true
  })
  const optionSelectedLength = props.getValue().length || 0
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        props.children
      ) : (
        <div className="Select__maxLimitAchieved">
          {msg({ id: 'maxLimitAchieved' })}
        </div>
      )}
    </components.Menu>
  )
}

LimitedMenu.propTypes = {
  getValue: PropTypes.func,
  children: PropTypes.node
}

LimitedMenu.displayName = 'LimitedMenu'

const BookablesSelector = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsCalendar.bookablesFilter',
    ignoreGlobalScope: true
  })
  const { loading, bookableCodes } = useGetBookableCodes()
  const bookingOptions = useMemo(() => {
    if (loading) {
      return []
    } else {
      return bookableCodes.map(({ slug, name }) => ({
        value: slug,
        label: name
      }))
    }
  }, [bookableCodes, loading])

  return (
    <SelectField
      label={msg({ id: 'selectBookables.label' })}
      className="BookablesSelector"
      variant="outline"
      placeholder={msg({ id: 'selectBookables.placeholder' })}
      disabled={loading}
      loading={loading}
      options={bookingOptions}
      components={{ Menu: LimitedMenu }}
      styles={{
        multiValueLabel: (provided, { data, selectProps: { value } }) => {
          const optionIndex = value.findIndex(
            (selectedOption) => selectedOption.value === data.value
          )
          const isSelected = optionIndex >= 0
          const bookingIndex = optionIndex % Object.keys(bookingColors).length
          const bookingColor = Object.values(bookingColors)[bookingIndex]
          return {
            ...provided,
            color: isSelected ? `${bookingColor}FF` : ''
          }
        },
        multiValue: (provided, { data, selectProps: { value } }) => {
          const optionIndex = value.findIndex(
            (selectedOption) => selectedOption.value === data.value
          )
          const isSelected = optionIndex >= 0
          const bookingIndex = optionIndex % Object.keys(bookingColors).length
          const bookingColor = Object.values(bookingColors)[bookingIndex]
          return {
            ...provided,
            background: isSelected ? `${bookingColor}50` : ''
          }
        }
      }}
      {...props}
    />
  )
}

export default BookablesSelector
