import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const BookingFormGuide = ({ guestTypes }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })
  const { classNames } = useClassNames({
    alias: 'BookingFormGuide'
  })

  const ordinals = useMemo(() => {
    return [
      msg({ id: 'bookingFormGuide.guestsOrder.first' }),
      msg({ id: 'bookingFormGuide.guestsOrder.second' }),
      msg({ id: 'bookingFormGuide.guestsOrder.third' })
    ]
  }, [msg])

  const guestsOrder = useMemo(() => {
    return guestTypes
      .map((guestType, index) => {
        const { labels, amount } = guestType
        const type = amount > 1 ? labels.plural : labels.singular
        return `${ordinals[index]} ${type.toLowerCase()}`
      })
      .join(', ')
  }, [guestTypes, ordinals])

  return (
    <Block className={classNames()} variant="fill">
      <div>
        <div className={classNames('title')}>
          <i className="fas fa-info-circle" />
          <span>{msg({ id: 'bookingFormGuide.title' })}</span>
        </div>
        <p>{msg({ id: 'bookingFormGuide.guests' })}</p>
        <p>
          {msg(
            { id: 'bookingFormGuide.guestsOrder' },
            {
              order: guestsOrder
            }
          )}
        </p>
      </div>
    </Block>
  )
}

BookingFormGuide.propTypes = {
  guestTypes: PropTypes.array
}

BookingFormGuide.displayName = 'BookingFormGuide'

export default BookingFormGuide
