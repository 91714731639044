import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  FieldSet,
  CheckboxField
} from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { noop } from '@trileuco/triskel-react-ui/utils'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'

const defaultAllSelected = (value, options) =>
  value && options && value.length === options.length

const MultiCheckboxField = ({
  options,
  label,
  direction,
  value,
  onChange,
  selectAllAllowed,
  selectAllSelected,
  selectAllLabel,
  selectAllCallback
}) => {
  const { classNames } = useClassNames({ alias: 'MultiCheckboxInput' })

  const isSelected = useCallback((option) => value.includes(option.value), [
    value
  ])

  const toggleOption = useCallback(
    (option) => {
      if (isSelected(option)) {
        onChange(value.filter((value) => value !== option.value))
      } else {
        onChange([...value, option.value])
      }
    },
    [isSelected, value, onChange]
  )
  return (
    <FieldSet label={label} className={classNames()} direction={direction}>
      {selectAllAllowed && (
        <CheckboxField
          variant="outline"
          label={selectAllLabel}
          value={selectAllSelected(value, options)}
          onChange={selectAllCallback}
        />
      )}
      {options.map((option) => {
        const { value, label } = option
        return (
          <CheckboxField
            key={option.value}
            variant="outline"
            label={label}
            name={value}
            value={isSelected(option)}
            onChange={() => toggleOption(option)}
          />
        )
      })}
    </FieldSet>
  )
}

MultiCheckboxField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  direction: PropTypes.oneOf(['column', 'row']),
  onChange: PropTypes.func,
  selectAllAllowed: PropTypes.bool,
  selectAllSelected: PropTypes.func,
  selectAllLabel: PropTypes.node,
  selectAllCallback: PropTypes.func
}

MultiCheckboxField.defaultProps = {
  onChange: noop,
  selectAllSelected: defaultAllSelected
}

MultiCheckboxField.displayName = 'MultiCheckboxField'

export default formalizer(MultiCheckboxField)
