import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-form'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import {
  FieldSet,
  TextField,
  SelectField,
  Button
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import WalletCardSummary from './WalletCardSummary'
import WalletCardNumberInput from './WalletCardNumberInput'
import {
  isValidCVV,
  isValidNumber,
  expirationMonthOptions,
  expirationYearOptions,
  isValidCard
} from './wallet-card-utils'

const WalletCardFieldset = ({ onChange, value }) => {
  const formInstance = useFormContext()
  const {
    values: { draftWallet = {} },
    setFieldValue
  } = formInstance

  const clearDraftWallet = useCallback(() => setFieldValue('draftWallet', {}), [
    setFieldValue
  ])

  const isValid = useMemo(() => {
    return draftWallet && isValidCard(draftWallet)
  }, [draftWallet])

  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.walletCard',
    ignoreGlobalScope: true
  })

  const getCardFieldProps = useCallback(
    (field) => ({
      variant: 'outline',
      field,
      label: msg({ id: field }),
      rules: ['required']
    }),
    [msg]
  )

  const handleClose = useCallback(() => {
    onChange(null)
    clearDraftWallet()
  }, [onChange, clearDraftWallet])

  const handleSaveWallet = useCallback(() => {
    onChange(draftWallet)
    clearDraftWallet()
  }, [onChange, draftWallet, clearDraftWallet])

  const showCard = !value && formInstance.values.method === 'wallet'

  const { classNames } = useClassNames({
    alias: 'AddCardModal'
  })

  return (
    <>
      <WalletCardSummary value={value} onChange={onChange} />
      <Modal
        header={<h1>{msg({ id: 'modalTitle' })}</h1>}
        isOpen={showCard}
        onClose={handleClose}
        className={classNames()}
        body={
          <div className={classNames('wrapper')}>
            <FieldSet
              direction={'column'}
              fieldset="draftWallet"
              className={classNames('fields')}
            >
              <TextField {...getCardFieldProps('holder')} />
              <WalletCardNumberInput
                {...getCardFieldProps('number')}
                rules={[
                  'required',
                  ({ value }) =>
                    isValidNumber(value) || msg({ id: 'validations.number' })
                ]}
              />
              <FieldSet
                direction={'row'}
                className={classNames('fieldDateAndCVV')}
              >
                <SelectField
                  {...getCardFieldProps('expirationMonth')}
                  options={expirationMonthOptions}
                  menuPosition={'fixed'}
                />
                <SelectField
                  {...getCardFieldProps('expirationYear')}
                  options={expirationYearOptions}
                  menuPosition={'fixed'}
                />
                <WalletCardNumberInput
                  {...getCardFieldProps('cvv')}
                  rules={[
                    'required',
                    ({ value }) => {
                      return isValidCVV(value) || msg({ id: 'validations.cvv' })
                    }
                  ]}
                  maxDigits={3}
                />
              </FieldSet>
              <Button
                text={msg({ id: 'submit' })}
                onClick={() => handleSaveWallet()}
                disabled={!isValid}
              />
            </FieldSet>
          </div>
        }
      />
    </>
  )
}

WalletCardFieldset.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  isOpen: PropTypes.bool,
  errors: PropTypes.string
}

WalletCardFieldset.displayName = 'WalletCardFieldset'

export default formalizer(WalletCardFieldset)
