import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Modal, { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import { useGetBookable } from 'components/api'
import BookingDate from 'components/booker/BookingForm/BookingDate'
import BookingGuestsSummary from 'components/booker/BookingForm/BookingGuestsSummary'
import BookableProviderBadge from 'components/bookableProvider/BookableProviderBadge'
import BookingBookablePrice from 'components/booking/BookingBookablePrice'
import _ from 'lodash'

const SummaryField = ({ className, label, children, field }) => {
  const { classNames } = useClassNames({ alias: 'BookingSummary', className })
  return (
    <p className={classNames(field)}>
      {label && <span className="label">{label}</span>}
      {children}
    </p>
  )
}

SummaryField.displayName = 'SummaryField'

SummaryField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.node,
  field: PropTypes.string
}

const GuestsSummaryField = ({ booking, maxVisibleGuests, ...rest }) => {
  const { open, close, isOpen } = useModal(false)
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsSummary',
    ignoreGlobalScope: true
  })
  const { guests } = booking
  const noVisibleGuests = guests.slice(maxVisibleGuests)
  const numberOfNoVisibleGuests = noVisibleGuests.length
  const visibleGuestNames = guests
    .filter(
      (guest) =>
        !_.isUndefined(_.get(guest, 'firstName')) &&
        !_.isUndefined(_.get(guest, 'lastName'))
    )
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
    .reduce((names, guestName, index) => {
      if (index < maxVisibleGuests) {
        return names.concat(guestName)
      } else if (index === maxVisibleGuests) {
        return names.concat(
          msg({ id: 'noVisibleGuests' }, { count: numberOfNoVisibleGuests })
        )
      }
      return names
    }, [])
  return (
    <SummaryField {...rest}>
      <span className="guests">{visibleGuestNames.join(', ')}</span>
      <button onClick={open} className="Link">
        {msg({ id: 'viewGuestDetails' })}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={close}
        header={<h1>{msg({ id: 'guests' })}</h1>}
        className="GuestsSummaryModal"
        body={<BookingGuestsSummary guests={booking.guests} variant="clear" />}
      />
    </SummaryField>
  )
}

GuestsSummaryField.displayName = 'GuestsSummaryField'

GuestsSummaryField.propTypes = {
  maxVisibleGuests: PropTypes.number,
  booking: PropTypes.shape()
}

GuestsSummaryField.defaultProps = {
  maxVisibleGuests: 1
}

const BookingPaymentSummary = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsSummary',
    ignoreGlobalScope: true
  })
  const { bookable, loading } = useGetBookable({
    bookableId: booking.bookable.id
  })
  return (
    <>
      {!loading && (
        <BookableProviderBadge size="s" provider={bookable.provider} />
      )}
      <SummaryField field="bookableName">{booking.bookable.name}</SummaryField>
      <SummaryField field="bookableDateTime">
        <BookingDate
          dateTime={booking.dateTime}
          dateRange={booking.dateRange}
          timeZone={booking.bookable.timeZone}
        />
      </SummaryField>
      <GuestsSummaryField
        maxVisibleGuests={8}
        booking={booking}
        field="bookableGuests"
        label={msg({ id: 'guests' })}
      />
      <BookingBookablePrice booking={booking} />
    </>
  )
}

BookingPaymentSummary.propTypes = {
  booking: PropTypes.shape()
}

BookingPaymentSummary.displayName = 'BookingPaymentSummary'

export default BookingPaymentSummary
