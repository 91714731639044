import React from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

const LoadingBar = (props) => {
  const { ...other } = props
  return <TopBarProgress {...other} />
}

LoadingBar.displayName = 'LoadingBar'

export default LoadingBar
