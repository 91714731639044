import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const SafeHtml = ({ WrapperTag, html, className, ...other }) => {
  const { classNames } = useClassNames({
    alias: 'SafeHtml'
  })
  return (
    <WrapperTag className={`${className} ${classNames()}`.trim()} {...other}>
      {ReactHtmlParser(html)}
    </WrapperTag>
  )
}

SafeHtml.propTypes = {
  WrapperTag: PropTypes.string,
  html: PropTypes.string,
  className: PropTypes.string
}

SafeHtml.defaultProps = {
  WrapperTag: 'div',
  className: ''
}

SafeHtml.displayName = 'SafeHtml'

export default SafeHtml
