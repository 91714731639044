import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  generateBookingSummaryPath,
  generateBookingGuestsPath,
  generateBookingCommentsPath,
  generateBookingObservationsPath
} from 'modules/bookables/routes'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

const BookingTabs = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsTabs',
    ignoreGlobalScope: true
  })
  const authContext = useAuth()
  return (
    <div className="BookingTabs">
      <div>
        <NavLink to={generateBookingSummaryPath({ id: booking.id })}>
          {msg({ id: 'summary' })}
        </NavLink>
        <NavLink to={generateBookingGuestsPath({ id: booking.id })}>
          {msg({ id: 'personalData' })}
        </NavLink>
        <NavLink to={generateBookingCommentsPath({ id: booking.id })}>
          {msg(
            { id: 'comments' },
            {
              admin: authContext.hasSomeRole([
                'balaena-admin',
                'balaena-provider',
                'balaena-bookings-manager'
              ])
                ? 1
                : 0
            }
          )}
          {/* <span className="NewMessageBadge">2</span> */}
          {/* TODO new messages notification */}
        </NavLink>
        <Visible
          condition={authContext.hasSomeRole([
            'balaena-admin',
            'balaena-bookings-manager'
          ])}
          ifTrue={
            <NavLink to={generateBookingObservationsPath({ id: booking.id })}>
              {msg({ id: 'observations' })}
            </NavLink>
          }
        />
      </div>
    </div>
  )
}

BookingTabs.propTypes = {
  booking: PropTypes.shape()
}

BookingTabs.displayName = 'BookingTabs'

export default BookingTabs
