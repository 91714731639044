import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import CountryRegionLabel from 'components/misc/CountryRegionLabel'
import SafeHtml from 'components/cms/SafeHtml'

import ProviderLogo from './ProviderLogo'

const ProvidersItem = ({ provider }) => {
  const { classNames } = useClassNames({
    alias: 'ProvidersItem'
  })
  const {
    name,
    descriptions: { short: shortDescription },
    country,
    region
  } = provider
  return (
    <div className={classNames()}>
      <ProviderLogo provider={provider} className={classNames('photo')} />
      <div className={classNames('textWrapper')}>
        <span className={classNames('title')}>{name}</span>
        {country && region && (
          <span className={classNames('location')}>
            <CountryRegionLabel countryCode={country} region={region} />
          </span>
        )}
        {shortDescription && (
          <span className={classNames('description')}>
            <SafeHtml html={shortDescription} />
          </span>
        )}
      </div>
    </div>
  )
}

ProvidersItem.propTypes = {
  provider: PropTypes.shape()
}

ProvidersItem.displayName = 'ProvidersItem'

export default ProvidersItem
