import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import PhotoImg from 'components/misc/PhotoImg'

const CategoryItem = ({ title, description, photos }) => {
  const { classNames } = useClassNames({
    alias: 'CategoryItem'
  })
  return (
    <div className={classNames()}>
      <Visible
        condition={!_.isEmpty(photos)}
        ifTrue={
          <PhotoImg
            photo={photos[0]}
            variant="card"
            className={classNames('photo')}
          />
        }
        otherwise={<img className={classNames('photo')} src={NoPhoto} alt="" />}
      />
      <div>
        <p className={classNames('title')}>{title}</p>
        <p className={classNames('description')}>{description}</p>
      </div>
    </div>
  )
}

CategoryItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  photos: PropTypes.array
}

CategoryItem.displayName = 'CategoryItem'

export default CategoryItem
