import React, { useMemo, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate/BoundedPageTemplate'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import FieldSet from '@trileuco/triskel-react-ui/components/ui/FieldSet'
import _ from 'lodash'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'
import useBookableAnalytics from 'modules/bookables/hooks/useBookableAnalytics'
import {
  useBookBookable,
  useGetBundleItemsAvailabilities
} from 'components/api'
import useCookie from 'components/api/useCookie'
import BookingGuestsFieldsets from 'components/booker/BookingForm/BookingGuestsFieldsets'
import BookingQuestionsFieldsets from 'components/booker/BookingForm/BookingQuestionsFieldsets'
import BookingExtrasByBooking from 'components/booker/BookingForm/BookingExtrasByBooking'
import BookingSummary from 'components/booker/BookingForm/BookingSummary'
import BookingBreadcrumb from 'components/booker/BookingForm/BookingBreadcrumb'
import BookingBundleItemsFieldsets from 'components/booker/BookingForm/BookingBundleItemsFieldsets'
import BookingFormGuide from 'components/booker/BookingForm/BookingFormGuide'

const BookingForm = ({
  bookable,
  onBooking,
  dateTime,
  dateRange,
  guests,
  isAdmin,
  pickedPriceType
}) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingBreadcrumb',
    ignoreGlobalScope: true
  })
  const { amount } = guests
  const { item: referrer } = useCookie('referrer')
  const { mutate: book } = useBookBookable({ bookable })

  const bookableAnalytics = useBookableAnalytics()

  const checkoutAction = useCallback(
    ({ step, option }) => {
      bookableAnalytics.checkoutAction({ bookable, step, option })
    },
    [bookable, bookableAnalytics]
  )

  const [formStepId, setformStepId] = useState(null)
  const [bundleItemsAvailabilities, setBundleItemsAvailabilities] = useState([])
  const [phoneInputError, setPhoneInputError] = useState(false)
  const { fetchBundleItemsAvailabilities } = useGetBundleItemsAvailabilities()

  const setCurrentStepAndAnalytics = useCallback(
    ({ stepNumber, stepName }) => {
      setformStepId(stepName)
      checkoutAction({ step: stepNumber, option: msg({ id: stepName }) })
    },
    [setformStepId, checkoutAction, msg]
  )

  const trimGuestValues = useCallback((guest) => {
    return Object.keys(guest).reduce((acc, curr) => {
      acc[curr] =
        typeof guest[curr] === 'string' ? guest[curr].trim() : guest[curr]
      return acc
    }, {})
  }, [])

  useEffect(() => {
    if (_.isEmpty(bookable.bundleItems)) {
      setCurrentStepAndAnalytics({ stepNumber: '1', stepName: 'details' })
    } else {
      fetchBundleItemsAvailabilities({
        id: bookable.id,
        dateTime,
        bundleItems: bookable.bundleItems,
        guestAmount: amount
      }).then(({ availabilities }) => {
        setBundleItemsAvailabilities(availabilities)
        setCurrentStepAndAnalytics({
          stepNumber: '1',
          stepName: !_.isEmpty(availabilities) ? 'schedule' : 'details'
        })
      })
    }
  }, [
    bookable,
    dateTime,
    checkoutAction,
    fetchBundleItemsAvailabilities,
    amount,
    setCurrentStepAndAnalytics
  ])

  const handleSubmit = useCallback(
    (values, instance) => {
      checkoutAction({
        step: !_.isEmpty(bundleItemsAvailabilities) ? '3' : '2',
        option: msg({ id: 'registry' })
      })
      if (instance.meta.isSubmitted) {
        book({
          dateRange,
          dateTime,
          amount,
          referrer,
          pickedPriceType: pickedPriceType.price.id,
          guests: values.guests.map((guestValue) => {
            const { countryAndRegion, extras, ...guest } = trimGuestValues(
              guestValue
            )
            return {
              ...countryAndRegion,
              ...guest,
              extraOptions: extras
                .filter(({ answer }) => Boolean(answer) || answer === false)
                .map((extra) => extra.extraId)
            }
          }),
          discountToken: _.get(values, 'discount.token', null),
          questions: values.questions.filter(
            ({ answer }) => Boolean(answer) || answer === false
          ),
          extraOptions: values.extrasByBooking
            .filter(({ answer }) => Boolean(answer) || answer === false)
            .map((extra) => extra.extraId),
          ...(!_.isEmpty(values.pickedOccurrences) && {
            pickedOccurrences: _.map(values.pickedOccurrences, (value, key) => {
              return {
                id: key.split('_')[1],
                dateTime: value
              }
            })
          })
        }).then((booking) => {
          onBooking(booking)
        })
      }
    },
    [
      checkoutAction,
      bundleItemsAvailabilities,
      msg,
      book,
      dateRange,
      dateTime,
      amount,
      referrer,
      pickedPriceType.price.id,
      trimGuestValues,
      onBooking
    ]
  )

  const extrasByBooking = useMemo(() => {
    return _.isEmpty(bookable.extraOptions)
      ? []
      : bookable.extraOptions.filter((extra) =>
          extra.type.includes('by_booking')
        )
  }, [bookable.extraOptions])

  const extrasByGuest = useMemo(() => {
    return _.isEmpty(bookable.extraOptions)
      ? []
      : bookable.extraOptions.filter((extra) => extra.type.includes('by_guest'))
  }, [bookable.extraOptions])

  const plainGuestTypes = useMemo(() => {
    return guests.guestTypes.reduce(
      (plainGuests, { guestType, amount }) => [
        ...plainGuests,
        ...Array(amount).fill(guestType)
      ],
      []
    )
  }, [guests])

  const memorizedDefaultValues = useMemo(() => {
    return {
      guests: plainGuestTypes.map((guestType) => ({
        guestTypeId: guestType.guestTypeId,
        extras: extrasByGuest.map(({ id, name }) => ({
          extraId: id,
          answer: undefined,
          name: name
        }))
      })),
      questions: bookable.reservationQuestions.map(({ id }) => ({
        questionId: id,
        answer: undefined
      })),
      termsOfUse: bookable.termsOfUse.reduce(
        (terms, term) => ({
          ...terms,
          [`term_${term.id}`]: isAdmin
        }),
        {}
      ),
      extrasByBooking: extrasByBooking.map(({ id, name }) => ({
        extraId: id,
        answer: undefined,
        name: name
      })),
      ...(!_.isEmpty(bundleItemsAvailabilities) && {
        pickedOccurrences: bundleItemsAvailabilities.reduce(
          (pickedOcurrences, { id, schedules }) => {
            return {
              ...pickedOcurrences,
              [`pickedOccurrence_${id}`]: schedules[0].dateTime
            }
          },
          {}
        )
      })
    }
  }, [
    plainGuestTypes,
    bookable.reservationQuestions,
    bookable.termsOfUse,
    extrasByBooking,
    bundleItemsAvailabilities,
    extrasByGuest,
    isAdmin
  ])

  const { Form, values, ...instance } = useForm({
    onSubmit: handleSubmit,
    defaultValues: memorizedDefaultValues
  })

  const groupedGuestTypes = useMemo(() => {
    return Object.entries(
      _.groupBy(plainGuestTypes, (guesType) => {
        return guesType.guestTypeId
      })
    ).map(([key, value]) => {
      return { labels: value[0].labels, amount: value.length }
    })
  }, [plainGuestTypes])

  const mandatoryFields = useMemo(() => {
    return bookable.mandatoryBookingFields.map(_.camelCase)
  }, [bookable])

  const phoneInputIsValid = useMemo(() => {
    return (
      !_.isEmpty(values.guests[0].phone) && values.guests[0].phone.length > 3
    )
  }, [values.guests])

  const handleFormSubmit = useCallback(() => {
    const phoneInputIsRequired = mandatoryFields.includes('phone')
    if (!phoneInputIsRequired || (phoneInputIsRequired && phoneInputIsValid)) {
      instance.handleSubmit()
    } else {
      setPhoneInputError(true)
      if (!instance.meta.isTouched) {
        instance.handleSubmit()
      }
    }
  }, [instance, mandatoryFields, phoneInputIsValid])

  useEffect(() => {
    if (phoneInputIsValid) {
      setPhoneInputError(false)
    }
  }, [phoneInputIsValid, values.guests])

  return (
    formStepId && (
      <Form className="BookingForm">
        <BoundedPageTemplate
          header={
            <BookingBreadcrumb
              initialBundleItemsAvailabilities={bundleItemsAvailabilities}
              formStepId={formStepId}
            />
          }
          mainContent={
            <FieldSet direction="column">
              <Visible
                condition={formStepId === 'schedule'}
                ifTrue={
                  <BookingBundleItemsFieldsets
                    bundleItemsAvailabilities={bundleItemsAvailabilities}
                  />
                }
                otherwise={
                  <>
                    {groupedGuestTypes && groupedGuestTypes.length > 1 && (
                      <BookingFormGuide guestTypes={groupedGuestTypes} />
                    )}
                    <BookingGuestsFieldsets
                      onlyMainGuestInfo={bookable.onlyMainGuestInfo}
                      requiredFields={mandatoryFields}
                      guests={plainGuestTypes}
                      bookingDate={dateTime}
                      extrasByGuest={extrasByGuest}
                      phoneInputError={phoneInputError}
                      currency={bookable.price.currency}
                    />
                    <Visible
                      condition={!_.isEmpty(bookable.reservationQuestions)}
                      ifTrue={
                        <BookingQuestionsFieldsets
                          questions={bookable.reservationQuestions}
                        />
                      }
                    />
                    <Visible
                      condition={!_.isEmpty(extrasByBooking)}
                      ifTrue={
                        <BookingExtrasByBooking
                          extras={extrasByBooking}
                          currency={bookable.price.currency}
                        />
                      }
                    />
                  </>
                }
              />
            </FieldSet>
          }
          sidebar={
            <BookingSummary
              bookable={bookable}
              pickedPriceType={pickedPriceType && pickedPriceType.price}
              dateTime={dateTime}
              dateRange={dateRange}
              guests={guests}
              extrasByBooking={extrasByBooking}
              extrasByGuest={extrasByGuest}
              handleContinue={() => {
                formStepId === 'schedule'
                  ? setCurrentStepAndAnalytics({
                      stepNumber: '2',
                      stepName: 'details'
                    })
                  : handleFormSubmit()
              }}
              formStepId={formStepId}
            />
          }
        />
      </Form>
    )
  )
}

BookingForm.propTypes = {
  bookable: PropTypes.shape(),
  onBooking: PropTypes.func,
  dateTime: PropTypes.string,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  guests: PropTypes.shape({
    amount: PropTypes.number,
    guestTypes: PropTypes.arrayOf(
      PropTypes.shape({
        guestType: PropTypes.shape(),
        amount: PropTypes.number
      })
    )
  }),
  isAdmin: PropTypes.bool,
  pickedPriceType: PropTypes.object
}

BookingForm.displayName = 'BookingForm'

export default BookingForm
