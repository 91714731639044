import { useGet } from 'components/api'

export default ({ bookableId, ...options }) => {
  const { data, ...rest } = useGet({
    path: `/bookables/${bookableId}`,
    resolve: (data) => {
      return data.bookable
    },
    ...options
  })
  return {
    bookable: data,
    ...rest
  }
}
