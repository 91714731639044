import { useFetchCache } from 'components/api/ApiCacheContext'

const useGetWebpage = ({ id, ...options }) => {
  const { [`webpages#${id}`]: webpage, ...rest } = useFetchCache({
    path: `/webpages/${id}`,
    entity: `webpages#${id}`,
    resolve: ({ webpage }) => webpage,
    ...options
  })
  return {
    ...rest,
    webpage
  }
}

export default useGetWebpage
