import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import _ from 'lodash'

const ReCaptchaField = ({ action, onChange, value }) => {
  const { msg } = useIntl({
    scope: 'balaena.reCaptchaField',
    ignoreGlobalScope: true
  })
  const [executing, setExecuting] = useState(false)
  const [error, setError] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const execute = useCallback(() => {
    setExecuting(true)
    if (!_.isUndefined(executeRecaptcha)) {
      executeRecaptcha(action)
        .then((token) => {
          onChange(token)
          setExecuting(false)
        })
        .catch(() => {
          setError(true)
          setExecuting(false)
        })
    }
  }, [action, executeRecaptcha, onChange])

  useEffect(() => {
    if (!value && !executing && !error) {
      execute()
    }
  }, [execute, value, executing, error])

  return (
    <>
      <div className="ReCaptchaField">
        {msg(
          {
            id: 'notice'
          },
          {
            privacyPolicy: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy"
                className="Link"
              >
                {msg({ id: 'notice.privacyPolicy' })}
              </a>
            ),
            TermsOfService: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/terms"
                className="Link"
              >
                {msg({ id: 'notice.TermsOfService' })}
              </a>
            )
          }
        )}
      </div>
    </>
  )
}

ReCaptchaField.propTypes = {
  onChange: PropTypes.func,
  action: PropTypes.string,
  value: PropTypes.string
}

ReCaptchaField.defaultProps = {
  onChange: () => null,
  action: 'social'
}

ReCaptchaField.displayName = 'ReCaptchaField'

export default formalizer(ReCaptchaField)
