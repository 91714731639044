import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Modal, { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import SettleAmount from './SettleAmountInfo'

const SettleStatus = ({ label, settleStatus }) => {
  const { classNames } = useClassNames({
    alias: 'SettleStatus'
  })
  return (
    <div className={classNames()}>
      <span className={classNames('label')}>{label}</span>
      <span className={classNames('status')}>{settleStatus}</span>
    </div>
  )
}

SettleStatus.propTypes = {
  label: PropTypes.string,
  settleStatus: PropTypes.string
}

const TenantFeeInfo = ({ label, tenantAmount, currency }) => (
  <SettleAmount amount={tenantAmount} label={label} currency={currency} />
)

TenantFeeInfo.propTypes = {
  label: PropTypes.string,
  tenantAmount: PropTypes.number,
  currency: PropTypes.string
}

const ReferralFeeInfo = ({
  label,
  referralAmount: { bookerName, amount, currency }
}) => {
  return (
    <SettleAmount
      name={bookerName}
      amount={amount}
      label={label}
      currency={currency}
    />
  )
}

ReferralFeeInfo.propTypes = {
  label: PropTypes.string,
  referralAmount: PropTypes.shape(),
  currency: PropTypes.string
}

const ProvidersFeeInfo = ({ label, providerAmounts, currency }) => {
  const { classNames } = useClassNames({
    alias: 'ProvidersFeeInfo'
  })
  return (
    <div className={classNames()}>
      <span className={classNames('label')}>{label}</span>

      {!_.isEmpty(providerAmounts) &&
        providerAmounts.map(({ providerId, providerName, amount }) => {
          return (
            <SettleAmount
              key={providerId}
              name={providerName}
              amount={amount}
              currency={currency}
            />
          )
        })}
    </div>
  )
}

ProvidersFeeInfo.propTypes = {
  label: PropTypes.string,
  providerAmounts: PropTypes.arrayOf(PropTypes.shape()),
  currency: PropTypes.string
}

const BookingPaymentFeeInfoModal = ({ paymentFee = {}, currency }) => {
  const {
    settleStatus,
    tenantSettleAmount,
    referralSettleAmount,
    providerSettleAmounts
  } = paymentFee

  const { msg } = useIntl({
    scope: 'bookingPaymentFeeInfoModal'
  })

  const { isOpen, open, close } = useModal(false)

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={close}
        className="BookingPaymentFeeInfoModal"
        header={<h2>{msg({ id: 'title' })}</h2>}
        body={
          <div>
            <SettleStatus
              label={msg({
                id: 'settleStatus'
              })}
              settleStatus={msg({
                id:
                  settleStatus === 'pending'
                    ? 'settleStatus.pending'
                    : 'settleStatus.settled'
              })}
            />
            {tenantSettleAmount && (
              <TenantFeeInfo
                label={msg({ id: 'tenantSettle' })}
                tenantAmount={tenantSettleAmount}
                currency={currency}
              />
            )}
            {referralSettleAmount && (
              <ReferralFeeInfo
                label={msg({ id: 'referralSettle' })}
                referralAmount={referralSettleAmount}
                currency={currency}
              />
            )}
            {providerSettleAmounts && (
              <ProvidersFeeInfo
                label={msg({ id: 'providerSettles' })}
                providerAmounts={providerSettleAmounts}
                currency={currency}
              />
            )}
          </div>
        }
      />
      <button className={'Link'} onClick={open}>
        {msg({ id: 'modalTrigger' })}
      </button>
    </>
  )
}

BookingPaymentFeeInfoModal.propTypes = {
  paymentFee: PropTypes.shape(),
  currency: PropTypes.string
}

export default BookingPaymentFeeInfoModal
