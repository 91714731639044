import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import Page from 'components/layout/Page'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useGetBookings, LoadingResults } from 'components/api'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import Block from 'components/layout/Block'
import BookingsTable from 'components/booker/BookingsTable/BookingsTable'
import { toQueryParams } from 'modules/bookables/routes'
import BookingsReport from 'components/booker/BookingsReport/BookingsReport'
import { PopOver, IconButton } from '@trileuco/triskel-react-ui/components/ui'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible/Visible'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth/useAuth'
import { useTenantConfig } from 'modules/bookables/Provider'

const BookingsActionsMenu = ({ actions }) => {
  const { classNames } = useClassNames({ alias: 'BookingsActionsMenu' })
  return (
    <PopOver
      content={<div className={classNames('tooltip')}>{actions}</div>}
      triggerEvent="click"
      placement="auto"
      TriggerTag={'div'}
    >
      <IconButton icon="fas fa-ellipsis-v" />
    </PopOver>
  )
}

BookingsActionsMenu.propTypes = {
  actions: PropTypes.node
}

const BookingsPage = ({ history, location }) => {
  const {
    intl: { timeZone }
  } = useTenantConfig()
  const {
    page = 1,
    pageSize = 20,
    orderBy = 'createdAt-desc',
    id,
    bookerId,
    bookableId,
    status,
    guestFilter,
    state,
    to: toQueryParam,
    from: fromQueryParam,
    createdFrom: createdFromQueryParam,
    createdTo: createdToQueryParam
  } = useQueryParams({
    id: QueryParamTypes.String,
    bookerId: QueryParamTypes.Int,
    bookableId: QueryParamTypes.Int,
    page: QueryParamTypes.Int,
    pageSize: QueryParamTypes.Int,
    orderBy: QueryParamTypes.String
  })
  const from = fromQueryParam && moment(fromQueryParam).tz(timeZone)
  const to = toQueryParam && moment(toQueryParam).tz(timeZone)

  const { hasSomeRole } = useAuth()

  const createdFrom =
    createdFromQueryParam && moment(createdFromQueryParam).tz(timeZone)
  const createdTo =
    createdToQueryParam && moment(createdToQueryParam).tz(timeZone)

  const { bookings, loading, pagination = {} } = useGetBookings({
    page,
    pageSize,
    id,
    bookerId,
    bookableId,
    status,
    guestFilter,
    state,
    orderBy,
    from: from && from.startOf('day').format(),
    to: to && to.endOf('day').format(),
    createdFrom: createdFrom && createdFrom.startOf('day').format(),
    createdTo: createdTo && createdTo.endOf('day').format()
  })

  const filterValue = {
    id,
    bookerId,
    bookableId,
    status,
    guestFilter,
    state,
    dateTime: {
      from: from && from.startOf('day').toDate(),
      to: to && to.startOf('day').toDate()
    },
    createdAt: {
      createdFrom: createdFrom && createdFrom.startOf('day').toDate(),
      createdTo: createdTo && createdTo.startOf('day').toDate()
    }
  }

  const handlePaginationChange = useCallback(
    (newPagination) => {
      if (
        newPagination.pageSize !== pagination.pageSize ||
        newPagination.page !== pagination.page ||
        newPagination.orderBy !== pagination.orderBy
      ) {
        history.replace({
          pathname: location.pathname,
          search: toQueryParams({
            page: newPagination.page,
            pageSize: newPagination.pageSize,
            orderBy: newPagination.orderBy,
            ...filterValue
          })
        })
      }
    },
    [history, location.pathname, filterValue, pagination]
  )

  const handleBookingFilterChange = useCallback(
    (newFilter) => {
      const { createdAt, ...restFilter } = newFilter
      history.replace({
        pathname: location.pathname,
        search: toQueryParams({
          ...(createdAt && {
            createdFrom: createdAt.from && createdAt.from.toISOString(),
            createdTo: createdAt.to && createdAt.to.toISOString()
          }),
          ...restFilter,
          page: 1,
          pageSize: pagination.pageSize,
          orderBy: pagination.orderBy
        })
      })
    },
    [history, location.pathname, pagination.pageSize, pagination.orderBy]
  )

  const reportParams = {
    bookerId,
    bookableId,
    status,
    guestFilter,
    state,
    from: from && from.startOf('day').format(),
    to: to && to.endOf('day').format(),
    createdFrom: createdFrom && createdFrom.startOf('day').format(),
    createdTo: createdTo && createdTo.endOf('day').format()
  }

  const { msg } = useIntl({ scope: 'bookingsPage' })
  return (
    <Page id="BookingsPage">
      <PublicPageSkeleton
        mainContent={
          <Block
            title={
              <>
                {msg({ id: 'pageTitle' })}
                {bookings && (
                  <Visible
                    condition={hasSomeRole([
                      'balaena-admin',
                      'balaena-bookings-manager'
                    ])}
                    ifTrue={
                      <BookingsActionsMenu
                        actions={<BookingsReport {...reportParams} />}
                      />
                    }
                  />
                )}
                {/* TODO unlink actions from title and handle close onclick */}
              </>
            }
            variant="clear"
            className="Bookings"
          >
            {loading && <LoadingResults />}
            {bookings && (
              <BookingsTable
                bookings={bookings}
                loading={loading}
                pagination={{ ...pagination, orderBy }}
                onPaginationChange={handlePaginationChange}
                filter={filterValue}
                onFilterChange={handleBookingFilterChange}
              />
            )}
          </Block>
        }
      />
    </Page>
  )
}

BookingsPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

BookingsPage.displayName = 'BookingsPage'

export default BookingsPage
