import React from 'react'
import PropTypes from 'prop-types'
import {
  DayPickerField,
  TextField
} from '@trileuco/triskel-react-ui/components/ui'
import RadioFieldSet from '@trileuco/triskel-react-ui/components/ui/RadioFieldSet'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { formatPrice } from 'components/misc/Price'
import TagField from 'components/misc/TagField'
import RangeSliderField from 'components/misc/RangeSliderField'
import moment from 'moment'

import DateIntervalFilterField from './DateIntervalFilterField'
import CheckboxFilterField from './CheckboxFilterField'
import PopOverField from './PopOverField'

const BookableSearchField = ({ id, type, showInline, ...moreProps }) => {
  const { classNames } = useClassNames({ alias: 'BookableSearchField' })

  const fieldProps = {
    className: classNames(),
    showInline,
    field: id,
    ...moreProps
  }

  switch (type) {
    case 'text':
      return <TextField {...fieldProps} />
    case 'checkbox':
      return <PopOverField Field={CheckboxFilterField} {...fieldProps} />
    case 'radio':
      return <PopOverField Field={RadioFieldSet} {...fieldProps} />
    case 'tag':
      return <PopOverField Field={TagField} {...fieldProps} />
    case 'date:interval':
      const additionalProps = {
        disabledDays: (day) =>
          moment().diff(day) > 0 || moment().add(1, 'years').diff(day) < 0
      }
      if (showInline) {
        return (
          <DateIntervalFilterField
            interval
            autoComplete={'off'}
            {...additionalProps}
            {...fieldProps}
          />
        )
      }
      return (
        <PopOverField
          interval
          autoComplete={'off'}
          Field={DayPickerField}
          renderStringValue={true}
          {...additionalProps}
          {...fieldProps}
        />
      )
    case 'number:range':
      return (
        <PopOverField
          Field={RangeSliderField}
          RenderValue={({ min, max }) =>
            `${formatPrice({
              amount: min,
              currency: 'EUR'
            })} - ${formatPrice({
              amount: max,
              currency: 'EUR'
            })}`
          }
          {...fieldProps}
        />
      )
    default:
      return null
  }
}

BookableSearchField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  showInline: PropTypes.bool
}

BookableSearchField.displayName = 'BookableSearchField'

export default BookableSearchField
