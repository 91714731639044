import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Price from 'components/misc/Price'

const SettleAmount = ({ name, amount, currency }) => {
  const { classNames } = useClassNames({
    alias: 'SettleAmount'
  })
  return (
    <span className={classNames()}>
      <span className={classNames('price')}>
        <Price amount={amount} currency={currency} />
      </span>
      {name && <span className={classNames('label')}>{name}</span>}
    </span>
  )
}

SettleAmount.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string
}

const SettleAmountInfo = ({ label, name, amount, currency }) => {
  const { classNames } = useClassNames({
    alias: 'SettleAmountInfo'
  })
  return (
    <div className={classNames()}>
      {label && <span className={classNames('label')}>{label}</span>}
      <SettleAmount name={name} amount={amount} currency={currency} />
    </div>
  )
}

SettleAmountInfo.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string
}

export default SettleAmountInfo
