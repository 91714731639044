import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import BookingCommentsList from 'components/booker/BookingComments/BookingCommentsList'
import BookingCommentsForm from 'components/booker/BookingComments/BookingCommentsForm'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

const BookingCommentsBlock = (props) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingComments',
    ignoreGlobalScope: true
  })
  const authContext = useAuth()
  const isAdmin = authContext.hasSomeRole([
    'balaena-admin',
    'balaena-provider',
    'balaena-bookings-manager'
  ])
  return (
    <Block
      className="BookingComments"
      title={msg(
        { id: 'title' },
        {
          admin: isAdmin ? 1 : 0
        }
      )}
    >
      <span className="Field_helperText">
        {msg(
          { id: 'titleHelperText' },
          {
            admin: isAdmin ? 1 : 0
          }
        )}
      </span>
      <Visible
        condition={!_.isEmpty(props.comments)}
        ifTrue={
          <BookingCommentsList
            refetch={props.refetch}
            bookingId={props.id}
            comments={props.comments}
          />
        }
      />
      <BookingCommentsForm refetch={props.refetch} bookingId={props.id} />
    </Block>
  )
}

BookingCommentsBlock.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.number,
  refetch: PropTypes.func
}

BookingCommentsBlock.displayName = 'BookingCommentsBlock'

export default BookingCommentsBlock
