import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Duration from 'components/misc/Duration'
import BookingDate from 'components/booker/BookingForm/BookingDate'
import moment from 'moment'
import _ from 'lodash'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

const BookingDateBlock = ({ booking, bookable }) => {
  const { msg } = useIntl({
    scope: 'bookingDateBlock'
  })
  const { classNames } = useClassNames({
    alias: 'BookingDateBlock'
  })

  const duration = useMemo(() => {
    let duration = bookable.duration
    if (bookable.continuous && !_.isEmpty(booking.dateRange)) {
      const from = moment(booking.dateRange.from)
      const to = moment(booking.dateRange.to)

      duration = moment.duration(to.diff(from)).asMinutes()
    }
    return duration
  }, [booking, bookable])

  return (
    <BookingDetailsSummaryBlock
      className="BookingDateBlock"
      title={msg({ id: 'title' })}
    >
      <div>
        <p className={classNames('bookingDate')}>
          <BookingDate
            dateTime={booking.dateTime}
            dateRange={booking.dateRange}
            timeZone={booking.bookable.timeZone}
          />
        </p>
        <p className={classNames('bookingDuration')}>
          {msg(
            { id: 'duration' },
            {
              duration: <Duration amount={duration} />
            }
          )}
        </p>
      </div>
    </BookingDetailsSummaryBlock>
  )
}

BookingDateBlock.propTypes = {
  booking: PropTypes.shape(),
  bookable: PropTypes.shape()
}

BookingDateBlock.displayName = 'BookingDateBlock'

export default BookingDateBlock
