import _ from 'lodash'

const findCategory = (categories, id) => {
  if (!categories) {
    return
  }
  for (const category of categories) {
    if (category.id === id || category.slug === id) {
      return category
    }
    const categoryChild = findCategory(category.children, id)
    if (categoryChild) {
      return categoryChild
    }
  }
}

const generateCategoryPath = (categories, id) => {
  if (!categories) {
    return
  }
  const category = findCategory(categories, id)

  if (!category) {
    return ''
  }

  return (
    '/' +
    (_.isEmpty(category.parents) ? '' : category.parents.join('/') + '/') +
    category.slug
  )
}

export { findCategory, generateCategoryPath }
