import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import RadioFieldSet from '@trileuco/triskel-react-ui/components/ui/RadioFieldSet'
import { useField } from '@trileuco/triskel-react-ui/components/ui/Form'

export const DateSelectorSchedule = ({
  msg,
  completedGuestsAmount,
  loadingBookableAvailabilities,
  availableTimes,
  onChange
}) => {
  const { classNames } = useClassNames({ alias: 'DateSelectorSchedule' })

  const { value: day } = useField('day')

  const disabled =
    loadingBookableAvailabilities || !day || !completedGuestsAmount

  return (
    <div className={classNames()}>
      <span className={classNames('title')}>
        {day
          ? msg(
              { id: 'scheduleTitleForDate' },
              {
                date: moment(new Date(day)).format('L')
              }
            )
          : msg({ id: 'scheduleTitle' })}
      </span>
      {availableTimes.length >= 1 ? (
        <RadioFieldSet
          field="time"
          variant="outline"
          onChange={onChange}
          disabled={disabled}
          options={availableTimes}
        />
      ) : (
        <span className={classNames('selectToSeeAvailability')}>
          {msg({
            id: 'selectToSeeAvailability'
          })}
        </span>
      )}
    </div>
  )
}

DateSelectorSchedule.displayName = 'DateSelectorSchedule'

DateSelectorSchedule.propTypes = {
  msg: PropTypes.func,
  onChange: PropTypes.func,
  completedGuestsAmount: PropTypes.bool,
  loadingBookableAvailabilities: PropTypes.bool,
  availableTimes: PropTypes.arrayOf(PropTypes.object)
}
