import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  MessageProvider,
  useIntl
} from '@trileuco/triskel-react-ui/components/i18n'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import {
  generateBookingsSearchPath,
  generateBookingsCalendarPath
} from 'modules/bookables/routes'
import BookingsCalendar, {
  bookingColors
} from 'components/booker/BookingsCalendar'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import Block from 'components/layout/Block'
import { useGetSchedulesByBookable } from 'components/api'

const BookingsCalendarPage = ({ history }) => {
  const { msg } = useIntl({ scope: 'bookingsCalendarPage' })

  const queryParams = useQueryParams({
    bookableIds: QueryParamTypes.Array,
    from: QueryParamTypes.Date,
    to: QueryParamTypes.Date,
    page: QueryParamTypes.Int,
    pageSize: QueryParamTypes.Int
  })

  const { loading, schedulesByBookable } = useGetSchedulesByBookable({
    queryParams
  })

  const handleFilterChange = useCallback(
    (filter) => {
      history.push(
        generateBookingsCalendarPath({
          searchParams: {
            ...filter,
            to: filter.to.toISOString(),
            from: filter.from.toISOString()
          }
        })
      )
    },
    [history]
  )

  const handleMoreBookingsDetails = useCallback(
    ({ bookableId, dateTime }) => {
      history.push(
        generateBookingsSearchPath({
          searchParams: {
            bookableId,
            from: moment(dateTime).startOf('day').format(moment.HTML5_FMT.DATE),
            to: moment(dateTime).endOf('day').format(moment.HTML5_FMT.DATE)
          }
        })
      )
    },
    [history]
  )

  const events = useMemo(() => {
    return schedulesByBookable.reduce(
      (events, { bookable, schedules }, index) => {
        const bookableColorIndex = index % Object.keys(bookingColors).length
        const bookableColor = Object.keys(bookingColors)[bookableColorIndex]
        return [
          ...events,
          ...Object.values(schedules).map((schedule) => ({
            bookable,
            bookableColor,
            start: schedule.dateTime,
            title: schedule.dateTime,
            dateTime: schedule.dateTime,
            capacity: schedule.capacity,
            onMoreDetails: () =>
              handleMoreBookingsDetails({
                bookableId: bookable.id,
                dateTime: schedule.dateTime
              })
          }))
        ]
      },
      []
    )
  }, [handleMoreBookingsDetails, schedulesByBookable])

  const timeZone = useMemo(() => {
    return schedulesByBookable[0] && schedulesByBookable[0].bookable.timeZone
  }, [schedulesByBookable])

  return (
    <Page id="BookingsCalendarPage">
      <MessageProvider scope="bookingsCalendarPage">
        <PublicPageSkeleton
          mainContent={
            <Block title={msg({ id: 'pageTitle' })} variant="clear">
              <BookingsCalendar
                filter={queryParams}
                loading={loading}
                timeZone={timeZone}
                events={events}
                onFilterChange={handleFilterChange}
              />
            </Block>
          }
        />
      </MessageProvider>
    </Page>
  )
}

BookingsCalendarPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

BookingsCalendarPage.displayName = 'BookingsCalendarPage'

export default BookingsCalendarPage
