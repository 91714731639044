import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useUpdateBookingDateTime = (bookingId, dateParams) => {
  const { mutate, ...rest } = useMutate({
    path: `/bookings/${bookingId}/datetime`,
    verb: 'PATCH',
    dateParams
  })

  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useUpdateBookingDateTime',
    ignoreGlobalScope: true
  })

  const notifyUpdatedDateTime = useCallback(
    (notification) => {
      notify({
        id: `BOOKING_${bookingId}_UPDATE_DATETIME`,
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [msg, notify, bookingId]
  )

  const updateDateTime = useCallback(
    (params) => {
      notifyUpdatedDateTime({ status: LOADING })
      return mutate(params)
        .then((result) => {
          notifyUpdatedDateTime({ status: SUCCESS })
          return result
        })
        .catch((error) => {
          notifyError({
            id: `BOOKING_${bookingId}_UPDATE_DATETIME`,
            title: msg({ id: 'error' }),
            error
          })
          throw error
        })
    },
    [
      notifyUpdatedDateTime,
      LOADING,
      mutate,
      SUCCESS,
      notifyError,
      msg,
      bookingId
    ]
  )
  return {
    updateDateTime,
    ...rest
  }
}

export default useUpdateBookingDateTime
