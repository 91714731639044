import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import BookingStatusAndExtraStates from 'modules/bookables/pages/bookingDetails/BookingStatusAndExtraStates'
import { generateBookingPath } from 'modules/bookables/routes'
import _ from 'lodash'

const BookingTicketsSummary = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.BookingTicketsSummary',
    ignoreGlobalScope: true
  })

  const { classNames } = useClassNames({
    alias: 'BookingTicketsSummary'
  })

  const { booker, guests } = booking

  const groupedGuests = _.flatten(
    Object.entries(
      _.groupBy(guests, ({ type: { labels } }) => labels.singular)
    ).map(([key, value]) => {
      return value.map(({ firstName, lastName }, index) => {
        return {
          label: `${key} #${index + 1}`,
          guest: `${firstName} ${lastName}`
        }
      })
    })
  )

  return (
    <div className={classNames()}>
      <div className={classNames('info')}>
        <p>{msg({ id: 'booker' })}</p>
        {booker.name}
      </div>
      <div className={classNames('info')}>
        <p>{msg({ id: 'guests' })}</p>
        <ul>
          {groupedGuests.map(({ label, guest }, index) => (
            <li key={index}>
              <span>{label}</span> - <span>{guest}</span>
            </li>
          ))}
        </ul>
      </div>
      <Link
        to={generateBookingPath({ id: booking.id })}
        className={classNames('viewDetails')}
      >
        {msg({ id: 'block_details' })}
      </Link>
      <BookingStatusAndExtraStates booking={booking} onlyStates={true} />
      {/* FIXME: Rename component in a more semantic way  */}
    </div>
  )
}

BookingTicketsSummary.propTypes = {
  booking: PropTypes.shape()
}

BookingTicketsSummary.displayName = 'BookingTicketsSummary'

export default BookingTicketsSummary
