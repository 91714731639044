import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import Block from 'components/layout/Block'

import CategoryItem from './CategoryItem'

const CategoryChildren = ({ categoryName, parentSlugs, children }) => {
  const { classNames } = useClassNames({
    alias: 'CategoryChildren'
  })

  const items = children.map((children, index) => {
    const path = `${parentSlugs || ''}/${children.slug}`
    return (
      <Link className={classNames('item')} key={index} to={path}>
        <CategoryItem
          photos={children.photos}
          title={children.name}
          description={children.descriptions && children.descriptions.short}
        />
      </Link>
    )
  })

  return (
    <div className={classNames()}>
      <Block variant="clear">{items}</Block>
    </div>
  )
}

CategoryChildren.propTypes = {
  categoryName: PropTypes.string,
  parentSlugs: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.shape())
}

CategoryChildren.displayName = 'CategoryChildren'

export default CategoryChildren
