import React from 'react'
import PropTypes from 'prop-types'
import Block from 'components/layout/Block'

const BookingDetailsSummaryBlock = ({ className, title, children }) => {
  return (
    <Block
      className={`BookingDetailsSummaryBlock ${className || ''}`.trim()}
      variant="clear"
      title={title}
    >
      {children}
    </Block>
  )
}

BookingDetailsSummaryBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
}

BookingDetailsSummaryBlock.displayName = 'BookingDetailsSummaryBlock'

export default BookingDetailsSummaryBlock
