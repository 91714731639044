import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const BalaenaToolkitAd = ({ view }) => {
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'balaena.balaenaToolkitAd'
  })
  const { classNames } = useClassNames({ alias: 'BalaenaToolkitAd' })

  return (
    <div className={classNames([{ view }])}>
      <div className={classNames('boundedWrapper')}>
        <div>
          <p className={classNames('title')}>{msg({ id: 'title' })}</p>
          <p className={classNames('description')}>
            {msg(
              { id: 'description' },
              {
                balaenaToolkit: <b>Balaena Toolkit</b>,
                balaenaTravel: <em>Balaena Travel</em>
              }
            )}
          </p>
        </div>
        <a
          href={`${window.location.origin}/toolkit`}
          className={classNames('button')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {msg({ id: 'button' })}
          <i className="fas fa-external-link-alt" />
        </a>
      </div>
    </div>
  )
}

BalaenaToolkitAd.propTypes = {
  view: PropTypes.oneOf(['default', 'mini'])
}

BalaenaToolkitAd.defaultProps = {
  view: 'default'
}

BalaenaToolkitAd.displayName = 'BalaenaToolkitAd'

export default BalaenaToolkitAd
