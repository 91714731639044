import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { LoadingResults, useGetArticle } from 'components/api'
import PhotoImg from 'components/misc/PhotoImg'
import TagsList from 'modules/blog/components/TagsList'
import DateTime from 'components/misc/DateTime'
import Duration from 'components/misc/Duration'
import MetaTags from 'components/layout/MetaTags'
import SafeHtml from 'components/cms/SafeHtml'
import moment from 'moment-timezone'
import { useTenantConfig } from 'modules/bookables/Provider'
import { NotFoundError } from 'components/error'

import ArticleNavigation from './ArticleNavigation'
import ArticleShareButtons from './ArticleShareButtons'

const Article = ({ articleId }) => {
  const { classNames } = useClassNames({ alias: 'Article' })
  const { article, loading } = useGetArticle({
    id: articleId
  })
  const {
    intl: { timeZone }
  } = useTenantConfig()
  const { msg } = useIntl({
    ignoreGlobalScope: true,
    scope: 'blog.articles.Article'
  })
  if (loading) {
    return <LoadingResults />
  }
  if (!article) {
    throw new NotFoundError('Article not found for id ' + articleId)
  }
  const currentYear = moment().year()
  const articleYear = moment(article.publishedAt).year()
  return (
    <article className={classNames()}>
      {article.metaTags && <MetaTags metaTags={article.metaTags} />}
      <h1 className={classNames('title')}>{article.title}</h1>
      <div className={classNames('data')}>
        <span className={classNames('date')}>
          {msg(
            { id: 'publishedAt' },
            {
              date: (
                <DateTime
                  data={article.publishedAt}
                  format={currentYear === articleYear ? 'D MMMM' : 'll'}
                  timeZone={timeZone}
                />
              )
            }
          )}
        </span>
        <span className={classNames('readTime')}>
          {msg(
            { id: 'readingTime' },
            { time: <Duration time={article.readingTime} /> }
          )}
        </span>
      </div>
      <Visible
        condition={!_.isEmpty(article.photos)}
        ifTrue={
          <PhotoImg
            photo={article.photos[0]}
            variant="gallery"
            className={classNames('featuredImg')}
          />
        }
      />
      <div className={classNames('content')}>
        <SafeHtml html={article.content} />
      </div>
      <ul className={classNames('tags')}>
        <TagsList tags={article.tags.map((name) => ({ name }))} />
      </ul>
      <div className={classNames('share')}>
        <p className={classNames('shareTitle')}>{msg({ id: 'share' })}</p>
        <div className={classNames('shareButtons')}>
          <ArticleShareButtons article={article} />
        </div>
      </div>
      <ArticleNavigation navigation={article.navigation} />
    </article>
  )
}

Article.propTypes = {
  articleId: PropTypes.string
}

Article.displayName = 'Article'

export default Article
