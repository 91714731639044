import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import SelectField from '@trileuco/triskel-react-ui/components/ui/SelectField'
import withAuth from '@trileuco/triskel-react-ui/modules/auth/withAuth'

const ProviderSelector = ({ providers, onChange, ...otherProps }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingsTable',
    ignoreGlobalScope: true
  })
  const providerOptions = useMemo(() => {
    return providers.map(({ id, name }) => ({
      label: name,
      value: id
    }))
  }, [providers])

  return (
    <SelectField
      onChange={(providerId) => onChange(providerId)}
      options={providerOptions}
      variant="outline"
      label={msg({ id: 'providerSelectorLabel' })}
      {...otherProps}
    />
  )
}

ProviderSelector.propTypes = {
  providers: PropTypes.array,
  onChange: PropTypes.func
}

ProviderSelector.displayName = 'ProviderSelector'

export default withAuth(ProviderSelector)
