import React from 'react'
import PropTypes from 'prop-types'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { CheckboxField, Link } from '@trileuco/triskel-react-ui/components/ui'

const DataProtectionPolicyField = ({ slug, text, rules }) => {
  const { msg } = useIntl({
    scope: 'balaena.dataProtectionPolicyField',
    ignoreGlobalScope: true
  })

  return (
    <>
      <div className="DataProtectionPolicyField">
        <CheckboxField
          variant="outline"
          label={msg(
            {
              id: 'confirm'
            },
            {
              privacyPolicy: (
                <Link to={slug}>{msg({ id: 'confirm.dataPolicy' })}</Link>
              )
            }
          )}
          field="answer"
          rules={rules}
        />
        {text}
      </div>
    </>
  )
}

DataProtectionPolicyField.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.string,
  rules: PropTypes.array
}

DataProtectionPolicyField.displayName = 'DataProtectionPolicyField'

export default DataProtectionPolicyField
