import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import BookingBookablePrice from 'components/booking/BookingBookablePrice'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

const BookingPriceBlock = ({ booking }) => {
  const { msg } = useIntl({
    scope: 'bookingPriceBlock',
    ignoreGlobalScope: false
  })
  return (
    <BookingDetailsSummaryBlock title={msg({ id: 'title' })}>
      <BookingBookablePrice booking={booking} />
    </BookingDetailsSummaryBlock>
  )
}

BookingPriceBlock.propTypes = {
  booking: PropTypes.shape()
}

BookingPriceBlock.displayName = 'BookingPriceBlock'

export default BookingPriceBlock
