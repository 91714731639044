import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal, { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useUpdateBookingGuest } from 'components/api'
import _ from 'lodash'
import {
  useForm,
  SubmitButton
} from '@trileuco/triskel-react-ui/components/ui/Form'
import { useHistory } from '@trileuco/triskel-react-ui/components/router'

import BookingGuestSummaryBlock from './BookingGuestSummaryBlock'
import BookingGuestsFieldsets from './BookingGuestsFieldsets'

const BookingGuestsSummary = ({
  guests,
  variant,
  bookable,
  booking,
  refetch
}) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingFields',
    ignoreGlobalScope: true
  })

  const history = useHistory()
  const inGuestsPage = history.location.pathname.includes('guests')

  const { open, close, isOpen } = useModal(false)

  const [phoneInputError, setPhoneInputError] = useState(false)

  const { updateGuest } = useUpdateBookingGuest()

  const mandatoryFields = useMemo(() => {
    if (bookable) {
      return bookable.mandatoryBookingFields.map(_.camelCase)
    }
  }, [bookable])

  const plainGuestTypes = useMemo(() => {
    return guests.reduce(
      (plainGuests, { type, amount, index }) => [
        ...plainGuests,
        ...Array(amount).fill({ ...type })
      ],
      []
    )
  }, [guests])

  const memorizedDefaultValues = useMemo(() => {
    if (plainGuestTypes) {
      return {
        guests: [plainGuestTypes[0]].map((plainGuestType) => {
          const guestInfo = guests[0]
          const { country, region, ...rest } = guestInfo
          return {
            guestId: plainGuestType.guestId,
            countryAndRegion: {
              country,
              region
            },
            ...rest
          }
        })
      }
    }
  }, [guests, plainGuestTypes])

  const extrasByGuest = useMemo(() => {
    if (bookable) {
      return _.isEmpty(bookable.extraOptions)
        ? []
        : bookable.extraOptions.filter((extra) =>
            extra.type.includes('by_guest')
          )
    }
  }, [bookable])

  const handleSubmit = useCallback(
    (values, instance) => {
      if (instance) {
        const phoneInputIsValid =
          !_.isEmpty(values.guests[0].phone) &&
          values.guests[0].phone.length > 3

        const formIsvalid =
          (instance.meta.isValid && !mandatoryFields.includes('phone')) ||
          (instance.meta.isValid &&
            mandatoryFields.includes('phone') &&
            phoneInputIsValid)

        if (formIsvalid) {
          const {
            id,
            countryAndRegion: { country, region } = {},
            ...rest
          } = values.guests[0]
          updateGuest(booking.id, id, {
            guest_country: country,
            guest_region: region,
            ...rest
          }).then(() => refetch({ bookingId: booking && booking.id }))
        } else {
          setPhoneInputError(true)
        }
      }
    },
    [booking, mandatoryFields, refetch, updateGuest]
  )

  const { Form, values, ...instance } = useForm({
    onSubmit: handleSubmit,
    defaultValues: memorizedDefaultValues
  })

  const handleGuestChange = useCallback(
    (index, guest) => {
      instance.setFieldValue(`guests.${index}`, {
        ..._.omit(guest, ['birthdate', 'country', 'region']),
        ...(guest.birthdate && { birthdate: new Date(guest.birthdate) }),
        countryAndRegion: {
          country: _.get(guest, ['country'], null),
          region: _.get(guest, ['region'], null)
        }
      })
      open()
    },
    [instance, open]
  )

  useEffect(() => {
    if (
      !_.isEmpty(values.guests[0].phone) &&
      values.guests[0].phone.length > 3
    ) {
      setPhoneInputError(false)
    }
  }, [values.guests])

  return (
    <>
      {guests.map((guest, index) => (
        <BookingGuestSummaryBlock
          key={index}
          guestIndex={index}
          variant={variant}
          guest={guest}
          booking={booking}
          showEditButton={inGuestsPage}
          handleChangeGuest={handleGuestChange}
        />
      ))}

      {inGuestsPage && (
        <Modal
          className="UpdateBookingGuestModal"
          isOpen={isOpen}
          onClose={() => {
            instance.reset()
            close()
          }}
          header={
            <h1>
              {msg(
                {
                  id: 'guestBlockTitle'
                },
                {
                  guestLabel:
                    plainGuestTypes &&
                    plainGuestTypes[0].labels.singular.toLowerCase(),
                  guestCounter:
                    _.findIndex(
                      guests,
                      (guest) => guest.id === values.guests[0].id
                    ) + 1
                }
              )}
            </h1>
          }
          body={
            <Form className="UpdateBookingGuestForm">
              <BookingGuestsFieldsets
                onlyMainGuestInfo={bookable && bookable.onlyMainGuestInfo}
                requiredFields={mandatoryFields}
                guests={[plainGuestTypes[0]]}
                extrasByGuest={extrasByGuest}
                phoneInputError={phoneInputError}
                currency={bookable && bookable.price.currency}
              />
              <SubmitButton
                size="l"
                disabled={false}
                text={msg({ id: 'guestBlockButton' })}
                onClick={handleSubmit}
              />
            </Form>
          }
        />
      )}
    </>
  )
}

BookingGuestsSummary.propTypes = {
  guests: PropTypes.arrayOf(PropTypes.shape()),
  variant: PropTypes.string,
  booking: PropTypes.object,
  bookable: PropTypes.object,
  refetch: PropTypes.func
}

BookingGuestsSummary.defaultProps = {
  variant: 'fill'
}

BookingGuestsSummary.displayName = 'BookingGuestsSummary'

export default BookingGuestsSummary
