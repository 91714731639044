const NMIScriptElementId = 'NMI'

const NMISrc = 'https://secure.networkmerchants.com/token/Collect.js'

let loading = true

const NMIUtils = {
  load: ({ tokenizationKey }) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(NMIScriptElementId)) {
        if (loading) {
          document
            .getElementById(NMIScriptElementId)
            .addEventListener('load', () => resolve())
        } else {
          resolve()
        }
      } else {
        loading = true
        var NMIScriptElement = document.createElement('script')
        NMIScriptElement.id = NMIScriptElementId
        NMIScriptElement.async = true
        NMIScriptElement.type = 'text/javascript'
        NMIScriptElement.src = NMISrc
        NMIScriptElement.dataset.tokenizationKey = tokenizationKey
        NMIScriptElement.onload = () => {
          loading = false
          resolve()
        }
        NMIScriptElement.onerror = () => {
          reject(new Error('Error loading redsys script'))
        }
        document.getElementsByTagName('head')[0].appendChild(NMIScriptElement)
      }
    })
  }
}

export default NMIUtils
