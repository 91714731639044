import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import PhotoImg, { toVariantUrl } from 'components/misc/PhotoImg'
import { useMediaQuery } from 'react-responsive'
import BackgroundSlider from 'react-background-slider'
import photosPropTypes from 'components/api/photos/photosPropTypes'

import TextOrNode from '../TextOrNode'

const HeaderPhotos = ({ photos }) => {
  const isScreenXS = useMediaQuery({ maxWidth: 767 })
  const sliderPhotos = useMemo(() => {
    if (!photos) {
      return null
    }
    return _.shuffle(
      photos.map((photo) => {
        return toVariantUrl(photo, 'header')
      })
    )
  }, [photos])
  if (isScreenXS || !sliderPhotos) {
    return null
  }
  if (photos.length === 1) {
    return (
      <PhotoImg photo={photos[0]} className="HeaderPhoto" variant="header" />
    )
  }
  return <BackgroundSlider images={sliderPhotos} duration={10} transition={2} />
}

HeaderPhotos.propTypes = {
  photos: photosPropTypes
}

const HeaderSection = ({
  className,
  size,
  variant,
  photos,
  logo,
  logoSvg,
  breadcrumb,
  preTitle,
  title,
  content,
  other
}) => {
  const { classNames } = useClassNames({
    alias: 'HeaderSection'
  })

  return (
    <header
      className={`${classNames([
        {
          variant
        },
        {
          size
        },
        {
          withLogo: (logo || logoSvg) && 'withLogo'
        }
      ])} ${className}`.trim()}
      {...other}
    >
      {variant === 'clear' ? null : <HeaderPhotos photos={photos} />}
      <div className={classNames('contentWrapper')}>
        {breadcrumb}
        <div className={classNames('content')}>
          {logo && <PhotoImg photo={logo} variant="medium" />}
          {logoSvg && (
            <img
              src={logoSvg.url}
              alt={logoSvg.alt}
              className={classNames('logoSvg')}
            />
          )}
          <div>
            <TextOrNode content={preTitle} className={classNames('preTitle')} />
            <TextOrNode
              TextTag={'h1'}
              content={title}
              className={classNames('title')}
            />
            <TextOrNode
              content={content}
              className={classNames('description')}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

HeaderSection.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.oneOf(['s', 'm']), PropTypes.string]),
  variant: PropTypes.oneOf(['hero', 'clear']),
  photos: photosPropTypes,
  logo: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  logoSvg: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  breadcrumb: PropTypes.node,
  preTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  other: PropTypes.object
}

HeaderSection.defaultProps = {
  className: ' ',
  variant: 'hero'
}

HeaderSection.displayName = 'HeaderSection'

export default HeaderSection
