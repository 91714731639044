import { useMemo } from 'react'
import { useFetchCache } from 'components/api/ApiCacheContext'
import _ from 'lodash'
import { useTenantConfig } from 'modules/bookables/Provider'

const setParentToCategory = (category, parent, parents = []) => {
  const categoryParents = [...parents, ...(parent ? [parent.slug] : [])]
  return {
    ...category,
    name: category.name,
    children:
      category.children &&
      category.children.map((child) =>
        setParentToCategory(child, category, categoryParents)
      ),
    parents: categoryParents
  }
}

const filterEmptyCategories = (categories) => {
  if (!categories) {
    return []
  }
  categories.forEach((category) => {
    category.children = filterEmptyCategories(category.children)
  })
  return categories.filter(
    (category) =>
      category &&
      (!_.isEmpty(category.children) || category.bookablesCount !== 0)
  )
}

const useGetCategories = (options = {}) => {
  const fetchedCategories = useFetchCache({
    path: `/bookable-categories`,
    entity: 'bookableCategories',
    ...options
  })

  const {
    loading,
    bookableCategories,
    error,
    refetch,
    reloading
  } = fetchedCategories

  const {
    components: { filterEmptyCategories: filterEmptyCategoriesFlag } = {}
  } = useTenantConfig()

  const processedCategories = useMemo(() => {
    if (loading) {
      return []
    }

    if (bookableCategories) {
      let result = bookableCategories
      if (filterEmptyCategoriesFlag) {
        result = filterEmptyCategories(bookableCategories)
      }
      return result.map((category) => setParentToCategory(category))
    }
    return []
  }, [loading, bookableCategories, filterEmptyCategoriesFlag])

  return {
    loading,
    reloading,
    refetch,
    error,
    bookableCategories: processedCategories
  }
}

export default useGetCategories
