import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const RichTextSection = ({ title, children, className }) => {
  const { classNames } = useClassNames({
    alias: 'RichTextSection'
  })
  return (
    <section className={`${classNames()} ${className}`.trim()}>
      <h2>{title}</h2>
      {children}
    </section>
  )
}

RichTextSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
}

RichTextSection.defaultProps = {
  className: ' '
}

RichTextSection.displayName = 'RichTextSection'

export default RichTextSection
