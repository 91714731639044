import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useGetCategories } from 'components/api'
import { generateCategoryPath } from 'modules/bookables/domain/categories'
import Block from 'components/layout/Block'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import PhotoImg from 'components/misc/PhotoImg'

const RootCategories = ({ title }) => {
  const { classNames } = useClassNames({
    alias: 'RootCategories'
  })
  const { bookableCategories, loading: loadingCategories } = useGetCategories()
  if (loadingCategories) return null
  return (
    <>
      {bookableCategories && (
        <Block title={title} variant="clear" className={classNames()}>
          <div className={classNames('list')}>
            {bookableCategories.map((category, index) => {
              const url = generateCategoryPath(bookableCategories, category.id)
              return (
                <Link to={url} key={index} className={classNames('category')}>
                  <div>
                    <Visible
                      condition={!_.isEmpty(category.photos)}
                      ifTrue={
                        <PhotoImg
                          photo={category.photos[0]}
                          variant="thumb"
                          className={classNames('categoryPhoto')}
                        />
                      }
                      otherwise={
                        <img
                          src={NoPhoto}
                          className={classNames('categoryPhoto')}
                          alt=""
                        />
                      }
                    />
                    <p className={classNames('categoryName')}>
                      {category.name}
                    </p>
                  </div>
                </Link>
              )
            })}
          </div>
        </Block>
      )}
    </>
  )
}

RootCategories.propTypes = {
  title: PropTypes.string
}

RootCategories.displayName = 'RootCategories'

export default RootCategories
