const redsysScriptElementId = 'redsys'

const redsysSrc = 'https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV2.js'

let loading = true

const redsysUtils = {
  load: () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(redsysScriptElementId)) {
        if (loading) {
          document
            .getElementById(redsysScriptElementId)
            .addEventListener('load', () => resolve())
        } else {
          resolve()
        }
      } else {
        loading = true
        var redsysScriptElement = document.createElement('script')
        redsysScriptElement.id = redsysScriptElementId
        redsysScriptElement.async = true
        redsysScriptElement.type = 'text/javascript'
        redsysScriptElement.src = redsysSrc
        redsysScriptElement.onload = () => {
          resolve()
          loading = false
        }
        redsysScriptElement.onerror = () => {
          reject(new Error('Error loading redsys script'))
        }
        document
          .getElementsByTagName('head')[0]
          .appendChild(redsysScriptElement)
      }
    })
  }
}

export default redsysUtils
