import { useMemo } from 'react'
import { useGetBookableAvailability, useGetBookings } from 'components/api'
import moment from 'moment'

const defaultFrom = moment().startOf('month').toDate()
const defaultTo = moment().endOf('month').toDate()

const useGetBookableBookingsByAvailableDateTime = ({
  bookableId,
  bookerId,
  timeZone,
  from = defaultFrom,
  to = defaultTo,
  page = 1,
  status,
  pageSize = 1000
}) => {
  if (!bookableId) {
    throw new Error('Bookable ID is required')
  }
  const {
    schedules,
    loading: loadingAvailabilities
  } = useGetBookableAvailability({
    id: bookableId,
    timeZone,
    from,
    to
  })

  const { bookings, loading: loadingBookings } = useGetBookings({
    bookerId,
    page,
    pageSize,
    status,
    bookableId: bookableId,
    from: from,
    to: to
  })

  const bookableBookingsByAvailableDateTime = useMemo(() => {
    const loading = loadingAvailabilities || loadingBookings
    const isPedingData = !schedules || !bookings
    if (loading || isPedingData) {
      return {
        bookableBookingsByAvailableDateTime: {},
        loading: true
      }
    }
    return {
      bookableBookingsByAvailableDateTime: schedules.reduce(
        (scheduleByDateTime, schedule) => ({
          ...scheduleByDateTime,
          [schedule.dateTime]: {
            dateTime: schedule.dateTime,
            remainingCapacity: schedule.capacity.remaining,
            bookings: bookings.filter(({ dateTime }) => {
              return dateTime === schedule.dateTime
            })
          }
        }),
        {}
      ),
      loading: false
    }
  }, [loadingAvailabilities, loadingBookings, schedules, bookings])

  return bookableBookingsByAvailableDateTime
}

export default useGetBookableBookingsByAvailableDateTime
