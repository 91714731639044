import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

const BookingExtrasBlock = ({ extras }) => {
  const { msg } = useIntl({
    scope: 'bookingExtras',
    ignoreGlobalScope: false
  })
  return (
    <BookingDetailsSummaryBlock
      className="BookingExtrasBlock"
      title={msg({ id: 'extras' })}
    >
      <ul className="BookingExtrasList">
        {extras && extras.map((extra) => <li key={extra.id}>{extra.name}</li>)}
      </ul>
    </BookingDetailsSummaryBlock>
  )
}

BookingExtrasBlock.propTypes = {
  extras: PropTypes.arrayOf(PropTypes.shape())
}

BookingExtrasBlock.displayName = 'BookingExtrasBlock'

export default BookingExtrasBlock
