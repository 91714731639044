import React from 'react'
import { LoadingResults, useGetProviders } from 'components/api'
import ProvidersLocationsMap from 'modules/bookables/pages/providers/ProvidersLocationsMap'
import { textOrNodePropType } from 'components/cms/cmsPropTypes'

const ProvidersLocationsSection = ({ title }) => {
  const { providers, loading } = useGetProviders({
    queryParams: { allAttributes: true }
  })
  if (loading) {
    return <LoadingResults />
  } else if (providers?.length) {
    return <ProvidersLocationsMap providers={providers} title={title} />
  }
  return null
}

ProvidersLocationsSection.propTypes = {
  title: textOrNodePropType
}
ProvidersLocationsSection.displayName = 'ProvidersLocationsSection'

export default ProvidersLocationsSection
