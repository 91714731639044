import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useGetBookingPaymentIframe } from 'components/api'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const BookingPaymentGatewayIframe = ({ cardData, booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentForm.PowertranzPaymentIframe',
    ignoreGlobalScope: true
  })
  const { paymentIframeHtml, errors, loading } = useGetBookingPaymentIframe({
    bookingId: booking.id,
    formId: 'iframePaymentGateway',
    reservationPayment: _.get(booking.payment, 'reservation'),
    cardData
  })
  return (
    <>
      {loading && <p>{msg({ id: 'connecting' })}</p>}
      {!loading &&
        (paymentIframeHtml ? (
          <iframe
            title="powertranz-payment"
            className="IframePaymentGateway_actionContainer"
            width="100%"
            height="700px"
            srcDoc={paymentIframeHtml}
          />
        ) : (
          <>
            <p>{msg({ id: 'errorTitle' })}</p>
            <p>{msg({ id: 'errorDetails' })}</p>
            <textarea style={{ height: '200px', width: '100%' }}>
              {JSON.stringify(errors)}
            </textarea>
          </>
        ))}
    </>
  )
}

BookingPaymentGatewayIframe.propTypes = {
  booking: PropTypes.shape(),
  reservationPayment: PropTypes.bool,
  cardData: PropTypes.shape()
}

BookingPaymentGatewayIframe.displayName = 'BookingPaymentGatewayIframe'

export default BookingPaymentGatewayIframe
