import React from 'react'
import PropTypes from 'prop-types'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import LoadingBar from 'components/layout/LoadingBar'
import { notify } from '@trileuco/triskel-react-ui/components/ui/notifications/NotifySetter'
import { Redirect } from '@trileuco/triskel-react-ui/components/router'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

import AuthModal from './AuthModal'

const BalaenaAuthComponent = ({ code }) => {
  const { msg } = useIntl({
    scope: 'balaena.globals.auth',
    ignoreGlobalScope: true
  })
  const authContext = useAuth()
  if (authContext.initializing) {
    return <LoadingBar />
  }
  switch (code) {
    case 'sign':
    case '401':
      return <AuthModal authContext={authContext} />
    case '403':
      notify({
        id: 'NotAuthorized',
        title: msg({ id: 'notAuthorized' }),
        status: 'danger'
      })
      return <Redirect to="/" />
    default:
      return <span>Authorization error</span>
  }
}

BalaenaAuthComponent.propTypes = {
  code: PropTypes.oneOf(['401', '403'])
}

BalaenaAuthComponent.displayName = 'BalaenaAuthComponent'

export default BalaenaAuthComponent
