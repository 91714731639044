import { useFetchCache } from 'components/api/ApiCacheContext'
import _ from 'lodash'

const defaultQueryParams = {
  includeBookerExtraInfo: false,
  pageSize: 1000,
  page: 1
}

export default ({ queryParams = {}, ...option }) => {
  const { bookers, ...rest } = useFetchCache({
    path: '/bookers',
    entity: 'bookers',
    resolve: (data) => ({
      bookers: _.sortBy(data.bookers, ['name']),
      pagination: _.get(data, 'meta.pagination')
    }),
    queryParams: {
      ...defaultQueryParams,
      ...queryParams
    },
    ...option
  })
  return {
    ...rest,
    bookers: _.get(bookers, 'bookers', []),
    pagination: _.get(bookers, 'pagination', {})
  }
}
