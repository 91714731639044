import React from 'react'
import _ from 'lodash'
import { MessageProvider } from '@trileuco/triskel-react-ui/components/i18n'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useBookingDetails } from 'components/booker/BookingDetails/BookingDetailsContext'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useGetBookable, LoadingResults } from 'components/api'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate/BoundedPageTemplate'

import BookingBookableBlock from './BookingBookableBlock'
import BookingDateBlock from './BookingDateBlock'
import BookingLocationBlock from './BookingLocationBlock'
import BookingGuestsBlock from './BookingGuestsBlock'
import BookingAditionalInfoBlock from './BookingAditionalInfoBlock'
import BookingPriceBlock from './BookingPriceBlock'
import BookingAdminBlock from './BookingAdminBlock'
import BookingExtrasBlock from './BookingExtrasBlock'
import ContactProviderBanner from './ContactProviderBanner'
import PendingBookingBanner from './PendingBookingBanner'

const BookingSummaryPage = () => {
  const { booking, refetch } = useBookingDetails()
  const { bookable, loading } = useGetBookable({
    bookableId: booking.bookable.id
  })
  const authContext = useAuth()

  if (loading) {
    return <LoadingResults />
  }

  return (
    <MessageProvider scope="bookingSummaryPage">
      <BoundedPageTemplate
        mainContent={
          <>
            {!loading && (
              <>
                <BookingBookableBlock booking={booking} bookable={bookable} />
                <BookingDateBlock booking={booking} bookable={bookable} />
                <BookingLocationBlock bookable={bookable} />
              </>
            )}
            <BookingGuestsBlock booking={booking} />
            {!_.isEmpty(booking.questions) && (
              <BookingAditionalInfoBlock booking={booking} refetch={refetch} />
            )}

            {!_.isEmpty(booking.extraOptions) && (
              <BookingExtrasBlock extras={booking.extraOptions} />
            )}

            <BookingPriceBlock booking={booking} />

            <Visible
              condition={authContext.hasSomeRole(['balaena-admin'])}
              ifTrue={<BookingAdminBlock booking={booking} />}
            />
          </>
        }
        sidebar={
          <>
            <Visible
              condition={
                authContext.hasSomeRole([
                  'balaena-admin',
                  'balaena-bookings-manager'
                ]) && booking.status === 'pending'
              }
              ifTrue={
                <PendingBookingBanner booking={booking} loading={loading} />
              }
              otherwise={
                <ContactProviderBanner
                  booking={booking}
                  bookable={bookable}
                  loading={loading}
                />
              }
            />
          </>
        }
      />
    </MessageProvider>
  )
}

BookingSummaryPage.displayName = 'BookingSummaryPage'

export default BookingSummaryPage
