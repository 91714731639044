import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { useTenantConfig } from 'modules/bookables/Provider'
import { homePath } from 'modules/bookables/routes'
import Footer from 'components/layout/Footer'
import { useGetCategories } from 'components/api'
import useCookie from 'components/api/useCookie'
import SafeHtml from 'components/cms/SafeHtml'
import BalaenaToolkitAd from 'components/misc/BalaenaToolkitAd'
import webpagesToGroups from 'components/misc/WebpageGroups'
import { withAuth } from '@trileuco/triskel-react-ui/modules/auth'

const minGroupsToBreakLine = 3

const addSeparatorBetweenIfNeccessary = (group1, group2) => {
  if (
    (group1.length > 0 && group1.length >= minGroupsToBreakLine) ||
    (group2.length > 0 && group2.length >= minGroupsToBreakLine)
  ) {
    return [{ separator: true }]
  }
  return []
}

const concatFooterGroups = (footerGroups) =>
  footerGroups.reduce((concatedGroups, groups, index) => {
    if (index < footerGroups.length - 1) {
      return [
        ...concatedGroups,
        ...groups,
        ...addSeparatorBetweenIfNeccessary(groups, footerGroups[index + 1])
      ]
    }
    return [...concatedGroups, ...groups]
  }, [])

const PoweredByBalaenaTookit = () => {
  return (
    <span>
      {'Powered by '}
      <a
        href="https://balaena.travel/toolkit"
        target="_blank"
        rel="noopener noreferrer"
      >
        Balaena Toolkit
      </a>
    </span>
  )
}

const BalaenaTravelLandingLink = () => {
  const { msg } = useIntl({
    scope: 'balaena.footer',
    ignoreGlobalScope: true
  })
  const { removeItem: removeLandingCookie } = useCookie('landingCountry')
  return (
    <span>
      <a href={window.location.origin} onClick={() => removeLandingCookie()}>
        {msg({ id: 'balaenaTravelLanding' })}
      </a>
    </span>
  )
}

const DefaultCopyright = () => {
  return (
    <span>
      <i className="far fa-copyright" /> {new Date().getFullYear()}{' '}
      {document.location.hostname}
    </span>
  )
}

const legalpageToFooterGroupItem = (legalPage) => ({
  to: `/${legalPage.termOfUseSlug}`,
  title: legalPage.name
})

const categoryToFooterGroupItem = (category) => ({
  to: `/${category.slug}`,
  title: category.name
})

const categoriesToFooterGroups = (categories, rootCategoriesTitle) => {
  if (categories) {
    const rootCategoriesFooterGroup = categories
      .filter((category) => !category.children || !category.children.length)
      .reduce((footerGroup, category, index, { length }) => {
        const items = footerGroup?.items || []
        if (length > 0) {
          return {
            title: rootCategoriesTitle,
            items: items.concat(categoryToFooterGroupItem(category))
          }
        }
        return false
      }, null)
    return [
      ...(rootCategoriesFooterGroup ? [rootCategoriesFooterGroup] : []),
      ...categories
        .filter((category) => category.children.length)
        .reduce((footerGroups, category) => {
          return [
            ...footerGroups,
            {
              title: categoryToFooterGroupItem(category),
              items: category.children.map(categoryToFooterGroupItem)
            }
          ]
        }, [])
    ]
  }
  return []
}

const TenantFooter = ({
  renderBalaenaToolkitAd,
  renderCategoriesFooter,
  renderTopFooter,
  authContext
}) => {
  const {
    components: {
      copyline,
      renderPoweredByBalaena,
      renderCategories,
      footerLogosRegion
    } = {},
    contact,
    webpages,
    legalPages,
    schema,
    intl: { locales },
    social,
    logo,
    name
  } = useTenantConfig()

  const { msg } = useIntl({
    scope: 'balaena.footer',
    ignoreGlobalScope: true
  })

  const { bookableCategories } = useGetCategories()

  const webpagesFooterGroups = useMemo(() => {
    const filter = ({ visibility }) =>
      visibility &&
      visibility.includes('footer') &&
      (!visibility.includes('only_providers') ||
        authContext.hasSomeRole(['balaena-admin', 'balaena-provider']))
    return webpagesToGroups(webpages, filter)
  }, [webpages, authContext])

  const legalPagesFooterGroups = useMemo(() => {
    if (legalPages) {
      return [
        {
          title: msg({ id: 'LegalPagesFooterGroups.title' }),
          items: legalPages.map(legalpageToFooterGroupItem)
        }
      ]
    }
    return []
  }, [legalPages, msg])

  const categoryGroups = useMemo(() => {
    if (
      bookableCategories &&
      renderCategories !== false &&
      renderCategoriesFooter
    ) {
      return categoriesToFooterGroups(
        bookableCategories,
        msg({ id: 'CategoriesFooterGroup.title' })
      )
    }
    return []
  }, [bookableCategories, renderCategories, renderCategoriesFooter, msg])

  const footerGroups = useMemo(
    () =>
      concatFooterGroups([
        categoryGroups,
        webpagesFooterGroups,
        legalPagesFooterGroups
      ]),
    [categoryGroups, webpagesFooterGroups, legalPagesFooterGroups]
  )

  const copyright = (
    <>
      {copyline && <SafeHtml WrapperTag={'span'} html={copyline} />}
      {!copyline && <DefaultCopyright />}
      {schema.endsWith('balaena.travel') && <BalaenaTravelLandingLink />}
      {renderPoweredByBalaena !== false && <PoweredByBalaenaTookit />}
    </>
  )
  const showBalaenatToolkitAd =
    renderBalaenaToolkitAd !== false && schema.endsWith('balaena.travel')
  return (
    <Footer
      renderTopFooter={renderTopFooter}
      advertisment={showBalaenatToolkitAd && <BalaenaToolkitAd view="mini" />}
      logosSection={footerLogosRegion}
      groups={footerGroups}
      locales={locales}
      logo={{
        to: homePath,
        url: logo,
        alt: name
      }}
      copyright={copyright}
      contact={contact}
      social={social}
    />
  )
}

TenantFooter.propTypes = {
  renderBalaenaToolkitAd: PropTypes.bool,
  renderCategoriesFooter: PropTypes.bool,
  renderTopFooter: PropTypes.bool,
  authContext: PropTypes.object
}

TenantFooter.defaultProps = {
  renderBalaenaToolkitAd: true,
  renderCategoriesFooter: true,
  renderTopFooter: true
}

TenantFooter.displayName = 'TenantFooter'

export default withAuth(TenantFooter)
