import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useMediaQuery } from 'react-responsive'
import { MobileBreakpoint } from 'components/layout/Mediaqueries'
import PhotoImg from 'components/misc/PhotoImg'
import TextOrNode from 'components/cms/TextOrNode'
import { textOrNodePropType } from 'components/cms/cmsPropTypes'
import { Parallax } from 'react-parallax'

const ImageAndTextSection = ({
  preTitle,
  title,
  description,
  img, // TODO add bgImg prop support
  order,
  className,
  other,
  textStyle,
  parallaxProps
}) => {
  const isMobile = useMediaQuery(MobileBreakpoint)
  const { classNames } = useClassNames({
    alias: 'ImageAndTextSection'
  })
  const textContent = (
    <div className={classNames('textWrapper')}>
      {preTitle && (
        <TextOrNode
          className={classNames('pretitle')}
          TextTag={'h4'}
          content={preTitle}
          style={textStyle}
        />
      )}
      {title && (
        <TextOrNode
          className={classNames('title')}
          TextTag={'h2'}
          content={title}
          style={textStyle}
        />
      )}
      {description && <TextOrNode content={description} style={textStyle} />}
    </div>
  )
  const sectionClassName = `${
    !isMobile || order === 'Parallax'
      ? classNames({ order: order })
      : classNames()
  } ${className}`.trim()
  return (
    <section className={sectionClassName} {...other}>
      <div>
        {order === 'Parallax' && (
          <Parallax
            bgImage={img}
            strength={500}
            blur={{ min: -1, max: 2 }}
            {...parallaxProps}
          >
            {textContent}
          </Parallax>
        )}
        {order !== 'Parallax' && (
          <>
            <div className={classNames('imgWrapper')}>
              <PhotoImg photo={img} variant="gallery" />
            </div>
            {textContent}
          </>
        )}
      </div>
    </section>
  )
}

ImageAndTextSection.propTypes = {
  order: PropTypes.oneOf(['TextStart', 'TextEnd']).isRequired,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.object
    })
  ]),
  preTitle: textOrNodePropType,
  title: textOrNodePropType,
  description: textOrNodePropType,
  other: PropTypes.object,
  className: PropTypes.string,
  textStyle: PropTypes.object,
  parallaxProps: PropTypes.object
}

ImageAndTextSection.defaultProps = {
  order: 'TextStart',
  className: ' '
}

ImageAndTextSection.displayName = 'ImageAndTextSection'

export default ImageAndTextSection
