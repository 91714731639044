import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDeleteComment } from 'components/api'

import BookingComment from './BookingComment'

const BookingCommentsList = ({ bookingId, comments, refetch }) => {
  const { deleteComment } = useDeleteComment({ bookingId })

  const handleDelete = useCallback(
    (commentId) => {
      deleteComment(commentId).then(refetch)
    },
    [deleteComment, refetch]
  )

  const bookingCommentsListRef = useRef()

  // Move the scroll to list bottom to show the last comment
  useEffect(() => {
    if (bookingCommentsListRef.current) {
      bookingCommentsListRef.current.scrollTop =
        bookingCommentsListRef.current.scrollHeight
    }
  }, [])

  return (
    <div className="BookingCommentsList" ref={bookingCommentsListRef}>
      {comments.map((comment) => (
        <BookingComment
          key={comment.id}
          comment={comment}
          onDelete={handleDelete}
        />
      ))}
    </div>
  )
}

BookingCommentsList.propTypes = {
  bookingId: PropTypes.number,
  refetch: PropTypes.func,
  comments: PropTypes.arrayOf(PropTypes.shape())
}

BookingCommentsList.displayName = 'BookingCommentsList'

export default BookingCommentsList
