import React from 'react'
import Block from 'components/layout/Block'
import PhotoGallery from 'components/misc/PhotoGallery'
import photosPropTypes from 'components/api/photos/photosPropTypes'

const PhotoGalleryBlock = ({ photos }) => (
  <Block title="" className="PhotoGalleryBlock" variant="clear">
    <PhotoGallery photos={photos} />
  </Block>
)

PhotoGalleryBlock.propTypes = {
  photos: photosPropTypes
}

PhotoGalleryBlock.displayName = 'PhotoGalleryBlock'

export default PhotoGalleryBlock
