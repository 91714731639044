import PropTypes from 'prop-types'

export default PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      weight: PropTypes.number
    }),
    PropTypes.object
  ])
)
