import { useGet } from 'components/api'

const useGetDiscount = ({ token, bookableId, ...options }) => {
  const { data: discount, ...state } = useGet({
    path: `/discounts/${token}`,
    queryParams: {
      bookableId
    },
    resolve: (data) => {
      return data.discount
    },
    ...options
  })
  return {
    discount,
    ...state
  }
}

export default useGetDiscount
