import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { FieldWrapper } from '@trileuco/triskel-react-ui/components/ui'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { useTenantConfig } from 'modules/bookables/Provider'

const BookingPhoneField = ({
  value,
  onChange,
  placeholder,
  preferredCountries,
  phoneInputError = false
}) => {
  const { msg } = useIntl({
    scope: 'components.form.rules',
    ignoreGlobalScope: true
  })

  const {
    intl: { defaultCountry }
  } = useTenantConfig()

  const errorMessage = phoneInputError && msg({ id: 'required' })
  return (
    <FieldWrapper variant="outline" fieldType="Text" error={errorMessage}>
      <PhoneInput
        country={defaultCountry}
        field="phone"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        preferredCountries={preferredCountries}
        inputClass="PhonePrefixInput"
        containerClass="Field___outline GuestField"
      />
    </FieldWrapper>
  )
}

BookingPhoneField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preferredCountries: PropTypes.arrayOf(PropTypes.shape()),
  phoneInputError: PropTypes.bool
}

BookingPhoneField.displayName = 'BookingPhoneField'

export default BookingPhoneField
