import React from 'react'
import PropTypes from 'prop-types'
import { homePath } from 'modules/bookables/routes'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { NavLink } from 'react-router-dom'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'

const Breadcrumb = ({ links, noHome, noLinks, alias }) => {
  const { msg } = useIntl({
    scope: 'balaena.breadcrumb',
    ignoreGlobalScope: true
  })
  const { classNames } = useClassNames({ alias })
  return (
    <ul className={classNames()}>
      {!noHome && (
        <li>
          <NavLink exact to={homePath}>
            {msg({ id: 'home' })}
          </NavLink>
        </li>
      )}
      {links.map((link, index) => {
        if (link.isNotLink || noLinks) {
          return <li key={index}>{link.name}</li>
        }
        return (
          <li key={index}>
            <NavLink exact to={link.to}>
              {link.name}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

Breadcrumb.displayName = 'Breadcrumb'

Breadcrumb.propTypes = {
  lastIsLink: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node,
      to: PropTypes.string,
      isNotLink: PropTypes.bool
    })
  ),
  noHome: PropTypes.bool,
  noLinks: PropTypes.bool,
  alias: PropTypes.string
}

Breadcrumb.defaultProps = {
  lastIsLink: false,
  noHome: false,
  noLinks: false,
  alias: 'Breadcrumb'
}

export default Breadcrumb
