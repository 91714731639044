import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Collapse, { Panel } from 'rc-collapse'
import 'rc-collapse/assets/index.css'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import NoPhoto from 'assets/img/fallback/missing_bookable_mainphoto.jpg'
import { generateCategoryPath } from 'modules/bookables/domain/categories'
import { useGetCategories } from 'components/api'
import PhotoImg from 'components/misc/PhotoImg'

import CategoryNavMenuItem from './CategoryNavMenuItem'

const ParentCategory = ({ category, linkTo }) => {
  const { classNames } = useClassNames({
    alias: 'ParentCategory'
  })
  const content = useMemo(
    () => (
      <>
        <Visible
          condition={!_.isEmpty(category.photos)}
          ifTrue={<PhotoImg photo={category.photos[0]} variant="thumb" />}
          otherwise={<img src={NoPhoto} alt="" />}
        />
        {category.name}
      </>
    ),
    [category]
  )
  return (
    <>
      {linkTo ? (
        <Link to={linkTo} className={classNames()}>
          {content}
        </Link>
      ) : (
        <div className={classNames()}>{content}</div>
      )}
    </>
  )
}

ParentCategory.propTypes = {
  category: PropTypes.object,
  linkTo: PropTypes.string
}

const CategoriesNavMenu = () => {
  const { bookableCategories, loading: loadingCategories } = useGetCategories()
  const { msg } = useIntl({
    scope: 'balaena.mobileNavMenu',
    ignoreGlobalScope: true
  })
  if (loadingCategories) {
    return null
  }
  return (
    <ul className="CategoriesNavMenu">
      {bookableCategories.map((category, index) => {
        const url = generateCategoryPath(bookableCategories, category.id)
        return (
          <Fragment key={index}>
            {category.children && category.children.length > 0 ? (
              <Collapse>
                <Panel header={<ParentCategory category={category} />}>
                  <CategoryNavMenuItem
                    className="CategoryWrapper"
                    category={category}
                    depthLevel={1}
                    maxDepth={2}
                    showSubCategoriesPhoto
                    onlyChildren={true}
                  />
                  <Link to={url} className="ParentCategoryLink">
                    {msg(
                      { id: 'parentCategoryLink' },
                      { categoryName: category.name }
                    )}
                  </Link>
                </Panel>
              </Collapse>
            ) : (
              <ParentCategory linkTo={url} category={category} />
            )}
          </Fragment>
        )
      })}
    </ul>
  )
}

CategoriesNavMenu.propTypes = {}

CategoriesNavMenu.displayName = 'CategoriesNavMenu'

export default CategoriesNavMenu
