import { createPortal } from 'react-dom'

const layoutId = 'root'

const createLayoutPortal = (node) =>
  createPortal(node, document.getElementById(layoutId))

createLayoutPortal.displayName = 'createLayoutPortal'

export default createLayoutPortal
