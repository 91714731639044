import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const ContactFormSection = ({ className, textContent, form }) => {
  const { classNames } = useClassNames({
    alias: 'ContactFormSection'
  })
  return (
    <section className={`${classNames()} ${className}`.trim()}>
      <div>
        <div className={classNames('textWrapper')}>
          <div>{textContent}</div>
        </div>
        <div className={classNames('formWrapper')}>{form}</div>
      </div>
    </section>
  )
}

ContactFormSection.propTypes = {
  className: PropTypes.string,
  textContent: PropTypes.node,
  form: PropTypes.object
}

ContactFormSection.defaultProps = {
  className: ' '
}

ContactFormSection.displayName = 'ContactFormSection'

export default ContactFormSection
