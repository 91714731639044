import PropTypes from 'prop-types'
import ISO6391 from 'iso-639-1'

const LanguageLabel = ({ languageCode }) => {
  if (!languageCode) {
    return '-'
  }
  const languageName = ISO6391.getNativeName(languageCode.toLowerCase())
  if (!languageName) {
    return '-'
  }
  // XXX: Fix for 'galego' language that is lower in ISO6391 data
  return languageName.charAt(0).toUpperCase() + languageName.slice(1)
}

LanguageLabel.propTypes = {
  languageCode: PropTypes.string
}

LanguageLabel.displayName = 'LanguageLabel'

export default LanguageLabel
