import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import { useGetCategory, LoadingResults } from 'components/api'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import MetaTags from 'components/layout/MetaTags'
import NotFoundError from 'components/error/NotFoundError'
import SafeHtml from 'components/cms/SafeHtml'

import CategoryHeader from './CategoryHeader'
import CategoryChildren from './CategoryChildren'
import CategoryBookables from './CategoryBookables'
import CategorySiblings from './CategorySiblings'

const CategoryPage = ({ location, match }) => {
  const parentSlugParam = location.pathname.split('/')[1]

  const { slug: categoryId } = match.params

  const { bookableCategory: category, loading } = useGetCategory({
    categoryId: categoryId
  })

  let header = null
  let mainContent = null

  if (loading) {
    mainContent = <LoadingResults />
  } else if (!category) {
    throw new NotFoundError('Category not found for id ' + categoryId)
  } else {
    const hasChildren = !_.isEmpty(category.children)
    const parentSlug = categoryId === parentSlugParam ? null : parentSlugParam
    const parentSlugs = `${match.params[0] || ''}/${categoryId}`

    header = (
      <>
        {category.metaTags && <MetaTags metaTags={category.metaTags} />}
        {
          <CategoryHeader
            photos={category.photos}
            name={category.title || category.name}
            descriptions={category.descriptions}
          />
        }
      </>
    )

    mainContent = (
      <BoundedPageTemplate
        mainContent={
          <>
            {hasChildren ? (
              <CategoryChildren
                categoryName={category.name}
                parentSlugs={parentSlugs}
                children={category.children}
              />
            ) : (
              <>
                <CategoryBookables
                  categoryId={category.id}
                  categoryName={category.name}
                />
                <CategorySiblings
                  categorySlug={category.slug}
                  parentSlug={parentSlug}
                />
              </>
            )}
            {category.descriptions && category.descriptions.long && (
              <div className="PageTextContent">
                <SafeHtml html={category.descriptions.long} />
              </div>
            )}
          </>
        }
      />
    )
  }
  return (
    <Page id="CategoryPage">
      <PublicPageSkeleton headerAssets={header} mainContent={mainContent} />
    </Page>
  )
}

CategoryPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

CategoryPage.displayName = 'CategoryPage'

export default CategoryPage
