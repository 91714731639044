import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import BookingDayPicker from './BookingDayPicker'

export const DateSelectorCalendar = ({
  completedGuestsAmount,
  prices,
  loadingBookableAvailabilities,
  scheduleGroupedByDayAndDateTime,
  handleChangeMonth,
  handleChangeDay,
  bookable,
  amount,
  initialMonth,
  bookingDateRange = {}
}) => {
  const { classNames } = useClassNames({ alias: 'DateSelectorCalendar' })
  return (
    <div className={classNames()}>
      <BookingDayPicker
        bookable={bookable}
        field="day"
        prices={Object.assign({}, prices, {
          currency: bookable.price.currency
        })}
        interval={bookable.continuous}
        initialMonth={initialMonth}
        timeZone={bookable.timeZone}
        reservationCutoff={bookable.reservationCutoff}
        disabled={
          loadingBookableAvailabilities || !amount || !completedGuestsAmount
        }
        onChange={handleChangeDay}
        onMonthChange={handleChangeMonth}
        scheduleGroupedByDayAndDateTime={scheduleGroupedByDayAndDateTime}
        bookingDateRange={bookingDateRange}
      />
    </div>
  )
}

DateSelectorCalendar.displayName = 'DateSelectorCalendar'

DateSelectorCalendar.propTypes = {
  completedGuestsAmount: PropTypes.bool,
  prices: PropTypes.object,
  loadingBookableAvailabilities: PropTypes.bool,
  schedulesGroupedByDay: PropTypes.object,
  scheduleGroupedByDayAndDateTime: PropTypes.object,
  handleChangeMonth: PropTypes.func,
  handleChangeDay: PropTypes.func,
  bookable: PropTypes.shape(),
  amount: PropTypes.number,
  initialMonth: PropTypes.object,
  bookingDateRange: PropTypes.object
}
