import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, TextField } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useGetBackendError } from 'modules/bookables/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const BookingTicketsForm = ({
  bookingId,
  onChange,
  isValidating,
  error,
  noResults
}) => {
  const { msg } = useIntl({
    scope: 'balaena.BookingTicketsForm',
    ignoreGlobalScope: true
  })

  const { msg: msgError } = useIntl({
    scope: 'backend.errors.common',
    ignoreGlobalScope: true
  })

  const { classNames } = useClassNames({ alias: 'BookingTicketsForm' })

  const { getBackendError } = useGetBackendError()

  const errorMessage = useMemo(() => {
    if (error) {
      return getBackendError(error.data)
    } else if (noResults) {
      return msgError({ id: 'not_found' })
    } else {
      return null
    }
  }, [error, noResults, msgError, getBackendError])

  return (
    <Form className={classNames()}>
      <TextField
        className={classNames('fieldSet')}
        variant="outline"
        label={msg({ id: 'title' })}
        onChange={onChange}
        value={bookingId}
        type="search"
        isValidating={isValidating}
        error={errorMessage}
      />
    </Form>
  )
}

BookingTicketsForm.propTypes = {
  bookingId: PropTypes.string,
  onChange: PropTypes.func,
  isValidating: PropTypes.bool,
  noResults: PropTypes.bool,
  error: PropTypes.shape()
}

BookingTicketsForm.displayName = 'BookingTicketsForm'

export default BookingTicketsForm
