import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useUpdateBookingState } from 'components/api'
import { useMediaQuery } from 'react-responsive'
import { MobileBreakpoint } from 'components/layout/Mediaqueries'
import _ from 'lodash'
import { useTenantConfig } from 'modules/bookables/Provider'

const BookingTicketsActions = ({ booking, refetch }) => {
  const { msg } = useIntl({
    scope: 'balaena.BookingTicketsActions',
    ignoreGlobalScope: true
  })
  const { classNames } = useClassNames({
    alias: 'BookingTicketsActions'
  })
  const isMobile = useMediaQuery(MobileBreakpoint)

  const {
    defaultStates: { bookables: defaultBookableStates = [] } = {}
  } = useTenantConfig()

  const {
    id: bookingId,
    states = [],
    bookable: { allowedStates: bookableStates }
  } = booking

  const allowedStates = useMemo(() => {
    if (booking && booking.bookable.continuos) {
      return _.difference(bookableStates, defaultBookableStates)
    }
    return bookableStates
  }, [booking, bookableStates, defaultBookableStates])

  const { updateState } = useUpdateBookingState(bookingId)

  const TicketAction = ({ allowedState }) => {
    const isDisabled =
      !_.isEmpty(states) &&
      states.timeline.map(({ state }) => state).includes(allowedState)
    const state = { state: allowedState }
    return (
      <>
        <Button
          size={isMobile ? 'l' : 'm'}
          variant="outline"
          text={msg({ id: 'setState' }, state)}
          onClick={() => updateState(state).then(refetch)}
          disabled={isDisabled}
        />
      </>
    )
  }

  TicketAction.propTypes = {
    allowedState: PropTypes.string
  }

  return (
    <div className={classNames()}>
      <p className={classNames('title')}>Actualizar estado</p>
      {allowedStates.map((allowedState, index) => (
        <TicketAction key={index} allowedState={allowedState} />
      ))}
    </div>
  )
}

BookingTicketsActions.propTypes = {
  booking: PropTypes.shape(),
  refetch: PropTypes.func
}

BookingTicketsActions.displayName = 'BookingTicketsActions'

export default BookingTicketsActions
