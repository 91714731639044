import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { StatelessSidebar } from 'components/layout/Sidebar'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import CategoryCard from 'components/layout/nav/CategoryCard'
import createLayoutPortal from 'components/hooks/createLayoutPortal'
import _ from 'lodash'

const CategoryDetailsSidebar = ({
  category,
  categories,
  sidebarSubcategoryText,
  ...sidebarProps
}) => {
  const { classNames } = useClassNames({ alias: 'CategoryDetailsSidebar' })

  const hasChildren = category?.children?.length > 0
  const columns = category?.children?.length > 3 ? 2 : 1

  const { msg } = useIntl({
    scope: 'components.nav.CategoryDetailsSidebar',
    ignoreGlobalScope: true
  })

  const subcategoryText = useMemo(() => {
    return !_.isUndefined(sidebarSubcategoryText)
      ? sidebarSubcategoryText
      : msg({ id: 'childrenTitle' })
  }, [msg, sidebarSubcategoryText])

  return createLayoutPortal(
    <StatelessSidebar {...sidebarProps} className={classNames()}>
      {category && (
        <div className={classNames('wrapper')}>
          <CategoryCard
            size="m"
            category={category}
            categories={categories}
            showChildren
          />
          {hasChildren && (
            <div className={classNames('children')}>
              <h2 className={classNames('childrenTitle')}>{subcategoryText}</h2>
              <ul
                className={classNames('childrenList', {
                  [`cols${columns}`]: true
                })}
              >
                {category.children.map((categoryChildren) => {
                  return (
                    <li key={categoryChildren.id}>
                      <CategoryCard
                        size="s"
                        category={categoryChildren}
                        categories={categories}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </StatelessSidebar>
  )
}

CategoryDetailsSidebar.propTypes = {
  renderCategories: PropTypes.bool,
  sidebarSubcategoryText: PropTypes.string
}

CategoryDetailsSidebar.displayName = 'CategoryDetailsSidebar'

export default CategoryDetailsSidebar
