import React, { useCallback, useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useLocation } from '@trileuco/triskel-react-ui/components/router'
import { useAnalytics } from '@trileuco/triskel-react-ui/modules/analytics'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Modal } from '@trileuco/triskel-react-ui/components/ui'
import { useSignIn, useSignUp } from 'components/api'
import Block from 'components/layout/Block'
import { useAbsoluteHomePath } from 'modules/bookables/Provider'

import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import SocialSignIn from './SocialSignIn'

const AuthModal = (props) => {
  const authContext = useAuth()
  const {
    initializing,
    recoveryUrl,
    keycloak: { clientId }
  } = authContext
  const [mode, setMode] = useState('signin')
  const { msg } = useIntl({
    scope: 'balaena.signModal',
    ignoreGlobalScope: true
  })
  const { signIn, loading: signingIn } = useSignIn()
  const { signUp, loading: signingUp } = useSignUp()
  const {
    notify,
    statusTypes: { DANGER }
  } = useNotifications()

  const location = useLocation()
  const Analytics = useAnalytics()
  const trackAuthModal = ({ action }) => {
    if (Analytics.enabled) {
      Analytics.event({
        category: 'Form',
        label: location.pathname,
        action
      })
    }
  }

  const absoluteHomePath = useAbsoluteHomePath()

  const authWrapperRef = useRef()

  // Scroll to top when mode change
  useEffect(() => {
    if (authWrapperRef.current) {
      authWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [mode])

  const toggleMode = useCallback(() => {
    setMode(mode === 'signin' ? 'signup' : 'signin')
  }, [mode, setMode])

  const setInitOptions = useCallback(
    // eslint-disable-next-line
    ({ access_token, refresh_token, refresh_expires_in }) => {
      authContext
        .setInitOptions({
          refreshExpiresIn: refresh_expires_in,
          token: access_token,
          refreshToken: refresh_token,
          checkLoginIframe: false
        })
        .catch((error) => {
          notify({
            id: 'SIGNIN',
            message: _.get(error, 'data.message'),
            title: _.get(error, 'message'),
            status: DANGER
          })
        })
    },
    [DANGER, authContext, notify]
  )

  const handleSignIn = useCallback(
    (params) => {
      signIn({
        ...params,
        clientId
      }).then(setInitOptions)
    },
    [signIn, setInitOptions, clientId]
  )
  const handleSignUp = useCallback(
    (params) => {
      signUp({
        ...params,
        clientId
      }).then(setInitOptions)
    },
    [signUp, setInitOptions, clientId]
  )

  const disabled = initializing || signingUp || signingIn
  let content = null
  if (mode === 'signup') {
    content = (
      <div className="AuthWrapper" ref={authWrapperRef}>
        <Block
          title={msg({ id: 'signUpTitle' })}
          TitleTag="p"
          variant="clear"
          className="SignUp"
        >
          <SignUpForm
            onSubmit={(params) => {
              trackAuthModal({
                action: `Click on SignUp button`
              })
              handleSignUp(params)
            }}
            disabled={disabled}
          />
        </Block>
        <SocialSignIn disabled={disabled} />
        <Block variant="clear" className="SignInLink">
          <p>
            {msg({ id: 'returnToSignIn' })}{' '}
            <button
              className="Link"
              onClick={() => {
                trackAuthModal({
                  action: 'Switch to SignIn'
                })
                toggleMode()
              }}
            >
              {msg({ id: 'goToSignIn' })}
            </button>
          </p>
        </Block>
      </div>
    )
  } else {
    content = (
      <div className="AuthWrapper" ref={authWrapperRef}>
        <Block
          title={msg({ id: 'signInTitle' })}
          TitleTag="p"
          variant="clear"
          className="SignIn"
        >
          <SignInForm
            onSubmit={(params) => {
              trackAuthModal({
                action: 'Click on SignIn Button'
              })
              handleSignIn(params)
            }}
            disabled={disabled}
          />
          <a
            className="ForgotPassword Link"
            href={`${recoveryUrl}&redirect_uri=${absoluteHomePath}`}
            disabled={disabled}
          >
            {msg({ id: 'forgotYourPassword' })}
          </a>
        </Block>
        <SocialSignIn />
        <Block variant="clear" className="SignUpLink">
          <p>
            {msg({ id: 'createAnAccount' })}{' '}
            <button
              className="Link"
              onClick={() => {
                trackAuthModal({
                  action: 'Switch to SignUp'
                })
                toggleMode()
              }}
            >
              {msg({ id: 'goToSignUp' })}
            </button>
          </p>
        </Block>
      </div>
    )
  }
  return (
    <Modal
      className="AuthModal"
      name={'AuthenticationModal'}
      body={content}
      {...props}
    />
  )
}

AuthModal.displayName = 'AuthModal'

export default AuthModal
