import React from 'react'
import PropTypes from 'prop-types'
import PhotoImg from 'components/misc/PhotoImg'
import _ from 'lodash'

const BookableItemInfo = ({ itemInfo }) => {
  const { title, photos, logo } = itemInfo
  const photo = _.isUndefined(logo) && !_.isEmpty(photos) ? photos[0] : logo
  return (
    <li>
      {photo && <PhotoImg photo={photo} variant="thumb" />}
      {title}
    </li>
  )
}

BookableItemInfo.propTypes = {
  itemInfo: PropTypes.shape()
}

export default BookableItemInfo
