import React from 'react'
import PropTypes from 'prop-types'
import Price from 'components/misc/Price'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import BookablePriceDescription from './BookablePriceDescription'

const BookableDiscountPrice = ({ discountName, discountAmount, currency }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookable.bookablePrice.discount',
    ignoreGlobalScope: true
  })
  const { classNames } = useClassNames({
    alias: 'BookableDiscountPrice'
  })
  return (
    <div className={classNames()}>
      <BookablePriceDescription className={classNames('description')}>
        <span className={classNames('label')}>{msg({ id: 'label' })}</span>
        <span className={classNames('amount')}>
          {discountName}: -<Price amount={discountAmount} currency={currency} />
        </span>
      </BookablePriceDescription>
    </div>
  )
}

BookableDiscountPrice.propTypes = {
  discountName: PropTypes.string,
  discountAmount: PropTypes.number,
  currency: PropTypes.string
}

BookableDiscountPrice.displayName = 'BookableDiscountPrice'

export default BookableDiscountPrice
