import { useGet } from 'components/api'
import _ from 'lodash'

export default (options) => {
  const { data, ...rest } = useGet({
    path: `/bookers/guests`,
    resolve: (data) => {
      return data
    },
    ...options
  })
  return {
    bookerGuests: _.get(data, 'guests', []),
    ...rest
  }
}
