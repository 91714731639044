import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import parse, { attributesToProps } from 'html-react-parser'
import { Helmet } from 'react-helmet'
import WebpageSections from 'modules/webpages/components/WebpageSections'
import HeaderSection from 'components/cms/HeaderSection'

const tagsToIgnore = ['meta', 'title']
const relPropsToIgnore = ['shortcut icon', 'apple-touch-icon']
const tagsForHelmet = ['link', 'script']

const parseOptions = {
  trim: true,
  // eslint-disable-next-line react/display-name
  replace: (domNode) => {
    const TagName = domNode.name
    const tagProps = attributesToProps(domNode.attribs)
    if (
      tagsToIgnore.includes(TagName) ||
      relPropsToIgnore.includes(tagProps.rel)
    ) {
      return <></>
    }
    if (tagsForHelmet.includes(TagName)) {
      return (
        <Helmet>
          <TagName {...tagProps} />
        </Helmet>
      )
    }
    if (TagName === 'div')
      if (tagProps['data-webpage-section']) {
        return (
          <WebpageSections
            sections={[JSON.parse(tagProps['data-webpage-section'])]}
          />
        )
      } else if (tagProps['data-webpage-header']) {
        return (
          <HeaderSection {...JSON.parse(tagProps['data-webpage-header'])} />
        )
      }
  }
}

const convertRelativePathsToAbsolute = (html, baseUrl) => {
  // If some js is not properly loaded with Helmet (ie: jQuery),
  // you should add it to tenant header snippet
  let processedHtml = html.replaceAll('href="css/', 'href="' + baseUrl + 'css/')
  processedHtml = processedHtml.replaceAll(
    'src="js/',
    'src="' + baseUrl + 'js/'
  )
  processedHtml = processedHtml.replaceAll(
    '"images/',
    '"' + baseUrl + 'images/'
  )
  processedHtml = processedHtml.replaceAll(
    '"videos/',
    '"' + baseUrl + 'videos/'
  )
  processedHtml = processedHtml.replaceAll(
    ',videos/',
    ',' + baseUrl + 'videos/'
  )
  return processedHtml
}

const buildPageComponents = (html) => {
  const parsedContent = parse(html, parseOptions)

  if (!parsedContent.props || !parsedContent.props.children) {
    return
  }

  const htmlChildrenContents = parsedContent.props.children.map(
    (node) => node.props && node.props.children
  )

  return (
    <>
      <Helmet>
        <style type="text/css">{`
        .PublicPageSkeleton_body {
          padding: 0 !important;
        }
        .BoundedPageTemplate {
          max-width: none;
        }
      `}</style>
      </Helmet>
      {htmlChildrenContents}
    </>
  )
}

const RemoteWebpage = ({ remoteHtmlUrl, remoteHtml }) => {
  const [pageComponents, setPageComponents] = useState('')
  useEffect(() => {
    if (remoteHtml) {
      setPageComponents(buildPageComponents(remoteHtml))
    } else if (remoteHtmlUrl) {
      fetch(remoteHtmlUrl)
        .then((response) => response.text())
        .then((html) => {
          const baseUrl =
            remoteHtmlUrl.substring(0, remoteHtmlUrl.lastIndexOf('/')) + '/'

          const remoteHtml = convertRelativePathsToAbsolute(html, baseUrl)

          setPageComponents(buildPageComponents(remoteHtml))
        })
    }
  }, [remoteHtml, remoteHtmlUrl])

  return pageComponents
}

RemoteWebpage.propTypes = {
  remoteHtmlUrl: PropTypes.string,
  remoteHtml: PropTypes.string
}

RemoteWebpage.displayName = 'RemoteWebpage'

export default RemoteWebpage
