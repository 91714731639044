import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'
import BookingObservationsForm from 'components/booker/BookingObservations/BookingObservationsForm'
import { useUpdateObservations } from 'components/api'

const BookingObservationsBlock = ({ id, observations, refetch }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingObservations',
    ignoreGlobalScope: true
  })

  const { updateObservations } = useUpdateObservations(id)

  const handleSubmit = useCallback(
    (text) => {
      updateObservations(text).then(refetch)
    },
    [updateObservations, refetch]
  )

  return (
    <>
      <Block className="BookingObservationsBlock" title={msg({ id: 'title' })}>
        <span className="Field_helperText">
          {msg({ id: 'titleHelperText' })}
        </span>
        <BookingObservationsForm
          observations={observations}
          handleSubmit={handleSubmit}
        />
      </Block>
    </>
  )
}

BookingObservationsBlock.propTypes = {
  id: PropTypes.number,
  observations: PropTypes.string,
  refetch: PropTypes.func
}

BookingObservationsBlock.displayName = 'BookingObservationsBlock'

export default BookingObservationsBlock
