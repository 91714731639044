import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import mapMarker from 'assets/img/misc/map-marker.svg'
import { useTenantConfig } from 'modules/bookables/Provider'

const MapMarker = ({ children, ...other }) => {
  const { components: { mapMarkerIcon } = {} } = useTenantConfig()
  const markerIcon = L.icon({
    iconRetinaUrl: mapMarkerIcon || mapMarker,
    iconUrl: mapMarkerIcon || mapMarker,
    iconSize: [26, 34]
  })

  return (
    <Marker icon={markerIcon} {...other}>
      {children}
    </Marker>
  )
}

MapMarker.propTypes = {
  children: PropTypes.node
}

MapMarker.displayName = 'MapMarker'

export default MapMarker
