import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl as useReactIntl } from 'react-intl'
import SelectField from '@trileuco/triskel-react-ui/components/ui/SelectField'

import LanguageLabel from '../LanguageLabel'

const LanguageSelectField = ({ showAsLinks, languageCodes, ...moreProps }) => {
  const { locale: currentLocale } = useReactIntl()
  const buildLanguagePath = useCallback(
    (locale) => {
      const regex = new RegExp(`^/${currentLocale}/`, 'gi')
      const path = `/${locale}${window.location.pathname.replace(regex, `/`)}`
      return path
    },
    [currentLocale]
  )
  if (showAsLinks) {
    return (
      <ul>
        {languageCodes.map((languageCode) => {
          const localeHref = buildLanguagePath(languageCode)
          return (
            <li key={languageCode}>
              <a className="Link" href={localeHref}>
                <LanguageLabel languageCode={languageCode} />
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
  return (
    <SelectField
      {...moreProps}
      className="LanguageSelectField"
      variant="outline"
      value={currentLocale}
      onChange={(locale) => {
        const localeHref = buildLanguagePath(locale)
        window.location.replace(localeHref)
      }}
      options={languageCodes.map((locale) => ({
        label: <LanguageLabel languageCode={locale} />,
        value: locale
      }))}
    />
  )
}

LanguageSelectField.propTypes = {
  showAsLinks: PropTypes.bool,
  languageCodes: PropTypes.array
}

LanguageSelectField.displayName = 'LanguageSelectField'

export default LanguageSelectField
