import { useCallback } from 'react'
import { useMutate } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useUpdateBookingGuest = () => {
  const { mutate, ...rest } = useMutate({
    path: ({ bookingId, guestId }) =>
      `/bookings/${bookingId}/guests/${guestId}`,
    verb: 'PATCH'
  })

  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useUpdateBookingGuest',
    ignoreGlobalScope: true
  })

  const notifyUpdatedGuest = useCallback(
    (bookingId, notification) => {
      notify({
        id: `BOOKING_${bookingId}_UPDATE_GUEST`,
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [msg, notify]
  )

  const updateGuest = useCallback(
    (bookingId, guestId, params) => {
      notifyUpdatedGuest(bookingId, { status: LOADING })
      return mutate(params, {
        pathParams: { guestId: guestId, bookingId: bookingId }
      })
        .then((result) => {
          notifyUpdatedGuest(bookingId, { status: SUCCESS })
          return result
        })
        .catch((error) => {
          notifyError({
            id: `BOOKING_${bookingId}_UPDATE_GUEST`,
            title: msg({ id: 'error' }),
            error
          })
          throw error
        })
    },
    [notifyUpdatedGuest, LOADING, mutate, SUCCESS, notifyError, msg]
  )
  return {
    updateGuest,
    ...rest
  }
}

export default useUpdateBookingGuest
