import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useGetBookingPaymentForm } from 'components/api'

const BookingPaymentGatewayForm = ({ booking, reservationPayment = false }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingActions',
    ignoreGlobalScope: true
  })
  const { paymentFormHtml } = useGetBookingPaymentForm({
    bookingId: booking.id,
    formId: 'goToPaymentGateway',
    buttonText: msg({
      id: reservationPayment
        ? 'goToReservationPaymentGateway'
        : booking.reservationPaymentEnabled
        ? 'goToFullPaymentGateway'
        : 'safePayment'
    }),
    reservationPayment: reservationPayment
  })
  return (
    <>
      {paymentFormHtml && (
        <div className="GotoPaymentGetaway_actionContainer">
          <div
            dangerouslySetInnerHTML={{
              __html: paymentFormHtml
            }}
          />
        </div>
      )}
    </>
  )
}

BookingPaymentGatewayForm.propTypes = {
  booking: PropTypes.shape(),
  reservationPayment: PropTypes.bool
}

BookingPaymentGatewayForm.displayName = 'BookingPaymentGatewayForm'

export default BookingPaymentGatewayForm
