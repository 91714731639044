import React from 'react'
import BookingCommentsBlock from 'components/booker/BookingComments/BookingCommentsBlock'
import { useBookingDetails } from 'components/booker/BookingDetails/BookingDetailsContext'

const BookingCommentsPage = () => {
  const { booking, refetch } = useBookingDetails()
  return (
    <BookingCommentsBlock
      id={booking.id}
      comments={booking.comments}
      refetch={refetch}
    />
  )
}

BookingCommentsPage.displayName = 'BookingCommentsPage'

export default BookingCommentsPage
