import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useTenantConfig } from 'modules/bookables/Provider'
import DateTime from 'components/misc/DateTime'
import _ from 'lodash'

const BookingState = ({ state, time, timeZone }) => {
  const { classNames } = useClassNames({
    alias: 'BookingState'
  })
  return (
    <span className={classNames()}>
      <span className={classNames('label')}>{state}</span>
      <DateTime data={time} timeZone={timeZone} />
    </span>
  )
}

BookingState.propTypes = {
  state: PropTypes.string,
  time: PropTypes.string,
  timeZone: PropTypes.string
}

const BookingStates = ({ booking }) => {
  const { allowedStates: defaultStates } = useTenantConfig()
  const { classNames } = useClassNames({
    alias: 'BookingStates'
  })
  const {
    bookable: { timeZone, allowedStates: acommodationStates = [] },
    states
  } = booking

  const showStates =
    (!_.isEmpty(defaultStates) || !_.isEmpty(acommodationStates)) &&
    !_.isUndefined(states)
  return (
    <div className={classNames()}>
      {showStates &&
        states.timeline.map(({ state, time }, index) => (
          <BookingState
            key={index}
            state={state}
            time={time}
            timeZone={timeZone}
          />
        ))}
    </div>
  )
}

BookingStates.propTypes = {
  booking: PropTypes.shape()
}

BookingStates.displayName = 'BookingStates'

export default BookingStates
