import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

const FooterGroupTitle = ({ title }) => {
  if (title.to) {
    return <Link to={title.to}>{title.title}</Link>
  }
  return title
}

const FooterGroup = ({ title, children, className }) => {
  const { classNames } = useClassNames({
    alias: 'FooterGroup'
  })
  return (
    <div className={`${classNames()} ${className}`.trim()}>
      {title && (
        <p className={classNames('title')}>
          <FooterGroupTitle title={title} />
        </p>
      )}
      <div className={classNames('children')}>{children}</div>
    </div>
  )
}

FooterGroup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string
}

FooterGroup.defaultProps = {
  className: ' '
}

FooterGroup.displayName = 'FooterGroup'

export default FooterGroup
