import React from 'react'
import PropTypes from 'prop-types'
import {
  MessageProvider,
  useIntl
} from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import Page from 'components/layout/Page'
import { bookingsPath } from 'modules/bookables/routes'
import { LoadingResults, useGetBooking } from 'components/api'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import { BookingDetailsProvider } from 'components/booker/BookingDetails/BookingDetailsContext'
import BookingActions from 'components/booking/BookingActions'
import { NotFoundError } from 'components/error'
import BookingTabs from 'modules/bookables/pages/bookingDetails/BookingTabs'
import BookingStatusAndExtraStates from 'modules/bookables/pages/bookingDetails/BookingStatusAndExtraStates'

const BookingDetailsPage = ({ match, routes }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsPage',
    ignoreGlobalScope: true
  })
  const bookingId = match.params.id
  const { booking, refetch, loading } = useGetBooking({ bookingId })
  const { classNames } = useClassNames({
    alias: 'BookingDetailsPage'
  })
  if (loading) {
    return <LoadingResults />
  }
  if (!booking) {
    throw new NotFoundError('Booking not found for id ' + bookingId)
  }
  return (
    <BookingDetailsProvider bookingId={bookingId}>
      <MessageProvider scope="bookingDetailsPage">
        <Page id="BookingDetailsPage">
          <PublicPageSkeleton
            mainContent={
              <BoundedPageTemplate
                header={
                  <>
                    <Link to={bookingsPath} className={classNames('goBack')}>
                      <i className="fas fa-arrow-left"></i>
                      {msg({ id: 'goBackLink' })}
                    </Link>
                    <div className={classNames('titleAndActions')}>
                      <h1 className={classNames('title')}>
                        {booking &&
                          msg(
                            { id: 'pageTitle' },
                            { bookingId: booking.hashId }
                          )}
                      </h1>
                      {booking && (
                        <BookingActions booking={booking} refetch={refetch} />
                      )}
                    </div>
                    {booking && (
                      <>
                        <BookingStatusAndExtraStates booking={booking} />
                        <BookingTabs booking={booking} />
                      </>
                    )}
                  </>
                }
                mainContent={routes}
              />
            }
          />
        </Page>
      </MessageProvider>
    </BookingDetailsProvider>
  )
}

BookingDetailsPage.propTypes = {
  match: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.element)
}

BookingDetailsPage.displayName = 'BookingDetailsPage'

export default BookingDetailsPage
