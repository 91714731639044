import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { generateTaggedArticles } from 'modules/blog/routes'

const Tag = ({
  name,
  link,
  linkedArticlesNumber,
  showLinkedArticlesNumber
}) => {
  const { classNames } = useClassNames({ alias: 'Tag' })
  return (
    <Link to={link} className={classNames()}>
      <span className={classNames('name')}>{name}</span>
      {showLinkedArticlesNumber && (
        <span className={classNames('linkedArticlesNumber')}>
          {linkedArticlesNumber}
        </span>
      )}
    </Link>
  )
}

Tag.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  linkedArticlesNumber: PropTypes.number,
  showLinkedArticlesNumber: PropTypes.bool
}

const TagsList = ({ tags, direction, showLinkedArticlesNumber }) => {
  const { classNames } = useClassNames({ alias: 'TagsList' })
  return (
    <ul className={classNames([{ direction }])}>
      {tags &&
        tags.map(({ name, articlesCount }, index) => (
          <li key={index}>
            <Tag
              name={name}
              link={generateTaggedArticles({ tag: name })}
              linkedArticlesNumber={articlesCount}
              showLinkedArticlesNumber={showLinkedArticlesNumber}
            />
          </li>
        ))}
    </ul>
  )
}

TagsList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, articlesCount: PropTypes.number })
  ),
  direction: PropTypes.oneOf(['row', 'column']),
  showLinkedArticlesNumber: PropTypes.bool
}

TagsList.defaultProps = {
  direction: 'row',
  showLinkedArticlesNumber: false
}

TagsList.displayName = 'TagsList'

export default TagsList
