import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import CustomDate from 'components/misc/Date'
import { intl } from '@trileuco/triskel-react-ui/components/i18n/intl'

const BookingEventCard = ({ onMoreDetails, dateTime, capacity, bookable }) => {
  const totalBookings = capacity.total - capacity.remaining
  const msg = ({ id }) =>
    intl.formatMessage({
      id: `balaena.bookingsCalendar.${id}`
    })
  return (
    <div className="BookingPopOver_content">
      <p className="BookingPopOver_date">
        <CustomDate
          data={dateTime}
          timeZone={bookable.timeZone}
          format="HH:mm"
        />
      </p>
      {totalBookings > 0 && (
        <p className="BookingPopOver_bookingsPaid">
          <span>{msg({ id: 'bookingsPaid' })}:</span>
          <span className="data">{totalBookings}</span>
        </p>
      )}
      <p className="BookingPopOver_availableBookings">
        <span>{msg({ id: 'availableBookings' })}:</span>
        <span>
          {capacity.remaining}/{capacity.total}
        </span>
      </p>
      <Button
        text={msg({
          id: 'viewDetails'
        })}
        onClick={onMoreDetails}
        size="m"
        variant="outline"
      />
    </div>
  )
}

BookingEventCard.propTypes = {
  bookable: PropTypes.shape(),
  dateTime: PropTypes.string.isRequired,
  capacity: PropTypes.shape({
    total: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired
  }),
  onMoreDetails: PropTypes.func
}

BookingEventCard.displayName = 'BookingEventCard'

export default BookingEventCard
