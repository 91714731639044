import PropTypes from 'prop-types'
import CountryRegionData from 'country-region-data/data'

const countryNameByCode = CountryRegionData.reduce(
  (countryByCode, countryData) => ({
    ...countryByCode,
    [countryData.countryShortCode]: countryData.countryName
  }),
  {}
)

const CountryLabel = ({ countryCode = null }) => {
  if (!countryCode) {
    return '-'
  }
  return countryNameByCode[countryCode]
}

CountryLabel.propTypes = {
  countryCode: PropTypes.string
}

CountryLabel.displayName = 'CountryLabel'

export default CountryLabel
