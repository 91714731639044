import React from 'react'
import PropTypes from 'prop-types'
import { useGetBookables, LoadingResults } from 'components/api'
import BookablesList from 'components/bookable/BookablesList/BookablesList'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Block from 'components/layout/Block'

const CategoryBookables = ({ categoryId, categoryName }) => {
  const { classNames } = useClassNames({
    alias: 'CategoryBookables'
  })
  const { msg } = useIntl({
    scope: 'components.CategoryBookables',
    ignoreGlobalScope: true
  })

  const { bookables, loading } = useGetBookables({
    queryParams: {
      category: categoryId
    }
  })

  if (loading) {
    return <LoadingResults />
  }

  return (
    <div className={classNames()}>
      <p className={classNames('title')}>
        {msg({ id: 'totalProducts' }, { total: bookables.length })}
      </p>
      <Block variant="clear">
        <BookablesList bookables={bookables} />
      </Block>
    </div>
  )
}

CategoryBookables.propTypes = {
  categoryName: PropTypes.string,
  categoryId: PropTypes.number
}

CategoryBookables.displayName = 'CategoryBookables'

export default CategoryBookables
