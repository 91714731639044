import React, { useCallback, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {
  useIntl,
  MessageProvider
} from '@trileuco/triskel-react-ui/components/i18n'
import {
  FieldSet,
  Button,
  SelectField,
  PopOver
} from '@trileuco/triskel-react-ui/components/ui'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks/useClassNames'
import {
  formatDateTime,
  groupSchedulesByDayAndDateTime,
  getSchedulesByDateTime,
  scheduleIsNotValidForSelection
} from 'modules/bookables/domain/availabilities'
import useBookableAnalytics from 'modules/bookables/hooks/useBookableAnalytics'
import { useGetBookableAvailability } from 'components/api'
import GuestsSelector from 'components/bookable/GuestsSelector'
import BookablePrice from 'components/bookable/BookablePrice'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import useWidget from 'components/hooks/useWidget'
import { toDateIgnoringTime } from 'components/misc/utils/dateUtils'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useTenantConfig } from 'modules/bookables/Provider'
import BookablePriceTypeSelector from 'modules/bookables/pages/bookableDetails/BookablePriceTypeSelector'

import { DateSelectorCalendar } from './DateSelectorCalendar'
import { DateSelectorSchedule } from './DateSelectorSchedule'
import { AvailableTimeLabel } from './AvailableTimeLabel'

const ToBookPicker = ({
  priceTypes,
  bookable,
  pickedPriceType = bookable.priceTypes[0],
  setPickedPriceType,
  onSubmit,
  providerBook = false
}) => {
  const defaultValues = useMemo(
    () => ({
      guests: {
        amount: 0,
        guestTypes:
          pickedPriceType &&
          pickedPriceType.guestTypes.map((guestType) => ({
            guestType,
            amount: 0
          }))
      },
      day: null,
      time: null
    }),
    [pickedPriceType]
  )
  const { Form, values = {}, ...instance } = useForm({
    defaultValues
  })

  const { msg } = useIntl({
    scope: 'balaena.toBookPicker',
    ignoreGlobalScope: true
  })

  const {
    components: { hideAvailableBookingAmountInBookPicker } = {}
  } = useTenantConfig()

  const authContext = useAuth()

  const { day, time, guests = {} } = values
  const [date, setDate] = useState(0)

  const showPriceTypeSelector = useMemo(() => {
    return !_.isEmpty(priceTypes) && priceTypes.length > 1
  }, [priceTypes])

  const { amount = 0 } = guests
  const currentGuestsAmount = useMemo(
    () =>
      guests.guestTypes.reduce((sum, { guestType, amount }) => {
        return guestType.consumesAvailability ? sum + amount : sum
      }, 0),
    [guests.guestTypes]
  )

  const completedGuestsAmount =
    parseInt(amount) > 0 &&
    (pickedPriceType.price.guests.min !== pickedPriceType.price.guests.max ||
      parseInt(amount) * pickedPriceType.price.guests.min ===
        currentGuestsAmount)
  const { timeZone } = bookable

  const popperRef = useRef(null)

  const closePopOver = useCallback(() => {
    if (popperRef.current) {
      popperRef.current.setVisible(false)
    }
  }, [])

  const handleChangeTime = useCallback(
    (time) => {
      if (time) {
        closePopOver()
      }
    },
    [closePopOver]
  )

  const {
    schedules,
    dateTimeFilters: schedulesDateTimeFilters,
    prices,
    maxCapacity: maxRemainingCapacity,
    loading: loadingBookableAvailabilities,
    refetch
  } = useGetBookableAvailability({
    id: bookable.slug
  })

  const formatBookableDate = useCallback(
    (date, format) => formatDateTime(date, timeZone, format),
    [timeZone]
  )

  const scheduleGroupedByDayAndDateTime = useMemo(
    () => groupSchedulesByDayAndDateTime(schedules, timeZone),
    [schedules, timeZone]
  )

  const getSchedulesByDate = useCallback(
    (date) =>
      getSchedulesByDateTime(
        formatBookableDate(date),
        scheduleGroupedByDayAndDateTime
      ),
    [formatBookableDate, scheduleGroupedByDayAndDateTime]
  )

  const selectedDateInterval = useMemo(() => {
    return (
      bookable.continuous &&
      date &&
      date.value &&
      date.value.from &&
      date.value.to
    )
  }, [bookable.continuous, date])

  const handleGuestsChange = () => {
    setDate(null)
    instance.debounce(() => {
      instance.setFieldValue('day', null)
      instance.setFieldValue('time', null)
    })
  }

  const handleChangeMonth = (month, instance) => {
    // FIXME: always set noon to avoid time zone issues
    if (!bookable.continuous) {
      instance.setFieldValue('day', null)
    }
    month.setHours(12, 0, 0, 0)
    refetch({
      queryParams: {
        from: moment(month).startOf('month').format(),
        to: moment(month).endOf('month').format()
      }
    }).then(() => {
      instance.debounce(() => {
        instance.setFieldValue('time', null)
      })
    })
  }

  const handleChangeDay = useCallback(
    (date) => {
      setDate(date)
      instance.debounce(() => {
        if (
          bookable.continuous &&
          date &&
          date.value &&
          date.value.from &&
          date.value.to &&
          date.schedules
        ) {
          closePopOver()
        }
      })
    },
    [bookable.continuous, instance, closePopOver]
  )

  const availableTimes = useMemo(() => {
    const mapScheduleToTimeOption = (schedule) => {
      const { capacity, dateTime } = schedule

      const full = capacity.remaining === 0
      const insufficient = !full && capacity.remaining < amount
      const showAvailableBookingAmount = !(
        hideAvailableBookingAmountInBookPicker &&
        !authContext.hasAllRoles(['balaena-admin'])
      )
      return {
        label: formatBookableDate(dateTime, 'LT'),
        helperText: showAvailableBookingAmount && (
          <AvailableTimeLabel
            pickedPriceType={pickedPriceType}
            bookable={bookable}
            capacity={capacity}
            schedule={schedule}
          />
        ),
        value: dateTime,
        disabled:
          full ||
          insufficient ||
          scheduleIsNotValidForSelection(
            dateTime,
            bookable.reservationCutoff,
            authContext.hasSomeRole([
              'balaena-admin',
              'balaena-bookings-manager'
            ])
          )
      }
    }
    return _.values(getSchedulesByDate(day)).map(mapScheduleToTimeOption)
  }, [
    getSchedulesByDate,
    day,
    amount,
    hideAvailableBookingAmountInBookPicker,
    authContext,
    formatBookableDate,
    pickedPriceType,
    bookable
  ])

  const dateRange = useMemo(() => {
    if (date && date.interval) {
      return {
        from: moment(date.value.from).format(),
        to: moment(date.value.to).format()
      }
    }
  }, [date])

  const { addAction } = useBookableAnalytics()

  const handleSubmit = useCallback(() => {
    addAction({ bookable })
    onSubmit({
      bookable,
      dateRange,
      dateTime: time,
      guests
    })
  }, [onSubmit, addAction, bookable, dateRange, time, guests])

  const standardPrice = useMemo(() => {
    if (date && date.interval) {
      return date.schedules && date.schedules[0].priceAmount
    } else if (date && !date.interval) {
      return date.schedule && date.schedule.priceAmount
    }
  }, [date])

  const validBookingParamsSelection = useMemo(() => {
    return (
      completedGuestsAmount &&
      standardPrice &&
      ((!bookable.continuous && time) ||
        (bookable.continuous && selectedDateInterval))
    )
  }, [
    completedGuestsAmount,
    standardPrice,
    bookable.continuous,
    time,
    selectedDateInterval
  ])

  const dateToText = useMemo(() => {
    let dateText = ''
    if (bookable.continuous && dateRange) {
      dateText = moment(dateRange.from).format('L')
      if (moment(new Date(dateRange.to)).isValid()) {
        dateText += ` - ${moment(dateRange.to).format('L')}`
      }
    } else {
      dateText = moment(time || day).format(time ? 'L LT' : 'L')
    }

    return day ? dateText : msg({ id: 'dateAndTimeSelector.placeholder' })
  }, [bookable.continuous, dateRange, time, day, msg])

  const isDesktop = useMediaQuery(DesktopBreakpoint)
  const { isWidget } = useWidget()

  const { classNames } = useClassNames({ alias: 'DateSelectorPopOver' })

  const initialMonth = useMemo(
    () =>
      schedulesDateTimeFilters &&
      toDateIgnoringTime(schedulesDateTimeFilters.from),
    [schedulesDateTimeFilters]
  )

  return (
    <MessageProvider scope="ToBookPicker">
      <Form>
        <FieldSet direction="column">
          {showPriceTypeSelector && (
            <BookablePriceTypeSelector
              priceTypes={bookable.priceTypes}
              pickedPriceType={pickedPriceType}
              onPriceSelected={(priceType) => {
                setPickedPriceType(priceType)
                handleGuestsChange()
              }}
            />
          )}
          <GuestsSelector
            field="guests"
            pickedPriceType={pickedPriceType}
            bookable={bookable}
            disabled={loadingBookableAvailabilities}
            maxRemainingCapacity={maxRemainingCapacity}
            onChange={handleGuestsChange}
          />

          {isDesktop && !isWidget ? (
            <PopOver
              triggerEvent="click"
              placement="bottom-end"
              positionFixed
              className={classNames({ withTime: !bookable.continuous })}
              ref={popperRef}
              TriggerTag={'div'}
              content={
                <>
                  <DateSelectorCalendar
                    completedGuestsAmount={completedGuestsAmount}
                    prices={prices}
                    loadingBookableAvailabilities={
                      loadingBookableAvailabilities
                    }
                    scheduleGroupedByDayAndDateTime={
                      scheduleGroupedByDayAndDateTime
                    }
                    handleChangeMonth={handleChangeMonth}
                    handleChangeDay={handleChangeDay}
                    bookable={bookable}
                    amount={amount}
                    initialMonth={initialMonth}
                  />

                  {!bookable.continuous && (
                    <DateSelectorSchedule
                      msg={msg}
                      onChange={handleChangeTime}
                      completedGuestsAmount={completedGuestsAmount}
                      loadingBookableAvailabilities={
                        loadingBookableAvailabilities
                      }
                      availableTimes={availableTimes}
                    />
                  )}
                </>
              }
            >
              <SelectField
                variant="outline"
                label={
                  bookable.continuous
                    ? msg({ id: 'continuousDateAndTimeSelector.label' })
                    : msg({ id: 'dateAndTimeSelector.label' })
                }
                value={dateToText}
                disabled={true}
              />
            </PopOver>
          ) : (
            <>
              <DateSelectorCalendar
                completedGuestsAmount={completedGuestsAmount}
                prices={prices}
                loadingBookableAvailabilities={loadingBookableAvailabilities}
                scheduleGroupedByDayAndDateTime={
                  scheduleGroupedByDayAndDateTime
                }
                handleChangeMonth={handleChangeMonth}
                handleChangeDay={handleChangeDay}
                bookable={bookable}
                amount={amount}
                initialMonth={
                  schedulesDateTimeFilters &&
                  moment(schedulesDateTimeFilters.from).toDate()
                }
              />
              {!bookable.continuous && (
                <DateSelectorSchedule
                  msg={msg}
                  time={time}
                  day={day}
                  completedGuestsAmount={completedGuestsAmount}
                  loadingBookableAvailabilities={loadingBookableAvailabilities}
                  availableTimes={availableTimes}
                />
              )}
            </>
          )}

          {validBookingParamsSelection && !providerBook && (
            <BookablePrice
              bookable={bookable}
              dateTime={time}
              dateRange={dateRange}
              guests={guests}
              price={standardPrice}
              pickedPriceType={pickedPriceType.price}
            />
          )}

          <Button
            disabled={!validBookingParamsSelection}
            text={msg({
              id: providerBook ? 'providerBook' : 'book'
            })}
            onClick={handleSubmit}
          />
        </FieldSet>
      </Form>
    </MessageProvider>
  )
}

ToBookPicker.displayName = 'ToBookPicker'

ToBookPicker.propTypes = {
  bookable: PropTypes.shape(),
  onSubmit: PropTypes.func,
  providerBook: PropTypes.bool,
  pickedPriceType: PropTypes.object,
  priceTypes: PropTypes.array,
  setPickedPriceType: PropTypes.func
}

export default ToBookPicker
