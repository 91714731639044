import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import useIntl from '@trileuco/triskel-react-ui/components/i18n/useIntl'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import {
  TextField,
  IconButton,
  FieldSet
} from '@trileuco/triskel-react-ui/components/ui'
import { useField } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useGetDiscount } from 'components/api'
import { useGetBackendError } from 'modules/bookables/i18n'

const BookingDiscountField = ({ bookable, field }) => {
  const [token, setToken] = useState('')
  const { msg } = useIntl({
    scope: 'balaena.bookingFields.discountField',
    ignoreGlobalScope: true
  })
  const { getBackendError } = useGetBackendError()
  const instance = useField(field)
  const {
    value: selectedDiscount,
    form: {
      meta: { isSubmitted }
    },
    setValue
  } = instance

  const {
    loading,
    error,
    discount: currentDiscount,
    refetch,
    cancel
  } = useGetDiscount({
    token,
    bookableId: bookable.id,
    debounce: 1000,
    lazy: !token,
    onError: () => null
  })

  const handleTokenChange = useCallback(
    (token) => {
      setToken(token)
      if (token) {
        refetch()
      } else {
        cancel()
      }
    },
    [refetch, cancel]
  )

  const applyDiscount = useCallback(() => {
    setValue(currentDiscount)
  }, [currentDiscount, setValue])

  const removeDiscount = useCallback(() => {
    setValue(null)
    setToken('')
  }, [setValue, setToken])

  if (!isSubmitted) {
    const showAddButton = Boolean(
      !selectedDiscount || currentDiscount.id !== selectedDiscount.id
    )
    const disabledAddButton = Boolean(
      loading || !currentDiscount || currentDiscount.token !== token
    )
    return (
      <FieldSet className="BookingDiscount" direction="row">
        <TextField
          variant="outline"
          label={msg({ id: 'label' })}
          value={token}
          name="discountToken"
          disabled={Boolean(selectedDiscount)}
          onChange={handleTokenChange}
          isValidating={loading}
          error={token && error && getBackendError(error.data)}
        />
        <Visible condition={showAddButton}>
          <IconButton
            icon="fas fa-plus"
            variant="fill"
            disabled={disabledAddButton}
            onClick={applyDiscount}
          />
        </Visible>
        <Visible condition={Boolean(selectedDiscount)}>
          <IconButton
            icon="fas fa-trash"
            variant="clear"
            color="danger"
            onClick={removeDiscount}
          />
        </Visible>
      </FieldSet>
    )
  }
  return null
}

BookingDiscountField.propTypes = {
  bookable: PropTypes.shape(),
  field: PropTypes.string
}

BookingDiscountField.defaultProps = {
  field: 'discount'
}

BookingDiscountField.displayName = 'BookingDiscountField'

export default BookingDiscountField
