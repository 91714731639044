import React from 'react'
import PropTypes from 'prop-types'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import ProviderLogo from 'modules/bookables/pages/providers/ProviderLogo'

const BookableProviderBadge = (props) => {
  const { provider, size, theme, ...other } = props
  const { classNames } = useClassNames({
    alias: 'BookableProviderBadge'
  })
  if (!provider) {
    return null
  }
  return (
    <div className={classNames([{ size: size }, { theme: theme }])} {...other}>
      <ProviderLogo provider={provider} className={classNames('logo')} />
      <span className={classNames('name')}>{provider.name}</span>
    </div>
  )
}

BookableProviderBadge.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  }),
  size: PropTypes.string,
  theme: PropTypes.string
}

BookableProviderBadge.defaultProps = {
  size: 'm', // m, s
  theme: 'base' // base, countertype
}

BookableProviderBadge.displayName = 'BookableProviderBadge'

export default BookableProviderBadge
