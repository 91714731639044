import React from 'react'
import { useBookingDetails } from 'components/booker/BookingDetails/BookingDetailsContext'
import BookingObservationsBlock from 'components/booker/BookingObservations/BookingObservationsBlock'

const BookingObservationsPage = () => {
  const { booking, refetch } = useBookingDetails()
  return (
    <BookingObservationsBlock
      id={booking.id}
      observations={booking.observations}
      refetch={refetch}
    />
  )
}

BookingObservationsPage.displayName = 'BookingObservationsPage'

export default BookingObservationsPage
