import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import TopBarProgress from 'react-topbar-progress-indicator'
import { render } from '@trileuco/triskel-react-ui'
import { BalaenaProvider } from 'modules/bookables'
import { buildWebpagesModule } from 'modules/webpages'
import BalaenaAuthComponent from 'components/auth/BalaenaAuthComponent'
import Body from 'components/layout/Body/Body'
import { getTenantConfig } from 'tenants'
import * as serviceWorker from 'serviceWorker'
import ErrorPage from 'components/error/ErrorPage'
import '@formatjs/intl-relativetimeformat/locale-data/gl'

const appThemeImport = (theme) => {
  if (theme === 'caribean') {
    TopBarProgress.config({
      barColors: {
        0: '#89be40',
        '1.0': '#89be40'
      },
      shadowBlur: 1
    })
  } else if (theme === 'balaena' || theme === 'balaenatravel') {
    TopBarProgress.config({
      barColors: {
        0: '#fcc736',
        '1.0': '#eaa902'
      },
      shadowBlur: 1
    })
  } else if (theme === 'forest') {
    TopBarProgress.config({
      barColors: {
        0: '#fcc736',
        '1.0': '#eaa902'
      },
      shadowBlur: 1
    })
  }
  if (theme) {
    // eslint-disable-next-line no-unused-expressions
    import(
      `styles/theme.${theme}.scss` /* webpackMode: "lazy" */ /* webpackChunkName: "theme" */
    )
  }
}

const appModulesImport = (modules) => {
  if (!modules) {
    return Promise.resolve([])
  }
  return Promise.all(
    modules.map((moduleName) =>
      import(/* webpackMode: "lazy" */ `modules/${moduleName}/index.js`).then(
        (module) => module.default
      )
    )
  )
}

getTenantConfig().then((tenantConfig) => {
  moment.locale(tenantConfig.intl.locale || tenantConfig.intl.defaultLocale)
  moment.tz.setDefault(tenantConfig.intl.timeZone)
  if (tenantConfig.googleTagManager) {
    TagManager.initialize(tenantConfig.googleTagManager)
  }
  if (tenantConfig.hotjar) {
    hotjar.initialize(tenantConfig.hotjar.hjid, tenantConfig.hotjar.hjsv)
  }
  appThemeImport(tenantConfig.theme)
  appModulesImport(['common', ...tenantConfig.modules]).then((modules) => {
    render({
      ...tenantConfig,
      rootId: 'root',
      RouterLayout: Body,
      // eslint-disable-next-line react/display-name
      CustomProvider: (props) => (
        <BalaenaProvider {...props} tenantConfig={tenantConfig} />
      ),
      ErrorHandler: ErrorPage,
      notifications: {
        position: 'right',
        align: 'end'
      },
      authentication: {
        AuthComponent: BalaenaAuthComponent,
        ...tenantConfig.authentication
      },
      modules: [buildWebpagesModule(tenantConfig), ...modules]
    })
  })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWAra

serviceWorker.unregister()
