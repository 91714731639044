import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import Block from 'components/layout/Block'
import ToUpdateBookingTimePicker from 'components/booking/ToUpdateBookingTimePicker'

const ToUpdateBookingTimePickerModal = ({
  booking,
  bookable,
  handleUpdateTime,
  isOpen,
  close
}) => {
  const { msg } = useIntl({
    scope: 'balaena.toUpdateBookingTimePickerModal',
    ignoreGlobalScope: true
  })

  return (
    <Modal
      name="PickerToBook"
      className="ToUpdateBookingTimePickerModal"
      isOpen={isOpen}
      onClose={close}
      header={
        <h1>
          {bookable &&
            msg({ id: 'modalTitle' }, { continuous: +bookable.continuous })}
        </h1>
      }
      body={
        <Block className="ToUpdateBookingTimePicker" variant="clear">
          <ToUpdateBookingTimePicker
            booking={booking}
            bookable={bookable}
            handleUpdateDateTime={(dateParams) =>
              handleUpdateTime(dateParams).then(close)
            }
          />
        </Block>
      }
    />
  )
}

ToUpdateBookingTimePickerModal.propTypes = {
  bookable: PropTypes.shape(),
  booking: PropTypes.shape(),
  handleUpdateTime: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

ToUpdateBookingTimePickerModal.displayName = 'ToUpdateBookingTimePickerModal'

export default ToUpdateBookingTimePickerModal
