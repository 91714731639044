import React from 'react'
import PropTypes from 'prop-types'

import FooterGroup from './FooterGroup'

const FooterSocialColumn = ({ className, social, title }) => (
  <FooterGroup className={className} title={title}>
    <ul>
      {social.map((socialInfo) => (
        <li key={socialInfo.platform}>
          <a target="_blank" rel="noopener noreferrer" href={socialInfo.url}>
            <i className={`fab fa-${socialInfo.faIcon}`}></i>
            {socialInfo.platform}
          </a>
        </li>
      ))}
    </ul>
  </FooterGroup>
)

FooterSocialColumn.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  social: PropTypes.arrayOf(PropTypes.object)
}

FooterSocialColumn.displayName = 'FooterSocialColumn'

export default FooterSocialColumn
