import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@trileuco/triskel-react-ui/components/router'
import { useAnalytics } from '@trileuco/triskel-react-ui/modules/analytics'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

const SocialAuthButton = (props) => {
  const { identityProvider, icon, disabled } = props
  const Analytics = useAnalytics()
  const location = useLocation()
  const trackAuthModal = ({ action }) => {
    if (Analytics.enabled) {
      Analytics.event({
        category: 'Form',
        action,
        label: location.pathname
      })
    }
  }
  const {
    keycloak: { login }
  } = useAuth()
  return (
    <>
      <Button
        disabled={disabled}
        text={`Continuar con ${identityProvider}`}
        iconBefore={icon}
        color={identityProvider}
        variant="outline"
        size="l"
        onClick={() => {
          trackAuthModal({
            action: `Click on ${identityProvider} login button`
          })
          login({
            idpHint: identityProvider
          })
        }}
      />
    </>
  )
}

SocialAuthButton.propTypes = {
  login: PropTypes.func,
  identityProvider: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool
}

SocialAuthButton.displayName = 'SocialAuthButton'

const SocialSignIn = ({ disabled }) => {
  const { msg } = useIntl({
    scope: 'balaena.signModal',
    ignoreGlobalScope: true
  })
  return (
    <div className="SocialSignIn">
      <span className="SocialSignIn_title">
        {msg({ id: 'socialSignInTitle' })}
      </span>
      <div>
        <SocialAuthButton
          identityProvider={'google'}
          icon="fab fa-google"
          disabled={disabled}
        />
      </div>
    </div>
  )
}

SocialSignIn.propTypes = {
  disabled: PropTypes.bool
}

SocialSignIn.displayName = 'SocialSignIn'

export default SocialSignIn
