import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import Sidebar from 'components/layout/Sidebar'
import AuthModal from 'components/auth/AuthModal'
import UserBadge from 'components/user/UserBadge'
import UserMenu from 'components/user/UserMenu'

const UserSidebarTrigger = forwardRef(function UserSidebarTrigger(
  { active, onClick },
  ref
) {
  return (
    <div ref={ref}>
      <IconButton
        icon={active ? 'fas fa-times' : 'fas fa-user'}
        size="l"
        variant="clear"
        onClick={onClick}
      />
    </div>
  )
})

UserSidebarTrigger.propTypes = {
  active: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func
}

const UserSidebar = () => {
  const { authenticated } = useAuth()
  const { isOpen, close, open } = useModal(false)
  return (
    <>
      {!authenticated && (
        <>
          <IconButton
            onClick={open}
            variant="clear"
            icon="fas fa-user"
            size="m"
          />
          <AuthModal isOpen={isOpen} onClose={close} />
        </>
      )}
      {authenticated && (
        <Sidebar
          className="UserSidebar"
          Trigger={UserSidebarTrigger}
          offsetY={50}
        >
          <UserBadge />
          <UserMenu />
        </Sidebar>
      )}
    </>
  )
}

UserSidebar.displayName = 'UserSidebar'

export default UserSidebar
