import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { TextField } from '@trileuco/triskel-react-ui/components/ui'
import {
  useForm,
  SubmitButton
} from '@trileuco/triskel-react-ui/components/ui/Form'

const BookingObservationsForm = ({ observations, handleSubmit }) => {
  const defaultValues = useMemo(() => ({ text: observations }), [observations])

  const { Form, values = {} } = useForm({
    defaultValues,
    onSubmit: handleSubmit
  })

  const { msg } = useIntl({
    scope: 'balaena.bookingObservations',
    ignoreGlobalScope: true
  })

  return (
    <Form className="BookingObservationsForm">
      <TextField
        field="text"
        label={msg({ id: 'textareaLabel' })}
        multiLine
        variant="outline"
        rules={['required']}
        helperText={msg({ id: 'helperText' })}
      />
      <SubmitButton
        disabled={values.text === observations}
        text={msg({ id: 'add' })}
      />
    </Form>
  )
}

BookingObservationsForm.propTypes = {
  observations: PropTypes.string,
  handleSubmit: PropTypes.func
}

BookingObservationsForm.displayName = 'BookingObservationsForm'

export default BookingObservationsForm
