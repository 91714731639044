import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  RadioField,
  FieldSet,
  IconButton
} from '@trileuco/triskel-react-ui/components/ui'
import {
  formalizer,
  useFormContext
} from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import AbancaLogo from 'assets/img/misc/abanca_logo.svg'
import CreditCardIcon from 'assets/img/payment/credit_card.svg'
import WalletCardIcon from 'assets/img/payment/gift_card.svg'

import WalletCardFieldset from './WalletCardFieldset'
import PaymentGatewayField from './PaymentGatewayField'

const CreditCardSummary = ({ card, removeCard }) => {
  const { classNames } = useClassNames({
    alias: 'CreditCardSummary'
  })
  if (card) {
    return (
      <div className={classNames()}>
        <div className={classNames('info')}>
          <p>{_.get(card, 'number', '****').replace(/.(?=.{4})/g, '*')}</p>
        </div>
        {removeCard && (
          <IconButton
            icon="fas fa-trash"
            color="danger"
            size="m"
            onClick={() => removeCard()}
          />
        )}
      </div>
    )
  }
  return null
}

CreditCardSummary.propTypes = {
  card: PropTypes.object,
  removeCard: PropTypes.func
}

const CardSummary = ({ paymentMethodIcon, children }) => {
  return (
    <div className="CardSummary">
      <img src={paymentMethodIcon} alt="" className="PaymentMethodIcon" />
      {children}
    </div>
  )
}

CardSummary.propTypes = {
  paymentMethodIcon: PropTypes.string,
  children: PropTypes.node
}

const BookingPaymentMethodFieldSet = formalizer(
  ({ onChange, value, disabled, paymentsConfig, booking, error }) => {
    const { msg } = useIntl({
      scope: 'balaena.bookingPaymentForm.paymentMethods',
      ignoreGlobalScope: true
    })
    const {
      values: { paymentGateway },
      setFieldValue
    } = useFormContext()

    const handleOnChangeMethod = useCallback(
      (value) => {
        if (value === 'gateway') {
          setFieldValue('gateway', paymentsConfig.gateway)
        } else {
          setFieldValue('gateway', null)
        }
        onChange(value)
      },
      [setFieldValue, paymentsConfig.gateway, onChange]
    )

    const removePaymentGateway = useCallback(() => {
      setFieldValue('method', null)
      setFieldValue('paymentGateway', null)
    }, [setFieldValue])

    const handlePaymentTokenChange = useCallback(
      (value) => {
        if (value === null) {
          onChange(null)
        }
      },
      [onChange]
    )

    const handleWalletChange = useCallback(
      (value) => {
        if (value === null) {
          onChange(null)
        }
      },
      [onChange]
    )

    return (
      <FieldSet direction={'column'} className={`RadioFieldSet`}>
        <div className="GatewayPaymentField">
          {error && (
            <span className={'Field_helperText errorText'}>{error}</span>
          )}
          <RadioField
            value={'gateway'}
            label={msg({
              id: 'gateway.label'
            })}
            checked={value === 'gateway'}
            onChange={handleOnChangeMethod}
            helperText={
              paymentsConfig.gateway === 'cecabank'
                ? msg(
                    {
                      id: 'gateway.hint'
                    },
                    {
                      bankLogo: (
                        <img
                          src={AbancaLogo}
                          alt="abanca"
                          className="bankLogo"
                          width="60"
                        />
                      )
                    }
                  )
                : msg({ id: 'card.hint' })
            }
          />
          <CardSummary paymentMethodIcon={CreditCardIcon}>
            <CreditCardSummary
              card={_.get(paymentGateway, 'card')}
              removeCard={removePaymentGateway}
            />
          </CardSummary>
          {value === 'gateway' && (
            <PaymentGatewayField
              field="paymentGateway"
              gatewayConfig={{
                gateway: paymentsConfig.gateway,
                options: paymentsConfig.opts
              }}
              booking={booking}
              onChange={handlePaymentTokenChange}
            />
          )}
        </div>
        {paymentsConfig.walletCardEnabled && (
          <div className="WalletPaymentField">
            <RadioField
              value={'wallet'}
              label={msg({
                id: 'wallet.label'
              })}
              helperText={msg({ id: 'wallet.hint' })}
              disabled={disabled}
              checked={value === 'wallet'}
              onChange={handleOnChangeMethod}
            />
            <CardSummary paymentMethodIcon={WalletCardIcon}>
              {value === 'wallet' && (
                <WalletCardFieldset
                  field="wallet"
                  onChange={handleWalletChange}
                />
              )}
            </CardSummary>
          </div>
        )}
      </FieldSet>
    )
  }
)

BookingPaymentMethodFieldSet.propTypes = {}

BookingPaymentMethodFieldSet.displayName = 'BookingPaymentMethodFieldSet'

export default BookingPaymentMethodFieldSet
