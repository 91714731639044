import React from 'react'
import PropTypes from 'prop-types'
import { PopOver } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

import BookingEventCard from './BookingEventCard'

const percent = (numerator, denominator) =>
  Math.round((numerator / denominator) * 100)

const BookingsPercentBar = ({ color, total, remaining }) => {
  const totalBookings = total - remaining
  const percentagePaid = percent(totalBookings, total)
  const percentageAvailable = percent(remaining, total)
  const { classNames } = useClassNames({
    alias: 'BookingsPercentBar'
  })
  return (
    <div className={classNames({ color })}>
      {totalBookings > 0 && (
        <div
          className={classNames('paid')}
          style={{ width: `${percentagePaid}%` }}
        >
          {totalBookings}
        </div>
      )}
      <div
        className={classNames('available')}
        style={{
          width: `${percentageAvailable}%`
        }}
      >
        {remaining}
      </div>
    </div>
  )
}

BookingsPercentBar.propTypes = {
  remaining: PropTypes.number,
  total: PropTypes.number,
  color: PropTypes.string
}

BookingsPercentBar.displayName = 'BookingsPercentBar'

const BookingEvent = (props) => {
  const { capacity, bookableColor } = props
  return (
    <>
      {capacity.total > 0 && (
        <PopOver
          alias="BookingPopOver"
          content={<BookingEventCard {...props} />}
          triggerEvent="click"
          placement="bottom-start"
          flipVariations="boolean"
        >
          <BookingsPercentBar
            color={bookableColor}
            total={capacity.total}
            remaining={capacity.remaining}
          />
        </PopOver>
      )}
    </>
  )
}

BookingEvent.propTypes = {
  capacity: PropTypes.shape({
    total: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired
  }),
  bookableColor: PropTypes.string
}

BookingEvent.displayName = 'BookingEvent'

export default BookingEvent
