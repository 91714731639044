import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Page from 'components/layout/Page'
import { useGetBooking, LoadingResults } from 'components/api'
import { useTenantConfig } from 'modules/bookables/Provider'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate/BoundedPageTemplate'
import Block from 'components/layout/Block'
import BookingBreadcrumb from 'components/booker/BookingForm/BookingBreadcrumb'
import BookingActions from 'components/booking/BookingActions'
import NotFoundError from 'components/error/NotFoundError'
import SSLBadge from 'components/booker/BookingForm/SSLBadge'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'

import BookingPaymentSummary from './BookingPaymentSummary'
import BookingPaymentForm from './BookingPaymentForm'
import PaymentIconsFooter from './PaymentIconsFooter'

const BookingPaymentPage = ({ match }) => {
  const bookingId = match.params.id
  const { booking, refetch, loading } = useGetBooking({
    bookingId
  })

  const authContext = useAuth()
  const { payments } = useTenantConfig()

  const [payment, setPayment] = useState({
    reservation: false,
    method: null
  })

  const paymentsConfig = useMemo(() => {
    return (
      booking && {
        ...payments,
        ...booking.payment.providerCompanyPaymentConfig
      }
    )
  }, [payments, booking])

  const handlePaymentChange = useCallback((payment) => {
    setPayment(payment)
  }, [])

  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentPage',
    ignoreGlobalScope: true
  })

  const hasAllowedRole = useMemo(() => {
    return authContext.hasSomeRole([
      'balaena-admin',
      'balaena-provider',
      'balaena-bookings-manager'
    ])
  }, [authContext])

  const paymentDisabled = useMemo(() => {
    return paymentsConfig && paymentsConfig.gateway === 'disabled'
  }, [paymentsConfig])

  const hidePaymentActions = useMemo(() => {
    return !hasAllowedRole && paymentDisabled
  }, [paymentDisabled, hasAllowedRole])

  if (loading) {
    return <LoadingResults />
  }
  if (!booking) {
    throw new NotFoundError('Booking not found for id ' + match.params.id)
  }

  return (
    <Page id="BookingPaymentPage" analytics={{ pagetype: 'Booking payment' }}>
      <PublicPageSkeleton
        renderBalaenaToolkitAd={false}
        renderCategoriesFooter={false}
        renderTopFooter={false}
        mainContent={
          <BoundedPageTemplate
            header={<BookingBreadcrumb booking={booking} />}
            mainContent={
              <>
                {!paymentDisabled ? (
                  <BookingPaymentForm
                    refetch={refetch}
                    booking={booking}
                    payment={payment}
                    allowReservation={booking.reservationPaymentEnabled}
                    onChange={handlePaymentChange}
                    paymentsConfig={paymentsConfig}
                  />
                ) : (
                  <div className="BookingPaymentDisabledSummaryWrapper">
                    <Block
                      className="BookingPaymentDisabledSummary"
                      title={msg(
                        { id: 'disabledPayment.title' },
                        { admin: hasAllowedRole ? 1 : 2 }
                      )}
                    >
                      <p>
                        {msg(
                          { id: 'disabledPayment.content' },
                          { admin: hasAllowedRole ? 1 : 2 }
                        )}
                      </p>
                    </Block>
                  </div>
                )}
              </>
            }
            sidebar={
              <div className="BookingSummaryWrapper">
                <Block
                  className="BookingSummary"
                  title={msg({ id: 'summaryBlockTitle' })}
                >
                  <BookingPaymentSummary booking={booking} />
                  {!hidePaymentActions && (
                    <BookingActions
                      booking={booking}
                      refetch={refetch}
                      payment={payment}
                      hidePayButton={paymentDisabled}
                    />
                  )}
                </Block>
                {!paymentDisabled && <SSLBadge />}
              </div>
            }
          />
        }
        footerAssets={
          !paymentDisabled && (
            <PaymentIconsFooter gateway={paymentsConfig.gateway} />
          )
        }
      />
    </Page>
  )
}

BookingPaymentPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

BookingPaymentPage.displayName = 'BookingPaymentPage'

export default BookingPaymentPage
