import moment from 'moment'

export const isValidCVV = (cvv) => cvv && cvv.length === 3
export const isValidNumber = (number) => number && number.length === 16
export const isValidName = (name) => Boolean(name)
export const isValidMonth = (month) => Boolean(month)
export const isValidYear = (year) => Boolean(year)

export const expirationMonthOptions = Array.apply(0, Array(12)).map((_, i) => {
  const monthIndex = i + 1
  return {
    label: monthIndex.toString().padStart(2, '0'),
    value: monthIndex
  }
})
export const expirationYearOptions = Array.apply(0, Array(6)).map((_, i) => ({
  label: moment().year() + i,
  value: moment().year() + i
}))

export const isValidCard = ({
  holder,
  cvv,
  number,
  expirationMonth,
  expirationYear
}) =>
  isValidCVV(cvv) &&
  isValidNumber(number) &&
  isValidName(holder) &&
  isValidMonth(expirationMonth) &&
  isValidYear(expirationYear)
