import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

import BookingDetailsSummaryBlock from './BookingDetailsSummaryBlock'

const BookingLocationBlock = ({ bookable }) => {
  const { msg } = useIntl({
    scope: 'bookingLocationBlock'
  })
  return (
    <BookingDetailsSummaryBlock title={msg({ id: 'title' })}>
      <div>
        <p>{bookable.location.description}</p>
      </div>
    </BookingDetailsSummaryBlock>
  )
}

BookingLocationBlock.propTypes = {
  bookable: PropTypes.shape()
}

BookingLocationBlock.displayName = 'BookingLocationBlock'

export default BookingLocationBlock
