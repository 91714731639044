import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import _, { noop } from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { FieldWrapper } from '@trileuco/triskel-react-ui/components/ui'
import { formalizer } from '@trileuco/triskel-react-ui/components/ui/Form'

import TagFilter from './TagFilter'

const TagInput = ({ options, label = '', direction, value, onChange }) => {
  const { classNames } = useClassNames({ alias: 'TagInput' })

  const isSelected = useCallback((option) => value.includes(option.value), [
    value
  ])

  const toggleOption = useCallback(
    (option) => {
      if (isSelected(option)) {
        onChange(value.filter((value) => value !== option.value))
      } else {
        onChange([...value, option.value])
      }
    },
    [isSelected, value, onChange]
  )

  return (
    <div className={classNames()}>
      {_.sortBy(options, ['label']).map((option, index) => {
        const { label, count } = option
        return (
          <TagFilter
            key={index}
            variant="outline"
            active={isSelected(option, options)}
            name={label}
            count={count}
            onClick={() => toggleOption(option)}
          />
        )
      })}
    </div>
  )
}

TagInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  direction: PropTypes.oneOf(['column', 'row']),
  allSelected: PropTypes.func,
  onChange: PropTypes.func,
  changeWithClick: PropTypes.bool,
  allowSelectAll: PropTypes.bool
}

TagInput.defaultProps = {
  allSelected: (value, options) =>
    value && options && value.length === options.length,
  onChange: noop,
  direction: 'row'
}

const TagField = (props) => {
  const { classNames } = useClassNames({ alias: 'TagField' })
  return <FieldWrapper {...props} className={classNames()} Field={TagInput} />
}

TagField.propTypes = {}

TagField.displayName = 'TagField'

export default formalizer(TagField)
