import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { LoadingResults, useGetBookables } from 'components/api'
import useGetProvider from 'components/api/providers/useGetProvider'
import NotFoundError from 'components/error/NotFoundError'
import Page from 'components/layout/Page'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import Breadcrumb from 'components/layout/Breadcrumb'
import Block from 'components/layout/Block'
import BookablesList from 'components/bookable/BookablesList/BookablesList'
import HeaderSection from 'components/cms/HeaderSection'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { providersPath } from 'modules/bookables/routes'
import SafeHtml from 'components/cms/SafeHtml'
import { useTenantConfig } from 'modules/bookables/Provider'
import PhotoGalleryBlock from 'components/misc/PhotoGalleryBlock'
import SearchPanel from 'components/layout/nav/DesktopNavBar/SearchPanel'
import SearchModal from 'components/layout/nav/TouchNavBar/SearchModal'
import { DesktopView, MobileView } from 'components/layout/Mediaqueries'

import ProviderInfo from './ProviderInfo'

const ProviderPage = ({
  match,
  renderFooter,
  renderNavBar,
  renderBreadcrumb
}) => {
  let header = null
  let mainContent = null
  const { slug } = match.params
  const { msg } = useIntl({
    scope: 'balaena.providersPage',
    ignoreGlobalScope: true
  })
  const { classNames: headerClassNames } = useClassNames({
    alias: 'ProviderHeader'
  })

  const { classNames } = useClassNames({
    alias: 'ProviderContent'
  })
  const [state, setState] = useState({ tabIndex: 0 })

  const { provider, loading } = useGetProvider({ id: slug })
  const { loading: loadingBookables, bookables } = useGetBookables({
    queryParams: {
      providerIds: slug
    }
  })
  const {
    components: {
      galleryInProviders = true,
      renderAvailabilityFilterInProvidersPage = false
    } = {}
  } = useTenantConfig()

  const searchProps = useMemo(() => {
    return {
      enabledSearchFilters: ['date'],
      providerId: provider && parseInt(provider.id)
    }
  }, [provider])

  if (loading || loadingBookables) {
    mainContent = <LoadingResults />
  } else if (!provider) {
    throw new NotFoundError('Provider not found for slug ' + slug)
  } else {
    header = (
      <HeaderSection
        className={headerClassNames()}
        variant={galleryInProviders ? 'clear' : 'hero'}
        size={!galleryInProviders && 's'}
        photos={provider.photos}
        breadcrumb={
          renderBreadcrumb && (
            <Breadcrumb
              links={[
                { name: msg({ id: 'providers' }), to: providersPath },
                { name: provider.name, isNotLink: true }
              ]}
            />
          )
        }
        logo={provider.logo}
        logoSvg={provider.logoSvg}
        title={{ text: provider.name }}
        content={
          <>
            {_.get(provider, 'descriptions.short') && (
              <SafeHtml
                html={provider.descriptions.short}
                className={headerClassNames('shortDescription')}
              />
            )}
          </>
        }
      />
    )
    mainContent = (
      <BoundedPageTemplate
        header={
          <>
            {galleryInProviders && !_.isEmpty(provider.photos) && (
              <PhotoGalleryBlock photos={provider.photos} />
            )}

            {renderAvailabilityFilterInProvidersPage && (
              <div className={headerClassNames('searchBar')}>
                <DesktopView>
                  <SearchPanel {...searchProps} />
                </DesktopView>

                <MobileView>
                  <SearchModal {...searchProps} />
                </MobileView>
              </div>
            )}
          </>
        }
        mainContent={
          <Tabs
            selectedIndex={state.tabIndex}
            onSelect={(tabIndex) => setState({ tabIndex })}
            selectedTabClassName={classNames('tab', { current: true })}
          >
            <TabList className={classNames('tabs')}>
              {bookables.length > 0 && (
                <Tab className={classNames('tab')}>
                  {msg({ id: 'products' })} ({bookables.length})
                </Tab>
              )}
              <Tab className={classNames('tab')}>
                {msg({ id: 'information' })}
              </Tab>
            </TabList>
            {bookables.length > 0 && (
              <TabPanel>
                <Block variant="clear">
                  <BookablesList bookables={bookables} />
                </Block>
              </TabPanel>
            )}
            <TabPanel>
              <ProviderInfo provider={provider} />
            </TabPanel>
          </Tabs>
        }
      />
    )
  }

  return (
    <Page id="ProviderPage">
      <PublicPageSkeleton
        headerAssets={header}
        mainContent={mainContent}
        renderFooter={renderFooter}
        renderNavBar={renderNavBar}
      />
    </Page>
  )
}

ProviderPage.propTypes = {
  match: PropTypes.object,
  renderFooter: PropTypes.bool,
  renderNavBar: PropTypes.bool,
  renderBreadcrumb: PropTypes.bool
}

ProviderPage.defaultProps = {
  renderFooter: true,
  renderNavBar: true,
  renderBreadcrumb: true
}

ProviderPage.displayName = 'ProviderPage'

export default ProviderPage
