import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Page from 'components/layout/Page'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  useQueryParams,
  QueryParamTypes
} from '@trileuco/triskel-react-ui/components/hooks/useQueryParams'
import {
  useGetBookables,
  useGetBookableAvailabilities,
  useGetProviders,
  LoadingResults
} from 'components/api'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import Block from 'components/layout/Block'
import ProviderBookingsTable from 'components/bookableProvider/ProviderBookingsTable/ProviderBookingsTable'
import { generateProviderBookings } from 'modules/bookables/routes'

const BookableProviderBookingsPage = ({ history, location, match }) => {
  const { providerId } = match.params
  const {
    page = 1,
    pageSize = 10,
    from = moment().startOf('week').toDate(),
    to = moment().endOf('week').toDate()
  } = useQueryParams({
    bookableIds: QueryParamTypes.Array,
    page: QueryParamTypes.Int,
    pageSize: QueryParamTypes.Int,
    from: QueryParamTypes.Date,
    to: QueryParamTypes.Date
  })

  const { loading, bookables } = useGetBookables({
    lazy: !providerId,
    queryParams: {
      providerIds: providerId
    }
  })

  const {
    loading: loadingBookings,
    availabilities
  } = useGetBookableAvailabilities({
    lazy: !bookables || !providerId,
    page,
    pageSize,
    ids: (bookables || []).map(({ id }) => id),
    from,
    to
  })

  const authContext = useAuth()

  const { providers, loading: loadingProviders } = useGetProviders({
    queryParams: {
      onlyMine:
        authContext.hasSomeRole(['balaena-provider']) &&
        !authContext.hasSomeRole(['balaena-admin'])
    }
  })

  const { msg } = useIntl({ scope: 'bookingsPage' })

  const handleChangeProvider = useCallback(
    (id) => {
      history.push(generateProviderBookings({ providerId: id }))
    },
    [history]
  )

  useEffect(() => {
    if (!loadingProviders && providers.length === 1) {
      history.push(generateProviderBookings({ providerId: providers[0].id }))
    }
  }, [providers, history, loadingProviders, providerId])

  const handleChangeNextWeek = useCallback(() => {
    history.push(
      generateProviderBookings({
        providerId,
        searchParams: {
          from: moment(from).add(1, 'weeks').startOf('week').format(),
          to: moment(to).add(1, 'weeks').endOf('week').format()
        }
      })
    )
  }, [providerId, history, from, to])

  const handleChangePreviousWeek = useCallback(() => {
    history.push(
      generateProviderBookings({
        providerId,
        searchParams: {
          from: moment(from).add(-1, 'weeks').startOf('week').format(),
          to: moment(to).add(-1, 'weeks').endOf('week').format()
        }
      })
    )
  }, [providerId, history, from, to])

  const handleSelectWeek = useCallback(
    (dateRange) => {
      history.push(
        generateProviderBookings({
          providerId,
          searchParams: {
            from: dateRange.from,
            to: dateRange.to
          }
        })
      )
    },
    [providerId, history]
  )

  const bookings = React.useMemo(() => {
    if (!bookables || !availabilities) {
      return []
    }
    return bookables.map((bookable) => {
      const availability = availabilities.find(
        (availability) => availability.bookableId === bookable.id
      )
      if (!availability) {
        return null
      }
      const schedules = availability.schedules
      return {
        bookable: bookable,
        schedules: schedules.reduce((mapByDay, schedule) => {
          const { dateTime } = schedule
          const formatedDate = moment(dateTime).format(moment.HTML5_FMT.DATE)
          const formatedTime = moment(dateTime).format('HH:mm')
          const schedulesByDay = mapByDay[formatedDate] || {}
          const schedulesByTime = schedulesByDay[formatedTime] || []
          return {
            ...mapByDay,
            [formatedDate]: {
              ...schedulesByDay,
              [formatedTime]: [...schedulesByTime, schedule]
            }
          }
        }, {}),
        daysOfWeek: {
          ...Array.from(Array(7).keys())
            .map((dayOfWeed) => {
              return schedules.filter(
                ({ dateTime }) =>
                  moment(dateTime).isoWeekday() === dayOfWeed + 1
              )
            })
            .reduce(
              (map, value, index) => ({
                ...map,
                [index + 1]: value
              }),
              {}
            )
        },
        availableTimes: schedules
          .reduce((availableTimes, { dateTime }) => {
            const availableTime = moment(dateTime).format('HH:mm')
            const weeklyBookings = Array.from(Array(7).keys())
              .map((dayOfWeek) => {
                return schedules.filter(({ dateTime }) => {
                  const timeOfBooking = moment(dateTime).format('HH:mm')
                  const dayOfWeekOfBooking = moment(dateTime).isoWeekday()
                  return (
                    dayOfWeekOfBooking === dayOfWeek + 1 &&
                    timeOfBooking === availableTime
                  )
                })
              })
              .reduce(
                (map, schedule, index) => ({
                  ...map,
                  [index + 1]: schedule
                }),
                {}
              )
            if (
              availableTimes.findIndex(
                (row) => row.availableTime === availableTime
              ) !== -1
            ) {
              return availableTimes
            }
            return [...availableTimes, { availableTime, weeklyBookings }]
          }, [])
          .sort(
            (a, b) =>
              moment(a.availableTime, 'HH:mm').toDate() -
              moment(b.availableTime, 'HH:mm').toDate()
          )
      }
    })
  }, [bookables, availabilities])

  return (
    <Page id="ProviderBookingsPage">
      <PublicPageSkeleton
        mainContent={
          <Block
            title={msg({ id: 'pageTitle' })}
            variant="clear"
            className="Bookings"
          >
            {(loading || loadingBookings || loadingProviders) && (
              <LoadingResults />
            )}
            {bookings && (
              <ProviderBookingsTable
                providers={providers}
                to={to}
                from={from}
                providerId={parseInt(providerId, 10)}
                bookings={bookings}
                onChangeProvider={handleChangeProvider}
                onNextWeek={handleChangeNextWeek}
                onPreviousWeek={handleChangePreviousWeek}
                onSelectWeek={handleSelectWeek}
              />
            )}
          </Block>
        }
      />
    </Page>
  )
}

BookableProviderBookingsPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
}

BookableProviderBookingsPage.displayName = 'BookableProviderBookingsPage'

export default BookableProviderBookingsPage
