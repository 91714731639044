import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import _ from 'lodash'

const BookingPaymentStatus = ({ booking, showTitle }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingPaymentStatus',
    ignoreGlobalScope: true
  })
  const status =
    booking.status === 'reserved'
      ? 'reserved'
      : _.get(booking.payment, 'status')
  return (
    <>
      {showTitle && (
        <span className="label">{msg({ id: 'paymentStatus' })}</span>
      )}
      <span className={`paymentStatus ${status}`}>
        {(() => {
          switch (status) {
            case 'pending':
              return msg({ id: 'pending' })
            case 'invited':
              return msg({ id: 'invited' })
            case 'paid':
              return booking.payment.adminPaymentMethod || msg({ id: 'paid' })
            case 'rejected':
              return msg({ id: 'rejected' })
            case 'canceled':
              return msg({ id: 'canceled' })
            case 'reserved':
              return msg({ id: 'reserved' })
            default:
              return msg({ id: 'unknownStatus' }) + ' : ' + status
          }
        })()}
      </span>
    </>
  )
}

BookingPaymentStatus.propTypes = {
  booking: PropTypes.shape(),
  showTitle: PropTypes.bool
}

BookingPaymentStatus.defaultProps = {
  showTitle: true
}

BookingPaymentStatus.displayName = 'BookingPaymentStatus'

export default BookingPaymentStatus
