import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Link } from '@trileuco/triskel-react-ui/components/ui'
import { generateBookingPaymentPath } from 'modules/bookables/routes'

const PendingBookingBanner = ({ loading, booking }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingDetailsPage.pendingBookingBanner',
    ignoreGlobalScope: true
  })
  return (
    <>
      {!loading && (
        <div className="PendingBookingBanner">
          <h2>
            {msg({
              id: 'title'
            })}
          </h2>
          <p>{msg({ id: 'text' })}</p>
          <Link to={generateBookingPaymentPath({ id: booking.id })}>
            <i className="fas fa-external-link-alt"></i>
            {msg({ id: 'link' })}
          </Link>
        </div>
      )}
    </>
  )
}

PendingBookingBanner.propTypes = {
  loading: PropTypes.bool,
  booking: PropTypes.shape()
}

PendingBookingBanner.displayName = 'PendingBookingBanner'

export default PendingBookingBanner
