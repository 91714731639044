import { useFetchCache } from 'components/api/ApiCacheContext'

const useGetArticleTags = (options = {}) => {
  const { tags, ...rest } = useFetchCache({
    path: `/articles/tags`,
    entity: 'tags',
    ...options
  })
  return {
    ...rest,
    tags: tags
  }
}

export default useGetArticleTags
