import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  FieldSet,
  TextField
} from '@trileuco/triskel-react-ui/components/ui'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useMediaQuery } from 'react-responsive'
import { DesktopBreakpoint } from 'components/layout/Mediaqueries'
import { withAuth } from '@trileuco/triskel-react-ui/modules/auth'
import Visible from '@trileuco/triskel-react-ui/components/helpers/Visible'

const BookerField = (props) => {
  return <TextField type="search" variant="outline" {...props} />
}

BookerField.displayName = 'BookerField'

const BookersTableFilter = ({ filter, onFilterChange, authContext }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookersTable',
    ignoreGlobalScope: true
  })

  const debouncedSubmit = (value, instance) => {
    instance.debounce(instance.handleSubmit, 2000)
  }

  const isDesktop = useMediaQuery(DesktopBreakpoint)

  return (
    <Form
      className="BookersFilter"
      defaultValues={filter}
      onSubmit={onFilterChange}
    >
      <FieldSet direction={isDesktop ? 'row' : 'column'}>
        <Visible
          condition={authContext.hasSomeRole([
            'balaena-admin',
            'balaena-provider'
          ])}
        >
          <BookerField
            label={msg({ id: 'bookersFilter' })}
            onChange={debouncedSubmit}
            field={'bookerFilter'}
          />
        </Visible>
      </FieldSet>
    </Form>
  )
}

BookersTableFilter.propTypes = {
  filter: PropTypes.shape({
    bookerFilter: PropTypes.string
  }),
  onFilterChange: PropTypes.func,
  authContext: PropTypes.object
}

BookersTableFilter.displayName = 'BookersTableFilter'

export default withAuth(BookersTableFilter)
