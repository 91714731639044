import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth/useAuth'
import { useTenantConfig } from 'modules/bookables/Provider'
import * as queryString from 'query-string'
import { bookingsReportPath } from 'modules/bookables/routes'

const BookingsReport = (props) => {
  const {
    bookerId,
    bookableId,
    status,
    guestFilter,
    state,
    from,
    to,
    createdTo,
    createdFrom
  } = props

  const { msg } = useIntl({ scope: 'bookingsReport' })

  const {
    api: { baseUrl }
  } = useTenantConfig()

  const {
    keycloak: { token: accessToken }
  } = useAuth()

  const reportParams = queryString.stringify({
    bookerId,
    bookableId,
    status,
    guestFilter,
    state,
    from,
    to,
    createdFrom,
    createdTo,
    'access-token': accessToken
  })
  return (
    <a download href={`${baseUrl}/${bookingsReportPath}?${reportParams}`}>
      {msg({ id: 'generate' })}
    </a>
  )
}
BookingsReport.propTypes = {
  bookerId: PropTypes.string,
  bookableId: PropTypes.string,
  status: PropTypes.string,
  guestFilter: PropTypes.string,
  state: PropTypes.string,
  to: PropTypes.object,
  from: PropTypes.object,
  createdTo: PropTypes.object,
  createdFrom: PropTypes.object
}

BookingsReport.displayName = 'BookingsReport'
export default BookingsReport
