import { useFetchCache } from 'components/api/ApiCacheContext'

const useGetProviders = (options = {}) => {
  const { providers, ...rest } = useFetchCache({
    path: `/providers`,
    entity: 'providers',
    ...options
  })
  return {
    ...rest,
    providers: providers || []
  }
}

export default useGetProviders
